import CropFreeIcon from '@mui/icons-material/CropFree';

export const spCountingStrategyDetailsConfig = {
    objects: {
        title: 'counting_objects',
        icon: <CropFreeIcon/>,
    },
    // logs: {
    //     title: 'logs',
    //     icon: <CropFreeIcon/>,
    // }
};
