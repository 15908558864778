import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useDeleteInvoiceProductMutation, useUpdateInvoiceProductMutation } from "../../../../../newapi/financial/invoiceProductSlice";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired } from "../../../../../utils/validation";
import { useGetEnumsQuery } from "../../../../../newapi/global/generalApiSlice";
import EMGeneralExplainerBox from "../../../ModalsPopover/explainers/EMGeneralExplainerBox";

import { useTranslation } from "react-i18next";
import { showToastMessageDeleteRequest, showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            {/* <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton> */}
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [invoiceProductInfo, setInvoiceProductInfo] = useState({
      id: 0,
      name: "",
      type: "",
      subType: "",
      ledger: "",
      invoiceline: "",
    });

    const [open, setOpen] = useState(false);
    const { data: globalEnums, enumIsLoading } = useGetEnumsQuery("invoiceproduct_type,invoiceproduct_subtype");
    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        type: yupRequired('type', t),
        subType: yupRequired('sub_type', t),
        invoiceline: yupRequired('invoiceline', t)
    };
    
    const schema = yup.object().shape(rules);
    
    const [EditInvoiceProduct,{isLoading}] = useUpdateInvoiceProductMutation();
    const handleSaveClick = async () => {
        try {
            setFieldErrors({});
            await schema.validate(invoiceProductInfo, { abortEarly: false });
            const response = await EditInvoiceProduct(invoiceProductInfo).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageUpdateRequest(t, {success: false, error: err.error});
              }
        }
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions 
                id={invoiceProductInfo?.id}
                action={handleSaveClick}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[handleSaveClick,invoiceProductInfo]);

    useEffect(() => {
        setInvoiceProductInfo(props.data);
    },[props.data]);

    const handleChange = (key, value) => {
        setInvoiceProductInfo({
          ...invoiceProductInfo,
          [key]: value
        });
      };

      const [DeleteInvoiceProductMutation] = useDeleteInvoiceProductMutation();
      const confirmDelete = async () => {
        try {
            console.log(props.data);
            const response = await DeleteInvoiceProductMutation(props.data.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };
    const handleDeleteClick = async () => {
        setOpen(true);
    };

    const confirmDeleteModal = {
        data: invoiceProductInfo?.name,
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
        <Dropdown title={t("general")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("name")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="name"
                                value={invoiceProductInfo?.name}
                                onChange={handleChange}
                                error={fieldErrors.name}
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text>{t("invoiceline")}</Text>
                            <EMGeneralExplainerBox title={t('em_invoiceproductinvoiceline_title')} text={t('em_invoiceproductinvoiceline_text')}/>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="invoiceline"
                                value={invoiceProductInfo?.invoiceline}
                                onChange={handleChange}
                                error={fieldErrors.invoiceline}
                            />
                        </Box>
                    </Box>
                    
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Box lineHeight={"40px"} width={"104px"}>
                                <Text>{t("type")}</Text>
                            </Box>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInputDropdown
                                name="type"
                                displayName="displayName"
                                changeField="name"
                                selected={globalEnums ? globalEnums["invoiceproduct.type"].findIndex(item => item === invoiceProductInfo?.type) ?? -1 : -1}
                                options={globalEnums ? globalEnums["invoiceproduct.type"].map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
                                noSelection={t("choose_option")} 
                                onChange={handleChange}
                                error={fieldErrors.type}
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Box lineHeight={"40px"} width={"104px"}>
                                <Text>{t("sub_type")}</Text>
                            </Box>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInputDropdown
                                name="subType"
                                displayName="displayName"
                                changeField="name"
                                selected={globalEnums ? globalEnums["invoiceproduct.subtype"].findIndex(item => item === invoiceProductInfo?.subType) ?? -1 : -1}
                                options={globalEnums ? globalEnums["invoiceproduct.subtype"].map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
                                noSelection={t("choose_option")} 
                                onChange={handleChange}
                                error={fieldErrors.subType}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Box lineHeight={"40px"} width={"104px"}>
                                <Text>{t("ledger")}</Text>
                            </Box>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            
                            <ShValidatedInput
                                height={40}
                                name="invoiceline"
                                value={invoiceProductInfo?.ledger}
                                onChange={handleChange}
                                error={fieldErrors.ledger}
                            />
                        </Box>
                    </Box>
                    
                    
                </Box>
            </Box>
        </Dropdown>
        <Box
            sx={{
                position:"absolute",
                bottom:0,
                right:0,
                padding:3,
                width:1,
                background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                    padding:2,
                    paddingY:2.5,
                }
            }}
        >
        <GridActions
            id={invoiceProductInfo?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
        />
            </Box>
            <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
        </Box>
    );
}

export default DetailsPanel;