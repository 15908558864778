import React from 'react';
import {Box, IconButton, useTheme} from "@mui/material";
import { useContext } from 'react';
import { tokens } from '../../../theme';
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import Icon from '../icons/Icon';
const CloseOverlayBtn = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const btnTheme = props.theme ?? "close-overlay"
    return (
        <Icon theme={btnTheme}>
            <CloseIcon/>
        </Icon>
    )
}

export default CloseOverlayBtn;