import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";
import ShLabel from "../../../../theme/label/ShLabel";
import Text from "../../../../theme/text/Text";

import { useTranslation } from "react-i18next";
import SpStockDetails from "../../../../../components/global/Sidepanels/SpStockDetails/SPStockDetails";
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import ShLabelSkeleton from '../../../../theme/skeleton/ShLabelSkeleton';
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';
import { useGetCountingStrategyStepsQuery } from '../../../../../newapi/countingstrategy/countingStrategySlice';
import SpLocationDetails from '../../SpLocationDetails/SpLocationDetails';
import { WarehouseCountingStrategyObjectsCard } from "../../../../theme/cards/other/CountingStrategiesCard";

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return <></>
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    
    filter : {
        name: "productsFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Objects = (props) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.productId);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    const [locationIsExtended, setLocationIsExtended] = useState(false);

    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);
    
    const getLocationDetails = (params) => {
        if (!params) return;
    
        const newLocationId = parseInt(params.row.warehouseLocationId);
        // Only update state if the productId has actually changed
        if (locationIdRef.current !== newLocationId) {
            setLocationId(newLocationId);
            locationIdRef.current = newLocationId; // Update the ref to the new value
        }
        setLocationIsExtended(true);
    };

    const handleRowClick = (params) => {
        if(params.row.productId && params.row.productId > 0){
            getProductDetails(params);
        } else if(params.row.warehouseLocationId && params.row.warehouseLocationId > 0){
            getLocationDetails(params);
        }
    }

    // Set countingobject description header name
    const locationTypes = ["picking_locations", "bulk_locations", "all_locations"];
    const descriptionHeaderName = props.strategyType && locationTypes.includes(props.strategyType) ? t("location") : t("product");

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    const columns = [
        {
            field: "description", 
            headerName: descriptionHeaderName, 
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                const description = params.row.warehouseLocation ? params.row.warehouseLocation.location : params.row.product.sku;

                return description;
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                let status = params.row.state;
                let labelcolor = "";
                let labeltext = "";
                switch(status){
                    case "active":
                        labelcolor = theme.palette.mode === "dark" ? "blue" : "primary"
                        labeltext = t('active');
                        break;
                    case "completed":
                        labelcolor = "green"
                        labeltext = t('completed');
                        break;
                    case "deleted":
                        labelcolor = "red"
                        labeltext = t('deleted');
                        break;
                    default:
                        labelcolor = "orange"
                        labeltext = t('unknown');
                        break;
                }
              
                return (
                    <ShLabel fitted size={32} palette="normal" variant={labelcolor}>
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
        {
            field: "createdAt", 
            headerName: t("created_at"), 
            flex: 1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
                }

                let date = new Date(params.row.createdAt);
            
                return (
                    <Text>
                        {date.toLocaleDateString(i18n.language)}
                    </Text>
                );
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
                return (
                    
                    <WarehouseCountingStrategyObjectsCard
                        onClick={() => handleRowClick(cellValues)}
                        skeleton={cellValues.row.skeleton}
                        {...cellValues.row}
                    />
                )
            }
        }
    ];
    // WarehouseCountingStrategyObjectsCard

    return (
        props.strategyId ? (
          <>
            <Box height={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
                {props.strategyId && 
                    <ShDataGrid2 key={theme.palette.mode}
                        itemId={props.strategyId}
                        content={useGetCountingStrategyStepsQuery}
                        // gridActions={<GridActions></GridActions>}
                        title={t("counting_objects")}
                        gridOptions={gridOptions}
                        isSidepanelGrid
                        columns={columns}
                        onRowClick={handleRowClick}
                    />
                }
            </Box>
            <SpStockDetails
              isExtended={isExtended}
              setIsExtended={setIsExtended}
              productId={productId}
            />
            <SpLocationDetails
              isExtended={locationIsExtended}
              setIsExtended={setLocationIsExtended}
              locationId={locationId}
            />
          </>
        ) : null
      );
      
}

export default Objects;