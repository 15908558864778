import { Box, IconButton, useTheme } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import { tokens } from "../../../../theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { tokens } from "../../../../../../../theme";
import Text from "../../../../../../../components/theme/text/Text";
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import { useTranslation } from "react-i18next";
import Subheading from "../../../../../../../components/theme/text/Subheading";
import NumberInput from "../../../../../../../components/theme/inputs/NumberInput";
import ShSwitch from "../../../../../../../components/theme/inputs/ShSwitch";
import { useUpdateSettingMutation } from "../../../../../../../newapi/global/generalApiSlice";
import BoolSetting from "../../../../../../../components/global/Settings/BoolSetting";
import NumberSetting from "../../../../../../../components/global/Settings/NumberSetting";

const WareHouseDropdown = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        height: 40,
        background:
          theme.palette.mode === "dark"
            ? colors.grey["400"]
            : colors.txt["primary"],
        borderRadius: 5,
        paddingX: 2,
        paddingRight: 1,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        color: "#fff",
      }}
    >
      <Box>
        <Text bold variant="blue">
          All
        </Text>
        &nbsp;<Text variant="white">Warehouses</Text>
      </Box>
      <KeyboardArrowDownIcon />
    </Box>
  );
};

const OrderSizing = ({ settings = [], ...props }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    props.replaceView({
        ...props.config,
        topRight: <WareHouseDropdown />,
    });

    return () => {
        // console.log("unmounting warehouse");
    }
  },[]);

  return (
    <Box
      sx={{
        height: 1,
        "@media screen and (max-width: 47.9375em)": {
          paddingTop: 0,
          paddingX: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
          gap: 4,
          overflow: "hidden",
          background:
            theme.palette.mode === "dark"
              ? colors.grey[300]
              : colors.bg["tertiary"],
          paddingX: 2,
          paddingTop: 4,
          height: 1,
          position: "relative",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          "@media screen and (max-width: 47.9375em)": {
            paddingTop: 0,
            paddingX: 0,
            background: colors.bg["tertiary"],
          },
          "& .carrier": {
            paddingLeft: 0,
            marginRight: 2,
          },
          "& [aria-label='Carrier']": {
            // background:"purple",
            paddingLeft: 0,
            marginRight: 2,
          },
        }}
      >
        <Box
          sx={{
            paddingX: 3,
            "@media screen and (max-width: 47.9375em)": {
              display: "none",
            },
          }}
        >
          <Subheading>{t("streams_ordersizing_heading")}</Subheading>
          <Text light>{t("streams_ordersizing_subtitle")}</Text>
        </Box>
        {/* <GridActions></GridActions> */}
        <Box
          sx={{
            background:
              theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
            flex: 1,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            "@media screen and (max-width: 48em)": {
              // display:"none"
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[200]
                  : colors.grey[0],
            },
          }}
        >
        <Box
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[400]
                  : colors.grey[0],
              height: 24,
              padding: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap", // Allow items to wrap onto multiple lines
              }}
            >
              <Box
                sx={{
                  width: 300, // Set width to 300px
                  marginRight: 10, // Add some margin to the right for spacing
                  marginBottom: 2, // Add some margin to the bottom for spacing
                }}
              >
                <Text bold>{t("streams_ordersizing_s_title")}</Text>
                <NumberSetting
                  settings={settings}
                  skey="streams_ordersizing_s_length"
                  title={false}
                />
                <NumberSetting
                  settings={settings}
                  skey="streams_ordersizing_s_width"
                  title={false}
                />
                <NumberSetting
                  settings={settings}
                  skey="streams_ordersizing_s_height"
                  title={false}
                />
              </Box>
              <Box
                sx={{
                  width: 300, // Set width to 300px
                  marginRight: 10, // Add some margin to the right for spacing
                  marginBottom: 2, // Add some margin to the bottom for spacing
                }}
              >
                <Text bold>{t("streams_ordersizing_m_title")}</Text>
                <NumberSetting
                  settings={settings}
                  skey="streams_ordersizing_m_length"
                  title={false}
                />
                <NumberSetting
                  settings={settings}
                  skey="streams_ordersizing_m_width"
                  title={false}
                />
                <NumberSetting
                  settings={settings}
                  skey="streams_ordersizing_m_height"
                  title={false}
                />
              </Box>
              <Box
                sx={{
                  width: 300, // Set width to 300px
                  marginRight: 10, // Add some margin to the right for spacing
                  marginBottom: 2, // Add some margin to the bottom for spacing
                }}
              >
                <Text bold>{t("streams_ordersizing_lm_title")}</Text>
                <NumberSetting
                  settings={settings}
                  skey="streams_ordersizing_lm_length"
                  title={false}
                />
                <NumberSetting
                  settings={settings}
                  skey="streams_ordersizing_lm_width"
                  title={false}
                />
                <NumberSetting
                  settings={settings}
                  skey="streams_ordersizing_lm_height"
                  title={false}
                />
              </Box>
              </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSizing;
