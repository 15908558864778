import { Box, List, useTheme, InputBase } from "@mui/material"
import SHModal from "../../theme/Modal/SHModal"
import Shbutton from "../../theme/buttons/Shbutton"
import { tokens } from "../../../theme";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import NumberInput from "../../theme/inputs/NumberInput";
import SelectGroup from "../../theme/Select/SelectGroup";
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";

const MConfirmationWithValue = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();


    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            confirm();
        }
    };

    const confirm = () => {
        // Handle empty number inputs
        if (props.data.inputType && props.data.inputType === "number" && (isNaN(props.data.value) || props.data.value === "")) {
            props.data.value = 0;
        }

        props.confirm(props.data.value);
        props.handleClose();
    }

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
            onKeyDown={handleKeyDown}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{props.data.title}</Subheading>
                <Text center light>{props.data.subtitle}</Text>
            </Box>
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    textAlign:"center",
                }}
            >
                <Text center>{props.data.content}</Text>
            </Box>
            {props.data.hasInput && (
            <Box sx={{ paddingLeft: 3,
                paddingRight: 3,
                background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
            }}>
                <Box
                >
                    <Text light>{t(props.data.inputLabel ?? "unknown")}</Text>
                </Box>
                
                {props.data.inputType === "text" && (
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <InputBase 
                        sx={{
                            px:2,
                            flex:1, 
                            color:colors.txt["primary"],
                            lineHeight:"32px",
                        }}
                        value={props.data.value}
                        onChange={(e) => props.data.value = e.target.value}
                    />
                </Box>
                )}
                
                {props.data.hasInput && props.data.inputType === "number" && (
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <NumberInput btnSize={"24"}
                        autoFocus={true}
                        value={props.data.value}
                        change={(e) => props.data.value = e}/>
                </Box>
                )}
            </Box>
            )}
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} onClick={confirm} variant="contained">{t("confirm")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MConfirmationWithValue;