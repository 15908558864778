import { Box, IconButton, InputBase, Switch, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";

import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
// import Textarea from 'muicss/lib/react/textarea';
import { toast } from 'react-toastify';
import { useUpdateWarehouseLocationMutation, useDeleteWarehouseLocationMutation } from "../../../../../newapi/warehouse/warehouselocationSlice";
// import { updateWarehouseLocation } from "../../../../../api/warehouse/warehouselocation";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";
import { showToastMessageDeleteRequest } from "../../../../../utils/toasts";
import SpDesktopFooter from "../../../../theme/sidepanel2/SidepanelFooter";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    

    
    // const handleButtonClick = () => {
    //     const location = { active: props.active, 
    //         blocked: props.blocked, 
    //         carrier: props.carrier, 
    //         enableLinkingWhenBlocked: props.enableLinkingWhenBlocked, 
    //         quarantine: props.quarantine,
    //         remark: props.remark};
    //         console.log(location);

    //     // updateWarehouseLocation(location, props.id, (result, data) => {
    //     //     if (result) {
    //     //         toast.success(t(data.message), {
    //     //             position: toast.POSITION.TOP_RIGHT
    //     //           });
    //     //     }
    //     // });
    // };

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.onclick}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const WarehouseLocation = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [state, setState] = useState({
        ...props.data,
        dataHasBeenSet: false,
        bulk: props.data?.warehouseLocationType?.type === "bulk",
    });

    useEffect(() => {
        if (!state.dataHasBeenSet) {
            setState((prevState) => ({
                ...prevState,
                dataHasBeenSet: true,
            }));
        }
    }, [props.data, state.dataHasBeenSet]);

    const handleStateChange = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions 
                onclick={handleButtonClick}
            />,
        });

        return () => {
            // console.log("unmounting warehouse");
        };
    }, [state, props.data]);

    const [updateLocation,{isLoading, isSuccess}] = useUpdateWarehouseLocationMutation();

    const handleButtonClick = () => {

        updateLocation({ id: state.id, data: state })
            .unwrap()
            .then((updatedLocation) => {
                // Handle the success state
                toast.success(t('update_success'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                // Handle the error state
                toast.error(t('update_failed'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

    };


    return (
        <Box display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("location")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    {/* Two columns */}
                    <Box flex={1}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text light>{t('active')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text light>{t('bulk_location')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text light>{t('blocked_location')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text light>{t('quarantine_location')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text light>{t('carrier_location')}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                <ShSwitch checked={state.active} onChange={(event) => handleStateChange("active", event.target.checked)} color="green" />
                            </Box>
                            <Box>
                                <ShSwitch checked={state.bulk} disabled color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                            <Box>
                                <ShSwitch checked={state.blocked} onChange={(event) => handleStateChange("blocked", event.target.checked)} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                            <Box>
                                <ShSwitch checked={state.quarantine} onChange={(event) => handleStateChange("quarantine", event.target.checked)} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                            <Box>
                                <ShSwitch checked={state.carrier} onChange={(event) => handleStateChange("carrier", event.target.checked)} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box flex={1}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1,
                                    // width:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text light>{t('echeck_location')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text light>{t('transit_location')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text light>{t('highcare_location')}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box lineHeight={"38px"}>
                                <Text bold  variant={state.warehouseLocationType?.type === "echeck" ? "green" : undefined}>{state.warehouseLocationType?.type === "echeck" ? t('yes') : t('no')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text bold variant={state.warehouseLocationType?.type === "transition" ? "green" : undefined}>{state.warehouseLocationType?.type === "transition" ? t('yes') : t('no')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text bold  variant={state.highcare ? "green" : undefined}>{state.highcare ? t('yes') : t('no')}</Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dropdown>
            <Dropdown title={t("bind_settings")} open={window.innerWidth > 767}>
                <Box sx={{
                    display:"flex",
                    // justifyContent:"space-between",
                    flexDirection:"column"
                }}>
                    {/* Two columns */}
                    <Box
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start",
                            flexDirection:"column",
                        }}
                    >

                    <Box 
                        sx={{
                            display:"flex",
                            flexDirection:"column",
                            gap:1.5,
                        }}
                    >

                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    overflow: "hidden",       // Hide content that overflows the box
                                    textOverflow: "ellipsis", // Add ellipsis if content overflows
                                    whiteSpace: "nowrap",     // Prevent text from wrapping into the next line

                                    "@media screen and (min-width:47.9375em)" : {
                                        width:"350px"
                                    }
                                    // width:140
                                }}
                            >
                            {/* Food Switch */}
                                <Text noBreak light>{t('link_allowed_when_location_is_blocked')}</Text>
                            </Box>
                            <Box minWidth={170}>
                                <ShSwitch checked={state.enableLinkingWhenBlocked} onChange={(event) => handleStateChange("enableLinkingWhenBlocked", event.target.checked)} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                        </Box>

                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                                "@media screen and (max-width:47.9375em)" : {
                                    width:1,
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    overflow: "hidden",       // Hide content that overflows the box
                                    textOverflow: "ellipsis", // Add ellipsis if content overflows
                                    whiteSpace: "nowrap",     // Prevent text from wrapping into the next line

                                    "@media screen and (min-width:47.9375em)" : {
                                        width:"350px"
                                    }
                                    // width:140
                                }}
                            >
                            {/* Food Switch */}
                                <Text noBreak light>{t('supplier')}</Text>
                            </Box>
                            
                            <Box display="flex"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    "@media screen and (min-width:47.9375em)" : {
                                    flex:1,
                                    }
                                }}
                            >
                                <InputBase sx={{ml: 2, flex:1,height:"32px"}} placeholder="" />
                                {/* <IconButton type="button" sx={{p: 1,marginRight:-1}}>
                                    <EditOutlinedIcon />
                                </IconButton>
                                <IconButton type="button" color="red" sx={{p: 1}}>
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton> */}
                            </Box>
                        </Box>
                    </Box>
                       
                       
                       
                    </Box>
                    <Box
                        sx={{
                            marginTop:2
                        }}
                    >
                        <Text light>{t('remark')}</Text>

                        <Box display="flex"
                            borderRadius="8px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:1
                            }}
                        >
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={3}
                                variant="outlined"
                                onChange={(event) => handleStateChange("remark", event.target.value)}
                                value={state.remark ?? ""}
                                sx={{
                                    width:1,
                                    border:"none",
                                    outlineColor:"transparent !important",
                                    color: colors.txt["primary"],
                                    padding:0,
                                    " .MuiOutlinedInput-notchedOutline": {
                                        border:"none !important"
                                    },
                                    " .MuiInputBase-root": {
                                        paddingX:2,
                                        paddingY:1.5
                                    }
                                }}
                            ></TextField>
                            {/* <InputBase sx={{ml: 2, flex:1, height:120, verticalAlign:"top"}} placeholder="" /> */}
                        </Box>
                    </Box>
                </Box>
            </Dropdown>
            <SpDesktopFooter>
                {/* Pass the necessary state values and setter functions to GridActions */}
                <GridActions
                   onclick={handleButtonClick}
                />
            </SpDesktopFooter>
        </Box>
    );
}

export default WarehouseLocation;