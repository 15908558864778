import { Box, useTheme, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";

import { useSelector } from "react-redux";
import { ENV_TYPES } from "../../../Sidebar";
import { useTranslation } from "react-i18next";
import { useGetInvoiceLinesQuery } from '../../../../../newapi/financial/invoiceSlice';
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';
import Shbutton from "../../../../theme/buttons/Shbutton";
import { formatPrice, removeTrailingZeros } from "../../../../../utils/helpers";
import MAddInvoiceLine from "../../../Modals/MAddInvoiceLine";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import AddIcon from '@mui/icons-material/Add';
import Text from "../../../../theme/text/Text";
import Subheading from "../../../../theme/text/Subheading";

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];

    return (
        <ButtonGroup version={2}>
            {!isRelation && <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('add_invoice_line')}
            </Shbutton>}
        </ButtonGroup>
    )
}

const gridOptions = {
    export:false,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const InvoiceLines = (props) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    // const {data: invoiceLines, isFetching, isLoading} = useGetInvoiceLinesQuery({id:props.invoiceId});

    const { data:invoiceLines, isLoading,isFetching, error } = useGetInvoiceLinesQuery({
        args: { id: props.invoiceId, page:1, size:200, body: {} },  // Adjust args as needed
    });


    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions: props.invoiceData?.isConcept && <GridActions  handleOpen={() => setAddModalOpen(true)}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props.invoiceData]);

    const columns = [
        {field: "amount", headerName: t("amount"), renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return removeTrailingZeros(params.row.amount);
        }},
        {field: "description", headerName: t("description"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.description;
        }},
        {field: "price", headerName: t("price"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return formatPrice(i18n, params.row.price);
            }
        },
        {field: "total", headerName: t("total"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return formatPrice(i18n, (params.row.amount * params.row.price));
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <ShTextSkeleton/>
                )
            }
        },
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            <Box
                sx={{
                    p:3,
                }}
            >
                {/* Header */}
                <Box
                    sx={{
                        mb:2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"none"
                        }
                    }}
                >
                    <Subheading>{t("content")}</Subheading>
                    {props.invoiceData?.isConcept && <GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>}
                </Box>

                <Box>
                    {/* Table header */}
                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center",
                            height:"40px",
                            borderBottom:`1px solid ${colors.bg['tertiary']}`
                        }}
                    >
                        <Box
                            sx={{
                                flex:3,
                            }}
                        >
                            <Text light>{t("description")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                            }}
                        >
                            <Text light>{t("price")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                            }}
                        >
                            <Text light>{t("amount")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                            }}
                        >
                            <Text light>{t("total")}</Text>
                        </Box>
                    </Box>
                    {invoiceLines?.data.map((line,index) => (
                        <Box key={"invoice-lines-"+index}
                            sx={{
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"center",
                                height:"48px",
                                // borderBottom:index < invoiceLines.data.length-1 && `1px solid ${colors.bg['tertiary']}`
                            }}
                        >
                            <Box
                                sx={{
                                    flex:3,
                                }}
                            >
                                <Text>{line.description}</Text>
                            </Box>
                            <Box
                                sx={{
                                    position:'relative',
                                    flex:1,
                                    // "&::after" : {
                                    //     right:"30%",
                                    //     top:"50%",
                                    //     content:'"*"',
                                    //     display:"block",
                                    //     position:"absolute",
                                    //     color:"red",
                                    //     fontWeight: 'bold', // Ensure it's visible
                                    //     display: 'block', // Ensure it is rendered
                                    //     transform:"translate(-50%,-40%)",
                                    // }
                                }}
                            >
                                <Text light>{formatPrice(i18n,line.price)}</Text>
                            </Box>
                            <Box
                                sx={{
                                    position:'relative',
                                    flex:1,
                                }}
                            >
                                <Text light>{removeTrailingZeros(line.amount)}</Text>
                            </Box>
                            <Box
                                sx={{
                                    flex:1,
                                }}
                            >
                                <Text semibold>{formatPrice(i18n,(line.amount * line.price))}</Text>
                            </Box>
                        </Box>
                    ))}

                    {/* Footer */}
                    <Box
                        sx={{
                            mt:2,
                            display:"flex",
                            justifyContent:"space-between",
                            // alignItems:"center",
                            height:"56px",
                        }}
                    >
                        <Box
                            sx={{
                                flex:1,
                            }}
                        >
                            
                        </Box>
                        <Box
                            sx={{
                                flex:3,
                            }}
                        >
                            
                        </Box>
                        <Box
                            sx={{
                                marginLeft:"-24px",
                                pl:3,
                                flex:1,
                                background:theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100],
                                borderTopLeftRadius:10,
                                borderBottomLeftRadius:10,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text>{t("total")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                background:"orange",
                                borderTopRightRadius:10,
                                borderBottomRightRadius:10,
                                display:"flex",
                                alignItems:"center",
                                background:theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100],
                                "@media screen and (max-width: 47.9375em)" : {

                                    marginRight:"-24px",
                                    pr:3,
                                }
                            }}
                        >
                            <Subheading bold>{formatPrice(i18n,props.invoiceData?.totalPrice)}</Subheading>
                        </Box>
                    </Box>
                </Box>
            </Box>
           
            <MAddInvoiceLine onOpen={() => addModal.setOpen(true)} relationId={props.relationId} invoiceId={props.invoiceId} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}

export default InvoiceLines;