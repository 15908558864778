import { Box, InputBase, List, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useState, useEffect } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import { themeSettings, tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { useAddInboundShipmentsMutation } from "../../../newapi/inbound/inboundsSlice";
import { showToastMessagePostRequest, showToastMessage, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import { InputDropdown } from "../../theme/dropdowns/InputDropdown";
import { yupRequired } from "../../../utils/validation";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";

const MAddInboundShipments = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);

    const [fieldErrors, setFieldErrors] = useState({});
    const [newInboundShipments, setNewInboundShipments] = useState([{
        rowIndex: 0,
        trackandtrace: ""
    }]);

    const handleClose = () => {
        setNewInboundShipments([{
            rowIndex: 0,
            trackandtrace: ""
        }]);
        props.handleClose();
    }

    const [addInboundShipments, {isLoading}] = useAddInboundShipmentsMutation();
    const SaveInboundShipments = async () => {
        try {
            const filteredShipments = newInboundShipments
            .filter(shipment => shipment.trackandtrace.length > 0)
            .map(shipment => ({
                trackandtrace: shipment.trackandtrace
            }));

            const requestData = {
                inboundId: props.inboundId,
                body: {
                    shipments: filteredShipments
                }
            }
            
            const data = await addInboundShipments(requestData).unwrap()
            showToastMessagePostRequest(t, data);
            handleClose();
        } catch (err) {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, err);
        }
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleDeleleteShipmentRow = (rowIndex) => {
        setNewInboundShipments(prev => prev.filter(shipment => shipment.rowIndex !== rowIndex));
    };

    const handleChange = (rowIndex, key, value) => {
        setNewInboundShipments(prev => prev.map((shipment, index) => {
            if (shipment.rowIndex === rowIndex) {
                return {
                    ...shipment,
                    [key]: value
                };
            }
            return shipment;
        }));

        if (newInboundShipments[newInboundShipments.length - 1].trackandtrace.length > 0) {
            setNewInboundShipments([...newInboundShipments, {
                trackandtrace: "",
                rowIndex: newInboundShipments[newInboundShipments.length - 1].rowIndex + 1
            }]);
        }
    };
      

    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_inbound_shipments")}</Subheading>
            </Box>

            {/* BODY */}
                {newInboundShipments && newInboundShipments.length > 0 && newInboundShipments.map((shipment, index) => (
                    <Box
                        key={"mpas-nis-"+index}
                        sx={{
                            background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                            paddingRight:3,
                            paddingLeft:3,
                            paddingTop:1,
                            paddingBottom:1,
                            borderTopLeftRadius:16,
                            borderTopRightRadius:16,
                            display:"flex",
                            alignItems:"center",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                flex:1,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"40px"} width={"104px"}>
                                <Text>{t("track_trace")}</Text>
                            </Box>
                            
                            <Box display="flex"
                                height="40px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                            <InputBase 
                                    sx={{
                                        px:2,
                                        flex:1, 
                                        color:colors.txt["primary"],
                                        lineHeight:"32px",
                                    }}
                                    value={shipment.trackandtrace}
                                    onChange={(e) => handleChange(index, 'trackandtrace', e.target.value)}
                                />  
                            </Box>
                        </Box>
                        {index > 0 && (
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"40px"} width={40}>
                                <Text>&nbsp;</Text>
                            </Box>

                            <Box display="flex"
                                height="40px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                
                                sx={{
                                    
                                }}
                            >
                                <IconButton color="red" type="button" onClick={() => handleDeleleteShipmentRow(index)} sx={{p:1}}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                        )}
                    </Box>
                ))}
            <Box
                component="footer"
                sx={{
                    pt:2,
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    // paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}> 
                    <Shbutton className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => SaveInboundShipments()}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddInboundShipments;
