import { id } from "date-fns/locale";
import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const PRODUCT_BASE = "/products";
const buildUrl = (endpoint) => PRODUCT_BASE + endpoint;

const productsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Products' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getProducts', 'Products')
        }),
        getProduct: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Products', id }],
            // onQueryStarted: muiDataGridStarted.bind(null, 'getProduct', 'Product')
        }),
        updateProduct: builder.mutation({
            query: (productInfo) => ({
                url: buildUrl(`/${productInfo.id}`),
                method: 'PUT',
                body: productInfo,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Products'},{ type: 'Products', id }],
        }),
        deleteProduct: builder.mutation({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Products'}, { type: 'Products', id }],
        }),
        getChildProducts: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/childproducts`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'ChildProducts'}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getChildProducts', 'ChildProducts')
        }),
        deleteChildProducts: builder.mutation({
            query: args => ({
                url: buildUrl(`/${args.productId}/childproducts`),
                method: 'DELETE',
                body: args.body,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'ChildProducts'}, { type: 'Products' }],
        }),
        addChildProducts: builder.mutation({
            query: args => ({
                url: buildUrl(`/${args.productId}/childproducts`),
                method: 'POST',
                body: args.body,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'ChildProducts'}, { type: 'Products' }],
        }),
        updateChildProduct: builder.mutation({
            query: childProductInfo => ({
                url: buildUrl(`/${childProductInfo.parentProductId}/childproducts/${childProductInfo.childProductId}`),
                method: 'PUT',
                body: childProductInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'ChildProducts'}, { type: 'Products' }],
        }),
        addProduct: builder.mutation({
            query: productInfo => ({
                url: buildUrl(`/add`),
                method: 'POST',
                body: productInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Products'}],
        }),
    })
});

export const { 
    useGetProductQuery,
    useGetProductsQuery,
    useUpdateProductMutation,
    useGetChildProductsQuery,
    useDeleteChildProductsMutation,
    useAddChildProductsMutation,
    useUpdateChildProductMutation,
    useAddProductMutation,
    useDeleteProductMutation
} = productsApiSlice;
