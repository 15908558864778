import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import Text from "../../../../theme/text/Text";

import { useEffect, useState } from "react";
import Subheading from "../../../../theme/text/Subheading";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";

import { useTranslation } from "react-i18next";
import { useGetOrderColliQuery } from "../../../../../newapi/order/ordersSlice";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../../../utils/validation";
import SpDesktopFooter from "../../../../theme/sidepanel2/SidepanelFooter";
import { useUpdateOrderMutation } from "../../../../../newapi/order/ordersSlice";
import { showToastMessageRequestError, showToastMessageUpdateRequest, showToastMessage} from "../../../../../utils/toasts";
import SaveIcon from '@mui/icons-material/Save';
import { countriesFromLocale } from '../../../../../utils/countries';
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { useSelector } from 'react-redux';
import { UserPrivileges } from "../../../../../utils/permissions";
import { selectUserPermission } from '../../../../../newapi/user/authentication/authSlice';
import ShDateRangePicker from "../../../../theme/ShDateRangePicker";
import { general_states } from "../../../../../utils/staticEnums";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (<>
        {props.hasPermission && (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.onclick}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    )}
    </>
    );
};

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Card = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const data = props.data;
    return (
        <Box
            sx={{
                // background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0]}`,
            }}
        >
            <Text bold>{data.description}</Text>
            <Box
                sx={{
                    marginTop:3,
                    display:"flex",
                    justifyContent:"space-between",
                    gap:5,
                }}
            >
                <Box display={"flex"} flexDirection={"column"}>
                    <Text light>{t("sku")}</Text>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{data.sku}</Text>
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                    <Text light>{t("location")}</Text>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{data.location}</Text>
                </Box>
            </Box>

            <Box
                sx={{
                    position:"absolute",
                    paddingY:0.5,
                    paddingX:2,
                    right:0,
                    top:0,
                    borderRadius: "8px 12px",
                    background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200]
                }}
            >
                {data.quantity}
            </Box>
        </Box>
    )

}

const ShipOrder = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({});
    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language);
    const hasOrderManagePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.OUTBOUND_MANAGE, UserPrivileges.RP_ORDER_MANAGE])
    );

    const [orderData, setOrderData] = useState({
        deliveryStreet: "",
        deliveryHousenumber: "",
        deliveryHousenumberAddition: "",
        deliveryPostalCode: "",
        deliveryCity: "",
        deliveryCountry: "",
        deliveryState: "",
        deliveryPhone: "",
        deliveryEmail: "",
        remark: ""
    });

    useEffect(() => {
        setOrderData(props.data);
    }, [props.data]);

    const handleChange = (key, value) => {
        setOrderData(prevState => ({
          ...prevState,
          [key]: value
        }));
        // Optionally clear the error for that field
        setFieldErrors(prevState => ({
          ...prevState,
          [key]: ''
        }));
    };

    const handleDateChange = (date) => {
        if (![general_states.SHIPPED, general_states.PICKING_IN_PROGRESS, general_states.FULLYPICKED].includes(orderData.state)) {
            handleChange("date_planned", date[0].formattedStartDate);
        } else {
            showToastMessage("error", `${t("order_date_planned_error_part1")} "${t(orderData.state)}" ${t("order_date_planned_error_part2")}`, t, 10000);
        }
    };

    const rules = {
        deliveryStreet: yupRequired('street', t),
        deliveryHousenumber: yupRequired('house_nr', t),
        deliveryPostalCode: yupRequired('postal', t),
        deliveryCity: yupRequired('city', t),
        deliveryCountry: yupRequired('country', t),
        deliveryPhone: yupRequired('phone', t),
        deliveryEmail: yupRequired('email', t)
    };
    
    const schema = yup.object().shape(rules);
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions onclick={handleSaveClick}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[ /* Add dependencies here once we have status data */]);
    
    const [EditOrder,{isLoading}] = useUpdateOrderMutation();
    const handleSaveClick = async () => {
        try {
            
            const updateObject = {
                deliveryStreet: orderData.deliveryStreet,
                deliveryHousenumber: orderData.deliveryHousenumber,
                deliveryHousenumberAddition: orderData.deliveryHousenumberAddition,
                deliveryPostalCode: orderData.deliveryPostalCode,
                deliveryCity: orderData.deliveryCity,
                deliveryCountry: orderData.deliveryCountry,
                deliveryState: orderData.deliveryState,
                deliveryPhone: orderData.deliveryPhone,
                deliveryEmail: orderData.deliveryEmail,
                remark: orderData.remark,
                state: orderData.state,
                date_planned: orderData.date_planned,
                id: props.data.id
            }
            
            setFieldErrors({});
            await schema.validate(updateObject, { abortEarly: false });
            
            const response = await EditOrder(updateObject).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("update_failed"), t, err);
              }
        }
    };
    
    const columns = [
        // {field: "id", hide: true },
        {field: "carrier.name", headerName: t("name"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold>{cellValues.row.carrier?.name}</Text>
                </Box>
            )
        }},
        {field: "carrier.weight", headerName: t("weight"),flex:1,renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold>{cellValues.row.carrier?.weight}</Text><Text> KG</Text>
                </Box>
            )
        }},
        
        {field: "carrier.length", headerName: `${t("dimensions")} (${t("mm")})`, flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text>{cellValues.row.carrier?.length} x {cellValues.row.carrier?.width} x {cellValues.row.carrier?.height}</Text>
                </Box>
            )
        }},

        {field: "dimensionsByShipper", headerName: `${t("dimensions_by_shipper")} (${t("mm")})`, flex:1.5, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text>{cellValues.value ? cellValues.value : "-"}</Text>
                </Box>
            )
        }},
        {field: "trackTrace", headerName: t("track_trace"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text>{cellValues.value}</Text>
                </Box>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                return (
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >
                        {/* {cellValues.row.id + " " + cellValues.row.name}
                        <br/>
                        {cellValues.row.age}
                        <br/>
                        {cellValues.row.phone} */}
                    </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={1.5} paddingY={1.5} overflow={"auto"}>
            {/* Details */}
            <Box 
                sx={{
                    display:"flex",
                    borderRadius:3,
                    overflow:"hidden",
                    marginBottom:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        marginTop:0,
                        background:"none",
                        flexDirection:"column",
                        overflow:"unset"
                    }
                }}
            >
                {/* Editable content */}
                <Box padding={1.5} paddingRight={4} paddingBottom={3} flex={1}
                    sx={{
                        "@media screen and (max-width: 47.9375em)" : {
                            paddingY:0,
                            paddingRight:1.5,
                        }
                    }}
                >
                    <Box 
                        sx={{
                            display:"flex",
                            // alignItems:"center",
                            flexDirection:"column",
                            "@media screen and (max-width: 47.9375em)" : {
                                gap:0.5,
                                alignItems:"flex-start",
                            }
                        }}
                    >
                        <Subheading>{t("details")}</Subheading>
                        <Text>{orderData?.deliveryName}</Text>
                    </Box>

                    <Box
                        sx={{
                            display:"flex",
                            marginTop:3,
                            gap:3,
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column",
                                gap:1,
                                alignItems:"flex-start",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("street")}</Text>
                            </Box>
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                <Box display="flex"
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                <ShValidatedInput
                                    readOnly={!hasOrderManagePermission}
                                    name="deliveryStreet"
                                    value={orderData.deliveryStreet ?? ""}
                                    onChange={handleChange}
                                    error={fieldErrors.deliveryStreet}
                                />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                width: 120,
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("house_nr")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[200]}
                                    sx={{
                                        marginTop:0.5,
                                        background: `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background: `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                        }
                                    }}
                                >
                                <ShValidatedInput
                                    sx={{
                                        px: 2, 
                                        width:56, 
                                        height:32,
                                        boxSizing:"border-box",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1,
                                            width:"unset"
                                        }
                                    }}
                                    readOnly={!hasOrderManagePermission}
                                    name="deliveryHousenumber"
                                    value={orderData.deliveryHousenumber ?? ""}
                                    onChange={handleChange}
                                    error={fieldErrors.deliveryHousenumber}
                                />
                                    <Box 
                                        sx={{
                                            marginY:0.5,
                                            height:24,
                                            width:"1px",
                                            background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[300]
                                        }}
                                    />
                                    <ShValidatedInput
                                        disabled={!hasOrderManagePermission}
                                        sx={{px: 2, width:56, height:32,boxSizing:"border-box"}}
                                        name="deliveryHousenumberAddition"
                                        value={orderData.deliveryHousenumberAddition ?? ""}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("postal_code")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                    <ShValidatedInput
                                        sx={{
                                            px: 2, 
                                            width:96, 
                                            height:32,
                                            boxSizing:"border-box",
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1,
                                                width:"unset"
                                            }
                                        }}
                                        disabled={!hasOrderManagePermission}
                                        name="deliveryPostalCode"
                                        value={orderData.deliveryPostalCode ?? ""}
                                        onChange={handleChange}
                                        error={fieldErrors.deliveryPostalCode}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("city")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                <ShValidatedInput
                                    sx={{
                                        px: 2, 
                                        flex:1, 
                                        height:32,
                                        boxSizing:"border-box",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1,
                                            width:"unset"
                                        }
                                    }}
                                    disabled={!hasOrderManagePermission}
                                    name="deliveryCity"
                                    value={orderData.deliveryCity ?? ""}
                                    onChange={handleChange}
                                    error={fieldErrors.deliveryCity}
                                />
                                </Box>
                            </Box>
                        </Box>
                    </Box>


                    <Box
                        sx={{
                            display:"flex",
                            marginTop:3,
                            gap:3,
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column",
                                gap:1,
                                alignItems:"flex-start",
                                marginTop:4
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("country")}</Text>
                            </Box>
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                <Box display="flex"
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                <ShValidatedInputDropdown
                                        disabled={!hasOrderManagePermission}
                                        name="deliveryCountry"
                                        displayName="name"
                                        changeField="alpha2"
                                        options={countries ?? null}
                                        noSelection={t("choose_option")} 
                                        onChange={handleChange}
                                        selected={countries?.findIndex(country => (orderData.deliveryCountry && orderData.deliveryCountry.toLowerCase() === country.alpha2.toLowerCase())) ?? -1}
                                        error={fieldErrors.deliveryCountry}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("delivery_state")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[200]}
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                <ShValidatedInput
                                    sx={{
                                        px: 2, 
                                        flex:1, 
                                        height:32,
                                        boxSizing:"border-box",
                                    }}
                                    disabled={!hasOrderManagePermission}
                                    name="deliveryState"
                                    value={orderData.deliveryState ?? ""}
                                    onChange={handleChange}
                                />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1,
                                    marginTop:4,
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("phone")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                <ShValidatedInput
                                    sx={{
                                        px: 2, 
                                        flex:1, 
                                        height:32,
                                        boxSizing:"border-box",
                                    }}
                                    disabled={!hasOrderManagePermission}
                                    name="deliveryPhone"
                                    value={orderData.deliveryPhone ?? ""}
                                    onChange={handleChange}
                                    error={fieldErrors.deliveryPhone}
                                />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("email")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                    <ShValidatedInput
                                        sx={{
                                            px: 2, 
                                            flex:1, 
                                            height:32,
                                            boxSizing:"border-box"
                                        }}
                                        disabled={!hasOrderManagePermission}
                                        name="deliveryEmail"
                                        value={orderData.deliveryEmail ?? ""}
                                        onChange={handleChange}
                                        error={fieldErrors.deliveryEmail}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            marginTop:3,
                            gap:3,
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column",
                                gap:1,
                                alignItems:"flex-start",
                                marginTop:4
                            }
                        }}
                    >
                    <Box
                        sx={{
                            display:"flex",
                            flexDirection:"column",
                            flex:1,
                            gap:0.5,
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"row",
                                gap:5,
                                alignItems:"center",
                                width:1
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    width:80
                                }
                            }}
                        >
                            <Text light>{t("date_planned_shipment")}</Text>
                        </Box>

                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >

                            <Box display="flex"
                            
                                borderRadius="20px"
                                sx={{
                                    marginTop:0.5,
                                    background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                    "@media screen and (max-width: 47.9375em)" : {
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                    }
                                }}
                            >
                            <ShDateRangePicker 
                                singleSelect={true} 
                                startDate={orderData?.date_planned && orderData.date_planned !== null ? orderData.date_planned : new Date()} 
                                select={handleDateChange} 
                                size={32}
                            >
                            </ShDateRangePicker>
                            </Box>
                        </Box>
                    </Box>
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                flex: 3,
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("remark")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                    <ShValidatedInput
                                        sx={{
                                            px: 2, 
                                            height:32,
                                            boxSizing:"border-box"
                                        }}
                                        disabled={!hasOrderManagePermission}
                                        name="remark"
                                        value={orderData.remark ?? ""}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Maps */}
                {/* <Box 
                    sx={{
                        width:200,
                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200]
                    }}
                ></Box> */}
            </Box>

            <Box marginX={-1.5}
                sx={{
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"none"
                    }
                }}
            >
                {orderData?.id &&
                <ShDataGrid2 key={theme.palette.mode}
                    content={useGetOrderColliQuery}
                    itemId={orderData?.id}
                    // gridActions={<GridActions></GridActions>} 
                    title={t("colli")} 
                    gridOptions={gridOptions} 
                    isSidepanelGrid 
                    columns={columns}
                    rows={data}>
                    
                </ShDataGrid2>
                }
            </Box>
            <SpDesktopFooter>
                {/* Pass the necessary state values and setter functions to GridActions */}
                <GridActions
                   onclick={handleSaveClick}
                   hasPermission={hasOrderManagePermission}
                />
            </SpDesktopFooter>
        </Box>
    );
}


export default ShipOrder;