
import { Box, ButtonBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme"

import { useTranslation } from 'react-i18next';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { useEffect, useRef, useState } from "react";
import { useGetInvoiceProductsQuery } from "../../../../../newapi/financial/invoiceProductSlice";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { general_states } from "../../../../../utils/staticEnums";
import AddIcon from '@mui/icons-material/Add';

import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SpInvoiceProductDetails from "../../../../../components/global/Sidepanels/SpInvoiceProductDetails/SpInvoiceProductDetails";
import MAddInvoiceProduct from "../../../../../components/global/Modals/MAddInvoiceProduct";
import Text from "../../../../../components/theme/text/Text";
import DropdownButton from "../../../../../components/theme/buttons/DropdownButton";
import ShDataGridSelectedActions from "../../../../../components/theme/datagrid2/ShDataGridSelectedActions";

import PrintIcon from '@mui/icons-material/Print';
import BallotIcon from '@mui/icons-material/Ballot';
import ShLabel from "../../../../../components/theme/label/ShLabel";
import MEditProductPrices from "../../../../../components/global/Modals/MEditProductPrices";
import { WarehouseInvoiceProductCard } from "../../../../../components/theme/cards/financial/InvoiceProductCard";

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('new_product')}
            </Shbutton>
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Products = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    // const [selectedRowId, setSelectedRowId] = useState(-1);
    // const [rowSelected, setRowSelected] = useState(false);
    
    const ref = useRef();
    const [config, setConfig] = useState({"state": general_states.ACTIVE});

    const [open, setOpen] = useState(false);

    const createContainerSizeModal = {
        open: open,
        setOpen: setOpen,
    }

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions handleOpen={handleOpen}></GridActions>
        });

        return () => {
        }
    },[]);

    const handleOpen = () => {
        setAddModalOpen(true);
    }

    const [selectedItems, setSelectedItems] = useState([]);
    const onSelected = (selectedItems) => {
        setSelectedItems(selectedItems);
    }
    
      // Function to select or deselect a card by its ID
      const handleCardSelection = (id) => {
        setSelectedItems((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
            // Deselect the card if it's already selected
            return prevSelectedIds.filter((selectedId) => selectedId !== id);
        } else {
            // Select the card if it's not selected
            return [...prevSelectedIds, id];
        }
        });
    };
    

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const [invoiceProductId, setInvoiceProductId] = useState();
    const invoiceProductIdRef = useRef(invoiceProductId);
    
    const getInvoiceProduct = (params) => {
        if (!params) return;
    
        const newInvoiceProductId = parseInt(params.row.id);
        // Only update state if the pickingJobListId has actually changed
        if (invoiceProductIdRef.current !== newInvoiceProductId) {
            setInvoiceProductId(newInvoiceProductId);
            invoiceProductIdRef.current = newInvoiceProductId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex:2,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return (
                    <Box 
                        sx={{
                            width:'100%',
                            cursor:"pointer",
                            lineHeight:"64px",
                        }} 
                        onClick={(event) => {
                            event.stopPropagation();
                            // getPickingList(params);
                            getInvoiceProduct(params);
                        }}
                    >
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.name}</Text>
                    </Box>
                );
                // return params.row.name;
            }
        },
        {
            field: "invoiceline",
            headerName: t("invoiceline"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return params.row.invoiceline;
            }
        },
        {
            field: "type", 
            headerName: t("type"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                let labelColor = "grey";
                switch(params.row.type) {
                    case "time" :
                        labelColor = "grey";
                        break;
                    case "material" :
                        labelColor = "orange";
                        break;

                    case "process_order" :
                        labelColor = "pink";
                        break;

                    case "process_orderline" :
                        labelColor = "purple";
                        break;

                    case "default" :
                        labelColor = "michael_brown";
                        labelColor = "blue";
                        break;

                }
                return (
                    <ShLabel fitted size={32} minWidth={150}  variant={labelColor}>
                      {t(params.row.type)}
                    </ShLabel>
                );
            }
        },
        {field: "subType", headerName: t("sub_type"),flex:1,

            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                // let labelColor = "grey";
                // switch(params.row.subType) {
                //     case "general" :
                //         labelColor = "grey";
                //         break;
                //     case "echeck" :
                //         labelColor = "blue";
                //         break;

                //     case "inbound" :
                //         labelColor = "yellow";
                //         break;

                //     case "repacking" :
                //         labelColor = "purple";
                //         break;

                // }
                // return (
                //     <ShLabel fitted size={32}  variant={labelColor}>
                //       {t(params.row.subType)}
                //     </ShLabel>
                // );
                return <Text> {t(params.row.subType)}</Text>
            }
            // renderCell: (params) => {
            //     if(params.row.skeleton) {
            //         return <ShTextSkeleton/>
            //     }
            //     return t(params.row.subType);
            // }
        },
        {field: "ledger", headerName: t("ledger"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return t(params.row.ledger);
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehouseInvoiceProductCard
                        select={handleCardSelection} 
                        selected={selectedItems.includes(params.row.id)}
                        onClick={() => getInvoiceProduct(params)}
                        skeleton={params.row.skeleton}
                        {...params.row}
                        canSelect={params.row.priceIncreases?.length === 0}
                    />
                    // <ShTextSkeleton/>
                )
            }
        }
    ];

    return (
        <Box height={1} ref={ref} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetInvoiceProductsQuery}
                config={config}
                gridActions={<GridActions handleOpen={handleOpen}></GridActions>} 
                title={t('products')}
                gridOptions={gridOptions} 
                columns={columns}
                rows={data}
                // onRowClick={getInvoiceProduct}
                sortModel={{field: columns[0].field,type:"ASC"}}
                selectable
                setSelected={onSelected}
                selectedItems={selectedItems}
                selectedActions={
                    <ShDataGridSelectedActions showButtons selectedItems={selectedItems}>
                        <ButtonBase
                            onClick={() => createContainerSizeModal.setOpen(true)}
                            sx={{
                                background:tokens('light').txt["white"],
                                height:40,
                                borderRadius:7,
                                fontSize:"14px",
                                fontFamily:"Roboto, sans-serif",
                                px:3,
                                boxShadow:"none",
                                border:"none",
                                fontWeight:500,
                                color:tokens('light').txt["primary"]
                            }}
                        >
                            {t('change_prize')}
                        </ButtonBase>
                    </ShDataGridSelectedActions>
                }
                isRowSelectable={(row) => {
                    if(row.priceIncreases?.length > 0) {
                        return false;
                    }
                    return true;
                    // return row.priceIncreases?.length > 0
                }}
            >
            </ShDataGrid2>
            
            {/* <ShDataGridSelectedActions selectedItems={selectedItems}>
                <DropdownButton icon={<PrintIcon/>} text={"print"}/>
                <DropdownButton icon={<BallotIcon/>} text={"Maak batch"}/>
            </ShDataGridSelectedActions> */}

            <SpInvoiceProductDetails
                invoiceProductId={invoiceProductId}
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                // data={pickingJobData}
            ></SpInvoiceProductDetails>
            <MEditProductPrices
                selectedItems={selectedItems.length}
                items={selectedItems}
                open={createContainerSizeModal.open}
                handleClose={() => createContainerSizeModal.setOpen(false)}
            />
            <MAddInvoiceProduct onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}

export default Products;