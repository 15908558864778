import { Box, useTheme } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { showToastMessagePostRequest } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired, yupNumberRequired } from "../../../utils/validation";
import { useCreatePickupCrateMutation, useGetContainerSizesSelectQuery } from "../../../newapi/warehouse/pickupCartSlice";
import MAddContainerSize from "./MAddContainerSize";

const MAddPickupCrate = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const { data: containerSizes, isLoadingx } = useGetContainerSizesSelectQuery();
    const [addPickupCrate,{isLoading}] = useCreatePickupCrateMutation();
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(0);

    const select = (selected) => {
        setSelected(selected);
    }

    const [pickingCrateInfo, setPickingCrateInfo] = useState({
        barcode: "",
        containerSizeId: -1
    });
      
    const handleClose = () => {
      setPickingCrateInfo({
          barcode: "",
          containerSizeId: -1
      });
      props.handleClose();
  }

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        barcode: yupRequired('name', t),
        containerSizeId: yupNumberRequired('size', t)
    };
        
    const schema = yup.object().shape(rules);
      
    const OpenCreateContainerSizeModal = () => {
        setModalData({
            title: 'create_new_containersize',
            confirm: confirmModalContainerSize
        });
        setOpen(true);
    }

    const confirmModalContainerSize = (obj) => {
        setPickingCrateInfo({
            ...pickingCrateInfo,
            containerSizeId: obj.id,
          });
          setOpen(false);
    }

    const createContainerSizeModal = {
        open: open,
        setOpen: setOpen,
        data:modalData,
        confirm: confirmModalContainerSize
    }

    const SavePickingCrate = async (xPickupCrateInfo, props) => {
        try {
            setFieldErrors({});
            await schema.validate(xPickupCrateInfo, { abortEarly: false });
            const data = await addPickupCrate(xPickupCrateInfo).unwrap()
            showToastMessagePostRequest(t, data);

            handleClose();
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessagePostRequest(t, { success: false, error: err.error });
              }
        }
    };
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setPickingCrateInfo(prev => ({
          ...prev,
          [key]: value
        }));
        setFieldErrors(prev => ({
            ...prev,
            [key]: ''
        }));
    };

    return(
        <>
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1,
                }}>
                <Subheading>{t("add_pickingcrate")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("barcode")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                            name="barcode"
                            value={pickingCrateInfo?.barcode ?? ""}
                            onChange={handleChange}
                            error={fieldErrors.barcode}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("size")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            // backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            
                            // height:32
                        }}
                    >
                        <ShValidatedInputDropdown
                            name="containerSizeId"
                            displayName="description"
                            changeField="id"
                            options={containerSizes?? []}
                            selected={containerSizes ? containerSizes?.findIndex(containerSize => containerSize.id === pickingCrateInfo?.containerSizeId) ?? -1 : -1}
                            noSelection={t("choose_containersize")} 
                            onChange={handleChange}
                            error={fieldErrors.containerSizeId}
                        />
                {/* <ButtonGroup version={2} > */}
                    <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    variant="contained" 
                    // startIcon={<Add/>}
                    onClick={() => OpenCreateContainerSizeModal()}
                    >{t("new")}</Shbutton>
                {/* </ButtonGroup> */}
    </Box></Box>
            </Box>
            
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>  
                    <Shbutton className={"group-btn"} color={"green"}
                    block
                    variant="contained" 
                    onClick={() => SavePickingCrate(pickingCrateInfo, props)}
                    >{t("add")}</Shbutton>
                    
                </ButtonGroup>
            </Box>
            
        </SHModal>
        <MAddContainerSize
        open={createContainerSizeModal.open}
        handleClose={() => createContainerSizeModal.setOpen(false)}
        data={createContainerSizeModal.data}
        confirm={createContainerSizeModal.confirm}
    />
    </>
    )
}

export default MAddPickupCrate;
