import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import Text from "../../../../../components/theme/text/Text";
import { tokens } from "../../../../../theme";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShLabel from "../../../../../components/theme/label/ShLabel";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetPickingJobsQuery } from "../../../../../newapi/pickingjob/pickingjobSlice";
import { general_states } from "../../../../../utils/staticEnums";

import SpPickingJobPickingList from "../../../../../components/global/Sidepanels/SpPickingJobPickingList/SpPickingJobPickingList";
import { WarehouseBatchCard } from "../../../../../components/theme/cards/stock/BatchCard";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
const GridActions = (props) => {
    const theme = useTheme();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <ButtonGroup version={2}>
                <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} endIcon={<AddIcon/>} variant="contained">New</Shbutton>
            </ButtonGroup>
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const isShipmentDue = (inputDateString) => {
   const inputDate = new Date(inputDateString);
   const now = new Date();
  
    return now > inputDate;
  };

const OutboundBatchesDoing = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    // const [selectedRowId, setSelectedRowId] = useState(-1);
    // const [rowSelected, setRowSelected] = useState(false);
    
    const ref = useRef();
    const [config, setConfig] = useState({"state": general_states.IN_PROGRESS});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
        }
    },[]);
    // pickingJobListId

    const [pickingJobListId, setPickingJobListId] = useState();
    const pickingJobListIdRef = useRef(pickingJobListId);
    
    const getPickingList = (params) => {
        if (!params) return;
    
        const newpickingJobListId = parseInt(params.row.id);
        // Only update state if the pickingJobListId has actually changed
        if (pickingJobListIdRef.current !== newpickingJobListId) {
            setPickingJobListId(newpickingJobListId);
            pickingJobListIdRef.current = newpickingJobListId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    // const { data: pickingJobData, isLoading } = useGetPickingJobPickingListQuery(selectedRowId,
    //     {
    //         enabled: rowSelected && selectedRowId !== -1,
    //         skip: !rowSelected
    //     }
    // );
    
    // const getPickingList = (event, params) => { 
    //     console.log(params);
    //     if (!params || !params.row) return;
    //     console.log(params.row);

    //     event.stopPropagation();
    //     setSelectedRowId(params.row.id);
    //     setRowSelected(true);
    // };

    // useEffect(() => {
    //     if (!isLoading && pickingJobData && rowSelected) {
    //         setIsExtended(true);
    //     }
    // }, [pickingJobData, isLoading, rowSelected]);
    // const [GetOrderPickingListPDF] = useGetOrderPickingListPDFMutation();
    // const handlePrintAction = async () => {
    //     // console.log(selectedItems.length);
    //     if (selectedItems.length === 0) {
    //         console.log("returning");
    //         return;
    //     }

    //     try {
    //         console.log("owo trying");
    //         const response = await GetOrderPickingListPDF({orders: selectedItems}).unwrap();
    //         console.log(response);
    //         const newWindow = window.open(response.data, '_blank', 'noopener,noreferrer');
    //         if (newWindow) {
    //             setTimeout(() => {
    //                 window.URL.revokeObjectURL(response.data);
    //             }, 500);
    //         } else {
    //             window.URL.revokeObjectURL(response.data);
    //         }
    //     } catch (err) {
    //         console.log("hello error");
    //         console.log("uwu",err);
    //         showToastMessageUpdateRequest(t, {success: false, error: err.error});
    //     }
    // };


    // const [GetPickingJobPickingListPDF] = useGetPickingJobPickingListPDFMutation();
    // const [isFetchingPdf, setIsFetchingPdf] = useState(false);

    // const handleButtonClick = async () => {
    //     setIsFetchingPdf(true);
    //     try {
    //         const response = await GetPickingJobPickingListPDF(props.data.pickingJob.id).unwrap();
    //         const newWindow = window.open(response.data, '_blank', 'noopener,noreferrer');
    //         if (newWindow) {
    //             setTimeout(() => {
    //                 window.URL.revokeObjectURL(response.data);
    //             }, 500);
    //         } else {
    //             window.URL.revokeObjectURL(response.data);
    //         }
    //     } catch (err) {
    //         showToastMessageUpdateRequest(t, {success: false, error: err.error});
    //     } finally {
    //         setIsFetchingPdf(false);
    //     }
    // };
    

    const columns = [
        {
            field: "id",
            headerName: t("reference"),
            shFilter : {
                type: "number",
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Box 
                        sx={{
                            width:80,
                            cursor:"pointer",
                            lineHeight:"64px",
                        }} 
                    >
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.id}</Text>
                    </Box>
                );
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                //TODO: brownie, implement
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                let labelcolor = "grey";
                switch(params.row.state){
                    case "in_progress":
                        labelcolor = theme.palette.mode === "dark" ? "blue" : "primary"
                        break;
                    case "completed":
                        labelcolor = "green"
                        break;
                    default:
                        labelcolor = "grey"
                        break;
                }
                
                return (
                    <ShLabel clickable size={32} palette="normal" variant={labelcolor}>
                      {params.row.state ? t(params.row.state) : t('unknown')}
                    </ShLabel>
                  );
              }
        },
        {field: "orderCount", headerName: t("pickinglists"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.orderCount
        }},
        {field: "total_products", headerName: t("products"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.total_products
        }},
        {field: "userName", headerName: t("appointed_to"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.userName
        }},
        {field: "warehouse", headerName: t("warehouse"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.warehouse?.name;
        }},
        {field: "createdAt", headerName: t("created"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            let created = new Date(params.row.createdAt);
            return created.toLocaleDateString(t.language);
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehouseBatchCard skeleton={params.row.skeleton ?? false} {...params.row}  />
                )
            }
        }
    ];

    const [selectedItems, setSelectedItems] = useState([]);
    // const setSelected
    const onSelected = (selectedItems) => {
        setSelectedItems(selectedItems);
    }

    return (
        <Box height={1} ref={ref} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetPickingJobsQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('doing')}
                gridOptions={gridOptions} 
                 
                columns={columns}
                rows={data}
                sortModel={{field: columns[0].field,type:"DESC"}}
                onRowClick={getPickingList}
                
                // selectable
                // setSelected={onSelected}
                // selectedItems={selectedItems}
                // selectedActions={
                //     <ShDataGridSelectedActions selectedItems={selectedItems}>
                //         <DropdownButton onClick={handlePrintAction} icon={<PrintIcon/>} text={"print"}/>
                //         {/* <DropdownButton icon={<BallotIcon/>} text={"Maak batch"}/> */}
                //     </ShDataGridSelectedActions>
                // }
            >
            </ShDataGrid2>

            {/* <ShDataGridSelectedActions selectedItems={selectedItems}>
                <DropdownButton icon={<PrintIcon/>} text={"print"}/>
                <DropdownButton icon={<BallotIcon/>} text={"Maak batch"}/>
            </ShDataGridSelectedActions> */}

            <SpPickingJobPickingList
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                pickingJobListId={pickingJobListId}
                // data={pickingJobData}
            ></SpPickingJobPickingList>
        </Box>
    );
}

export default OutboundBatchesDoing;