import React, { useState, useContext } from "react";
import { Box, Typegraphy, Typography, useTheme } from '@mui/material';
import { tokens } from "../../../theme";
import cx from 'classnames';

import 'react-pro-sidebar/dist/css/styles.css';


const Heading = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const color = props.color ?? colors.txt["primary"];
    const align = typeof props.align !== "undefined" ? props.align : "left"; 

    return (
        <Typography variant="h2" fontWeight="bold" fontSize={21} fontFamily="Roboto, sans-serif"
            sx={{
                color:`${color}`,
                textOverflow:"ellipsis",
                whiteSpace:"nowrap",
                overflow:"hidden",
                textAlign:align,
                "&.allow-overflow": {
                    whiteSpace:"unset",
                    textOverflow:"initial",
                    overflow:"visible"
                },
                ...props.sx
            }}
        >
            {props.children}
        </Typography>
    );
};

export default Heading;