import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { cacheKeys } from '../cacheKeys';


export const etailizeApiSlice = createApi({
    baseUrl: "https://etailize-webapp-a-ase-win.azurewebsites.net",
    reducerPath: "etailize",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://etailize-webapp-a-ase-win.azurewebsites.net"
        
    }),
    tagTypes: cacheKeys,
    refetchOnMountOrArgChange: false,
    endpoints: (builder) => ({
    getConnectionDefinitions: builder.query({
        query: (credentials) => ({
            url: '/public/api/v1/Connections/definitions',
            method: 'GET',
            headers: {
                'X-Api-ClientId': credentials.clientId,
                'X-Api-Key': credentials.apiKey,
                'X-Api-Secret': credentials.apiSecret,
            },
        }),
    }),
})
});

export const { useGetConnectionDefinitionsQuery } = etailizeApiSlice;


// Export hooks for usage in functional components
// export const { useGetRandQuery } = apiSlice; // Uncomment and adjust as necessary
