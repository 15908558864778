import React, { useState, useContext, useEffect, useRef } from "react";
import {Routes, Route, useLocation, Outlet } from "react-router-dom";
// import Topbar from "./components/theme/global/Topbar";

import Sidebar from './components/global/Sidebar';
import Dashboard from "./environments/warehouse/dashboard";
import Outbound from "./environments/warehouse/outbound/dashboard";

import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./components/global/Sidepanels/SpStockDetails/Views/Warehouse";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import Login from "./scenes/login";
import Calendar from "./scenes/calendar";
// import { RequireAuth } from "react-auth-kit";
import { MenuStateContext } from "./contexts/MenuState";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import { tokens } from "./theme";

// import cx from classNames
import cx from "classnames";
import SHTransition from "./components/theme/SHTransition";
import StockManagement from "./environments/warehouse/stock/stock/Index";
import StockProduct from "./environments/warehouse/stock/products/Index";
import StockLocation from "./environments/warehouse/stock/locations/Index";

//Outbound
import OutboundDashboard from "./environments/warehouse/outbound/dashboard";
import OutboundOrders from "./environments/warehouse/outbound/orders/index";
import OutboundBatches from "./environments/warehouse/outbound/batches/index";

// Automation
import AutomationWorkflows from "./environments/warehouse/automation/workflows/index";
import AutomationCountingStrategies from "./environments/warehouse/stock/countingstrategies";

// Warehouse Relation
import WarehouseRelation from "./environments/warehouse/relation";

// Financial
import FinancialInvoices from "./environments/warehouse/financial/invoices/index";
import FinancialInvoiceComponents from "./environments/warehouse/financial/components";

// Outbound setting
import OutBoundSettingsPicking from "./environments/settings/warehouse/outbound/picking/Picking";
import OutBoundSettingsCarriers from "./environments/settings/warehouse/outbound/carriers/Carriers";
import OutBoundSettingsStreams from "./environments/settings/warehouse/picking/streams/Streams";
import OutboundSettingsStock from "./environments/settings/warehouse/outbound/stock/Stock";

//Settings
import Shippers from "./environments/settings/warehouse/shippers/Shippers";
import SettingsGeneral from "./environments/settings/warehouse/general/General";
import SettingsWebshop from "./environments/settings/integrations/webshop/Index";

//Inbound
import InboundDashboard from "./environments/warehouse/inbound/dashboard/index";
import InboundInbounds from "./environments/warehouse/inbound/inbounds/index";

// Relation
import RelationDashboard from "./environments/relation/dashboard";
import RelationSettingsWebshop from "./environments/relation/settings/webshop/Index";
import RelationFinancialInvoices from "./environments/relation/financial/invoices";

import Carriers from "./environments/settings/warehouse/outbound/carriers/Carriers";
import Echeck from "./environments/warehouse/echeck/index";

import Topbar from "./components/global/Topbar";
import { RequireAuth } from "./authentication/RequireAuth";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { AxiosBackendWrapper, AxiosWrapper } from "./api/Settings";
import Logout from "./environments/global/Logout";
import { useDispatch, useSelector } from "react-redux";
import { removeViewInstance, resetFilter } from "./newapi/global/filter/gridFilterSlice";
import { UserState, selectAuthStatus } from "./newapi/user/authentication/authSlice";
import AccountGeneral from "./environments/user/account/General";
import { UserPrivileges } from './utils/permissions';
import MigrationImport from "./environments/settings/warehouse/import/MigrationImport";
import Users from "./environments/settings/access/users/Users";
import Usergroups from "./environments/settings/access/usergroups/Usergroups";
import RelationOutboundOrders from "./environments/relation/orders";
import RelationOutbound from "./environments/relation/orders";
import RelationInbound from "./environments/relation/inbounds";
import RelationProducts from "./environments/relation/products/Index";
import SettingsSupplier from "./environments/relation/settings/supplier/Index";
import Warehouses from "./environments/settings/warehouse/warehouses/Warehouses";
import Workspaces from "./environments/settings/warehouse/office/workspaces/Workspaces";
import Printers from "./environments/settings/warehouse/office/printers/Printers";
import SettingsReturnPortal from "./environments/relation/settings/returnportal/Index";
import Resources from "./environments/settings/warehouse/picking/resources/Resources";
import SettingsCountingStrategies from "./environments/settings/warehouse/countingstrategies/CountingStrategies";
import SettingsWorkflowTemplates from "./environments/settings/warehouse/workflowtemplates/WorkflowTemplates";
import SettingsProductFields from "./environments/settings/products/customfields/Index";
import Forgotpassword from "./scenes/login/ForgotPassword";
import ResetPassword from "./scenes/login/ResetPassword";
import RelationReturns from "./environments/relation/returns";
import RelationUsers from "./environments/relation/access/users/Users";
import RelationUsergroups from "./environments/relation/access/usergroups/Usergroups";
import SettingsIntegrator from "./environments/settings/integrations/integrator/Index";

// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
// import { TouchBackend } from 'react-dnd-touch-backend';
// import  {MultiBackend, TouchTransition, MouseTransition } from 'react-dnd-multi-backend';
// import { Preview } from 'react-dnd-multi-backend';

// // Define your backends
// const HTML5toTouch = {
//   backends: [
//     {
//       backend: HTML5Backend,
//       transition: MouseTransition,
//     },
//     {
//       backend: TouchBackend, // Note that you can pass options to the TouchBackend here
//       options: { enableMouseEvents: true },
//       preview: true,
//       transition: TouchTransition,
//     },
//   ],
// };

const AppMenu = () => {
  const theme = useTheme();
  const menuContext = useContext(MenuStateContext);
  const collapsed = menuContext.collapsed;
  const backdropRef = useRef(null);

  useEffect(() => {
      if(collapsed && window.innerWidth <= 1366) {
        backdropRef.current.classList.add("menu-backdrop-enter-done");
      }
  },[theme.palette.mode]);

  return (
    <React.Fragment>
      <SHTransition
      nodeRef={backdropRef}
        in={collapsed && window.innerWidth <= 1366}
        classNames="menu-backdrop"
        appear
        
        timeout={500}
      >
        <Box className={`${theme.palette.mode}`} onClick={() => menuContext.setCollapsed.toggleMenuState()} ref={backdropRef} sx={{position:"fixed", left:0,top:0,width:1,height:1,zIndex:"-1"}}></Box> 
      </SHTransition>
      
      <ToastContainer autoClose={1200} />
    </React.Fragment>
  )

}

function App() {
  console.log("powo");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoginPage = location.pathname === "/login" || location.pathname === "/forgot" || location.pathname === "/reset";


   
  useEffect(() => {
    dispatch(resetFilter());
  },[])



  return (
    // <DndProvider backend={MultiBackend} options={HTML5toTouch}>
     <div className="app">
        {!isLoginPage && <Sidebar/>}
        <Box 
          component={"main"} 
          className={cx(`content`, {
            'hide-side' : isLoginPage
          })}
          sx={{
            background:theme.palette.mode === "dark" ? colors.grey["200"] : colors.grey["0"]
          }}
        >
        {!isLoginPage && <Topbar/>}
          {/* <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/team" element={<Team />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/form" element={<Form />} />
            <Route path="/bar" element={<Bar />} />
            <Route path="/pie" element={<Pie />} />
            <Route path="/line" element={<Line />} />
            <Route path="/calendar" element={<Calendar />} />
          </Routes> */}
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth path="/login">
                <Dashboard />
              </RequireAuth>
            }
          ></Route>

          <Route path="/warehouse">
            {/* Echeck Route */}
            <Route path="dashboard" 
              element={
                <RequireAuth permissions={[UserPrivileges.ECHECK]} path="/login">
                  <Dashboard />
                </RequireAuth>
              }
            />

            {/* Outbound Routes */}
            <Route path="outbound">
              <Route path="dashboard" 
                element={
                  <RequireAuth permissions={[UserPrivileges.OUTBOUND_MANAGE]} path="/login">
                    <OutboundDashboard />
                  </RequireAuth>
                }
              />
              <Route path="orders" 
                element={
                  <RequireAuth permissions={[UserPrivileges.OUTBOUND_READ]} path="/login">
                    <OutboundOrders />
                  </RequireAuth>
                }
              />
              <Route path="batches" 
                element={
                  <RequireAuth permissions={[UserPrivileges.OUTBOUND_READ]} path="/login">
                    <OutboundBatches />
                  </RequireAuth>
                }
              />
            </Route>

            {/* Automation Routes */}
            <Route path="automation">
              <Route path="workflows" 
                element={
                  <RequireAuth permissions={[UserPrivileges.WORKFLOW_READ]} path="/login">
                    <AutomationWorkflows />
                  </RequireAuth>
                }
              />
            </Route>

            {/* Relations Routes */}
            <Route path="relations">
              <Route path="" 
                element={
                  <RequireAuth permissions={[UserPrivileges.RELATION_READ, UserPrivileges.RELATION_MANAGE]} path="/login">
                    <WarehouseRelation />
                  </RequireAuth>
                }
              />
            </Route>

            {/* Financial Routes */}
            <Route path="financial">
              <Route path="invoices" 
                element={
                  <RequireAuth permissions={[UserPrivileges.INVOICE_READ, UserPrivileges.INVOICE_MANAGE]} path="/login">
                    <FinancialInvoices />
                  </RequireAuth>
                }
              />
              <Route path="components" 
                element={
                  <RequireAuth permissions={[UserPrivileges.INVOICEPRODUCT_READ, UserPrivileges.INVOICEPRODUCT_MANAGE]} path="/login">
                    <FinancialInvoiceComponents />
                  </RequireAuth>
                }
              />
            </Route>

            {/* Inbound Routes */}
            <Route path="inbound">
              <Route path="dashboard" 
                element={
                  <RequireAuth permissions={[UserPrivileges.INBOUND_READ]} path="/login">
                    <InboundDashboard />
                  </RequireAuth>
                }
              />
              <Route path="inbounds" 
                element={
                  <RequireAuth permissions={[UserPrivileges.INBOUND_READ]} path="/login">
                    <InboundInbounds />
                  </RequireAuth>
                }
              />
            </Route>

            {/* Stock Routes */}
            <Route path="stock">
              <Route path="stock" 
                element={
                  <RequireAuth permissions={[UserPrivileges.STOCK_READ]} path="/login">
                    <StockManagement />
                  </RequireAuth>
                }
              />
              <Route path="locations" 
                element={
                  <RequireAuth permissions={[UserPrivileges.STOCK_READ]} path="/login">
                    <StockLocation />
                  </RequireAuth>
                }
              />
              <Route path="products" 
                element={
                  <RequireAuth permissions={[UserPrivileges.STOCK_READ]} path="/login">
                    <StockProduct />
                  </RequireAuth>
                }
              />
              
              <Route path="countingstrategies" 
                element={
                  <RequireAuth permissions={[UserPrivileges.COUNTINGSTRATEGY_READ]} path="/login">
                    <AutomationCountingStrategies />
                  </RequireAuth>
                }
              />
            </Route>

            {/* Echeck Route */}
            <Route path="echeck" 
              element={
                <RequireAuth permissions={[UserPrivileges.ECHECK]} path="/login">
                  <Echeck />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/relation">

            <Route path="dashboard" 
              element={
                <RequireAuth path="/login">
                  <RelationDashboard />
                </RequireAuth>
              } 
            />
            <Route path="outbound" 
              element={
                <RequireAuth path="/login">
                  <RelationOutbound />
                </RequireAuth>
              } 
            />
            <Route path="products" 
              element={
                <RequireAuth path="/login" permissions={[UserPrivileges.RP_PRODUCT_READ, UserPrivileges.RP_PRODUCT_MANAGE]}>
                  <RelationProducts />
                </RequireAuth>
              } 
            />
            <Route path="inbound" 
              element={
                <RequireAuth path="/login">
                  <RelationInbound />
                </RequireAuth>
              } 
            />
            <Route path="returns" 
              element={
                <RequireAuth path="/login">
                  <RelationReturns />
                </RequireAuth>
              } 
            />

            <Route path="financial/invoices" 
              element={
                <RequireAuth path="/login">
                  <RelationFinancialInvoices />
                </RequireAuth>
              } 
            />
            <Route path="settings">
              <Route path="access/users" 
                element={
                  <RequireAuth path="/login">
                    <RelationUsers />
                  </RequireAuth>
                } 
              />
              <Route path="access/usergroups" 
                element={
                  <RequireAuth path="/login">
                    <RelationUsergroups />
                  </RequireAuth>
                } 
              />
              <Route path="suppliers" 
                element={
                  <RequireAuth path="/login">
                    <SettingsSupplier />
                  </RequireAuth>
                } 
              />
              <Route path="saleschannels" 
                element={
                  <RequireAuth path="/login">
                    <RelationSettingsWebshop />
                  </RequireAuth>
                } 
              />
            </Route>
          </Route>

          <Route
            path="/settings"
          >
            <Route path="outbound/carriers" 
              element={
                <RequireAuth
                  path="/login"
                >
                  <OutBoundSettingsCarriers/>
                </RequireAuth>
              }
            />
            <Route path="outbound/picking" 
              element={
                <RequireAuth
                  path="/login"
                >
                  <OutBoundSettingsPicking/>
                </RequireAuth>
              }
            />
            <Route path="outbound/stock" 
              element={
                <RequireAuth
                  path="/login"
                >
                  <OutboundSettingsStock/>
                </RequireAuth>
              }
            />
            <Route path="general" 
              element={
                <RequireAuth
                  path="/login"
                >
                  <SettingsGeneral/>
                </RequireAuth>
              }
            />
            <Route path="shippers" 
              element={
                <RequireAuth
                  path="/login"
                >
                  <Shippers/>
                </RequireAuth>
              }
            />
            <Route path="warehouses" 
              element={
                <RequireAuth
                  path="/login"
                >
                  <Warehouses/>
                </RequireAuth>  
              }
            />
            <Route path="picking/streams" 
              element={
                <RequireAuth
                  path="/login"
                >
                  <OutBoundSettingsStreams/>
                </RequireAuth>
              }
            />
            <Route path="picking/resources" 
              element={
                <RequireAuth
                  path="/login"
                >
                  <Resources/>
                </RequireAuth>
              }
            />
            <Route path="migratie" element={
                <RequireAuth
                  path="/login"
                >
                  <MigrationImport/>
                </RequireAuth>
              }
            />
            <Route path="products/customfields" 
              element={
                <RequireAuth
                  path="/login"
                >
                  <SettingsProductFields/>
                </RequireAuth>
              }
            />
              
            <Route path="automation/countingstrategies" 
              element={
                <RequireAuth path="/login">
                  <SettingsCountingStrategies />
                </RequireAuth>
              }
            />
            <Route path="automation/workflowtemplates" 
              element={
                <RequireAuth path="/login">
                  <SettingsWorkflowTemplates />
                </RequireAuth>
              }
            />
            <Route path="integrations/saleschannels" 
              element={
                <RequireAuth path="/login">
                  <SettingsWebshop />
                </RequireAuth>
              }
            />
            <Route path="integrations/integrators" 
              element={
                <RequireAuth path="/login">
                  <SettingsIntegrator />
                </RequireAuth>
              }
            />
            <Route path="integrations/pim" 
              element={
                <RequireAuth path="/login">
                  <SettingsWebshop />
                </RequireAuth>
              }
            />
            <Route path="office/workspaces" 
              element={
                <RequireAuth path="/login">
                  <Workspaces />
                </RequireAuth>
              }
            />
            <Route path="office/printers" 
              element={
                <RequireAuth path="/login">
                  <Printers />
                </RequireAuth>
              }
            />
            <Route path="access/users" 
              element={
                <RequireAuth path="/login">
                  <Users />
                </RequireAuth>
              }
            />
            <Route path="access/usergroups" 
              element={
                <RequireAuth path="/login">
                  <Usergroups />
                </RequireAuth>
              }
            />
            <Route path="access/usergroups" 
              element={
                <RequireAuth
                  path="/login"
                >
                  <Usergroups/>
                </RequireAuth>
              }
            />
          </Route>

          <Route
            path="/user"
          >
            <Route path="account/general" element={
                <RequireAuth
                  path="/login"
                >
                  <AccountGeneral/>
                </RequireAuth>
              }
            />
            
          </Route>

          <Route
            path="/*"
            element={
                <Dashboard />
              // </RequireAuth>
            }
          ></Route>
          <Route path="/logout" element={<Logout />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/forgot" element={<Forgotpassword />}></Route>
          <Route path="/reset" element={<ResetPassword />}></Route>
        </Routes>

        <AppMenu/>
        </Box>
      </div>
    // </DndProvider>
  );
}

export default App;
