/**
 * Formats a price according to the given locale with EUR currency
 * @param {object} i18n - Internationalization object containing language preference
 * @param {number} price - The price value to format
 * @returns {string} - Formatted price string with currency symbol
 */
exports.formatPrice = (i18n,price) => {
    return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'EUR'
    }).format(price);
}

/**
 * Removes unnecessary trailing zeros from a number
 * @param {number|string} input - The number to format
 * @returns {string} - Formatted number without trailing zeros
 *                     (e.g., "1.20" becomes "1.2", "1.00" becomes "1")
 */
exports.removeTrailingZeros = (input) => {
    // Format amount to remove trailing zeros and only show necessary decimals
    const num = Number(input);
    return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2).replace(/\.?0+$/, '');
}

/**
 * Checks if a given date is in the future, comparing dates only (ignoring time)
 * @param {string} date - Date string in YYYY-MM-DD format
 * @returns {boolean} - Returns true if the date is in the future, false otherwise
 */
exports.isFutureDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const compareDate = new Date(date);
    compareDate.setHours(0, 0, 0, 0);
    
    return compareDate > today;
}
