import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import Objects from "./Views/Objects";
import Logs from "./Views/Logs";

import {useTranslation} from "react-i18next";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useGetCountingStrategyQuery } from "../../../../newapi/countingstrategy/countingStrategySlice";
import { useSelector } from "react-redux";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spCountingStrategyDetailsConfig } from "./spCountingStrategyDetailsConfig";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import MConfirmationWithValue from "../../Modals/MConfirmationWithValue";
import CheckIcon from '@mui/icons-material/Check';
import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useCompleteCountingStrategyMutation } from "../../../../newapi/countingstrategy/countingStrategySlice";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../utils/toasts";
import Text from "../../../theme/text/Text";
import Subheading from "../../../theme/text/Subheading";
import ShLabel from "../../../theme/label/ShLabel";

const SidePanelActions = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>
            {props.state === "active" ? (
                <Shbutton
                    className={"group-btn"}
                    onClick={props.completeAction}
                    color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    variant="contained"
                    endIcon={<CheckIcon />}
            >
                    {t('complete')}
                </Shbutton>
            ) : (
                <Box
                    sx={{
                        ml:"auto",
                        mr:"-24px",
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <ShLabel sx={{
                        borderTopRightRadius:0,
                        borderBottomRightRadius:0,
                        borderRight:0,
                        minWidth:210,
                    }} variant="green" palette="normal" fitted>{t(props.state)}</ShLabel>
                </Box>
            )}
        </SidepanelHeaderActiongroup>
    )
}

const SpCountingStrategyDetails = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { t, ready, i18n } = useTranslation();
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    const [completeCountingStrategy, { isLoading: isCompleteLoading }] = useCompleteCountingStrategyMutation();

    const { data: strategyData, isLoading,isFetching,isError } = useGetCountingStrategyQuery(props.strategyId,
    {
        skip: !props.strategyId
    });
        
    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ImportExportIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        // { component: <ShRowSkeleton heading={t("total_products")} /> },
        // { component: <ShRowSkeleton heading={t("total_locations")} /> }
    ], [t]);



    const confirmComplete = async () => {
        try {
            const response = await completeCountingStrategy(props.strategyId).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageRequestError(t('update_failed'),t, err);
        }
    };
    
    const [open, setOpen] = useState(false);
    const completeActionModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmComplete,
        data: {
            title: t("complete_counting_strategy"),
            subtitle: t("complete_counting_strategy_confirmation"),
            content: t("are_you_sure_you_want_to_complete_this_counting_strategy")
        }
    } 

    const handleCompleteClick = useCallback(() => {
        setOpen(true);
    }, []);
    
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions: <SidePanelActions setIsExtended={setIsExtendedCallback}/>,
        styling: {
            header : {
                padding:"0px !important",
            }
        },
    }), [defaultIcon, defaultOptions, setIsExtendedCallback,props.data]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.strategyId,theme.palette.mode])
    

    const getBackgroundColor = useCallback((type,contrast=400) => {
        let tabColor = colors["grey"][contrast];
        if(type === null) {
            return tabColor;
        }
        if(type === "picking_locations"){
            tabColor = colors["pink"][contrast];
        }
        else if(type === "bulk_locations"){
            tabColor = colors["orange"][contrast];
        }
        else if(type === "all_locations"){
            tabColor = colors["green"][contrast];
        }
        else if(type === "inventory_turnover_ratio"){
            tabColor = colors["purple"][contrast];
        }
        return tabColor;
    })
    useEffect(() => {
        if (!isLoading && !isFetching && strategyData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: strategyData.name,
                subTitle: "",
                icon :defaultIcon,
                options : [
                    // {
                    //     heading: t("total_products"),
                    //     content: strategyData.total_products
                    // },
                    // {
                    //     heading: t("total_locations"),
                    //     content: strategyData.total_locations
                    // }
                ],
                styling: {
                    header : {
                        padding:"0px !important",
                    }
                },
                tabColor:getBackgroundColor(strategyData?.type),
                actions: <SidePanelActions setIsExtended={setIsExtendedCallback} showComplete={strategyData?.state === "active"} completeAction={handleCompleteClick}/>
            }));
        }
    }, [strategyData, isLoading, isFetching, t,theme.palette.mode]);
   
    const date = useMemo(() => {
        return strategyData?.createdAt && strategyData.createdAt !== null
          ? new Date(strategyData.createdAt).toLocaleDateString(i18n.language)
          : "-";
      }, [strategyData?.createdAt, i18n.language]);

    const completedAt = useMemo(() => {
        return strategyData?.completedAt && strategyData.completedAt !== null
          ? new Date(strategyData.completedAt).toLocaleDateString(i18n.language)
          : "-";
      }, [strategyData?.completedAt, i18n.language]);

    return (<>
        <Sidepanel2
            isCustom
            skeleton={isLoading || isFetching}
            tabConfig={spCountingStrategyDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    <Objects config={spCountingStrategyDetailsConfig.objects} strategyId={props.strategyId} strategyType={strategyData?.type}/>
                    {/* <Logs config={spCountingStrategyDetailsConfig.logs} id={props.strategyId}/>  */}
                </>

                <Box
                    sx={{
                        paddingBottom:3,
                        // position:"relative",
                    }}
                >
                    <Box
                        sx={{
                            pr:3,
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"flex-end",
                        }}
                    >
                        <Box
                            sx={{
                                background:getBackgroundColor(strategyData?.type,100),
                                pt:3,
                                px:3,
                                pb:2,
                                fontSize:21,
                                fontWeight:"bold",
                                fontFamily:"Roboto, sans-serif",
                                color: getBackgroundColor(strategyData?.type),
                                borderBottomRightRadius:24,
                                "@media screen and (max-width: 47.9375em)" : {
                                    position:"absolute",
                                    top:0,
                                },
                                "@media screen and (max-width: 566px)" : {
                                    display:"none"
                                }
                            }}
                        >
                           {t(strategyData?.type)}
                        </Box>
                        <Box
                            sx={{
                                pb:1,
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"none"
                                }
                            }}
                        >
                            {isLoading || isFetching ? 
                                <ShTextSkeleton/> 
                            : 
                                strategyData?.state !== "active" ? 
                                <ShLabel variant="green" palette="normal" fitted>{t(strategyData?.state)}</ShLabel>
                            :
                                <SidePanelActions setIsExtended={setIsExtendedCallback} state={strategyData?.state} completeAction={handleCompleteClick}/>  
                            }
                        </Box>

                        <Box
                            sx={{
                                display:"unset",
                                "@media screen and (min-width: 47.9375em)" : {
                                    display:"none",
                                }
                            }}
                        >
                            {/* {console.log(strategyData?.state)} */}
                            <SidePanelActions setIsExtended={setIsExtendedCallback} state={strategyData?.state} completeAction={handleCompleteClick}/>
                        </Box>

                    </Box>

                    <Box
                        sx={{
                            px:3,
                            pt:4,
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center",
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column"
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"flex",
                                    alignItems:"center",
                                    flexDirection:"column",
                                    mb:4,
                                }
                            }}
                        >
                            <Box
                                sx={{

                                    display:"flex",
                                    alignItems:"center",
                                    gap:2,
                                }}
                            >
                                <Box
                                    sx={{
                                        display:"flex",
                                        gap:1,
                                    }}
                                >
                                    <Subheading>{strategyData?.name}</Subheading>
                                </Box>

                            </Box>
                            <Box>
                                <Text light>{t(strategyData?.frequency)}</Text>
                            </Box>
                        </Box>
{/* <Subheading */}
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"flex",
                                    gap:2,
                                    // flexDirection:"column"
                                },
                                "@media screen and (max-width: 566px)" : {
                                    width:1,
                                    flexDirection:"column"
                                }
                            }}
                        >

                            <Box
                                sx={{
                                    // height:40,
                                    lineHeight:"40px",
                                    background:getBackgroundColor(strategyData?.type,100),
                                    px:3,
                                    fontSize:14,
                                    fontWeight:"bold",
                                    fontFamily:"Roboto, sans-serif",
                                    color: getBackgroundColor(strategyData?.type),
                                    borderRadius:20,
                                    display:"none",
                                    alignItems:"center",
                                    flex:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        display:"flex",
                                    },
                                    "@media screen and (max-width: 566px)" : {
                                        justifyContent:"center"
                                    }
                                }}
                            >
                                {t(strategyData?.type)}
                            </Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    gap:4,
                                    borderRadius:7,
                                    height:56,
                                    px:4,
                                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                                    "@media screen and (max-width: 47.9375em)" : {
                                        // width:400,
                                    },
                                    "@media screen and (max-width: 566px)" : {
                                        flexDirection:"column",
                                        gap:2,
                                        height:"unset",
                                        px:3,
                                        py:3,
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 566px)" : {
                                            flexDirection:"row",
                                            width:1,
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Text light>{t("amount_of_objects")}</Text>
                                    <Text>{strategyData?.amountOfSteps}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 566px)" : {
                                            flexDirection:"row",
                                            width:1,
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Text light>{t("created_on")}</Text>
                                    <Text>{date}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        "@media screen and (max-width: 566px)" : {
                                            flexDirection:"row",
                                            width:1,
                                            justifyContent:"space-between",
                                        }
                                    }}
                                >
                                    <Text light>{t("completed_on")}</Text>
                                    <Text>{completedAt}</Text>
                                </Box>
                                
                            </Box>
                            
                        </Box>

                    </Box>
                </Box>
        </Sidepanel2>
        
        <MConfirmationWithValue
                    open={completeActionModal.open}
                    handleClose={() => completeActionModal.setOpen(false)}
                    data={completeActionModal.data}
                    confirm={completeActionModal.confirm}
                />
                </>
    );
};

export default SpCountingStrategyDetails;