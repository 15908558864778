
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

export const relationSettingsSupplierConfig = {
    all:  {
        title: 'suppliers',
        subtitle: 'suppliers',
        icon: <Inventory2OutlinedIcon/>,
    },
};
