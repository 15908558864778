import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import { useGetWarehouseLevelsQuery, useDeleteWarehouseLevelMutation, useEditWarehouseLevelIndexMutation } from "../../../../../newapi/warehouse/warehouseApiSlice";
import AddIcon from '@mui/icons-material/Add';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import DeleteIcon from '@mui/icons-material/Delete';
import { showToastMessageDeleteRequest, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import MAddWarehouseLevel from "../../../Modals/MAddWarehouseLevel";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('new_level')}
            </Shbutton>
        </ButtonGroup>
    )
}

const WarehouseLevels = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [isExtended, setIsExtended] = useState(false);
    const [config, setConfig] = useState(props.warehouseId);

    const [view, setView] = useState({
        title: 'levels',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
        apiRef: props.apiRef,
        mobileActions: <GridActions />,
        gridOptions:gridOptions,
    });
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

      const [DeleteWarehouseLevel,{syncIsLoading}] = useDeleteWarehouseLevelMutation();
      const handleDeleteWarehouseLevel = async (id) => {
          try {
              const response = await DeleteWarehouseLevel({id: id, warehouseId: props.warehouseId}).unwrap();
              showToastMessageDeleteRequest(t, response);
          } catch (err) {
              showToastMessageDeleteRequest(t, {success: false, error: err.error});
          }
      };



      const [EditWarehouseLevelIndex,{syncEditIsLoading}] = useEditWarehouseLevelIndexMutation();
      const handleUpdateIndex = async (id, mutation) => {
          try {
              const response = await EditWarehouseLevelIndex({id: id, warehouseId: props.warehouseId, mutation}).unwrap();
              showToastMessageUpdateRequest(t, response);
          } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
          }
      };
    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);


    const columns = [
        {
            field: "description", 
            headerName: t('description'),
            flex:2, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.description
            }
        },
        {
            field: "index", 
            headerName: t('level'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.index
            }
        },
        {
            field: "actions", 
            headerName: "",
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                <Box>
                    <IconButton type="button" onClick={() => handleDeleteWarehouseLevel(params.row.id)}
                    sx={{color: colors.red[400]}}>
                        <DeleteIcon/>
                    </IconButton>
                    { params.row.index > 1 ? 
                    <IconButton type="button" onClick={() => handleUpdateIndex(params.row.id, -1)}
                    sx={{transform: 'rotate(270deg)'}}>
                        <ArrowRightAltIcon/>
                    </IconButton> 
                    : 
                    <></>
                    }
                    { !params.row.isMax ? 
                    <IconButton type="button" onClick={() => handleUpdateIndex(params.row.id, 1)}
                    sx={{transform: 'rotate(90deg)'}}>
                        <ArrowRightAltIcon/>
                    </IconButton> 
                    : 
                    <></>
                    }
                </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetWarehouseLevelsQuery}
                itemId={props.warehouseId}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t('levels')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>
            <MAddWarehouseLevel warehouseId={props.warehouseId} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}

export default WarehouseLevels;