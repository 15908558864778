
import WidgetsIcon from '@mui/icons-material/Widgets';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const spLocationDetailsConfig = {
    stockOnLocation:  {
        title: 'stock_on_location',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    details: {
        title: "details",
        icon: <CropFreeIcon/>,
    },
    logs: {
        title: 'logs',
        icon: <FormatListBulletedIcon/>,
        isSingleDataGrid: true,
    }
};
