import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '../../../../../../components/theme/text/Text';
import { tokens } from '../../../../../../theme';

// import { tokens } from "../../../../theme";
const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}

const Standard = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WareHouseDropdown/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    // useEffect(() => {
    //     if (props.apiRef.current) {
    //         props.addView({
    //             ...view,
    //         },true);
    //     } 
    // }, [props.apiRef.current]);

    // useEffect(() => {
    //     if (props.apiRef.current) {
    //         let newView = {
    //             ...view,
    //             apiRef: props.apiRef
    //         }
    //         setView(newView)
    //         props.addView(newView,true);
    //     }
    // }, [props.apiRef.current]);
    
    // useEffect(() => {
    //     if(props.isActive && !dataHasBeenSet) {
    //         // getOrderStatusCounts((result,data) => {
    //         //     if(result){
    //         //         data.Relations.push({
    //         //             id: 9999,
    //         //             name: "Totaal",
    //         //             shipped: data.All.shipped,
    //         //             open: data.All.open,
    //         //             overdue: data.All.overdue,
    //         //             phoneView: ""
    //         //         });
    //                 // setData(data.Relations);
    //                 setDataHasBeenSet(true);
    //             // }
    //         // });
    //     }
    // },[props.isActive]);

    return (
        <Box 
        sx={{
            background:"orange"
        }}>

        </Box>
    )
};


// const Standard = () => {
//     return {
//         title: "Standard carriers",
//         icon: <LocalShippingOutlinedIcon/>,
//         view: <StandardBody/>,
//         topRight:<WareHouseDropdown/>,
//     }
// }

export default Standard;