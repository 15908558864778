import { Box, Fade, Popper, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { tokens } from "../../theme";
import CheckIcon from '@mui/icons-material/Check';
import { ReactComponent as Triangle } from "../../styles/svg/triangle.svg";
import { useTranslation } from "react-i18next";

export const CopyPopper = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        
      <Popper sx={{zIndex:1200,}} open={props.open} anchorEl={props.anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box 
                sx={{ 
                    mt:2,
                    // border: 1, 
                    borderRadius:5,
                    // height:40,
                    pr:0.5,
                    height:40,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    color:colors.blue[400],
                    fontWeight:400,
                    bgcolor: theme.palette.mode === "dark" ? colors.grey[400] : colors.txt["primary"],
                    width:200,
                    wordBreak:"break-all",
                    textAlign:"center",
                    fontSize:14,
                    userSelect:"none",
                    position:'relative',
                    "& .popover-triangle" : {
                        position:"absolute",
                        left:"50%",
                        top:-11,
                        width:32,
                        height:12,
                        zIndex:200,
                        transform:"translateX(-50%)",
                        "& *" : {
                            fill: theme.palette.mode === "dark" ? colors.grey[400] : colors.txt["primary"],
                        }
                    },
                }}
            >
                
                <Triangle 
                    className="popover-triangle"
                ></Triangle>
                <Typography
                    variant="p"
                    sx={{
                        color:"#fff",
                        // height:32,
                        // background:"rgba(255,255,255,.15)",
                        // borderRadius:4,
                        // px:1,
                        display:"flex",
                        alignItems:"center",
                        fontSize:16,
                        fontWeight:700,
                    }}
                >
                    {t("copied")}! 
                </Typography>
                <Box
                    sx={{
                        bgcolor:"rgba(147,251,245,.15)",
                        color:"rgba(147,251,245,1)",
                        height:'32px',
                        width:"32px",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        borderRadius:4,
                        position:"absolute",
                        top:4,
                        right:4
                    }}
                >
                    <CheckIcon/>
                </Box>
              {/* {props.message} */}
            </Box>
          </Fade>
        )}
      </Popper>
    );
  };

export const Copy = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpen(true);
        props.onClick();
        setTimeout(() => {
            setOpen(false);
            setAnchorEl(null);
        }, props.closeAfter ? props.closeAfter : 3000); // Automatically close the popper after 3 seconds
    };

    const child = React.Children.only(props.children);

    const enhancedChild = React.cloneElement(child, {
        ...child.props,
        onClick: handleClick, // Enhance the child component with the onClick handler
    });

    return (
        <>
            {enhancedChild}
            <CopyPopper open={open} anchorEl={anchorEl} message={props.message} />
        </>
    );
};