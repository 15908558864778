import { Box, useTheme, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";

import { useTranslation } from "react-i18next";
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import AddIcon from '@mui/icons-material/Add';
import MAddProduct from "../../../Modals/MAddProduct";
import { useSelector } from "react-redux";
import { ENV_TYPES } from "../../../Sidebar";
import DeleteIcon from '@mui/icons-material/Delete';
import { showToastMessageDeleteRequest } from "../../../../../utils/toasts";
import { useDeletePriceIncreaseProductMutation, useGetPriceIncreaseProductsQuery } from "../../../../../newapi/financial/priceIncreaseSlice";
import Text from "../../../../theme/text/Text";
import ShLabel from "../../../../theme/label/ShLabel";


const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];

    return (
        <ButtonGroup version={2}>
            {!isRelation && props.state === "active" && <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('add_products')}
            </Shbutton>}
        </ButtonGroup>
    )
}



const Products = (props) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];
    const [selectedInvoiceLine, setSelectedInvoiceLine] = useState({id: 0});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions: <GridActions state={props.state} handleOpen={() => setAddModalOpen(true)}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const gridOptions = {
        export:false,
        disableCustomActionsOnPhone: props.state === "active",
        isSidepanel: true,
        
        filter : {
            name: "stockFilter",
            selectedFilter : null,
            groups : [
                {
                    conditionExtensionType : 0,
                    rules : [
                    ]
                }
            ]
        }
    };
    // console.log("props.priceIncreaseId",props.priceIncreaseId);

    const handleOpen = () => {
        setAddModalOpen(true);
    }
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const handleEditOpen = () => {
        setAddModalOpen(true);
    }
    
    const [editModalOpen, setEditModalOpen] = useState(false);
    const editModal = {
        open: editModalOpen,
        setOpen: setEditModalOpen
    }

    const handleEditClose = () => {
        setEditModalOpen(false);
    };

    

    const [DeleteProducts,{syncIsLoading}] = useDeletePriceIncreaseProductMutation();
    const handleDeleteProduct = async (product) => {
        try {
            const response = await DeleteProducts({ 
                id: props.priceIncreaseId, 
                productId: product.id 
            }).unwrap();
            // const response = await DeleteProducts(props.priceIncreaseId, product.id).unwrap();
            showToastMessageDeleteRequest(t, response);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };

    // const handleDeleteProduct = (product) => {
    //     console.log()
    //     // setSelectedInvoiceLine(invoiceLine);
    //     // setEditModalOpen(true);
    // }
    const columns = [
        {field: "name", headerName: t("name"),flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return <Text>{params.row.name}</Text>
        }},
        {
            field: "type", 
            headerName: t("type"),
            width:180,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                let labelColor = "grey";
                switch(params.row.type) {
                    case "time" :
                        labelColor = "grey";
                        break;
                    case "material" :
                        labelColor = "orange";
                        break;

                    case "process_order" :
                        labelColor = "pink";
                        break;

                    case "process_orderline" :
                        labelColor = "purple";
                        break;

                    case "default" :
                        labelColor = "michael_brown";
                        labelColor = "blue";
                        break;

                }
                return (
                    <ShLabel fitted size={32} minWidth={150}  variant={labelColor}>
                      {t(params.row.type)}
                    </ShLabel>
                );
            }
        },
        {
            field: "actions", 
            headerName: "",
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
                        {props.state === "active" &&
                            <IconButton type="button" onClick={() => handleDeleteProduct(params.row)}
                            sx={{ 
                                color: colors.red[400]
                                }}>
                                <DeleteIcon/>
                            </IconButton>
                        }
                    </Box>
                )
            }
        },
        // {field: "description", headerName: t("description"), flex:1, renderCell : (params) => {
        //     if(params.row.skeleton) {
        //         return <ShTextSkeleton/>
        //     }

        //     return params.row.description;
        // }},
        // {field: "price", headerName: t("price"), flex:1,
        //     renderCell: (params) => {
        //         if(params.row.skeleton) {
        //             return <ShTextSkeleton/>
        //         }
    
        //         return formatPrice(i18n, params.row.price);
        //     }
        // },
        // {field: "total", headerName: t("total"), flex:1,
        //     renderCell: (params) => {
        //         if(params.row.skeleton) {
        //             return <ShTextSkeleton/>
        //         }
    
        //         return formatPrice(i18n, (params.row.amount * params.row.price));
        //     }
        // },
        
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <ShTextSkeleton/>
                )
            }
        },
    ];


  return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
               {/* <GridActions></GridActions> */}
               <ShDataGrid2 key={theme.palette.mode}
                content={useGetPriceIncreaseProductsQuery}
                itemId={props.priceIncreaseId}
                gridActions={<GridActions state={props.state} handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t('products')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
            >
            </ShDataGrid2>
            <MAddProduct 
                onOpen={() => addModal.setOpen(true)} 
                priceIncreaseId={props.priceIncreaseId}
                onClose={() => addModal.setOpen(false)} 
                open={addModal.open} 
                handleClose={handleClose} 
            />
        </Box>
    );
}

export default Products;