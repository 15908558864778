import { ENV_TYPES } from "../../components/global/Sidebar";
import { apiSlice } from "../createApiSlice";

const API_BASE = "/import";
const buildUrl = (endpoint) => API_BASE + endpoint;

export const uploadApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        uploadImport: builder.mutation({
            query: (args) => {
                const { source, type, file } = args;
                const formData = new FormData();
                formData.append('file', file);

                return {
                    url: buildUrl(`/preprocess/${source}/${type}`),
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        processImport: builder.mutation({
            query: (args) => {
                const { source, type, data } = args;
                return {
                    url: buildUrl(`/process/${source}/${type}`),
                    method: 'POST',
                    body: data,
                };
            },
        }),
        uploadLogo: builder.mutation({
            query: (args, { getState }) => {
            // query: (args,{getState}) => {
                const { file } = args;
                const formData = new FormData();
                formData.append('file', file);


                return {
                    url: buildUrl(`/logo`),
                    method: 'POST',
                    body: formData,
                    headers: args.headers
                };
            },

        }),
    })
});

export const { 
    useUploadImportMutation,
    useProcessImportMutation,
    useUploadLogoMutation,
} = uploadApiSlice;
