import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import { useGetRelationContactsQuery, useDeleteRelationContactMutation } from "../../../../../newapi/global/relation/relationSlice";
import AddIcon from '@mui/icons-material/Add';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import MAddRelationContact from "../../../Modals/MAddRelationContact";
import MUpdateRelationContact from "../../../Modals/MUpdateRelationContact";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { showToastMessageDeleteRequest } from "../../../../../utils/toasts";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('new_contact')}
            </Shbutton>
        </ButtonGroup>
        // <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        //     <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        // </Box>
    )
}

const RelationContacts = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [contactInfo, setContactInfo] = useState(null);
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };
    
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const updateModal = {
        open: updateModalOpen,
        setOpen: setUpdateModalOpen
    }
  
    const handleCloseUpdate = () => {
        setUpdateModalOpen(false);
    };

    const [contactId, setContactId] = useState(null);
    const handleDeleteContactClick = (id) => {
        setContactId(id);
    }

    useEffect(() => {
        if (contactId) {
            setConfirmDeleteModalOpen(true);
        }
    }, [contactId]);

    const [DeleteRelationContact,{syncIsLoading}] = useDeleteRelationContactMutation();
    const handleDeleteContact = async (id) => {
        try {
            const response = await DeleteRelationContact({id: contactId, relationId: props.relationId}).unwrap();
            showToastMessageDeleteRequest(t, response);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };

    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const confirmDeleteModal = {
        open: confirmDeleteModalOpen,
        setOpen: setConfirmDeleteModalOpen,
        confirm: handleDeleteContact
    }

    useEffect(() => {
        if (contactInfo) {
            updateModal.setOpen(true);
        }
    }, [contactInfo]);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions handleOpen={() => setAddModalOpen(true)}/>,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    

    const columns = [
        {
            field: "name", 
            headerName: t('name'),
            flex:1.5, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return `${params.row.first_name} ${params.row.last_name}`
            }
        },
        {
            field: "phone", 
            headerName: t('phone'),
            flex:2,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.phone
            }
        },
        {
            field: "email", 
            headerName: t('email'),
            flex:2,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.email
            }
        },
        {
            field: "actions", 
            headerName: "",
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return <Box>
                <IconButton type="button" onClick={() => setContactInfo(params.row)}
                sx={{ 
                    color: colors.txt["primary"]
                }}>
                    <EditIcon/>
                </IconButton>
                <IconButton type="button" onClick={() => handleDeleteContactClick(params.row.id)}
                sx={{ 
                    color: colors.red[400]
                }}>
                    <DeleteIcon/>
                </IconButton>
            </Box>
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetRelationContactsQuery}
                itemId={props.relationId}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t('contacts')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>
            <MAddRelationContact relationId={props.relationId} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
            <MUpdateRelationContact relationId={props.relationId} onOpen={() => updateModal.setOpen(true)} onClose={() => updateModal.setOpen(false)} open={updateModal.open} handleClose={handleCloseUpdate} contactInfo={contactInfo} />
                
            <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
        </Box>
    );
}

export default RelationContacts;