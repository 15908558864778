import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditCarrierMutation, useDeleteCarrierMutation } from "../../../../../newapi/carrier/carrierSlice";
import { useGetShippersSelectQuery } from "../../../../../newapi/shipper/shipperSlice";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedNumberInput from "../../../../theme/inputs/ShValidatedNumberInput";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired, yupNumberRequired } from "../../../../../utils/validation";

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { showToastMessageDeleteRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { useGetCarriersTypesQuery } from "../../../../../newapi/carrier/carrierSlice";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data: carrierTypes} = useGetCarriersTypesQuery();
    const [open, setOpen] = useState(false);
    

    const [carrierInfo, setCarrierInfo] = useState({
        state: "active",
        id: 4,
        name: "",
        width: 0,
        length: 0,
        height: 0,
        weight: 0,
        fitsPost: false,
        isFlexible: false,
        ownProtection: false,
        canFitOther: false,
        barcode: "",
        type: "",
        preferredShipper: 0
    });
    useEffect(() => {
        setCarrierInfo(props.data);
    },[props.data]);
    
    const handleChange = (key, value) => {
        setCarrierInfo(prevState => ({
            ...prevState,
            [key]: value
        }));
      };

      
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={carrierInfo}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[carrierInfo]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        type: yupRequired('type', t),
        width: yupNumberRequired('width', t),
        length: yupNumberRequired('length', t),
        height: yupNumberRequired('height', t),
        weight: yupNumberRequired('weight', t),
    };
    
    const schema = yup.object().shape(rules);

    const [EditCarrier,{isLoading}] = useEditCarrierMutation();
    const handleSaveClick = async () => {
        console.log(carrierInfo);
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                setFieldErrors({});
                await schema.validate(carrierInfo, { abortEarly: false });
                const data = await EditCarrier(carrierInfo).unwrap()
                showToastMessageUpdateRequest(t,data);
            } catch (err) {
                if (err.name === 'ValidationError') {
                    // Create an errors object where keys are field names and values are error messages
                    const errors = err.inner.reduce((acc, currentError) => ({
                      ...acc,
                      [currentError.path]: currentError.message
                    }), {});
              
                    // Set the validation errors in the state
                    setFieldErrors(errors);
                  } else {
                    // Handle other errors, such as network errors
                    showToastMessageRequestError(t("update_failed"), t, err);
                  }
            }
        } else {
            if(!isLoading) {
                toast.error(t("not_all_fields_filled"), {
                    position: toast.POSITION.TOP_RIGHT
                });
            }    
        }        
    };

    const [DeleteCarrierMutation] = useDeleteCarrierMutation();
    const confirmDelete = async () => {
        try {
            const response = await DeleteCarrierMutation({id: props.data.id}).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };

    const handleDeleteClick = async () => {
        setOpen(true);
    };

    const confirmDeleteModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }

    const shippersData = useGetShippersSelectQuery();
    const [shippersOptions, setShippersOptions] = useState([]);
    useEffect(() => {
        if (shippersData) {
            setShippersOptions(shippersData.data);
        }
    }, [shippersData]);
    

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("basic")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    
                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("name")}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t("active")}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t("is_flexible")}</Text>
                            </Box> 
                            <Box lineHeight={"38px"}>
                                <Text>{t("fits_post")}</Text>
                            </Box> 
                            <Box lineHeight={"38px"}>
                                <Text>{t("type")}</Text>
                            </Box> 
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <ShValidatedInput
                                    name="name"
                                    value={carrierInfo?.name}
                                    onChange={handleChange}
                                    error={fieldErrors?.name}
                                />
                            </Box>
                            <Box 
                                sx={{
                                    lineHeight:"38px",
                                    "@media screen and (max-width:47.9375em)" : {
                                        display:"flex",
                                        justifyContent:"flex-end"
                                    }
                                }}
                            >
                                <ShSwitch checked={carrierInfo?.state === "active"} onChange={(e) => handleChange('state', e.target.checked ? "active" : "inactive")} color="green" />
                            </Box>
                            <Box 
                                sx={{
                                    lineHeight:"38px",
                                    "@media screen and (max-width:47.9375em)" : {
                                        display:"flex",
                                        justifyContent:"flex-end"
                                    }
                                }}
                            >
                                <ShSwitch checked={carrierInfo?.isFlexible} onChange={(e) => handleChange('isFlexible', e.target.checked)} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                            <Box 
                                sx={{
                                    lineHeight:"38px",
                                    "@media screen and (max-width:47.9375em)" : {
                                        display:"flex",
                                        justifyContent:"flex-end"
                                    }
                                }}
                            >
                                <ShSwitch checked={carrierInfo?.fitsPost} onChange={(e) => handleChange('fitsPost', e.target.checked)} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                            <Box 
                                sx={{
                                    lineHeight:"38px",
                                    "@media screen and (max-width:47.9375em)" : {
                                        display:"flex",
                                        justifyContent:"flex-end"
                                    }
                                }}
                            >
                                <ShValidatedInputDropdown
                                    name="type"
                                    displayName="name"
                                    value={t(carrierInfo?.type)}
                                    noSelection={t("choose_type")}
                                    onChange={handleChange}
                                    options={carrierTypes ?? null}
                                    error={fieldErrors?.type}
                                />
                            </Box>


                        </Box>
                    </Box>

                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    > 
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1,
                                    // width:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("barcode")}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t("is_its_own_protection")}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t("can_fit_other_carriers")}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                            // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase sx={{ml: 2, flex:1}} placeholder="" value={carrierInfo?.barcode} onChange={(e) => handleChange('barcode', e.target.value)}/>
                            </Box>
                            <Box 
                                sx={{
                                    lineHeight:"38px",
                                    "@media screen and (max-width:47.9375em)" : {
                                        display:"flex",
                                        justifyContent:"flex-end"
                                    }
                                }}
                            >
                                <ShSwitch checked={carrierInfo?.ownProtection} onChange={(e) => handleChange('ownProtection', e.target.checked)} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                            <Box 
                                sx={{
                                    lineHeight:"38px",
                                    "@media screen and (max-width:47.9375em)" : {
                                        display:"flex",
                                        justifyContent:"flex-end"
                                    }
                                }}
                            >
                                <ShSwitch checked={carrierInfo?.canFitOther} onChange={(e) => handleChange('canFitOther', e.target.checked)} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                        </Box>
                    </Box>
                    
                </Box>
                <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                
            </Box>
            </Dropdown>
            <Dropdown title={t("shipping")} subTitle={t("shipping_dimensions_and_weight_description")} open={window.innerWidth > 767} isLast sx={{paddingBottom:"0px"}}>
            {/* <Divider></Divider> */}
                <Box sx={{
                    display:"flex",
                    flexDirection:"column"
                }}>
                    {/* Two columns */}
                    <Box
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        {/* <Box display={"flex"} flexDirection={"column"} gap={2}> */}
                            <Box lineHeight={"38px"}>
                                <Text>{t("length")} ({t("mm")})</Text>
                                <ShValidatedNumberInput
                                    btnSize={"32"}
                                    name="length"
                                    value={carrierInfo?.length}
                                    onChange={handleChange}
                                    error={fieldErrors.length}
                                />
                            </Box>
                            <Box lineHeight={"40px"}>
                                <Text>{t("width")} ({t("mm")})</Text>
                                <ShValidatedNumberInput
                                    btnSize={"32"}
                                    name="width"
                                    value={carrierInfo?.width}
                                    onChange={handleChange}
                                    error={fieldErrors.width}
                                />
                            </Box>
                            <Box lineHeight={"40px"}>
                                <Text>{t("height")} ({t("mm")})</Text>
                                <ShValidatedNumberInput
                                    btnSize={"32"}
                                    name="height"
                                    value={carrierInfo?.height}
                                    onChange={handleChange}
                                    error={fieldErrors.height}
                                />
                            </Box>
                            <Box lineHeight={"40px"}>
                                <Text>{t("weight")} (gram)</Text>
                                <ShValidatedNumberInput
                                    btnSize={"32"}
                                    name="weight"
                                    value={carrierInfo?.weight}
                                    onChange={handleChange}
                                    error={fieldErrors.weight}
                                />
                            </Box>
                        {/* </Box> */}
                        {/* <Box display={"flex"} flexDirection={"column"} gap={2}>
                            <Box>
                                
                            </Box>
                            <Box>
                                <Box display="flex"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[100]}
                                    sx={{
                                    }}
                                >
                                    <InputBase sx={{ml: 2, flex:1}} placeholder="" />
                                </Box>
                            </Box>
                        </Box> */}
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                marginTop:6
                            }}
                        >
                            <Text>{t('preferred_shipper')}</Text>
                            <Box display="flex"
                                width={0.5}
                                height="35px"
                        // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    justifyContent:"space-between",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"column"
                                        }
                                }}
                            >
                                <InputDropdown
                                    displayName={"name"} 
                                    onChange={(selected) => handleChange('preferredShipper', shippersOptions[selected].id)}
                                    noSelection={t("choose_shipper")} //Custom text when nothing is selected
                                    options={shippersOptions ?? null}
                                    selected={shippersOptions?.findIndex(shippersOption => shippersOption.id === carrierInfo?.preferredShipper) ?? 0}
                                    closeOnSelection
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dropdown>

            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
                    <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
        <GridActions
            id={carrierInfo?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
            // active={active}
            // blocked={blocked}
            // remark={remark}
            // carrier={carrier}
            // quarantine={quarantine}
            // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
        />
            </Box>
        </Box>
    );
}

export default DetailsPanel;