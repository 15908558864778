import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import { useTranslation } from "react-i18next";

const StockOnLocationCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    return (
        <BaseCard  onClick={props.skeleton ? null : props.onClick} sx={{p:0,pb:1,}}>
            <Box>
                <Box>
                    <Box display={"flex"}
                        sx={{
                            gap:3,
                        }}
                    >
                        <Box
                            sx={{
                                minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                pt:2,
                                pl:2,
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ? 
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text noBreak bold>{props.product?.description}</Text>
                                    <Text >{props.product?.relation?.name}</Text>
                                </>
                            }
                        </Box>
                        
                        <Box
                            sx={{
                                width:"fit-content",
                            }}
                        >
                            <CardLabel variant={theme.palette.mode === "dark" ? "blue" : "primary"}  position="child-tr">
                                {props.skeleton ? 
                                    <ShDoubleTextSkeleton/> 
                                    :
                                    props.product?.sku
                                }
                            </CardLabel>
                        </Box>
                    </Box>

                    {/* <Box display={"flex"} width={props.skeleton ? "70%" : null} flexDirection={"column"} pr={14}>
                        {props.skeleton ?
                            <ShDoubleTextSkeleton/>
                            :    
                            <>
                                <Text noBreak bold>{props.product?.description}</Text>
                                <Text>{props.product?.relation?.name}</Text>
                            </>
                        }
                    </Box>

                    <CardLabel variant={theme.palette.mode === "dark" ? "blue" : "primary"}>
                        {props.product?.sku}
                    </CardLabel> */}

                    {/* Holds all physical stock */}
                    <Box
                        sx={{
                            mx:1,
                            marginTop:2,
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[50],
                            borderRadius:3,
                            display:"flex",
                            flexDirection:"column",
                            gap:1.5,
                            padding:1,
                        }}
                    >
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Text>{t('physical')}</Text>

                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text>{props.quantity ?? 0}</Text>
                            }
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Text>{t('reserved')}</Text>

                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text>{props.reserved ?? 0}</Text>
                            }
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Text>{t('pickingprocess')}</Text>

                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text>{props.pickingprocess ?? 0}</Text>
                            }
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Text>{t('available')}</Text>

                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text>{props.available ?? 0}</Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default StockOnLocationCard;