import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import Bottom from "../../positionals/Bottom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import ShLabel from "../../label/ShLabel";
import Icon from "../../icons/Icon";
import { PrintDisabledRounded, PrintRounded } from "@mui/icons-material";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";

export const WarehouseStockCard = (props) => {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <BaseCard sx={{
            pl:2,
            pr:0,
            pt:0,
            pb:2,
        }} onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box
                >
                    <Box display={"flex"}
                        sx={{
                            gap:3,
                        }}
                    >
                        <Box
                            sx={{
                                minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                pt:2,
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ? 
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text noBreak bold>{props.product.description}</Text>
                                    { props.isFulfilment ?
                                        <Text noBreak light>{props.product.relation?.name}</Text>
                                    : 
                                        <Text light>{props.product.sku}</Text>
                                    }
                                </>
                            }
                        </Box>
                        
                        <Box
                            sx={{
                                width:"fit-content",
                            }}
                        >
                            <CardLabel variant={theme.palette.mode === "dark" ? "blue" : "primary"}  position="child-tr">
                                {props.skeleton ? 
                                    <ShDoubleTextSkeleton/> 
                                    :
                                    props.isFulfilment ?
                                        <Text variant={theme.palette.mode === "dark" ? "blue" : "primary"}>
                                            {props.product.sku}
                                        </Text>
                                        
                                    :
                                    <Text variant={theme.palette.mode === "dark" ? "blue" : "primary"}>
                                        {props.product.barcode}
                                    </Text>
                                }
                            </CardLabel>
                        </Box>
                    </Box>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        {props.isFulfilment &&
                            <Box display={"flex"} flexDirection={"column"}>
                                <Text light>{t("barcode")}</Text>

                                {props.skeleton ?
                                    <ShTextSkeleton />
                                    :
                                    <Text>
                                        {props.product.barcode}
                                        {/* {props.stock && props.stock.total_quantity ? props.stock.total_quantity : 0}     */}
                                    </Text>
                                }
                            </Box>
                        }

                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("stock")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>
                                    {props.available}
                                    {/* {props.stock && props.stock.total_available ? props.stock.total_available : 0} */}
                                </Text>
                            }
                        </Box>

                        <Box 
                            sx={{
                                ml:"auto",
                                mt:"auto",
                                mr:2,
                            }}
                        >
                            <ShLabel variant={"yellow"} fitted size={32}>
                                {props.warehouseLocation?.location}
                            </ShLabel>
                        </Box>
                    </Box>
                    
                </Box>
            </Box>
        </BaseCard>
    )
}
