import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import { useTranslation } from 'react-i18next';
import Details from "./Views/Details"; // Only import the Details view
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spSupplierDetailsConfig } from "./spSupplierDetailsConfig";
import { useGetSupplierQuery } from "../../../../newapi/global/supplier/supplierSlice";


const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpSupplierDetails = (props) => { // Change the component name to SpSupplierDetails
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props

    const { data: supplierData, isLoading,isFetching,isError } = useGetSupplierQuery(props.supplierId,
    {
        skip: !props.supplierId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <LocalShippingIcon/>
    }), [theme.palette.mode]);

    // const defaultOptions = useMemo(() => [
    //     { component: <ShRowSkeleton heading={t("order")} /> },
    //     { component: <ShRowSkeleton heading={t("reference")} /> },
    //     { component: <ShRowSkeleton heading={t("name")} /> },
    //     { component: <ShRowSkeleton heading={t("phone")} /> },
    //     { component: <ShRowSkeleton heading={t("email")} /> },
    //     { component: <ShRowSkeleton heading={t("relation")} /> },
    //     { component: <HeaderDateBox skeleton t={i18n}/> },
    //     { component: <ShRowSkeleton heading={t("warehouse")} /> },
    //     { component: <ShRowSkeleton heading={t("sku_amount")} /> },
    //     { component: <ShRowSkeleton heading={t("items")} /> },
    // ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        // options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon,/*defaultOptions,*/ setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.supplierId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && supplierData) {
        
            setPanel((prevPanel) => ({
                ...prevPanel,
                title: supplierData.name,
                subTitle: supplierData.reference ?? "",
                icon :{
                    theme: theme.palette.mode === "dark" ? "blue" : "primary",
                    content: <LocalShippingIcon/>
                },
                options: [
                    // Only include the necessary options for the Supplier
                ],
            }));
        }
    }, [supplierData, isLoading, isFetching, t,theme.palette.mode]);

    // const [panel, setPanel] = useState({
    //     title: "",
    //     subTitle: "",
    //     icon :{
    //         theme: theme.palette.mode === "dark" ? "blue" : "primary",
    //         content: <LocalShippingIcon/>
    //     },
    //     //Heading row for default shit
    //     options : [],
    //     actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    // });

    // useEffect(() => {
    //     if (typeof data !== "undefined" && data !== null && typeof supplierData.id !== "undefined") {
    //       setPanel((prevPanel) => ({
    //         ...prevPanel,
    //         title: supplierData.name,
    //         subTitle: supplierData.reference ?? "",
    //         icon :{
    //             theme: theme.palette.mode === "dark" ? "blue" : "primary",
    //             content: <LocalShippingIcon/>
    //         },
    //         options: [
    //             // Only include the necessary options for the Supplier
    //         ],
    //       }));
    //     }
    //   }, [data,theme.palette.mode]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spSupplierDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spSupplierDetailsConfig.details} data={supplierData}/> 
                </>
        </Sidepanel2>
    );
};

export default SpSupplierDetails;
