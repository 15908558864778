import { Box, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import cx from "classnames"
import { showToastMessagePostRequest, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired, yupNumberRequired } from "../../../utils/validation";
import { useCreateWorkspaceMutation } from "../../../newapi/warehouse/workspaceSlice";
import { useGetWarehousesSelectQuery } from "../../../newapi/warehouse/warehouseApiSlice";
import { useFindPrintersSelectQuery } from "../../../newapi/warehouse/printerSlice";

const MAddWorkspace = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [addWorkspace,{isLoading}] = useCreateWorkspaceMutation();
    const { data: warehouses } = useGetWarehousesSelectQuery();
    const { data: printers } = useFindPrintersSelectQuery();


    const selectedActiveWarehouseId = useSelector(state => state.warehouse.activeId);

    const [workspaceInfo, setWorkspaceInfo] = useState({
        name: "",
        printer_packinglist: 0,
        printer_label: 0,
        printer_documents: 0,
        autoPrint: false,
        warehouseId: 0
    });

    const handleClose = () => {
        setWorkspaceInfo({
            name: "",
            printer_packinglist: 0,
            printer_label: 0,
            printer_documents: 0,
            autoPrint: false,
            warehouseId: 0
        });
        props.handleClose();
    }

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        printer_packinglist: yupNumberRequired('printer_packinglist', t),
        printer_label: yupNumberRequired('printer_label', t),
        printer_documents: yupNumberRequired('printer_documents', t),
        warehouseId: yupNumberRequired('warehouseId', t),
    };
    
    const schema = yup.object().shape(rules);
   
    const SaveWorkspace = async () => {
        try {
            // Reset field errors
            setFieldErrors({});
        
            await schema.validate(workspaceInfo, { abortEarly: false });
        
            // If validation is successful, proceed with the addSupplier call
            const data = await addWorkspace(workspaceInfo).unwrap();
            showToastMessagePostRequest(t, data);

            setWorkspaceInfo({
                name: "",
                printer_packinglist: 0,
                printer_label: 0,
                printer_documents: 0,
                autoPrint: false,
                warehouseId: 0
            });

            handleClose();
        } catch (err) {
          if (err.name === 'ValidationError') {
            // Create an errors object where keys are field names and values are error messages
            const errors = err.inner.reduce((acc, currentError) => ({
              ...acc,
              [currentError.path]: currentError.message
            }), {});
      
            // Set the validation errors in the state
            setFieldErrors(errors);
          } else {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, err);
          }
        }
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    const handleChange = (key, value) => {
        setWorkspaceInfo(prevState => ({
            ...prevState,
            [key]: value
        }));
        
        setFieldErrors(prevErrors => ({
            ...prevErrors,
            [key]: ''
        }));
    };

    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_workspace")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    pb:2,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            display:"flex",
                            alignItems:"center",
                            pt:1,
                        }}
                    >
                        <Text>{t("name")}</Text>
                    </Box>
                    <ShValidatedInput
                        name="name"
                        value={workspaceInfo.name}
                        onChange={handleChange}
                        error={fieldErrors.name}
                    />
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            display:"flex",
                            alignItems:"center",
                            pt:1,
                        }}
                    >
                        <Text>{t("printer_packinglist")}</Text>
                    </Box>
                    
                    <ShValidatedInputDropdown
                        name="printer_packinglist"
                        displayName="description"
                        changeField="id"
                        selected={printers?.data?.findIndex(item => item.id === workspaceInfo?.printer_packinglist) ?? -1}
                        options={printers?.data ?? []}
                        noSelection={t("choose_printer")} 
                        onChange={handleChange}
                        error={fieldErrors.printer_packinglist}
                    />
                        
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                        <Box
                            sx={{
                                width:120,
                                flexShrink:0,
                                display:"flex",
                                alignItems:"center",
                                pt:1,
                            }}
                        >
                            <Text>{t("printer_label")}</Text>
                        </Box>
                    
                    
                        
                        <ShValidatedInputDropdown
                            name="printer_label"
                            displayName="description"
                            changeField="id"
                            selected={printers?.data?.findIndex(item => item.id === workspaceInfo?.printer_label) ?? -1}
                            options={printers?.data ?? []}
                            noSelection={t("choose_printer")} 
                            onChange={handleChange}
                            error={fieldErrors.printer_label}
                        />
                    
                </Box>
                    <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            display:"flex",
                            alignItems:"center",
                            pt:1,
                        }}
                    >
                        <Text>{t("printer_documents")}</Text>
                    </Box>
                    
                    
                        <ShValidatedInputDropdown
                            name="printer_documents"
                            displayName="description"
                            changeField="id"
                            selected={printers?.data?.findIndex(item => item.id === workspaceInfo?.printer_documents) ?? -1}
                            options={printers?.data ?? []}
                            noSelection={t("choose_printer")} 
                            onChange={handleChange}
                            error={fieldErrors.printer_documents}
                        />
                        
                </Box>

                <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"flex-start",
                    justifyContent:"space-between",
                    gap:7
                }}
                >

                    <Box
                        sx={{
                            width:150,
                            flexShrink:0,
                            display:"flex",
                            alignItems:"center",
                            pt:1,
                        }}
                    >
                        <Text>{t("autoprint")}</Text>
                    </Box>
                        <ToggleButtonGroup
                                color={workspaceInfo.autoPrint === "true" ? "green" : "red"}
                                value={workspaceInfo.autoPrint === "true" ? "true" : "false"}
                                exclusive
                                onChange={(e) => handleChange('autoPrint', e.target.value)}
                                aria-label="Active"
                                className={cx(`${workspaceInfo.autoPrint}`, {
                                    'active': workspaceInfo.autoPrint === "true"
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('no')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('yes')}
                                </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            display:"flex",
                            alignItems:"center",
                            pt:1,
                        }}
                    >
                        <Text>{t("warehouse")}</Text>
                    </Box>
                    
                    
                    <ShValidatedInputDropdown
                        name="warehouseId"
                        displayName="name"
                        changeField="id"
                        selected={warehouses?.data?.findIndex(item => item.id === selectedActiveWarehouseId) ?? -1}
                        options={warehouses?.data ?? []}
                        noSelection={t("choose_warehouse")} 
                        onChange={handleChange}
                        error={fieldErrors.warehouseId}
                    />
                        
                </Box>
              </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    block
                    variant="contained" 
                    onClick={() => SaveWorkspace()}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddWorkspace;
