import { Box, List, ListItemIcon, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";

import { useTranslation } from 'react-i18next';
import DetailsPanel from "./Views/Details";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { SpReturnReasonDetailsConfig } from "./SpReturnReasonDetailsConfig";
import { ReactComponent as ReachTruck } from '../../../../styles/svg/reachtruck.svg';
import { useGetReturnReasonQuery } from "../../../../newapi/inbound/returnsSlice";
import { ListAltOutlined } from "@mui/icons-material";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpReturnReasonDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

    const { data: returnReasonData, isLoading,isFetching,isError } = useGetReturnReasonQuery(props.returnReasonId,
    {
        skip: !props.returnReasonId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ListAltOutlined/>
    }), [theme.palette.mode]);

    // const defaultOptions = useMemo(() => [
    //     { component: <ShRowSkeleton heading={t("warehouse")} /> },
    //     { component: <ShRowSkeleton heading={t("label_workspaces")} /> },
    //     { component: <ShRowSkeleton heading={t("packing_list_workspaces")} /> },
    // ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        // options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.reachTypeId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && returnReasonData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: returnReasonData.description,
                subTitle: [""],
            }));
        }
    }, [returnReasonData, isLoading, isFetching, t,theme.palette.mode]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={SpReturnReasonDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <DetailsPanel setIsExtended={props.setIsExtended} config={SpReturnReasonDetailsConfig.details} data={returnReasonData}/>
                </>
        </Sidepanel2>
    );
};

export default SpReturnReasonDetails;