// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { tokens } from '../../../../../theme';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { countryCodeNL } from '../../../../../utils/constants';
import { countriesFromLocale } from '../../../../../utils/countries';
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { useDispatch } from 'react-redux';
import ShSwitch from '../../../../theme/inputs/ShSwitch';
import { DropdownBase } from '../../../../theme/dropdowns/Dropdown';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();
    const data = { ...props.data };
    
    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language);

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    
    // Set standard country on init
    if (typeof props.data?.country === "undefined") {
        handleChange('country', countryCodeNL);
    }

        return (
            <div>
                 <Box
                        sx={{
                            display:"flex",
                            //marginTop:3,
                            gap:3,
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column",
                                gap:1,
                                alignItems:"flex-start",
                            }
                        }}
                    >
                     <Box
                                sx={{
                                    flex:1,
                                    display:"flex",
                                    // paddingTop:2,
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text>{t("company")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            
                                        }}
                                    >
                                        <ShValidatedInput
                                        name="company"
                                        value={data.company ?? ""}
                                        onChange={handleChange}
                                        error={props.errors.company}
                                        sx={{px: 2, flex:1, height:32,boxSizing:"border-box"}}
                                    />
                                        
                                    </Box>
                                </Box>
                            </Box>       
                            <Box
                                sx={{
                                    flex:1,
                                    display:"flex",
                                    // paddingTop:2,
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text>{t("contactperson")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            
                                        }}
                                    >
                                        <ShValidatedInput
                                        name="contactperson"
                                        value={data.contactperson ?? ""}
                                        onChange={handleChange}
                                        error={props.errors.contactperson}
                                        sx={{px: 2, flex:1, height:32,boxSizing:"border-box"}}
                                    />
                                        
                                    </Box>
                                </Box>
                            </Box>
                    </Box>
                <Box 
                    sx={{
                        background: colors["orange"][100],
                        color: colors["orange"][400],
                        padding:2,
                        width:1,
                        marginTop:2
                    }}
                >
                    <Typography fontWeight={"bold"} fontSize={"17"}>
                        {t("mp_add_shipper_address_title")}
                    </Typography>
                    <Box sx={{paddingTop:0.5}}>
                        <Typography fontSize={"14"}>
                        {t("mp_add_shipper_address_text")}
                        </Typography>
                        <ShSwitch
                                name="alternativeAddress"
                                value={data?.alternativeAddress}
                                checked={data?.alternativeAddress}
                                onChange={(e) => handleChange('alternativeAddress', e.target.checked)}
                            ></ShSwitch>
                    </Box>
                </Box>
        {data?.alternativeAddress && (
            <Box>
                           
                            

                            <Box
                                sx={{
                                    display:"flex",
                                    marginTop:3,
                                    gap:3,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column",
                                        gap:1,
                                        alignItems:"flex-start",
                                    }
                                }}
                            >
                            {/* Postal */}
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        gap:0.5,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"row",
                                            gap:5,
                                            alignItems:"center",
                                            width:1
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            "@media screen and (max-width: 47.9375em)" : {
                                                width:80
                                            }
                                        }}
                                    >
                                        <Text>{t("postal_code")}</Text>
                                    </Box>

                                    <Box
                                        sx={{
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1
                                            }
                                        }}
                                    >

                                        <Box display="flex"
                                            borderRadius="20px"
                                            sx={{
                                                marginTop:0.5,
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                                }
                                            }}
                                        >
                                            <ShValidatedInput
                                            name="postalcode"
                                            value={data.postalcode ?? ""}
                                            onChange={handleChange}
                                            error={props.errors.postalcode}
                                            sx={{
                                                px: 2, 
                                                width:96, 
                                                height:32,
                                                boxSizing:"border-box",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flex:1,
                                                    width:"unset"
                                                }
                                            }}
                                        />
                                            {/* <Box 
                                                sx={{
                                                    marginY:0.5,
                                                    height:24,
                                                    width:"1px",
                                                    background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[300]
                                                }}
                                            />
                                            <InputBase type="text" sx={{px: 2, width:56, height:32,boxSizing:"border-box"}} /> */}
                                        </Box>
                                    </Box>
                                </Box>
                                {/* City */}
                                <Box
                                    sx={{
                                        flex:1,
                                        display:"flex",
                                        flexDirection:"column",
                                        gap:0.5,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"row",
                                            gap:5,
                                            alignItems:"center",
                                            width:1
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            "@media screen and (max-width: 47.9375em)" : {
                                                width:80
                                            }
                                        }}
                                    >
                                        <Text>{t("city")}</Text>
                                    </Box>

                                    <Box
                                        sx={{
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1
                                            }
                                        }}
                                    >

                                        <Box display="flex"
                                            borderRadius="20px"
                                            sx={{
                                                marginTop:0.5,
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                                }
                                            }}
                                        >
                                            <ShValidatedInput
                                            name="city"
                                            value={data.city ?? ""}
                                            onChange={handleChange}
                                            error={props.errors.city}
                                        />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            

                            <Box
                                sx={{
                                    display:"flex",
                                    marginTop:3,
                                    gap:3,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column",
                                        gap:1,
                                        alignItems:"flex-start",
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        gap:0.5,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"row",
                                            gap:5,
                                            alignItems:"center",
                                            width:1
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            "@media screen and (max-width: 47.9375em)" : {
                                                width:80
                                            }
                                        }}
                                    >
                                        <Text>{t("street")}</Text>
                                    </Box>
                                    <Box
                                        sx={{
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1
                                            }
                                        }}
                                    >
                                        <Box display="flex"
                                            borderRadius="20px"
                                            sx={{
                                                marginTop:0.5,
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                                }
                                            }}
                                        >
                                        <ShValidatedInput
                                            name="street"
                                            value={data.street ?? ""}
                                            onChange={handleChange}
                                            error={props.errors.street}
                                        />
                                        </Box>
                                    </Box>
                                </Box>
                                {/* House nr + xtra */}
                                <Box
                                    sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        gap:0.5,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"row",
                                            gap:5,
                                            alignItems:"center",
                                            width:1
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            "@media screen and (max-width: 47.9375em)" : {
                                                width:80
                                            }
                                        }}
                                    >
                                        <Text>{t("house_nr")}</Text>
                                    </Box>

                                    <Box
                                        sx={{
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1
                                            }
                                        }}
                                    >

                                        <Box display="flex"
                                            borderRadius="20px"
                                            sx={{
                                                marginTop:0.5,
                                                
                                            }}
                                        >
                                            <ShValidatedInput
                                                name="housenr"
                                                value={data.housenr ?? ""}
                                                onChange={handleChange}
                                                error={props.errors.housenr}
                                                sx={{
                                                    px: 2, 
                                                    width:56, 
                                                    height:32,
                                                    boxSizing:"border-box",
                                                    "@media screen and (max-width: 47.9375em)" : {
                                                        flex:1,
                                                        width:"unset"
                                                    }
                                                }}
                                            />
                                        
                                            <Box 
                                                sx={{
                                                    height:24,
                                                    width:"1px",
                                                    width:80,
                                                    // flex:0,
                                                    ml:1,
                                                }}
                                            >
                                                <ShValidatedInput
                                                    name="housenrextra"
                                                    value={data.housenrextra ?? ""}
                                                    onChange={handleChange}
                                                    error={props.errors.housenrextra}
                                                    sx={{px: 2, width:56, height:32,boxSizing:"border-box"}}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    paddingTop:2,
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text>{t("country")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <DropdownBase
                                            displayName={"name"} 
                                            valueKey={"id"}
                                            onChange={(selected) => handleChange('country', selected)}
                                            noSelection={t("search_country_placeholder")} //Custom text when nothing is selected
                                            options={countries} // array
                                            //selected={countries.findIndex(country => country.id === parseInt(shipperInfo?.country))}
                                            selected={parseInt(data?.country)}
                                            closeOnSelection
                                            //toggleDragAndDrop={(isEnabled) => setDisableDnD(!isEnabled)}
                                            searchOptions={{
                                                enabled: true,
                                                placeholder: t("search_country_placeholder")
                                            }}
                                            styles={{
                                                minWidth:300
                                            }}
                                            disallowDeselection
                                        >
                                            <Box
                                                sx={{
                                                    flex:1,
                                                    // flexGrow:0,
                                                    display:"flex",
                                                    alignItems:"center",
                                                    justifyContent:"center",
                                                    //background:colorSettings.streamrulevalue,
                                                    height:32,
                                                    borderRadius:4,
                                                    "&:hover" : {
                                                        //background:colorSettings.streamrulevaluehover,
                                                        cursor:"pointer",
                                                    },
                                                    "@media screen and (max-width: 768px)" : {
                                                        // ml:1,
                                                    }
                                                }}
                                            >
                                                <Text noBreak>{countries.find(country => country.id === data?.country)?.name}</Text>
                                            </Box>
                                        </DropdownBase>
                                    {/* <InputDropdown
                                        displayName={"name"} 
                                        onChange={(selected) => handleChange('country', countries[selected].alpha2)}
                                        noSelection={t("select_country")} //Custom text when nothing is selected
                                        options={countries ?? null}
                                        selected={countries?.findIndex(country => (props.data?.country && props.data.country === country.alpha2) || (country.alpha2 === countryCodeNL)) ?? -1}
                                        closeOnSelection
                                    /> */}
                                       
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        )}
        </div>
        );
    };

const ShipperSender = (props) => {
    const { t } = useTranslation();
    const schema = yup.object({
        company: yupRequired('company', t),
        contactperson: yupRequired('contactperson', t),
        postalcode: yup.lazy((value, { parent }) =>
            !value && !parent.housenr && !parent.housenrextra && !parent.city && !parent.street
                ? yup.string().notRequired()
                : yupRequired('postalcode', t)
        ),
        housenr: yup.lazy((value, { parent }) =>
            !value  && !parent.postalcode && !parent.housenrextra && !parent.city && !parent.street
                ? yup.string().notRequired()
                : yupRequired('housenr', t)
        ),
        housenrextra: yup.string().notRequired(),
        city: yup.lazy((value, { parent }) =>
            !value &&  !parent.postalcode && !parent.housenr && !parent.housenrextra && !parent.street
                ? yup.string().notRequired()
                : yupRequired('city', t)
        ),
        street: yup.lazy((value, { parent }) =>
            !value && !parent.postalcode && !parent.housenr && !parent.housenrextra && !parent.city
                ? yup.string().notRequired()
                : yupRequired('street', t)
        ),
    });

    const { maxSteps } = useSelector((state) => state.progressiontabs);

    return (
        <ProgressionTab 
            tabId="tabShipperSender" 
            subtitle={t('sender')} 
            step={4}
            nextTab={"tabPickupTimes"}
            maxSteps={maxSteps}
            schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default ShipperSender;

