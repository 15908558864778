

import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
export const settingsWarehouseOutboundPickingConfig = {
    core: {
        title: 'core',
        icon: <ChecklistOutlinedIcon/>,
    },
    streams:  {
        title: 'streams',
        icon: <ChecklistOutlinedIcon/>,
    // view: <StandardBody apiRef={apiRef} {...props.addStandardModal}/>,
    // topRight:<WareHouseDropdown {...props.warehouseModal}/>,
    }
};
