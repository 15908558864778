import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useTranslation } from 'react-i18next';
import Details from "./Views/Details";
import CropFreeIcon from '@mui/icons-material/CropFree';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spCountingStrategyTemplateDetailsConfig } from "./spCountingStrategyTemplateDetailsConfig";
import { useGetCountingStrategyTemplateQuery } from "../../../../newapi/countingstrategy/countingStrategyTemplateSlice";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpCountingStrategyTemplateDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

    const { data: countingStrategyData, error, isLoading,isFetching } = useGetCountingStrategyTemplateQuery(
        props.strategyId,
         {
             skip: !props.strategyId
         }
     );


    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <CropFreeIcon/>
    }), [theme.palette.mode]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        actions: <SidePanelActions setIsExtended={setIsExtendedCallback} />
    }), [defaultIcon, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.carrierId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && countingStrategyData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: countingStrategyData.name,
                icon: defaultIcon,
            }));
        }
    }, [countingStrategyData, isLoading,isFetching, t,theme.palette.mode]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spCountingStrategyTemplateDetailsConfig}
            content={panel} 
            oneView //new, shows singular view if need be
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spCountingStrategyTemplateDetailsConfig.details} data={countingStrategyData} setIsExtended={props.setIsExtended}/>
                </>
        </Sidepanel2>
    );
};

export default SpCountingStrategyTemplateDetails;