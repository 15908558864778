// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { yupEmailRequired, yupRequired } from '../../../../../utils/validation';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const dispatch = useDispatch();


    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (
        <>
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("name")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                        name="name"
                        value={data.name ?? ""}
                        onChange={handleInput}
                        error={props.errors.name}
                        sx={{
                            px:2,
                            flex:1, 
                            color:colors.txt["primary"],
                            lineHeight:"32px",
                        }}
                    />
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("phone")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                        name="phone"
                        value={data.phone ?? ""}
                        onChange={handleInput}
                        error={props.errors.phone}
                        sx={{
                            px:2,
                            flex:1, 
                            color:colors.txt["primary"],
                            lineHeight:"32px",
                        }}
                    />
                    
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("email")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                        name="email"
                        value={data.email ?? ""}
                        onChange={handleInput}
                        error={props.errors.email}
                        sx={{
                            px:2,
                            flex:1, 
                            color:colors.txt["primary"],
                            lineHeight:"32px",
                        }}
                    />
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("website")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                        name="website"
                        value={data.website ?? ""}
                        onChange={handleInput}
                        error={props.errors.website}
                        sx={{
                            px:2,
                            flex:1, 
                            color:colors.txt["primary"],
                            lineHeight:"32px",
                        }}
                    />
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("kvk")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                        name="kvk"
                        value={data.kvk ?? ""}
                        onChange={handleInput}
                        error={props.errors.kvk}
                        sx={{
                            px:2,
                            flex:1, 
                            color:colors.txt["primary"],
                            lineHeight:"32px",
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

const ContactDetails = (props) => {
    const { t } = useTranslation();

    const schema = yup.object({
        name: yupRequired('name',t),
        phone: yupRequired('phone',t),
        email: yupEmailRequired('email',t),
        website: yupRequired('website',t),
        kvk: yupRequired('kvk',t),
    });

    return (
        <ProgressionTab 
        tabId="ContactDetails" 
        subtitle={t('contact_details')} 
        step={1}
        nextTab={"tabLocationDetails"}
        maxSteps={3}
        schema={schema}
    >
        <Content />
    </ProgressionTab>
    )
}

export default ContactDetails;