import React, { useRef, useState,createRef,Component,componentDidMount, forwardRef, useEffect, useContext } from "react";
import { Box, IconButton, Typegraphy, Typography, useTheme, Button, Icon, ButtonBase, colors } from '@mui/material';
import { Link, json } from "react-router-dom";
import { tokens } from "../../theme";
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import CallReceivedOutlinedIcon from '@mui/icons-material/CallReceivedOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import SettingsIcon from '@mui/icons-material/Settings';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import TakeoutDiningOutlinedIcon from '@mui/icons-material/TakeoutDiningOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PaymentIcon from '@mui/icons-material/Payment';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import SensorDoorOutlinedIcon from '@mui/icons-material/SensorDoorOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import LowPriorityOutlinedIcon from '@mui/icons-material/LowPriorityOutlined';
import ContactsIcon from '@mui/icons-material/Contacts';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
// import { useAuthUser, useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
// import { createRef } from "react";
import cx from 'classnames';
import { MenuStateContext } from "../../contexts/MenuState";

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import CloseOverlayBtn from "../theme/buttons/CloseOverlayBtn";
import Text from "../theme/text/Text";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MSelectWarehouses from "./Modals/MSelectWarehouses";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import StreamOutlinedIcon from '@mui/icons-material/StreamOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { AUTH_STATUS_LOGGED_IN, nav_environment, nav_selected } from "../../storagekeys";
import { useTranslation } from 'react-i18next';
import { selectAuthStatus, selectCustomers, selectRelations, selectUser, UserState } from "../../newapi/user/authentication/authSlice";
import { useDispatch, useSelector } from "react-redux";
import MLogout from "./Modals/MLogout";
import { useLogoutMutation } from "../../newapi/user/authentication/authApiSlice";
import { checkUserPermission, UserPrivileges } from '../../utils/permissions';
import { selectUserPermission } from '../../newapi/user/authentication/authSlice';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import { ReactComponent as ReturnPackage } from "../../styles/svg/package-return.svg";
import { setSelectedEnvironment, setSelectedInnerEnvironment, setSelectedMenuItem, toggleMenu, updateMenuState } from "../../newapi/global/settingsSlice";
import { apiSlice } from "../../newapi/createApiSlice";
// import DropdownMenu from "./DropdownMenuitem";
// import MenuItem from "./MenuItem";
export const ENV_TYPES = {
    0: "USER",
    100: "COMPANY",
    200: "RELATION",
    300: "DIRECT_ACTION"
};

const Item = ({ item, type, title, index, setEnvironment,activeEnvironmentIndex, ...rest }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [saveType, setSaveType] = useState(type);

    useEffect(() => {
        setSaveType(type);
    }, [type]);

    const handleClick = () => {
        if (saveType !== ENV_TYPES[300]) {
            setEnvironment(index);
        } else {
            rest.onClick();
        }
        
    };


    return (
        <Box
            {...rest}
            className={cx(rest.className, {
                'active': parseInt(activeEnvironmentIndex) === parseInt(index),
                'settings': typeof item !== "undefined" && item.active === "settings"
             })}
            onClick={handleClick}
            sx={{
                mt: `${rest.mt ?? "16px"}`,
                mb: `${rest.mb ?? 0}`,
                ml: 2,
                width: 40,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: `${colors.nav["linkBg"]}`,
                borderRadius: "100%",
                color: "#fff",
                transition: "250ms",
                cursor: "pointer",
                opacity: 0.3,
                position: "relative",
                "&:before": {
                    content: '""',
                    position: "absolute",
                    width: "16px",
                    height: "32px",
                    left: "-28px",
                    top: "4px",
                    background: "transparent",
                    borderRadius: "6px",
                    transition: "250ms",
                },
                "&:hover": {
                    opacity: 1,
                    background: `${colors.nav["linkBg"]}`,
                    color: "#fff",
                    transition: "250ms",
                    borderRadius: 3,
                },
                "&.active": {
                    opacity: 1,
                    background: "blue",
                    color: `${colors.nav["color"]}`,
                    background: `${colors.blue[400]}`,
                    transition: "250ms",
                    borderRadius: 3,
                    "&.settings" : {
                        background: "#8DFFF8"
                    }
                },
                "&.active:before": {
                    background: "#fff",
                    transition: "250ms",
                },
                "&.user": {
                    opacity: 1,
                    background: `#7AD7FB`,
                    color: `${colors.nav["color"]}`,
                },
                "&.fc": {
                    opacity: 1,
                    background: `${colors.nav["linkBg"]}`,
                    color: "#fff",
                    "&:hover": {
                        color: `${colors.nav["color"]}`,
                        background: `${colors.blue[400]}`,
                    }
                }
            }}>
            {rest.children}
        </Box>
    );
};


const innerTypes = {
    0: "NORMAL",
    100: "SUBMENU",
    200: "SETTINGS",
};

const MenuWrapperItem = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const childRef = useRef(null);
    const type = typeof props.type === "undefined" ? props.link.submenus !== "undefined" ? innerTypes[0] : innerTypes[100] : props.type;
    const { setCollapsed } = useContext(MenuStateContext);
    const dispatch = useDispatch();


    const dropdownOnClick = (e, key) => {
        if (key === props.link.key) {

            let splitted = props.selected && props.selected.includes("_") && props.selected.split("_");
            if((splitted !== null && splitted[0] === props.link.key) || props.selected === key){ 
                props.setSelected(null);
            } else {
                props.setSelected(key);

            }
            
        } else {
            //child
            selectMenuItem(props.link.key + key);
        }
        e.stopPropagation();
    };

    const selectMenuItem = (key) => {
        props.setSelected(key);
        if (window.innerWidth <= 1366) {
            dispatch(toggleMenu());
            // setCollapsed.toggleMenuState();
        }
    };

    const switchInnerEnvironment = (env) => {
        props.switchInnerEnvironment(env);
    }

    if (type === innerTypes[200]) {
        return (
            <MenuItem
                ref={childRef}
                className={cx(props.className, {
                    'active': props.selected === props.link.key,
                })}
                onClick={() => switchInnerEnvironment(props.env)}
                icon={props.link.logo}
            >
                <Link to={props.link.to}>{props.link.name}</Link>
            </MenuItem>
        );
    }

    return (
        typeof props.link.submenus === 'undefined' ?
            <MenuItem
                ref={childRef}
                className={cx(props.className, {
                    'active': props.selected === props.link.key,
                })}
                onClick={() => selectMenuItem(props.link.key)}
                icon={props.link.logo}>
                <Link style={{display:"flex",justifyContent:"space-between"}} to={props.link.to}>
                    {props.link.name}
                    {props.link.new && 
                        <Box sx={{
                            height:24,
                            background: tokens('dark').green[100],
                            color:tokens('dark').green[400],
                            px:1,
                            borderRadius:3,
                            fontWeight:500
                        }}>
                            {t('new')}
                        </Box>
                    }
                </Link>
            </MenuItem>
            :
            <SubMenu 
                ref={childRef} 
                defaultOpen={props.defaultOpen} 
                open={props.toOpen} 
                onClick={(e) => dropdownOnClick(e, props.link.key)} 
                title={props.link.name} icon={props.link.logo}
            >
                {props.link.submenus.map((sublink, index) => (
                    <MenuItem
                        className={props.selected === props.link.key + sublink.key ? "active" : ""}
                        onClick={(e) => dropdownOnClick(e, sublink.key)}
                        key={index}>

                        <Link style={{display:"flex",justifyContent:"space-between"}} to={sublink.to}>
                            {sublink.name} 
                            {sublink.new && 
                                <Box sx={{
                                    height:24,
                                    background: tokens('dark').green[100],
                                    color:tokens('dark').green[400],
                                    px:1,
                                    borderRadius:3,
                                    fontWeight:500
                                }}>
                                    {t('new')}
                                </Box>
                            }
                        </Link>
                    </MenuItem>
                ))}
            </SubMenu>
    )
}

const getCustomerShortName = (name) => {
    let splitted = name.split(" ");
    if(splitted.length > 1) {
        return splitted[0].charAt(0) + splitted[1].charAt(0);
    }
    return name.slice(0,2);
}

class CustomerLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgError: false
        };
    }

    handleImgError = (e) => {
        this.setState({ imgError: true });
        e.target.onerror = null; // Prevents infinite loop in case of further errors
    };

    render() {
        const { customer, relation } = this.props;
        const { imgError } = this.state;
        //if(customer){
        return (
            <>
                {imgError ? (
                    <span>{customer.name}</span>
                ) : (
                    // <Box
                    //     sx={{
                    //         background:"blue",
                    //         backgroundImage: `url(https://stockhubfs.blob.core.windows.net/logos/cust/${customer.db_catalog}.png)`,
                    //         width: 1,
                    //         height: 40,

                    //     }}
                    // >
                        <img
                            src={`https://stockhubfs.blob.core.windows.net/logos/cust/${customer.db_catalog}.png`}
                            // width="130"
                            alt={customer.name}
                            onError={this.handleImgError}
                        />
                    // </Box>
                )}
            </>
        );
    // } else {
    //     return (
    //         <>
    //             {imgError ? (
    //                 <span>{relation.name}</span>
    //             ) : (
    //                 <img
    //                     src={`https://stockhubfs.blob.core.windows.net/logos/rel/${relation.id}.png`}
    //                     width="130"
    //                     alt={relation.name}
    //                     onError={this.handleImgError}
    //                 />
    //             )}
    //         </>
    //     );
    // }
    }
}

const Sidebar = () => {
    // const { t } = useTranslation();
    // const { t, i18n } = useTranslation();
    // additional ready will state if translations are loaded or not
    const { t, ready } = useTranslation();

    // Set permissions WMS
    const hasStockReadPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.STOCK_READ])
    );
    const hasOutboundReadPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.OUTBOUND_READ])
    );
    const hasInboundReadPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.INBOUND_READ])
    );
    const hasEcheckPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.ECHECK])
    );
    const hasRelationReadPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RELATION_READ, UserPrivileges.RELATION_MANAGE])
    );
    const hasBackOfficePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.BACKOFFICE_GENERAL])
    );

    const hasAutomationReadPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.WORKFLOW_READ])
    );

    const hasFinancialReadPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.INVOICEPRODUCT_READ, UserPrivileges.INVOICE_READ])
    );

    // Set permissions Relation portal
    const hasRelationProductPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_PRODUCT_READ, UserPrivileges.RP_PRODUCT_MANAGE])
    );

    const hasRelationOrderPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_ORDER_READ, UserPrivileges.RP_ORDER_MANAGE])
    );

    const hasRelationInboundPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_INBOUND_READ, UserPrivileges.RP_INBOUND_MANAGE])
    );

    const hasRelationInvoicePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_INVOICE_READ])
    );

    const hasRelationSupplierPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_SUPPLIER_READ, UserPrivileges.RP_SUPPLIER_MANAGE])
    );

    const hasRelationReturnPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_RETURN_READ, UserPrivileges.RP_RETURN_MANAGE])
    );

    const hasRelationAccessPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_ACCESS_READ, UserPrivileges.RP_ACCESS_MANAGE])
    );

    const hasRelationWebshopCredentialsPermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_WEBSHOPCREDENTIALS_MANAGE])
    );

    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    // const user = useAuthUser()();
    const buildCustomerList = (customers, relations) => {
        let navList = [];
        for(let i = 0; i < customers.length; i++) {
            // Create navitems based on permissions. Dashboard is always included
            const navLinks = [{
                logo:<AssessmentOutlinedIcon/>,
                name: "Dashboard",
                key:"warehouse-dashboard",
                to: "/warehouse/dashboard",
                active: true
            }];

            // Stock
            if (hasStockReadPermission) {
                const submenus = [];
                navLinks.push({
                    logo:<AllInboxOutlinedIcon/>,
                    name: t('warehouse'),
                    key:"warehouse-stock",
                    to: "/warehouse/stock",
                    active: false,
                    submenus: [
                        {
                            name: t('stock'),
                            to: "/warehouse/stock/stock",
                            key:"_stock",
                            active: false,
                        },
                        {
                            name: t('warehouse_locations'),
                            to: "/warehouse/stock/locations",
                            key:"_locations",
                            active: false,
                        },
                        {
                            name: t('products'),
                            to: "/warehouse/stock/products",
                            key:"_products",
                            active: false,
                        },
                        ...(hasAutomationReadPermission 
                            ? [{
                                name: t("stock_counts"),
                                key: "_countingstrategies",
                                to: "/warehouse/stock/countingstrategies",
                                active: false,
                            }]
                            : []
                        ),
                    ]
                });
            }

            // Outbound
            if (hasOutboundReadPermission) {
                navLinks.push({
                    logo:<CallMadeOutlinedIcon/>,
                    name: t('outbound'),
                    to: "/warehouse/outbound",
                    key:"warehouse-outbound",
                    active: false,
                    submenus: [
                        {
                            name: t('dashboard'),
                            to: "/warehouse/outbound/dashboard",
                            key:"_dashboard",
                            active: false,
                        },
                        {
                            name: t('orders'),
                            to: "/warehouse/outbound/orders",
                            key:"_orders",
                            active: false,
                        },
                        {
                            name: t('batches'),
                            to: "/warehouse/outbound/batches",
                            key:"_batches",
                            active: false,
                        },
                    ]
                });
            }

            // Inbound
            if (hasInboundReadPermission) {
                navLinks.push({
                    logo:<CallReceivedOutlinedIcon/>,
                    name: t('inbound'),
                    to: "/warehouse/inbound",
                    key:"warehouse-inbound",
                    active: false,
                    submenus: [
                        {
                            name: t('dashboard'),
                            to: "/warehouse/inbound/dashboard",
                            key:"_dashboard",
                            active: false,
                        },
                        {
                            name: t('inbounds'),
                            to: "/warehouse/inbound/inbounds",
                            key:"_inbounds",
                            active: false,
                        }
                    ]
                });
            }

            // Echeck
            if (hasRelationReadPermission) {
                navLinks.push(
                    ...isFulfilment ? [{
                    logo:<ContactsIcon/>,
                    name: t('relations'),
                    to: "/warehouse/relations",
                    key:"warehouse-relations",
                    active: false
                }] : [],);
            }

            // Financial
            if (hasFinancialReadPermission) {
                navLinks.push({
                    logo:<PaymentIcon/>,
                    name: t('financial'),
                    to: "/warehouse/financial",
                    key:"warehouse-financial",
                    active: false,
                    submenus: [
                        {
                            name: t('invoices'),
                            to: "/warehouse/financial/invoices",
                            key:"_invoices",
                            active: false,
                        },
                        {
                            name: t('components'),
                            to: "/warehouse/financial/components",
                            key:"_invoice_components",
                            active: false,
                        }
                    ]
                });
            }

            // if (hasAutomationReadPermission) {
            //     navLinks.push({
            //         logo:<AutorenewIcon/>,
            //         name: t('automation'),
            //         to: "/warehouse/automation",
            //         key:"warehouse-automation",
            //         active: false,
            //         submenus: [ 
            //
            //             // {
            //             //     name: t("workflows"),
            //             //     key:"_workflows",
            //             //     to: "/warehouse/automation/workflows",
            //             //     active: false,
            //             // }
            //         ]
            //     });
            // }

            // Echeck
            if (hasEcheckPermission) {
                navLinks.push({
                    logo:<LibraryAddCheckOutlinedIcon/>,
                    name: t('echeck'),
                    to: "/warehouse/echeck",
                    key:"warehouse-echeck",
                    active: false,
                });
            }

            // Backoffice
            // if (hasBackOfficePermission) {
            //     navLinks.push({
            //         logo:<RoomPreferencesOutlinedIcon />,
            //         name: "Backoffice",
            //         key:"warehouse-backoffice",
            //         to: "/",
            //         active: false
            //     });
            // }

            // alert("hi",customers.length);
            
            navList.push({
                id: customers[i].id,
                active: 'core',
                type: ENV_TYPES[100],
                core: {  
                    background:`${colors.blue[400]}`,
                    color: "rgba(0,0,0,.75)",
                    title: <CustomerLogo customer={customers[i]}  />,
                    logoInner:  <Typography fontSize={16} fontWeight={700}> {getCustomerShortName(customers[i].name)} </Typography>,
                    links : navLinks
                },
                settings: {
                    background:`#8DFFF8`,
                    color: "rgba(0,0,0,.75)",
                    title: 'settings',
                    logoInner: <SettingsOutlinedIcon/>,
                    links : [
                        // {
                        //     logo:<CallMadeOutlinedIcon/>,
                        //     name: t("outbound"),
                        //     key:"warehouse-settings-outbound",
                        //     to: "/",
                        //     active: false,
                        //     submenus: [
                        //         // {
                        //         //     name: t("picking"),
                        //         //     key:"_picking",
                        //         //     to: "/settings/outbound/picking",
                        //         //     active: true,
                        //         // },
                        //         // {
                        //         //     name: t("stock"),
                        //         //     key:"_stock",
                        //         //     to: "/settings/outbound/stock",
                        //         //     active: false,
                        //         // },
                        //         // {
                        //         //     name: t("carriers"),
                        //         //     key:"_carriers",
                        //         //     to: "/settings/outbound/carriers",
                        //         //     active: false,
                        //         // }
                        //     ]
                        // },
                        
                        {
                            logo:<SettingsIcon/>,
                            name: t("general"),
                            key:"warehouse-settings-inbound",
                            to: "/settings/general",
                            active: false
                        },
                        // {
                        //     logo:<LowPriorityOutlinedIcon/>,
                        //     name: t("returns"),
                        //     key:"warehouse-settings-returns",
                        //     to: "/",
                        //     active: false
                        // },
                        {
                            logo:<LocalShippingOutlinedIcon/>,
                            name: t("shippers"),
                            to: "/settings/shippers",
                            key:"warehouse-settings-shippers",
                            active: false
                        },
                        {
                            logo:<TakeoutDiningOutlinedIcon/>,
                            name: t("carriers"),
                            key:"_carriers",
                            to: "/settings/outbound/carriers",
                            active: false,
                        },
                        {
                            logo:<WarehouseIcon/>,
                            name: t("warehouses"),
                            to: "/settings/warehouses",
                            key:"warehouse-settings-warehouses",
                            active: false
                        },
                        {
                            logo:<StreamOutlinedIcon/>,
                            name: t("picking"),
                            key:"warehouse-settings-picking",
                            active: false,
                            submenus: [ 
                                {
                                    name: t("streams"),
                                    key:"_streams",
                                    to: "/settings/picking/streams",
                                    active: false,
                                },
                                {
                                    name: t("resources"),
                                    key:"_resources",
                                    to: "/settings/picking/resources",
                                    active: false,
                                }
                                ]
                        },
                        {
                            logo:<InventoryIcon/>,
                            name: t("products"),
                            key:"warehouse-settings-products",
                            active: false,
                            submenus: [ 
                                {
                                    name: t("custom_fields"),
                                    key:"_customfields",
                                    to: "/settings/products/customfields",
                                    active: false,
                                },
                            ]
                        },
                        {
                            logo: <PublishOutlinedIcon/>,
                            name: t("import"),
                            key:"warehouse-settings-import",
                            to: "/settings/migratie",
                            active: false,
                        },
                        {
                            logo: <AutorenewIcon/>,
                            name: t("automation"),
                            key:"warehouse-settings-automation",
                            to: "/",
                            active: false,
                            submenus: [ 
                            {
                                name: t("counting_strategies"),
                                key:"_countingstrategies",
                                to: "/settings/automation/countingstrategies",
                                active: false,
                            },
                            // {
                            //     name: t("workflow_templates"),
                            //     key:"_workflows",
                            //     to: "/settings/automation/workflowtemplates",
                            //     active: false,
                            // }
                            ]
                        },
                        {
                            logo: <IntegrationInstructionsOutlinedIcon/>,
                            name: t("integrations"),
                            key:"warehouse-settings-integrations",
                            to: "/",
                            active: false,
                            submenus: [ 
                            {
                                name: t("saleschannels"),
                                key:"_channels",
                                to: "/settings/integrations/saleschannels",
                                active: false,
                            },
                            {
                                name: t("integrators"),
                                key:"_integrators",
                                to: "/settings/integrations/integrators",
                                active: false,
                            },
                            // {
                            //     name: t("pim"),
                            //     key:"_pim",
                            //     to: "/settings/integrations/pim",
                            //     active: false,
                            // }
                            ]
                        },
                        {
                            logo:<LocalPrintshopOutlinedIcon/>,
                            name: t("office"),
                            key:"warehouse-settings-office",
                            to: "/",
                            active: false,
                            submenus: [
                                {
                                    name: t("printers"),
                                    key:"_printers",
                                    to: "/settings/office/printers",
                                    active: false,
                                },
                                {
                                    name: t("workspaces"),
                                    key:"_workspaces",
                                    to: "/settings/office/workspaces",
                                    active: false,
                                }                                
                            ]
                        },
                        {
                            logo:<AdminPanelSettingsOutlinedIcon/>,
                            name: t("access"),
                            key:"warehouse-settings-access",
                            to: "/",
                            active: false,
                            submenus: [
                                {
                                    name: t("users"),
                                    key:"_users",
                                    to: "/settings/access/users",
                                    active: false,
                                },
                                {
                                    name: t("usergroups"),
                                    key:"_usergroups",
                                    to: "/settings/access/usergroups",
                                    active: false,
                                }
                            ]
                        }
                    ]
                }
            })
        }
        for(let i = 0; i < relations.length; i++) {
            const relationLinks = [{
                logo:<AssessmentOutlinedIcon/>,
                name: "Dashboard",
                key:"relation-dashboard",
                to: "/relation/dashboard",
                active: true
            }];

            if (hasRelationProductPermission) {
                relationLinks.push({
                    logo:<AllInboxOutlinedIcon/>,
                    name: t('products'),
                    key:"relation-products",
                    to: "/relation/products",
                    active: false,
                });
            }

            if (hasRelationOrderPermission) {
                relationLinks.push({
                    logo:<CallMadeOutlinedIcon/>,
                    name: t('orders'),
                    key:"relation-outbound",
                    to: "/relation/outbound",
                    active: false,
                });
            }

            if (hasRelationInboundPermission) {
                relationLinks.push({
                    logo:<CallReceivedOutlinedIcon/>,
                    name: t('inbound'),
                    key:"relation-inbound",
                    to: "/relation/inbound",
                    active: false,
                });
            }

            if (hasRelationReturnPermission) {
                relationLinks.push({
                    logo:<ReturnPackage/>,
                    name: t('returns'),
                    key:"relation-returns",
                    to: "/relation/returns",
                    active: false,
                });
            }

            if (hasRelationInvoicePermission) {
                relationLinks.push({
                    logo:<ReceiptLongIcon/>,
                    name: t('invoices'),
                    to: "/relation/financial/invoices",
                    key:"relation-finanial-invoices",
                    active: false,
                });
            }

            if (hasRelationAccessPermission) {
                relationLinks.push({
                    logo:<AdminPanelSettingsOutlinedIcon/>,
                    name: t("access"),
                    key:"relation-settings-access",
                    to: "/",
                    active: false,
                    submenus: [
                        {
                            name: t("users"),
                            key:"_users",
                            to: "/relation/settings/access/users",
                            active: false,
                        },
                        {
                            name: t("usergroups"),
                            key:"_usergroups",
                            to: "/relation/settings/access/usergroups",
                            active: false,
                        }
                    ]
                });
            }

            if (hasRelationSupplierPermission || hasRelationWebshopCredentialsPermission) {
                relationLinks.push({
                    logo:<SettingsOutlinedIcon/>,
                    name: t("settings"),
                    key:"relation-settings",
                    to: "/",
                    active: false,
                    submenus: [
                        ...hasRelationSupplierPermission ? [{
                            name: t("suppliers"),
                            key:"_suppliers",
                            to: "/relation/settings/suppliers",
                            active: false,
                        }] : [],
                        ...hasRelationWebshopCredentialsPermission ? [{
                            name: t("saleschannels"),
                            key:"_webshop",
                            to: "/relation/settings/saleschannels",
                            active: false,
                        }] : [],
                    ]
                },);
            }

            navList.push({
                id: relations[i].id,
                active: 'core',
                type: ENV_TYPES[200],
                core: {
                    background:`${colors.blue[400]}`,
                    color: "rgba(0,0,0,.75)",
                    title: <CustomerLogo customer={relations[i]}  />,
                    logoInner:  <Typography fontSize={16} fontWeight={700}> {getCustomerShortName(relations[i].name)} </Typography>,
                    links: relationLinks
                },
            })
        }
        
        if(selectedEnvironmentType === ENV_TYPES[100] || selectedEnvironmentType === ENV_TYPES[200]){
            navList[selectedEnvironmentIndex-1].active = selectedEnvironmentActive;
        }

        return navList;
    }

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();

    const language = useSelector((state) => state.settings.language);
    const selectedMenuItem = useSelector((state) => state.settings.environment.selectedMenuItem);
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const selectedEnvironmentActive = useSelector((state) => state.settings.environment.selectedEnvironmentActive);
    const selectedEnvironmentIndex = useSelector((state) => state.settings.environment.selectedEnvironmentIndex);
    
    const navigate = useNavigate();
    const [nav, setNav] = useState([]);

    const authStatus = useSelector(selectAuthStatus);
    const customers = useSelector(selectCustomers);
    const relations = useSelector(selectRelations);
    const user = useSelector(selectUser);
    // const {auth, authenticated, user} = useAuth();
    
    useEffect(() => {
        if(ready){
            // if(authStatus === UserState.LOGGED_IN) {
                setNav([
                    {
                        background:`#7AD7FB`,
                        color: "rgba(0,0,0,.75)",
                        title: user?.name,
                        type: ENV_TYPES[0],
                        logoInner: <PersonOutlinedIcon/>,
                        links : [
                            // {
                            //     logo:<PersonOutlinedIcon/>,
                            //     name: t("Account"),
                            //     active: true,
                            //     submenus: [
                            //         {
                            //             name: t("General"),
                            //             to: "/user/account/general",
                            //             active: true,
                            //         },
                            //         {
                            //             name: t("Security"),
                            //             to: "/user/account/security",
                            //             active: false,
                            //         },
                            //         {
                            //             name: t("Archive"),
                            //             to: "/user/account/archive",
                            //             active: false,
                            //         }
                            //     ]
                            // },
                            {
                                logo:<PersonOutlinedIcon/>,
                                name: t("Account"),
                                key:"account-general",
                                to: "/user/account/general",
                                active: true
                            },
                            {
                                logo:<SensorDoorOutlinedIcon/>,
                                name: t("Accessibility"),
                                key:"account-accessibility",
                                to: "/user/accessibility",
                                active: false
                            },
                            {
                                logo:<NotificationsActiveOutlinedIcon/>,
                                name: t("notifications"),
                                key:"account-notifications",
                                to: "/user/notifications",
                                active: false
                            }
                        ]
                    },
                    // ...buildCustomerList(customers ?? [], relations ?? []),

                    ...(authStatus === UserState.LOGGED_IN ? buildCustomerList(customers ?? [], relations ?? []) : []),
                    {
            
                        background:`${colors.blue[400]}`,
                        color: "rgba(0,0,0,.75)",
                        title: t("logged_out"),
                        type: ENV_TYPES[300],
                        logoInner: <LogoutOutlinedIcon/>,
                        links : [
                            {
                                logo:<PersonOutlinedIcon/>,
                                name: "Login",
                                to: "/login",
                                active: true
                            },
                        ]
                    }
                ]);
            // } 
        }
    }, [authStatus,customers,ready,language,user,selectedEnvironmentIndex])

    const setEnvironment = (envIndex) => {
        if(selectedEnvironmentIndex !== envIndex){
            let isFulfilment = false;
            let relationCustomerId = 0;
            let relationRootId = 0;
            
            if (nav[envIndex].type === ENV_TYPES[100] && customers.length > 0) {
                const customer = customers.find(c => c.id === nav[envIndex].id);
                if (customer) {
                    isFulfilment = customer.isFulfilment;
                }
            } 
            else if (nav[envIndex].type === ENV_TYPES[200] && relations.length > 0) {
                const relation = relations.find(r => r.id === nav[envIndex].id);
                if (relation) {
                    relationCustomerId = relation.customers.length > 0 ? relation.customers[0].id : 0;
                    relationRootId = relation.internalId;
                }
            }

            dispatch(setSelectedEnvironment({
                selectedId: nav[envIndex].id,
                selectedEnvironmentType: nav[envIndex].type,
                selectedEnvironmentIndex: envIndex,
                isFulfilment: isFulfilment,
                relationCustomerId: relationCustomerId,
                relationRootId: relationRootId
            }));

            if(nav[envIndex].type === ENV_TYPES[100]){
                //WAREHOUSE
                navigate('/warehouse/dashboard'); // Use navigate to go to /warehouse
                setSelected("warehouse-dashboard");
            } else if (nav[envIndex].type=== ENV_TYPES[200]) {
                //RELATION
                navigate('/relation/dashboard'); // Use navigate to go to /relation
                setSelected("relation-dashboard");
            } else if (nav[envIndex].type === ENV_TYPES[0]) {

                // key:"account-general",
                // to: "/user/account/general",
                navigate('/user/account/general'); // Use navigate to go to /relation
                setSelected("account-general");
            }

            //Resets api state
            dispatch(apiSlice.util.resetApiState());
        }
    }
    const setSelected = (selected) => {
        if(nav[selectedEnvironmentIndex].type === ENV_TYPES[0]) {

        }
        dispatch(setSelectedMenuItem(selected));
    }

    const collapsed = useSelector((state) => state.settings.environment.collapsed);

    const menuChildRef = useRef();
    const token = useSelector(state => state.auth.refreshToken);
    const [logout, { 
        isSuccess : logOutSuccess, 
        isError: logOutIsError,
        error: logOutError
    }] = useLogoutMutation();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleLogout = async () => {
        await(logout(token));
    }
    const handleClose = async () => {
        setOpen(false);
    };

    useEffect(() => {
        if(window.innerWidth <= 1368) {
            dispatch(toggleMenu());
        }
    },[])
    
    useEffect(() => {
        if(logOutIsError) { 
            return;
        }

        if(logOutSuccess) {
            dispatch(updateMenuState(false));
            // setCollapsed.updateMenuState(false);
            setOpen(false);
            navigate("/login");
            return;
        }
    },[logOutSuccess, logOutIsError,logOutError]);


    useEffect(() => {
        if(!collapsed && window.innerWidth <=1366){
            document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "181820": "#ecf0f5"}`);
            document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
        } else {
            document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? colors.bg["secondary"]: colors.bg["primary"]}`);
            document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? colors.bg["secondary"]: colors.bg["primary"]}`);
        }
    },[collapsed,theme.palette.mode])

    const defaultOpenSubMenu = (link) => {
        if(typeof link.submenus !== 'undefined') {
            for(let i = 0; i < link.submenus.length; i++) {
                if(selectedMenuItem === link.key+link.submenus[i].key) {
                    return true;
                }
            }
        }
        return false;
    }

    const openMenu = (link) => {
        if(typeof link.submenus === "undefined") return false;
        if(selectedMenuItem){
            let splitted = selectedMenuItem.split("_");
            if(splitted[0] === link.key) return true;
        }
        return false;
    }

    // const closeMenu = (link) => {
    //     if(typeof link.submenus === "undefined") return false;
    //     let splitted = selectedMenuItem.split("_");
    //     if(splitted[0] === link.key) return false;
    // }

    
    const switchInnerEnvironment = (env) => {
        let index = nav.findIndex(item => (item.type === ENV_TYPES[100] || item.type === ENV_TYPES[200]) && item.core.title === env.core.title);
        if (index !== -1) {
            // Create a new array with the modified company item
            let updatedNav = [...nav];
            if (updatedNav[index].active === "settings") {
                updatedNav[index].active = "core";
            } else {
                updatedNav[index].active = "settings";
            }
        
            dispatch(setSelectedInnerEnvironment({
                selectedId: updatedNav[index].id,
                selectedEnvironmentType: updatedNav[index].type,
                selectedEnvironmentActive: updatedNav[index].active,
                selectedEnvironmentIndex: index,
            }));
            // dispatch(setSelectedEnvironment({
            //     selectedId: updatedNav[index].id,
            //     selectedEnvironmentType: updatedNav[index].type,
            //     selectedEnvironmentActive: updatedNav[index].active,
            //     selectedEnvironmentIndex: index,
            // }));
            // Update the 'nav' state variable with the modified array
            setNav(updatedNav);
        }
    }

    const beforeSubmenuBarColor = () => {
        if(typeof nav[selectedEnvironmentIndex] === "undefined") return colors.primary[400];

        if(nav[selectedEnvironmentIndex].type === ENV_TYPES[0] || nav[selectedEnvironmentIndex].type === ENV_TYPES[300]) return nav[selectedEnvironmentIndex].background;
        if(nav[selectedEnvironmentIndex].type === ENV_TYPES[200]) return nav[selectedEnvironmentIndex].core.background;
        if(nav[selectedEnvironmentIndex].type === ENV_TYPES[100]) return nav[selectedEnvironmentIndex][nav[selectedEnvironmentIndex].active].background;
        
    }

    const getSecondaryHeaderBackground = () => {
        // console.log("selectedEnvironmentIndex",selectedEnvironmentIndex);
        // console.log("nav",nav[selectedEnvironmentIndex]);
        if(nav[selectedEnvironmentIndex]){
            if(nav[selectedEnvironmentIndex].type === ENV_TYPES[100]) return nav[selectedEnvironmentIndex][nav[selectedEnvironmentIndex].active].background;
            if(nav[selectedEnvironmentIndex].type === ENV_TYPES[200]) return nav[selectedEnvironmentIndex].core.background;
            return nav[selectedEnvironmentIndex].background;
        }
        return colors.blue[400]
    }
    
    const getSecondaryHeaderColor = () => {

        if(nav[selectedEnvironmentIndex]){
            if(nav[selectedEnvironmentIndex].type === ENV_TYPES[100]) return nav[selectedEnvironmentIndex][nav[selectedEnvironmentIndex].active].color;
            if(nav[selectedEnvironmentIndex].type === ENV_TYPES[200]) return nav[selectedEnvironmentIndex].core.color;
            return nav[selectedEnvironmentIndex].color;
        }
        return colors.blue[400]
    }

    const getSecondaryHeaderLogoInner = () => {
        if(nav[selectedEnvironmentIndex]){
            if(nav[selectedEnvironmentIndex].type === ENV_TYPES[100]) return nav[selectedEnvironmentIndex][nav[selectedEnvironmentIndex].active].logoInner;
            if(nav[selectedEnvironmentIndex].type === ENV_TYPES[200]) return nav[selectedEnvironmentIndex].core.logoInner;
            return nav[selectedEnvironmentIndex].logoInner;
        }
        return tokens("light").txt["primary"];
    }

    const changeMenu = () => {
        dispatch(toggleMenu());
    }
    return (
        nav.length  > 0 ?
        <Box 

            className={cx('menu', {
                'collapsed': collapsed,
            })}
            display="flex"
            sx={{
                position:"fixed",
                height:1,
                top:0,
                width:"342px !important",
                flexShrink: 0,
                height: 1,
            }}
        >

            {/* Main menu */}
            <Box sx={{
                background:  `${colors.bg["primary"]} !important`,
                width:72,
                height: 1,
                paddingY: 3,
                display: "flex",
                flexDirection: "column",
                overflow:"hidden"}}>
                {/* User */}
                {nav.length > 0 ?
                <>
                    <Item
                        index={0}
                        title={nav[0].title}
                        activeEnvironmentIndex={selectedEnvironmentIndex}
                        setEnvironment={setEnvironment}
                        className={"user"}
                        type={nav[0].type}
                        mt="0"
                    >
                        {nav[0].logoInner}
                    </Item>

                    {/* Contains our environments */}
                    <Box
                        display="block"
                        flex="1"
                        sx={{
                            mt:3,
                            overflowY:"auto",
                            marginBottom:"40px",
                            marginTop:"24px"
                        }}>
                            {nav.map((item, index) => (
                                index > 0 && index < nav.length-1 ?
                                    <Item
                                        key={"nav-env-"+index}
                                        item={item}
                                        type={item.type}
                                        title={item.core.title}
                                        index={index}
                                        activeEnvironmentIndex={selectedEnvironmentIndex}
                                        setEnvironment={setEnvironment}
                                    >
                                        {item.core.logoInner}
                                    </Item>
                                :null
                            ))}

                    </Box>

                    
                    {/* Logoout */}
                    <Item
                        className={"fc"}
                        index={nav.length-1}
                        title={nav[nav.length-1].title}
                        type={nav[nav.length-1].type}
                        activeEnvironmentIndex={selectedEnvironmentIndex}
                        onClick={handleOpen}
                        setEnvironment={setEnvironment}
                    >
                        {nav[nav.length-1].logoInner}
                        {/* <LogoutOutlinedIcon/> */}
                    </Item>
                </>
                :null}
            </Box>
            {/* Contextual menu based on what is set up there */}
            <Box
                className="secondary-menu" 
                sx={{
                    background: `${colors.bg["secondary"]} !important`,
                    width:"270px",
                    height: 1,
                    display:"flex",
                    flexDirection:"column",
                    "& .pro-sidebar" :{
                        minWidth: "unset !important",
                        width: "unset !important",
                        flex:1,
                        paddingBottom:3,
                    },
                    "& .pro-sidebar-inner": {
                        background: "none !important",
                        paddingX:2,
                        "ul" : {
                            height:1,
                            display:"flex",
                            flexDirection:"column"
                        },
                        ".pro-menu" : {
                            height:1,
                            paddingY: "0 !important",
                            ".pro-menu-item":{
                                ".pro-inner-item": {
                                    // padding: "5px 35px 5px 35px !important"
                                    
                                    textDecoration:"none",
                                    marginTop:1,
                                    // marginTop:index > 0 ? 1:0,
                                    display:"flex",
                                    alignItems:"center",
                                    paddingLeft:"8px !important",
                                    height:40,
                                    cursor:"pointer",
                                    borderRadius:3,
                                    color: `${theme.palette.mode === "dark" ? "#88869D" : "rgba(255,255,255,.75)"}`,
                                    transition:"250ms",

                                    "a" : {
                                        color: `${theme.palette.mode === "dark" ? "#88869D" : "rgba(255,255,255,.75)"}`,
                                        transition:"250ms",
                                        "&:hover" : {
                                            color: "#fff !important",
                                            transition:"250ms",
                                            ".pro-arrow" : {
                                                borderColor:"#fff !important",
                                                transition:"250ms"
                                            },
                                        }
                                    },
                                    "&:hover" : {
                                        background: `${colors.nav.linkBg} !important`,
                                        color: "#fff !important",
                                        transition:"250ms",
                                        ".pro-arrow" : {
                                            borderColor:"#fff !important",
                                            transition:"250ms"
                                        },
                                    },
                                    ".pro-arrow" : {
                                        borderColor:`${theme.palette.mode === "dark" ? "#88869D" : "#fff"} !important`,
                                        transition:"250ms"
                                    },
                                    "& .pro-icon-wrapper": {
                                        backgroundColor: "transparent !important",
                                        marginRight:"8px !important",
                                        width:"32px !important",
                                        minWidth: "32px !important",
                                        justifyContent:"center !important",
                                        ".pro-icon" : {
                                            justifyContent:"center !important",
                                            width:20,
                                            "& svg path" : {
                                                fill: `${theme.palette.mode === "dark" ? "#88869D" : "rgba(255,255,255,.75)"}`,
                                            }
                                        }
                                    },
                                },
                                
                                ".pro-inner-list-item" : {
                                    paddingLeft: "0px !important",
                                    background:"none !important",
                                    "ul" : {
                                        paddingY: "0px !important",
                                        ".pro-inner-item" : {
                                            marginLeft:"16px !important",
                                            paddingLeft:"32px !important",
                                            marginTop:"0px !important",
                                            position:"relative",
                                            transition:"250ms",
                                            "&:hover" : {
                                                background: "none !important",
                                                transition:"250ms",
                                            },
                                            "&:before" : {
                                                content: '""',
                                                position:"absolute",
                                                height:"100%",
                                                width:"2px",
                                                left:"3px",
                                                // background:"orange",
                                                borderRadius:2,
                                                background: `${colors.nav.linkBg}`,
                                                transition:"250ms"
                                            }
                                        },
                                        "&:before" : {
                                            content: '""',
                                            position:"absolute",
                                            height:"100%",
                                            width:"2px",
                                            left:19,
                                            borderRadius:2,
                                            background: `${colors.nav.linkBg}`,
                                        }
            
                                    },
                                    ".pro-menu-item.active > .pro-inner-item": {
                                        background: "none !important",
                                        color: "#fff !important",
                                        "a": {

                                            color: `#fff !important`,
                                        },
                                        "&:before" :{
                                            background:beforeSubmenuBarColor(),
                                            transition:"250ms"
                                        }
                                    },
                                },
                                "&.open > .pro-inner-item, &.active > .pro-inner-item" : {
                                    background: `${colors.nav.linkBg} !important`,
                                    color: "#fff !important",
                                    transition:"250ms",
                                    ".pro-arrow" : {
                                        borderColor:"#fff !important",
                                        transition:"250ms"
                                    },
                                },
                                "&.active a" :{
                                    color: "#fff !important",
                                    
                                },
                                "&.settings" : {
                                    marginTop:"auto",
                                   ".pro-inner-item" : { background:"rgba(141,255,248,.15)",
                                    borderRadius:"8px",
                                    "& a" : {
                                        fontWeight:500,
                                        color:"rgb(141,255,248) !important"
                                    },
                                    "& .pro-icon-wrapper" : {
                                        color:"rgb(141,255,248) !important"
                                    },
                                    "&:hover" : {
                                        background:"rgba(141,255,248,1) !important",

                                        "& a" : {
                                            color: 'rgba(0,0,0,1) !important'
                                        },
                                        "& .pro-icon-wrapper" : {
                                            color:"rgb(0,0,0,.75) !important"
                                        },

                                    }}
                                }
                            },
                        }
                    },
                    "@media screen and (max-width: 47.9375em)":{
                        flex:1
                    }
                }}>
                    {nav.length > 0 && nav[selectedEnvironmentIndex] ?
                        <>
                        {collapsed && window.innerWidth <= 1366 ? <Box onClick={changeMenu}> <CloseOverlayBtn></CloseOverlayBtn> </Box>: null}
                        
                        {(nav[selectedEnvironmentIndex]?.type === ENV_TYPES[100] || nav[selectedEnvironmentIndex]?.type === ENV_TYPES[200]) && nav[selectedEnvironmentIndex]?.active !== "core" ?
                        <ButtonBase
                            onClick={() => switchInnerEnvironment(nav[selectedEnvironmentIndex])}
                            sx={{
                                color:"white",
                                fontSize:14,
                                // justifyContent:"flex-start",
                                height:"40px",
                                width:"40px",
                                alignItems:"center",
                                // pl:2,
                                mx:2,
                                background:"rgba(255,255,255,.15)",
                                mt:3,
                                borderRadius:3,
                                marginBottom:2,
                                flexShrink:0
                            }}
                        >
                            <ArrowBackIcon fontSize="small"/>
                        </ButtonBase>
                        :null}

                        <Box
                            sx={{
                                paddingX:2,
                                display:"flex",
                                mt:3,
                                mb:4,
                                height:40,
                                alignItems:"center",
                            }}>
                            <Box
                                sx={{
                                    width:40,
                                    flexShrink:0,
                                    height:40,
                                    display:"flex",
                                    justifyContent: "center",
                                    alignItems:"center",
                                    background: getSecondaryHeaderBackground(),
                                    // background: `${nav[environment][nav[environment].active].background}`,
                                    borderRadius:3,
                                    color: getSecondaryHeaderColor(),
                                    // color: `${nav[environment][nav[environment].active].color}`,
                                    transition: "250ms",
                                }}>
                                    {getSecondaryHeaderLogoInner()}
                            </Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    overflow:"hidden"
                                }}
                            >
                                {(nav[selectedEnvironmentIndex]?.type === ENV_TYPES[100] || nav[selectedEnvironmentIndex]?.type === ENV_TYPES[200]) && nav[selectedEnvironmentIndex]?.active === "settings" ? 
                                <Typography
                                    sx={{
                                        fontSize:"14px",
                                        fontWeight:400,
                                        color: "rgba(255,255,255,.5)"
                                    }}
                                    marginLeft={2}
                                    component={"span"}
                                >
                                    {t('settings')}
                                    {/* {nav[environment][nav[environment].active].title} */}
                                </Typography>
                                :null}

                                <Typography
                                    marginLeft={2}
                                    whiteSpace={"nowrap"}
                                    textOverflow={"ellipsis"}
                                    fontSize={17}
                                    fontWeight={700}
                                    color={"#fff"}
                                    component={"span"}
                                    overflow={"hidden"}
                                >
                                { (nav[selectedEnvironmentIndex]?.type === ENV_TYPES[100] || nav[selectedEnvironmentIndex]?.type === ENV_TYPES[200]) ? nav[selectedEnvironmentIndex]['core'].title : nav[selectedEnvironmentIndex]?.title}
                                    {/* {nav[environment][nav[environment].active].title} */}
                                </Typography>
                            </Box>
                        </Box>

                        <ProSidebar>
                            <Menu iconShape="square">
                                
                                {(nav[selectedEnvironmentIndex]?.type === ENV_TYPES[100]) ?
                                    <>

                                        {nav[selectedEnvironmentIndex][nav[selectedEnvironmentIndex]?.active].links.map((link, index) => (
                                            <MenuWrapperItem
                                                style={{
                                                    marginTop:index > 0 ? 1:0,
                                                    // marginTop:-1
                                                }}
                                                forwardRef={menuChildRef}
                                                // for={menuChildRef}
                                                key={index}
                                                toOpen={openMenu(link)}
                                                // toClose={closeMenu(link)}
                                                selected={selectedMenuItem}
                                                setSelected={setSelected}
                                                defaultOpen={defaultOpenSubMenu(link)}
                                                link={link}
                                            />
                                        ))}

                                        {nav[selectedEnvironmentIndex]?.type === ENV_TYPES[100] && nav[selectedEnvironmentIndex]?.active !== "settings" ?
                                        <MenuWrapperItem
                                            style={{
                                                marginTop:"auto",
                                                // marginTop:-1
                                            }}
                                            type={innerTypes[200]}
                                            className={"settings"}
                                            // toOpen={openMenu(link)}
                                            link={{
                                                logo: <SettingsOutlinedIcon/>,
                                                name:t('settings')
                                            }}
                                            env={nav[selectedEnvironmentIndex]}
                                            switchInnerEnvironment={switchInnerEnvironment}
                                        />
                                        :null}
                                    </>
                                : nav[selectedEnvironmentIndex]?.type === ENV_TYPES[200] ? 
                                    nav[selectedEnvironmentIndex]?.core.links.map((link, index) => (
                                        <MenuWrapperItem
                                            style={{
                                                marginTop:index > 0 ? 1:0,
                                                // marginTop:-1
                                            }}
                                            forwardRef={menuChildRef}
                                            // for={menuChildRef}
                                            key={index}
                                            toOpen={openMenu(link)}
                                            selected={selectedMenuItem}
                                            setSelected={setSelected}
                                            defaultOpen={defaultOpenSubMenu(link)}
                                            link={link}
                                        />
                                    ))
                                :
                                    nav[selectedEnvironmentIndex]?.links.map((link, index) => (
                                        <MenuWrapperItem
                                        style={{
                                            marginTop:index > 0 ? 1:0,
                                            // marginTop:-1
                                        }}
                                        forwardRef={menuChildRef}
                                        // for={menuChildRef}
                                        key={index}
                                        toOpen={openMenu(link)}
                                        selected={selectedMenuItem}
                                        setSelected={setSelected}
                                        defaultOpen={defaultOpenSubMenu(link)}
                                        link={link}/>
                                    ))
                                }
                            </Menu>
                        </ProSidebar>
                        </>
                    :null}
            </Box>

            <MLogout
                open={open}
                handleLogout={handleLogout}
                handleClose={handleClose}
            />

        </Box>
        : null
    );
};

export default Sidebar;