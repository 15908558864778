import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { showToastMessageDeleteRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import { useDeleteIntegratorMutation, useEditIntegratorMutation } from "../../../../../newapi/webshop/integratorSlice";
import { useGetWarehousesSelectQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";
import * as yup from "yup";
import cx from "classnames"
import { yupRequired, yupNumberRequired } from "../../../../../utils/validation";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete"; // Import the confirmation modal

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const data = props.data;

    const [integratorInfo, setIntegratorInfo] = useState({
        state: "active",
        id: 0,
        type: "",
        name: "",
        syncOrders: false,
        syncProducts: false,
        syncStock: false,
        syncCreateProducts: false,
        relationId: 0,
        webshopcredentials: [],
    });
    const [open, setOpen] = useState(false); // State for confirmation modal

    useEffect(() => {
        setIntegratorInfo(props.data);
    },[props.data]);
    

    const handleChange = (key, value) => {
        setIntegratorInfo({
          ...integratorInfo,
          [key]: value
        });
      };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={integratorInfo} action={handleSaveClick} deleteAction={handleDeleteClick}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[integratorInfo]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name',t)
    };
    
    const schema = yup.object().shape(rules);

    const [EditIntegrator,{isLoading}] = useEditIntegratorMutation();
    const [DeleteIntegrator,{isLoading2}] = useDeleteIntegratorMutation();
    const handleSaveClick = async () => {
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                
                const data = await EditIntegrator(integratorInfo).unwrap()
                showToastMessageUpdateRequest(t,data);
        } catch (err) {
            showToastMessageRequestError(t("update_failed"),t,err);
          }
            
        } else {
            if(!isLoading) {
                toast.error(t("not_all_fields_filled"), {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }    
        }        
    };

    const handleDeleteClick = () => {
        setOpen(true); // Open confirmation modal
    };

    const confirmDelete = async () => {
        try {
            const response = await DeleteIntegrator(integratorInfo.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageRequestError(t("delete_failed"), t, err);
        } finally {
            setOpen(false); // Close modal after deletion
        }
    };

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
        <Dropdown title={t("general")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:160,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text>{t("name")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                name="name"
                                value={integratorInfo?.name ?? ""}
                                onChange={handleChange}
                                error={fieldErrors.name}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:160,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                    <Text>{t("sync_orders")}</Text>
                    </Box>
                
               <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                    <ToggleButtonGroup
                                color={integratorInfo?.syncOrders ? "green" : "red"}
                                value={integratorInfo?.syncOrders ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncOrders", value)}
                                aria-label="Active"
                                className={cx(`${integratorInfo?.syncOrders}`, {
                                    'active': integratorInfo?.syncOrders
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
            <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:160,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                    <Text>{t("sync_products")}</Text>
                    </Box>
                
               <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                    <ToggleButtonGroup
                                color={integratorInfo?.syncProducts ? "green" : "red"}
                                value={integratorInfo?.syncProducts ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncProducts", value)}
                                aria-label="Active"
                                className={cx(`${integratorInfo?.syncProducts}`, {
                                    'active': integratorInfo?.syncProducts
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
            
            <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:160,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                    <Text>{t("sync_create_products")}</Text>
                    </Box>
                
               <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                    <ToggleButtonGroup
                                color={integratorInfo?.syncCreateProducts ? "green" : "red"}
                                value={integratorInfo?.syncCreateProducts ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncCreateProducts", value)}
                                aria-label="Active"
                                className={cx(`${integratorInfo?.syncCreateProducts}`, {
                                    'active': integratorInfo?.syncCreateProducts
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
            <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:160,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                    <Text>{t("sync_stock")}</Text>
                    </Box>
                
               <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                    <ToggleButtonGroup
                                color={integratorInfo?.syncStock ? "green" : "red"}
                                value={integratorInfo?.syncStock ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncStock", value)}
                                aria-label="Active"
                                className={cx(`${integratorInfo?.syncStock}`, {
                                    'active': integratorInfo?.syncStock
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
                </Box>
            </Box>
        </Dropdown>
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
        <GridActions
            id={props.data?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
        />
        <MConfirmDelete
            open={open}
            handleClose={() => setOpen(false)}
            confirm={confirmDelete}
        />
            </Box>
        </Box>
    );
}

export default DetailsPanel;