import { Box, useTheme } from "@mui/material"
import { useState, useEffect } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedNumberInput from "../../theme/inputs/ShValidatedNumberInput";
import { yupNumberRequired } from "../../../utils/validation";
import { useUpdateChildProductMutation } from "../../../newapi/warehouse/productSlice";

const MEditChildProduct = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [updateChildProduct,{isLoading}] = useUpdateChildProductMutation();

    const [childProduct, setChildProduct] = useState({
        quantity: 0,
        childProductId: 0,
        parentProductId: 0
    });

    useEffect(() => {
        if (props.childProduct && props.childProduct.childProductId) {
            setChildProduct(props.childProduct);
        }
    }, [props.childProduct]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        quantity: yupNumberRequired('quantity', t)
    };
    
    const schema = yup.object().shape(rules);
   
    const SaveChildProduct = async () => {
        try {
            setFieldErrors({});
            await schema.validate(childProduct, { abortEarly: false });

            // // If validation is successful, proceed with the  call
            const data = await updateChildProduct(childProduct).unwrap();
            showToastMessageUpdateRequest(t, data);

             props.handleClose();
        } catch (err) {
          if (err.name === 'ValidationError') {
            // Create an errors object where keys are field names and values are error messages
            const errors = err.inner.reduce((acc, currentError) => ({
              ...acc,
              [currentError.path]: currentError.message
            }), {});
      
            // Set the validation errors in the state
            setFieldErrors(errors);
          } else {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("update_failed"), t, err);
          }

        }
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setChildProduct({
            ...childProduct,
            [key]: value
        });

        // Optionally clear the error for that field
        setFieldErrors({
          ...fieldErrors,
          [key]: ''
        });
      };      

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("edit_product")}</Subheading>
                <Text light center>{childProduct.description}</Text>
            </Box>

            {/* BODY */}
            
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    pb:2,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:4
                    }}
                >

                    <Box
                        sx={{
                            width:178,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text>{t("amount")}</Text>&nbsp;
                    </Box>
                    
                    <Box 
                        sx={{
                            display:"flex",
                            flex:1,
                        }}
                    >
                        <Box    
                            sx={{
                                flex:1,
                                borderRadius:4,
                                display:"flex",
                                gap: 1
                            }}
                        >
                        <ShValidatedNumberInput
                            btnSize={"24"}
                            name="quantity"
                            value={parseInt(childProduct.quantity)}
                            onChange={handleChange}
                            error={fieldErrors.quantity}
                        />
                        </Box>
                        
                    </Box>
                </Box>
            </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    block
                    variant="contained" 
                     onClick={() => SaveChildProduct()}
                    >{t("save")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MEditChildProduct;
