import { Box, useTheme } from "@mui/material";
import Subheading from "../../../components/theme/text/Subheading";
import Text from "../../../components/theme/text/Text";
import Shbutton from "../../../components/theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useMemo, useRef, useState } from "react";

import { useGridApiRef } from "@mui/x-data-grid";
import OverflowRow from "../../../components/theme/OverflowRow";

// import { getOrderStatusCounts } from "../../../api/orders/orders";
import { useGetOrderCompareCountsDashboardQuery } from "../../../newapi/order/ordersSlice";
import OutboundOrdersView from "./views/Orders";
import OutboundActionRequiredView from "./views/ActionRequired";
import OutboundPriorityView from "./views/Priority";
import { useTranslation } from 'react-i18next';

import AreaChart from "../../../components/theme/charts/AreaChart";
import Heading from "../../../components/theme/text/Heading";
import { useGetOutboundDashboardQuery } from "../../../newapi/reports/fulfillment/reportFulfillmentSlice";
import TabView3 from "../../../components/theme/TabView3/TabView3";
import { relationOrdersConfig } from "./relationOrdersConfig";
import { useGetDashboardRelationOrderChartQuery, useGetDashboardRelationOrderTotalsQuery } from "../../../newapi/reports/relation/reportRelationSlice";
import { ReactComponent as OrdersLogo } from '../../../styles/svg/orders.svg';
import { ReactComponent as EuroLogo } from '../../../styles/svg/euro.svg';
import { ReactComponent as ProductLogo } from '../../../styles/svg/shirt-product.svg';
import cx from 'classnames';
import ShDoubleTextSkeleton from "../../../components/theme/skeleton/ShDoubleTextSkeleton";

const relationChartStates = {
    avgRevenue: "avgRevenue",
    orderCount: "orderCount",
    revenue: "revenue",
    productCount: "productCount"
}

const RelationDashboardCard = (props) => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            component={"button"}
            onClick={() => props.changeChartType(props.relationChartType)}
            className={cx('',{
                'active': props.active,
            })}
            sx={{
                cursor:"pointer",
                background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                borderRadius:4,
                padding:3,
                display:"flex",
                flexDirection:"column",
                borderColor:"transparent",

                ".logo-box" : {
                    width:40,
                    height:40,
                    borderRadius:2,
                    background:colors.grey[100],
                    color:colors.txt["primary"],
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    "& .logo" : {
                        fill:colors.txt["primary"],
                    }
                },

                "&.active": {
                    background: theme.palette.mode === "dark" ? colors.blue[100]: colors.primary[100],
                    borderColor: theme.palette.mode === "dark" ? colors.blue[400]: colors.primary[400],

                    ".logo-box" : {
                        background: theme.palette.mode === "dark" ? colors.blue[100]: colors.primary[100],
                        "& .logo, & .logo path" : {
                            fill: theme.palette.mode === "dark" ? colors.blue[400]: colors.primary[400],
                        }
                    },
                },
                "@media screen and (max-width: 47.9375em)" : {
                    p:2,
                    flexDirection:"row",
                    justifyContent:"space-between",
                    ".logo-box" : {
                        width:32,
                        height:32,
                    }

                }

            }}
        >
            <Box
                sx={{
                    mb:"auto",
                    "@media screen and (max-width: 47.9375em)" : {
                        order:2
                    }
                    
                }}
            >
                <Box className="logo-box">
                    {props.logo}
                </Box>
            </Box>
            {props.isLoading ?
                <ShDoubleTextSkeleton/> 
            : 
                <Box 
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 47.9375em)" : {
                            order:1
                        }
                    }}
                >
                    <Text light>{t(props.data[props.type].label)}</Text>
                    <Heading sx={{pt:0.5}}>{props.valueAdd}{t(props.data[props.type].value)}</Heading>
                </Box>
            }
        </Box>
    )
}

const TopRow = () => { 
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [chartType, setChartType] = useState(relationChartStates.revenue);

    const changeChartType = (type) => {
        setChartType(type);
    }

    const [ chartDataData, setChartDataData] = useState([]);

    const { data : totalData, isLoading,isFetching } = useGetDashboardRelationOrderTotalsQuery();

    const {data: dashData, isLoading: chartIsLoading, isFetching: chartIsFetching, isError: isChartError, refetch: chartRefetch} = useGetDashboardRelationOrderChartQuery(chartType);

    useEffect(() => {
        if (!chartIsLoading && !chartIsFetching && dashData && dashData !== chartDataData) {
            setChartDataData(dashData.data); // Directly store the chart data
        }
    }, [dashData, chartIsLoading, chartIsFetching, chartDataData]);

    // Memoize chart data: directly use the data from the backend
    const chartData = useMemo(() => {
        if (!chartDataData) return []; // Return empty if no chart data is available
        return dashData?.data?.series; // Directly use the series returned by the backend
    }, [chartDataData, dashData]);

    return (
        <OverflowRow className={"is-mobile-col"}>
            <Box
                sx={{
                    
                    display:"grid",
                    gridTemplateColumns:"repeat(2, 1fr)",
                    gridTemplateRows:"repeat(2, 1fr)",
                    columnGap:3,
                    rowGap:3,
                    flex:2,
                    "@media screen and (max-width: 64em)" : {
                        minWidth:"calc(100% - 56px)",
                        // minWidth:1,
                    },
                    "@media screen and (max-width: 47.9375em)" : {
                        minWidth:"calc(100% - 48px)",
                        columnGap:2,
                        rowGap:2,
                    }
                }}
            >

                <RelationDashboardCard
                    logo={<EuroLogo className="logo"/>}
                    changeChartType={changeChartType}
                    relationChartType={relationChartStates.revenue}
                    active={chartType === relationChartStates.revenue}
                    isLoading={isLoading || isFetching}
                    data={totalData}
                    type={"revenue"}
                    valueAdd={"€"}
                />

                <RelationDashboardCard
                    logo={<EuroLogo className="logo"/>}
                    changeChartType={changeChartType}
                    relationChartType={relationChartStates.avgRevenue}
                    active={chartType === relationChartStates.avgRevenue}
                    isLoading={isLoading || isFetching}
                    data={totalData}
                    type={"avgRevenue"}
                    valueAdd={"€"}
                />

                <RelationDashboardCard
                    logo={<OrdersLogo className="logo"/>}
                    changeChartType={changeChartType}
                    relationChartType={relationChartStates.orderCount}
                    active={chartType === relationChartStates.orderCount}
                    isLoading={isLoading || isFetching}
                    data={totalData}
                    type={"orderCount"}
                />
                
                <RelationDashboardCard
                    logo={<ProductLogo className="logo"/>}
                    changeChartType={changeChartType}
                    relationChartType={relationChartStates.productCount}
                    active={chartType === relationChartStates.productCount}
                    isLoading={isLoading || isFetching}
                    data={totalData}
                    type={"productCount"}
                />
            </Box>
            
            <Box
                sx={{
                    overflow:"hidden",
                    flex:3,
                    "@media screen and (max-width: 47.9375em)" : {
                        minWidth: "calc(100% - 48px)"
                    }
                }}
            >
                <AreaChart
                    stacked={false}
                    hideFilter
                    optionsEnabled={false}
                    onRetry={chartRefetch}
                    isError={isChartError}
                    isLoading={chartIsLoading || chartIsFetching}
                    title={t("shipped_orderlines")}
                    subtitle={t("per_day")}
                    type="bar"
                    staticColors={[colors.primary[400],colors.primary[200]]}
                    colors={['primary']}
                    categories={dashData?.data?.categories ? dashData.data.categories : []}
                    // categories={Object.keys(dashData?.data?.categories)} // Dates as categories (keys of `chart`)
                    // values={[]}
                    values={chartData}
                    color={"default"}
                    tickAmount={30}
                />
            </Box>
            
        </OverflowRow>
    )
}

const RelationOutbound = (props) => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box sx={{background:"", width:1,paddingTop:9}}>
            <TopRow/>

            <TabView3
                tabConfig={relationOrdersConfig}
                sx={{
                    width:1,
                    background:"green",
                    minHeight:"calc(100% - 431px)",
                    background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    position:"relative",
                    zIndex:2,

                    display:"flex",
                    flexDirection:"column",

                    "&:before" : {
                        content: "''",
                        display:"block",
                        width:"32px",
                        height:"32px",
                        position:"absolute",
                        left:0,
                        top:0,
                        zIndex:1,
                        backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        top:"40px",
                        height:"48px",
                        width:"24px",
                        borderTopLeftRadius:"24px",
                        boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                        transition:"250ms"
                    },

                    "&:after" : {
                        content: "''",
                        display:"block",
                        width:"32px",
                        height:"32px",
                        position:"absolute",
                        top:0,
                        zIndex:1,
                        backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        top:"40px",
                        right:0,
                        height:"48px",
                        width:"24px",
                        borderTopLeftRadius:"24px",
                        boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                        transform:"scaleX(-1)",
                        transition:"250ms"
                    },

                    "@media screen and (max-width: 47.9375em)" : {
                        "&:after, &:before" : {
                            top:"-0px"
                        }
                    }
                }}
                title={t("outbound")}
                
            >
                <>
                    <OutboundOrdersView config={relationOrdersConfig.orders}/>
                    <OutboundActionRequiredView config={relationOrdersConfig.ordersActionRequired} />
                    <OutboundPriorityView config={relationOrdersConfig.ordersPriority}/>
                </>
            </TabView3>
        </Box>
    );
};

export default RelationOutbound;