import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";

import { useTranslation } from "react-i18next";
import { useGetPriceIncreaseLogsQuery } from '../../../../../newapi/financial/priceIncreaseSlice';
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import { formatPrice } from "../../../../../utils/helpers";
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';

const GridActions = (props) => {
    return <></>
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    
    filter : {
        name: "productsFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Logs = (props) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const columns = [
        {field: "invoiceProduct", headerName: t("product"), flex:2, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.invoiceProduct.name;
        }},
        {field: "createdAt", headerName: t("created_on"), flex: 2, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            const date = new Date(params.row.createdAt);
            return date.toLocaleString(i18n.language, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
        }},
        {field: "oldPrice", headerName: t("old_price"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return formatPrice(i18n, params.row.oldPrice);
        }},
        {field: "newPrice", headerName: t("new_price"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return formatPrice(i18n, params.row.newPrice);
        }},
        {field: "linkedTo", headerName: t("linked_to"), flex:2,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.categoryProduct ? params.row.categoryProduct.invoiceCategory.name : params.row.relationProduct.relation.name;
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <ShTextSkeleton/>
                )
            }
        },
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
            {props.priceIncreaseId && 
                <ShDataGrid2 key={theme.palette.mode}
                    itemId={props.priceIncreaseId}
                    content={useGetPriceIncreaseLogsQuery}
                    // gridActions={<GridActions></GridActions>}
                    title={t("logs")}
                    gridOptions={gridOptions}
                    isSidepanelGrid
                    columns={columns}
                />
            }
        </Box>
      );
      
}

export default Logs;