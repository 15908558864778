import { Box, InputBase, List, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useState, useEffect } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import { themeSettings, tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { useGetRelationInvoiceProductsQuery } from "../../../newapi/global/relation/relationSlice";
import { useAddInvoiceLinesMutation } from "../../../newapi/financial/invoiceSlice";
import { showToastMessagePostRequest, showToastMessage, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import { InputDropdown } from "../../theme/dropdowns/InputDropdown";
import { yupRequired } from "../../../utils/validation";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";
import { EuroOutlined } from "@mui/icons-material";
import { useAddPriceIncreaseProductsMutation, useGetPriceIncreaseProductOptionsQuery } from "../../../newapi/financial/priceIncreaseSlice";

const MAddProduct = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);

    const { data: priceIncreaseProducts, productsLoading } = useGetPriceIncreaseProductOptionsQuery();

    const [fieldErrors, setFieldErrors] = useState({});
    const [newProductLines, setnewProductLines] = useState([{
        id: 0,
        rowIndex: 0,
    }]);


    const [addProducts, {isLoading}] = useAddPriceIncreaseProductsMutation();
    const SaveInvoiceLines = async (newProductLines, props) => {
        try {
            

            const mappedLines = newProductLines.filter(line => line.id > 0);

            const requestData = {
                id: props.priceIncreaseId,
                products: mappedLines.map(line => line.id) // Map to extract only the `id` from each product
            }
            console.log(requestData);
            
            const data = await addProducts(requestData).unwrap()
            showToastMessagePostRequest(t, data);
            props.handleClose();
        } catch (err) {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, err);
        }
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    
    const handleProductSelect = (rowIndex, selected) => {
        rowIndex = parseInt(rowIndex);
        // Check if selected product is already in the list
        const isDuplicate = newProductLines.some(line => line.id === selected.id);
        if (isDuplicate) {
            showToastMessage("error", t("product_already_in_list"));
            return;
        }

        // Check if last item has id = 0
        setnewProductLines(prev => prev.map((line, index) => {
            if (line.rowIndex === rowIndex) {
                return {
                    rowIndex: rowIndex,
                    amount: 0,
                    ...selected
                };
            }
            return line;
        }));
    };

    useEffect(() => {
        const lastItem = newProductLines[newProductLines.length - 1];
        if (lastItem.id > 0) {
            setnewProductLines(prev => [...prev, {
                id: 0,
                rowIndex: newProductLines.length
            }]);
        }
    }, [newProductLines]);

    const handleDeleleteLineRow = (rowIndex) => {
        setnewProductLines(prev => prev.filter(line => line.rowIndex !== rowIndex));
    };

    const handleChange = (rowIndex, key, value) => {
        // Only allow numbers and one decimal point
        if (!/^\d*\.?\d*$/.test(value) && key === "amount") {
            return;
        }

        rowIndex = parseInt(rowIndex);
        if (key === "amount") {
            value = parseInt(value);
        }

        setnewProductLines(prev => prev.map((line, index) => {
            if (line.rowIndex === rowIndex) {
                return {
                    ...line,
                    [key]: value
                };
            }
            return line;
        }));
    };
      

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_products")}</Subheading>
            </Box>
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    paddingRight:3,
                    paddingLeft:3,
                    paddingTop:3,
                    paddingBottom:1,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    alignItems:"center",
                    flexDirection:"column",
                    gap:1
                }}
            >
                {newProductLines && newProductLines.length > 0 && newProductLines.map((invoiceLine, index) => (
                   
                    <InputDropdown
                        key={"add-proudct-npl-"+index}
                        displayName={"name"} 
                        onChange={(selected) => handleProductSelect(index, priceIncreaseProducts[selected])}
                        noSelection={t("choose_option")}
                        options={priceIncreaseProducts ?? []}
                        closeOnSelection
                    />
                    
                ))}
            </Box>
            <Box
                component="footer"
                sx={{
                    pt:2,
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    // paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}> 
                    <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    variant="contained" 
                    onClick={() => SaveInvoiceLines(newProductLines, props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddProduct;
