// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { tokens } from '../../../../../theme';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import ShValidatedNumberInput from "../../../../theme/inputs/ShValidatedNumberInput";
import { yupNumberRequired } from "../../../../../utils/validation";

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    
    const data = {...props.data};
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (
        <>
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                        height:"1px",
                    }}
                >

                </Box>
                
                <Box    
                    sx={{
                        flex:1,
                        display:"flex",
                        justifyContent:"space-between",
                        alignItems:"center",
                        gap:1,
                    }}
                >
                    <Box   
                        sx={{
                            flex:1,
                            display:"flex",
                            justifyContent: "center"
                        }}
                    >
                        <Text bold>{t("min")}</Text>
                    </Box>
                    <Box  
                        sx={{
                            flex:1,
                            display:"flex",
                            justifyContent: "center"
                        }}
                    >
                        <Text bold>{t("max")}</Text>
                    </Box>
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("weight")}</Text>&nbsp;
                    <Text italic light>(kg)</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                        gap:1,
                        alignItems:"flex-start",
                    }}
                >
                
                    <NumberInput
                    value={data.minWeight ?? 0}
                    change={(e) => handleChange('minWeight', e)}/>
                    
                
                    
                    <ShValidatedNumberInput
                    
                        name="maxWeight"
                        value={data.maxWeight ?? 0}
                        onChange={handleChange}
                        error={props.errors.maxWeight}
                    />
                        
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("length")}</Text>&nbsp;
                    <Text italic light>({t("mm")})</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                        gap:1,
                        alignItems:"flex-start",
                    }}
                >
                    
                    <NumberInput
                    value={data.minLength ?? 0}
                    change={(e) => handleChange('minLength', e)}/>
                    
                
                    <ShValidatedNumberInput
                    
                        name="maxLength"
                        value={data.maxLength ?? 0}
                        onChange={handleChange}
                        error={props.errors.maxLength}
                    />
                        
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("width")}</Text>&nbsp;
                    <Text italic light>({t("mm")})</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                        gap:1,
                        alignItems:"flex-start",
                    }}
                >
                    
                    <NumberInput
                    value={data.minWidth ?? 0}
                    change={(e) => handleChange('minWidth', e)}/>
                    
                
                    <ShValidatedNumberInput
                        name="maxWidth"
                        value={data.maxWidth ?? 0}
                        onChange={handleChange}
                        error={props.errors.maxWidth}
                    />
                        
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("height")}</Text>&nbsp;
                    <Text italic light>({t("mm")})</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                        gap:1,
                        alignItems:"flex-start",
                    }}
                >
                    
                    <NumberInput 
                    value={data.minHeight ?? 0}
                    change={(e) => handleChange('minHeight', e)}/>
                        
                    
                    <ShValidatedNumberInput
                        name="maxHeight"
                        value={data.maxHeight ?? 0}
                        onChange={handleChange}
                        error={props.errors.maxHeight}
                    />
                        
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("volume")}</Text>&nbsp;
                    <Text italic light>({t("mm3")})</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                        gap:1,
                        alignItems:"flex-start",
                    }}
                >
                    
                    <NumberInput
                    value={data.minVolume ?? 0}
                    change={(e) => handleChange('minVolume', e)}/>
                        
                    
                    <ShValidatedNumberInput
                        name="maxVolume"
                        value={data.maxVolume ?? 0}
                        onChange={handleChange}
                        error={props.errors.maxVolume}
                    />
                        
                </Box>
            </Box>
        </>
    );
};

const StreamMeasurements = (props) => {
    const { t } = useTranslation();

    const schema = yup.object({
        maxWeight: yupNumberRequired('max_weight',t),
        maxLength: yupNumberRequired('max_length',t),
        maxWidth: yupNumberRequired('max_width',t),
        maxHeight: yupNumberRequired('max_height',t),
        maxVolume: yupNumberRequired('max_volume',t)
    });

    return (
        <ProgressionTab 
            tabId="tabMeasurements" 
            subtitle={"measurements"} 
            step={2}
            nextTab={"tabShipper"}
            maxSteps={4}
            schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default StreamMeasurements;

