import { Box, useTheme } from "@mui/material"
import { useEffect, useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import {  showToastMessagePostRequest, showToastMessageRequestError, showToastMessage } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedNumberInput from "../../theme/inputs/ShValidatedNumberInput";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import ShDateRangePicker from "../../theme/ShDateRangePicker";
import { yupRequired, yupDecimalRequired } from "../../../utils/validation";
import { useAddPriceIncreaseMutation, useUpdatePriceIncreaseMutation } from "../../../newapi/financial/priceIncreaseSlice";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { isFutureDate } from "../../../utils/helpers";
// import { required } from '../../utils/validation';

const MEditPriceIncrease = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [editPrice,{isLoading}] = useUpdatePriceIncreaseMutation();

    const select = (selected) => {
        setSelected(selected);
    }

    const [priceIncreaseData, setPriceIncreaseData] = useState({
        name: '',
        percentage: 0,
        date: new Date().toISOString().split('T')[0],
        modifyCustomerPrices: false
    });

    useEffect(() => {
        let dataToSet = { ...props.priceIncreaseData };
    
        // Delete the property you don't want
        delete dataToSet['invoiceProducts'];
        setPriceIncreaseData(dataToSet);
    },[props.priceIncreaseData]);


    const handleClose = () => {
      props.handleClose();
    }

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        percentage: yupDecimalRequired('percentage', t),
    };
    
    const schema = yup.object().shape(rules);
   
    const SavePriceIncrease = async () => {
        try {
            const requestData = {
                ...priceIncreaseData,
            }

            if (!isFutureDate(priceIncreaseData.date)) {
                showToastMessage("error", t("effective_date_must_be_in_the_future"), 2000);
                return;
            }

            // Reset field errors
            setFieldErrors({});
            await schema.validate(priceIncreaseData, { abortEarly: false });
        
            // If validation is successful, proceed with the  call
            const data = await editPrice(requestData).unwrap();
            showToastMessagePostRequest(t, data);

            handleClose();

        } catch (err) {
          if (err.name === 'ValidationError') {
            // Create an errors object where keys are field names and values are error messages
            const errors = err.inner.reduce((acc, currentError) => ({
              ...acc,
              [currentError.path]: currentError.message
            }), {});
      
            // Set the validation errors in the state
            setFieldErrors(errors);
          } else {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, err);
          }

        }
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    
    const handleDateChange = (date) => {
        handleChange('date', date[0].formattedStartDate);
    }

    const handleChange = (key, value) => {
        setPriceIncreaseData({
          ...priceIncreaseData,
          [key]: value
        });
        // Optionally clear the error for that field
        setFieldErrors({
          ...fieldErrors,
          [key]: ''
        });
      };      

    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:3,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    // flexDirection:"column",
                    gap:4
                }}>
                <Subheading>{t("edit_price_increase")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    pb:2,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:178,
                        display:"flex",
                        alignItems:"center",
                    }}
                >
                    <Text>{t("name")}</Text>&nbsp;
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                    }}
                >
                    <Box    
                        sx={{
                            flex:1,
                            borderRadius:4,
                            display:"flex",
                            gap: 1
                        }}
                    >
                        <ShValidatedInput
                            name="name"
                            value={priceIncreaseData?.name}
                            onChange={handleChange}
                            error={fieldErrors.name}
                        />
                    </Box>
                    
                </Box>
            </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:4
                    }}
                >

                    <Box
                        sx={{
                            width:178,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text>{t("percentage_change")}</Text>&nbsp;
                    </Box>
                    
                    <Box 
                        sx={{
                            display:"flex",
                            flex:1,
                        }}
                    >
                        <Box    
                            sx={{
                                flex:1,
                                borderRadius:4,
                                display:"flex",
                                gap: 1
                            }}
                        >
                            <ShValidatedNumberInput
                                min={0}
                                btnSize={"24"}
                                name="percentage"
                                value={priceIncreaseData?.percentage}
                                onChange={handleChange}
                                error={fieldErrors.percentage}
                            />
                        </Box>
                        
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:4
                    }}
                >

                    <Box
                        sx={{
                            width:178,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text>{t("effective_date")}</Text>&nbsp;
                    </Box>
                    
                    <Box 
                        sx={{
                            display:"flex",
                            flex:1,
                        }}
                    >
                        <Box    
                            sx={{
                                flex:1,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32,
                                gap: 1
                            }}
                        >
                            <ShDateRangePicker 
                            singleSelect={true} 
                            startDate={priceIncreaseData?.date} 
                            select={handleDateChange} 
                            size={32}
                        >

                            </ShDateRangePicker>
                        </Box>
                        
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:4
                    }}
                >

                    <Box
                        sx={{
                            width:178,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text bold variant={"orange"} noBreak>{t("edit_changed_prices")}</Text>
                    </Box>
                    
                    <Box 
                        sx={{
                            display:"flex",
                            flex:1,
                        }}
                    >
                        <Box    
                            onClick={() => handleChange('modifyCustomerPrices',!priceIncreaseData?.modifyCustomerPrices)}
                            sx={{
                                cursor:"pointer",
                                flex:1,
                                backgroundColor :priceIncreaseData?.modifyCustomerPrices ? colors.orange[400] : colors.orange[100],
                                borderRadius:4,
                                display:"flex",
                                height:32,
                                gap: 1,
                                pl:0.5,
                                alignItems:"center",
                                color:priceIncreaseData?.modifyCustomerPrices ? "#fff" : colors.orange[400] + ` !important`
                            }}
                        >
                            
                            {!priceIncreaseData?.modifyCustomerPrices ?
                                <CheckBoxOutlineBlank color="orange"/>
                            :
                                <CheckBox htmlColor="#fff"/>
                            }      

                            {!priceIncreaseData?.modifyCustomerPrices ?
                                t("no")
                            :
                                t("yes")
                            }
                            
                        </Box>
                        
                    </Box>
                </Box>




            </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    block
                    variant="contained" 
                     onClick={() => SavePriceIncrease()}
                    >{t("edit")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MEditPriceIncrease;
