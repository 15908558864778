
import CropFreeIcon from '@mui/icons-material/CropFree';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const spStockDetailsConfig = {
    warehouse: {
        title: 'warehouse_locations',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
    info: {
        title: 'info',
        icon: <InfoOutlinedIcon/>,
    },
    sizeAndWeight: {
        title: 'size_and_weight',
        icon: <CropFreeIcon/>,
    },
    stock:  {
        title: 'stock',
        icon: <InfoOutlinedIcon/>
        //mobileactions added later
    },
    instructions: {
        title: 'instructions',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    // efullfillment: {
    //     title: 'efullfillment',
    //     icon: <InfoOutlinedIcon/>,
    // },
    stockMutations: {
        title: 'stock_mutations',
        icon: <FormatListBulletedIcon/>,
        isSingleDataGrid: true,
    },
};
