import { Box, } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";

import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useDeleteShipperMutation, useEditShipperMutation } from "../../../../../newapi/shipper/shipperSlice";
import { yupRequired, yupNumberRequired, yupArrayRequired } from "../../../../../utils/validation";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedNumberInput from "../../../../theme/inputs/ShValidatedNumberInput";
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import { showToastMessageDeleteRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";
import { useGetWarehousesSelectQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
        <Shbutton
            className={"group-btn"}
            onClick={props.deleteAction}
            color="red"
            variant="contained"
            endIcon={<DeleteIcon />}
        >
            {t('delete')}
        </Shbutton>
        <Shbutton
            className={"group-btn"}
            onClick={props.action}
            color={theme.palette.mode === "dark" ? "blue" : "primary"}
            variant="contained"
            endIcon={<SaveIcon />}
        >
            {t('save')}
        </Shbutton>
    </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data: warehouses } = useGetWarehousesSelectQuery();

    const [shipperInfo, setShipperInfo] = useState({
        id: 0,
        name: "",
        reference: "",
        maxWeight: 0,
        maxLength: 0,
        maxWidth: 0,
        maxHeight: 0,
        maxVolume: 0,
        warehouses: []
    });
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setShipperInfo(props.data);
        setShipperInfo({
            ...props.data,
            warehouses: props.data?.warehouses?.map(warehouse => warehouse.id)
        });
    },[props.data]);

    const handleChange = (key, value) => {
        setShipperInfo({
            ...shipperInfo,
            [key]: value
        });
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={shipperInfo}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name',t),
        reference: yupRequired('reference',t),
        maxWeight: yupNumberRequired('max_weight', t),
        maxLength: yupNumberRequired('max_length', t),
        maxWidth: yupNumberRequired('max_width', t),
        maxHeight: yupNumberRequired('max_height', t),
        maxVolume: yupNumberRequired('max_volume', t),
        //warehouses: yupArrayRequired('warehouses', t),
    };
    
    const schema = yup.object().shape(rules);

    const [EditShipper,{isLoading}] = useEditShipperMutation();
    const handleSaveClick = async () => {
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                const updateData = {
                    id: shipperInfo?.id,
                    name: shipperInfo?.name,
                    reference: shipperInfo?.reference,
                    maxWeight: shipperInfo?.maxWeight,
                    maxLength: shipperInfo?.maxLength,
                    maxWidth: shipperInfo?.maxWidth,
                    maxHeight: shipperInfo?.maxHeight,
                    maxVolume: shipperInfo?.maxVolume,
                    warehouses: shipperInfo?.warehouses
                }
                setFieldErrors({});
                await schema.validate(updateData, { abortEarly: false });
                const data = await EditShipper({
                    ...updateData
                  }).unwrap()
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                if (err.name === 'ValidationError') {
                    // Create an errors object where keys are field names and values are error messages
                    const errors = err.inner.reduce((acc, currentError) => ({
                        ...acc,
                        [currentError.path]: currentError.message
                    }), {});
                
                    // Set the validation errors in the state
                    setFieldErrors(errors);
                } else {
                    // Handle other errors, such as network errors
                    showToastMessageRequestError(t("update_failed"), t, err);
                }
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }   
        }        
    };

    const [DeleteShipperMutation] = useDeleteShipperMutation();
    const confirmDelete = async () => {
        try {
            const response = await DeleteShipperMutation(props.data.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };
    const handleDeleteClick = async () => {
        setOpen(true);
    };

    const confirmDeleteModal = {
        data: shipperInfo?.name,
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }
    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("basic")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    flexDirection:"column",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    
                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box sx={{
                            width:80,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}>
                                <Text>{t("name")}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <ShValidatedInput
                                    name="name"
                                    value={shipperInfo?.name || ""}
                                    onChange={handleChange}
                                    error={fieldErrors.name}
                                />
                            </Box>
                            
                        </Box>
                    </Box>

                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    > 
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1,
                                    // width:1
                                }
                            }}
                        >
                            <Box sx={{
                            width:80,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}>
                                <Text>{t("reference")}</Text>
                            </Box>
                            
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                            // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <ShValidatedInput
                                    name="reference"
                                    value={shipperInfo?.reference || ""}
                                    onChange={handleChange}
                                    error={fieldErrors.reference}
                                />
                            </Box>
                            
                        </Box>
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        // alignItems:"flex-start",
                        // justifyContent:"space-between",
                        gap: 7,
                        paddingTop:1,
                    }}
                >

                    <Box
                        sx={{
                            width:60,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text>{t("warehouse")}</Text>
                    </Box>
                    <InputDropdown
                        key={shipperInfo?.warehouses}
                        name="warehouses"
                        displayName="name"
                        changeField="id"
                        valueKey="id"
                        multiple
                        selected={shipperInfo?.warehouses ?? []}
                        options={warehouses?.data ?? []}
                        noSelection={t("choose_warehouses")} 
                        onChange={(val) => handleChange("warehouses",val)}
                    />
                </Box>
            </Dropdown>
            <Dropdown title={t("measurements")} subTitle={t("shipper_max_dimensions_and_weight_description")} open={window.innerWidth > 767} isLast sx={{paddingBottom:"0px"}}>
            {/* <Divider></Divider> */}
                <Box sx={{
                    display:"flex",
                    flexDirection:"column"
                }}>
                    {/* Two columns */}
                    <Box
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        {/* <Box display={"flex"} flexDirection={"column"} gap={2}> */}
                            <Box lineHeight={"38px"}>
                                <Text>{t("max_weight")} (gram)</Text>
                                <ShValidatedNumberInput
                                    name="maxWeight"
                                    value={shipperInfo?.maxWeight ?? 0}
                                    onChange={handleChange}
                                    error={fieldErrors.maxWeight}
                                />
                            </Box>
                            <Box lineHeight={"40px"}>
                                <Text>{t("max_length")} ({t("mm")})</Text>
                                <ShValidatedNumberInput
                                    name="maxLength"
                                    value={shipperInfo?.maxLength ?? 0}
                                    onChange={handleChange}
                                    error={fieldErrors.maxLength}
                                />
                            </Box>
                            <Box lineHeight={"40px"}>
                                <Text>{t("max_width")} ({t("mm")})</Text>
                                <ShValidatedNumberInput
                                    name="maxWidth"
                                    value={shipperInfo?.maxWidth ?? 0}
                                    onChange={handleChange}
                                    error={fieldErrors.maxWidth}
                                />
                            </Box>
                            
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                    <Box lineHeight={"40px"}>
                                <Text>{t("max_height")} ({t("mm")})</Text>
                                <ShValidatedNumberInput
                                    name="maxHeight"
                                    value={shipperInfo?.maxHeight ?? 0}
                                    onChange={handleChange}
                                    error={fieldErrors.maxHeight}
                                />
                            </Box>
                            <Box lineHeight={"40px"}>
                                <Text>{t("max_volume")} ({t("mm")})</Text>
                                <ShValidatedNumberInput
                                    name="maxVolume"
                                    value={shipperInfo?.maxVolume ?? 0}
                                    onChange={handleChange}
                                    error={fieldErrors.maxVolume}
                                />
                            </Box>
                            </Box>
                    <Box>
                    </Box>
                    </Box>
            </Dropdown>

            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
                    <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
                    <GridActions
            id={props.data?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
            // active={active}
            // blocked={blocked}
            // remark={remark}
            // carrier={carrier}
            // quarantine={quarantine}
            // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
        />
            </Box>
            
            
        </Box>
    );
}

export default DetailsPanel;