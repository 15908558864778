import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import PopoverModal from "../../theme/Modal/PopoverModal";
import Text from "../../theme/text/Text";

import  SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import PublicIcon from '@mui/icons-material/Public';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

import { ReactComponent as NoteIcon } from "../../../styles/svg/note.svg";
import { ReactComponent as IntegrationsIcon } from "../../../styles/svg/Integrations.svg";
import ShSwitch from "../../theme/inputs/ShSwitch";
import { useContext, useState } from "react";
import { localeSettings } from "../../../utils/locales";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage, setThemeMode } from "../../../newapi/global/settingsSlice";
import { t } from "i18next";
import MSelectWorkspaces from "./MSelectWorkspaces";
import { useTranslation } from "react-i18next";
import { ENV_TYPES } from "../Sidebar";

export const TopbarActionButton = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Button
            onClick={props.onClick}
            TouchRippleProps={{ 
                style: { 
                    color: theme.palette.mode === "dark" ? "rgba(255,255,255,.5)" : 'rgba(49, 65, 95, 0.5)' 
                } 
            }} // Directly styling the ripple here
            variant='contained'
            sx={{
                background:theme.palette.mode === "dark" ? colors.grey[100]  : "#fff",
                width:1,
                height:40,
                borderRadius:"8px",
                padding:0,
                minWidth:0,
                paddingX:1.5,
                display: "flex",
                alignItems:"center",
                justifyContent:"flex-start",
                textTransform:"none",
                minWidth:250,
                boxShadow: "none",
                opacity:1,
                "&:hover" : {
                    transition:"250ms",
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.bg["tertiary"],
                    boxShadow:"none"
                },
            }}
        >
            {props.children}

        </Button>
    );

}

export const TopbarActionIcon = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                marginRight:2,
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                color: colors.txt["secondary"]
            }}
        >
            {props.children}
        </Box>
    );

}

const TopbarActionModal = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const language = useSelector((state) => state.settings.language);
    const workSpace = useSelector((state) => state.settings.workSpace);
    const themeMode = useSelector((state) => state.settings.themeMode);
    const dispatch = useDispatch();
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);

    const [open, setOpen] = useState(false);
    const workSpaceModal = {
        open: open,
        setOpen: setOpen
    }

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    
    const toggleColor = () => {
        dispatch(setThemeMode(themeMode === "dark" ? "light" : "dark"));
        document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
        document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
        // props.onClose();
    }

    const toggleLanguage = () => {
        if(language == 'nl') {
            dispatch(setLanguage("en"));
        } else {
            dispatch(setLanguage("nl"));
        }
    }


    return (
        <PopoverModal
            {...props}
        >
            <Box 
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[100] : "#fff",
                    padding:1.5,
                    paddingBottom: isStandAlone ? 4 : 1.5,
                    display:"flex",
                    gap:1,
                    flexDirection:"column",
                    borderRadius:"24px",
                    borderBottomLeftRadius:0,
                    borderBottomRightRadius:0,
                    "@media screen and (max-width: 48em)" : {
                        borderRadius:"32px",
                        borderBottomLeftRadius:0,
                        borderBottomRightRadius:0,

                    }
                }}
            >
                {/* <TopbarActionButton>
                    <TopbarActionIcon>
                        <SettingsOutlinedIcon fontSize="small" />
                    </TopbarActionIcon>
                    <Text medium>
                        Instellingen
                    </Text>
                </TopbarActionButton> */}
                
                {/* <TopbarActionButton>
                    <TopbarActionIcon>
                        <Box
                            sx={{
                                width:20,
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center"
                            }}
                        >

                            <IntegrationsIcon />
                        </Box>
                    </TopbarActionIcon>
                    <Text medium>
                        Integraties
                    </Text>
                </TopbarActionButton> */}

                {/* <TopbarActionButton>
                    <TopbarActionIcon>
                        <Box
                            sx={{
                                width:20,
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center"
                            }}
                        >

                            <NoteIcon  />
                        </Box>
                    </TopbarActionIcon>
                    <Text medium>
                        Gids  / FAQ
                    </Text>
                </TopbarActionButton> */}

                

                <TopbarActionButton
                    onClick={toggleLanguage}
                >
                    <TopbarActionIcon>
                        <PublicIcon fontSize="small"/>
                    </TopbarActionIcon>
                    <Box>
                        <Text light>
                            {t('language')}:&nbsp;
                        </Text>
                        <Text>
                            {localeSettings[language].language}
                            {/* {localeSettings[language].language} */}
                        </Text>
                    </Box>
                    <Box
                        sx={{
                            marginLeft:"auto",
                            display:"flex",
                            alignItems:"center"
                        }}
                    >
                        {localeSettings[language].flag}
                    </Box>
                </TopbarActionButton>
                {selectedEnvironmentType === ENV_TYPES[100] &&
                    <TopbarActionButton
                        onClick={() => {workSpaceModal.setOpen(true)}}
                    >
                        <TopbarActionIcon>
                            <WorkspacesIcon fontSize="small"/>
                        </TopbarActionIcon>
                        <Box>
                            <Text light>
                                {t('workspace')}:&nbsp;
                            </Text>
                            <Text>
                                {workSpace.id === -1 ? t(workSpace.name) : workSpace.name}
                                {/* Werkplek 1 */}
                                {/* {localeSettings[language].language} */}
                                {/* {localeSettings[language].language} */}
                            </Text>
                        </Box>
                    </TopbarActionButton>
                }

                <TopbarActionButton
                    onClick={toggleColor}
                >
                    <TopbarActionIcon>
                        {/* <DarkModeOutlinedIcon fontSize="small"/> */}
                        {themeMode === 'dark' ? (
                            <NightsStayIcon fontSize="small"/>
                        ) : (
                            <NightsStayOutlinedIcon fontSize="small"/>
                        )}
                    </TopbarActionIcon>
                    <Text medium>
                        {t('night_mode')}
                        {/* {theme.palette.mode === 'dark' ? (
                            "Nacht modus"
                        ) : (
                            "Dag modus"
                        )} */}
                    </Text>
                    <Box
                        sx={{
                            marginLeft:"auto",
                            marginRight:"-8px"
                        }}
                    >
                        <ShSwitch 
                            checked={themeMode === "dark"}
                            color="blue"
                        ></ShSwitch>
                    </Box>
                </TopbarActionButton>

                <TopbarActionButton>
                    <TopbarActionIcon>
                        <HelpOutlineOutlinedIcon fontSize="small" />
                    </TopbarActionIcon>
                    <Text medium>
                        {t('help_center')}
                    </Text>
                </TopbarActionButton>

                
            </Box>
            <MSelectWorkspaces 
                open={workSpaceModal.open} 
                handleClose={() => workSpaceModal.setOpen(false)}
            />

        </PopoverModal>
    );
}

export default TopbarActionModal;