import { apiSlice } from "./createApiSlice";

export async function muiDataGridStarted(queryName, typeName, { args, invalidate }, { dispatch, getState, queryFulfilled }) {
    //Certain options will cause our datagrid the need to invalidate state
    if (invalidate) {
        dispatch(apiSlice.util.invalidateTags([{ type: typeName }]));
    }

    try{
        const { data } = await queryFulfilled;
        // Optionally, append new items to existing cache for pagination
        const previousPageData = getState().api.queries[`${queryName}?page=${args.page - 1}`];

        if (previousPageData && previousPageData.data) {

            // Append new items to existing data
            const newData = previousPageData.data.items.concat(data.items);
            dispatch(apiSlice.util.updateQueryData(queryName, { args }, { items: newData }));
        }
    } catch(exc) {
        console.log(exc);
    }
}




