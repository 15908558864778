import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import PickingLines from "./Views/PickingLines";

import {useTranslation} from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../utils";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spPickingJobPickingListConfig } from "./spPickingJobPickingListConfig";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { useGetPickingJobPickingListQuery } from "../../../../newapi/pickingjob/pickingjobSlice";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Close
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpPickingJobPickingList = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const { data } = props;

    const { t, ready, i18n } = useTranslation();


    const { data: pickingJobListData, isLoading,isFetching,isError } = useGetPickingJobPickingListQuery(props.pickingJobListId,
    {
        skip: !props.pickingJobListId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <FormatListNumberedIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t("created")} /> },
        { component: <ShRowSkeleton heading={t("appointed_to")} /> },
        { component: <ShRowSkeleton heading={t("type")} /> },
    ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.pickingJobListId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && pickingJobListData) {
            const dateTime = getTranslatedDateAndTime(pickingJobListData.pickingJob.createdAt, t);
            setPanel(prevPanel => ({ 
                ...prevPanel,
                title: `Batch ${pickingJobListData.pickingJob.id}`,
                subTitle: `${pickingJobListData.pickingJob.warehouse} - ${pickingJobListData.pickingJob.orderCount} orders`,
                icon :defaultIcon,
                options : [
                    {
                        heading: t("created"),
                        content: `${dateTime.date} ${dateTime.time}`
                    },
                    {
                        heading: t("appointed_to"),
                        content: pickingJobListData.pickingJob.userName
                    },
                    {
                        heading: t("type"),
                        content: pickingJobListData.pickingJob.type
                    },
                ],
            }));
        }
    }, [pickingJobListData, isLoading, isFetching, t,theme.palette.mode]);


    // const [panel, setPanel] = useState({
    //     title: "",
    //     subTitle: "",
    //     icon :{
    //         theme: "green",
    //         content: <Inventory2Icon/>
    //     },
    //     //Heading row for default shit
    //     options : [],
    //     actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    // });

    // useEffect(() => {
    //     if (typeof data !== "undefined" && ready) {
    //       const dateTime = getTranslatedDateAndTime(pickingJobListData.pickingJob.createdAt, t);
    //       setPanel((prevPanel) => ({
    //         ...prevPanel,
    //         title: `Batch ${pickingJobListData.pickingJob.id}`,
    //         subTitle: `${pickingJobListData.pickingJob.warehouse} - ${pickingJobListData.pickingJob.orderCount} orders`,
    //         icon :{
    //             theme: "blue",// getOrderStatusColor(props.data), 
    //             content: <Inventory2Icon/>
    //         },
    //         options : [
    //             {
    //                 heading: t("created"),
    //                 content: `${dateTime.date} ${dateTime.time}`
    //             },
    //             {
    //                 heading: t("appointed_to"),
    //                 content: pickingJobListData.pickingJob.userName
    //             },
    //             {
    //                 heading: t("type"),
    //                 content: pickingJobListData.pickingJob.type
    //             },
    //         ],
    //       }));
    //     }
    //   }, [data,ready]);

   
    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spPickingJobPickingListConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    <PickingLines config={spPickingJobPickingListConfig.pickingLines} data={pickingJobListData}/> 
                </>
        </Sidepanel2>
    );
};

export default SpPickingJobPickingList;