const convertDisplayNameStockMutation = (mutation, t) => {
    if (mutation.userId > 0) {
        return mutation.displayName;
    } else {
        switch (mutation.userName) {
            case 'order':
                return `${t('order')} ${mutation.displayName}`;
            case 'inbound':
                return `${t('inbound')} ${mutation.displayName}`;
        }
    }
}

module.exports = {
    convertDisplayNameStockMutation
}
