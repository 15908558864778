import { Box, InputBase, List, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useState, useEffect } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import { themeSettings, tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { useGetRelationInvoiceProductsQuery } from "../../../newapi/global/relation/relationSlice";
import { useAddInvoiceInvoiceProductsMutation } from "../../../newapi/financial/invoiceSlice";
import { showToastMessagePostRequest, showToastMessage, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import { InputDropdown } from "../../theme/dropdowns/InputDropdown";
import { yupRequired } from "../../../utils/validation";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";
import { EuroOutlined } from "@mui/icons-material";

const MAddInvoiceLine = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);

    const { data: relationProducts, productsLoading } = useGetRelationInvoiceProductsQuery({id: props.relationId, onlyActive: true});

    const [fieldErrors, setFieldErrors] = useState({});
    const [newInvoiceLines, setNewInvoiceLines] = useState([{
        id: 0,
        rowIndex: 0,
        amount: 0
    }]);

    const handleClose = () => {
        setNewInvoiceLines([{
            id: 0,
            rowIndex: 0,
            amount: 0
        }]);
        props.handleClose();
    }

    const [addInvoiceProducts, {isLoading}] = useAddInvoiceInvoiceProductsMutation();
    const SaveInvoiceProducts = async (newInvoiceLines, props) => {
        try {
            const incompleteLine = newInvoiceLines.find(line => line.id > 0 && line.amount === 0);
            if (incompleteLine) {
                showToastMessage("error", `${t("incomplete_line")} - ${incompleteLine.name}`);
                return;
            }

            const mappedProducts = newInvoiceLines
            .filter(line => line.id > 0)
            .map(line => ({
                relationInvoiceProductId: line.id,
                amount: line.amount
            }));

            const requestData = {
                invoiceId: props.invoiceId,
                invoiceProducts: mappedProducts
            }
            
            const data = await addInvoiceProducts(requestData).unwrap()
            showToastMessagePostRequest(t, data);
            handleClose();
        } catch (err) {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, err);
        }
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleProductSelect = (rowIndex, selected) => {
        rowIndex = parseInt(rowIndex);
        // Check if selected product is already in the list
        const isDuplicate = newInvoiceLines.some(line => line.id === selected.id);
        if (isDuplicate) {
            showToastMessage("error", t("product_already_in_list"));
            return;
        }

        // Check if last item has id = 0
        setNewInvoiceLines(prev => prev.map((line, index) => {
            if (line.rowIndex === rowIndex) {
                return {
                    rowIndex: rowIndex,
                    amount: 0,
                    ...selected
                };
            }
            return line;
        }));
    };

    useEffect(() => {
        const lastItem = newInvoiceLines[newInvoiceLines.length - 1];
        if (lastItem.id > 0) {
            setNewInvoiceLines(prev => [...prev, {
                id: 0,
                rowIndex: newInvoiceLines.length
            }]);
        }
    }, [newInvoiceLines]);

    const handleDeleleteLineRow = (rowIndex) => {
        setNewInvoiceLines(prev => prev.filter(line => line.rowIndex !== rowIndex));
    };

    const handleChange = (rowIndex, key, value) => {
        // Only allow numbers and one decimal point
        if (!/^\d*\.?\d*$/.test(value) && key === "amount") {
            return;
        }

        rowIndex = parseInt(rowIndex);
        if (key === "amount") {
            value = parseInt(value);
        }

        setNewInvoiceLines(prev => prev.map((line, index) => {
            if (line.rowIndex === rowIndex) {
                return {
                    ...line,
                    [key]: value
                };
            }
            return line;
        }));
    };
      

    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_invoice_lines")}</Subheading>
            </Box>

            {/* BODY */}
                {newInvoiceLines && newInvoiceLines.length > 0 && newInvoiceLines.map((invoiceLine, index) => (
                    <Box key={"add-invoice-line-"+index}
                        sx={{
                            background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                            paddingRight:3,
                            paddingLeft:3,
                            paddingTop:1,
                            paddingBottom:1,
                            borderTopLeftRadius:16,
                            borderTopRightRadius:16,
                            display:"flex",
                            alignItems:"center",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                flex:1,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"40px"} width={"104px"}>
                                <Text>{t("product")}</Text>
                            </Box>
                            
                            <Box display="flex"
                                height="40px"
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputDropdown
                                    displayName={"name"} 
                                    onChange={(selected) => handleProductSelect(index, relationProducts[selected])}
                                    noSelection={t("choose_option")}
                                    options={relationProducts ?? []}
                                    closeOnSelection
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"40px"} width={"104px"}>
                                <Text>{t("amount")}</Text>
                            </Box>
                            
                            <Box display="flex"
                                height="40px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                            <InputBase 
                                    sx={{
                                        px:2,
                                        flex:1, 
                                        color:colors.txt["primary"],
                                        lineHeight:"32px",
                                    }}
                                    value={invoiceLine.amount}
                                    onChange={(e) => handleChange(index, 'amount', e.target.value)}
                                    disabled={invoiceLine.id === 0}
                                />  
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"40px"} width={"104px"}>
                                <Text>{t("price")}</Text>
                            </Box>
                            
                            <Box 
                            
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    background:colors.grey[100],
                                    borderRadius:5,
                                    height:40,
                                    flex:1,
                                    pl:0.5,
                                }}
                            >
                            
                                <Box
                                    sx={{
                                        background: themeSettings(theme.palette.mode).palette.primaryGrey.main,
                                        color:themeSettings(theme.palette.mode).palette.primaryGrey.contrastText,
                                        height:32,
                                        width:32,
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        borderRadius:4,
                                    }}
                                >
                                    <EuroOutlined fontSize="small"/>
                                </Box>
                            <InputBase 
                                    sx={{
                                        px:2,
                                        flex:1, 
                                        color:colors.txt["primary"],
                                        lineHeight:"32px",
                                    }}
                                    value={invoiceLine.id > 0 ? (invoiceLine.isCustomPrice ? invoiceLine.price : invoiceLine.categoryProduct.price) : ""}
                                    disabled={true}
                                />  
                                
                            </Box>
                        </Box>
                        { index > 0 ?

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                <Box lineHeight={"40px"} width={40}>
                                    <Text>&nbsp;</Text>
                                </Box>

                                <Box display="flex"
                                    height="40px"
                                    // maxWidth="400px"
                                    borderRadius="20px"
                                    
                                    sx={{
                                        
                                    }}
                                >
                                    <IconButton color="red" type="button" onClick={() => handleDeleleteLineRow(index)} sx={{p:1}}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Box>
                            </Box>
                            // <Box
                            //     sx={{
                            //         display:"flex",
                            //         alignItems:"center",
                            //         width:40

                            //     }}
                            // >
                            //     <IconButton type="button" onClick={() => handleDeleleteLineRow(index)} sx={{p:1}}>
                            //         <DeleteIcon/>
                            //     </IconButton>
                            // </Box>
                        : 
                        newInvoiceLines.length > 0 ?
                            <Box
                                sx={{
                                    width:40,
                                }}
                            >

                            </Box>
                            : null
                        }
                    </Box>
                ))}
            <Box
                component="footer"
                sx={{
                    pt:2,
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    // paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}> 
                    <Shbutton className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => SaveInvoiceProducts(newInvoiceLines, props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddInvoiceLine;
