import { Box, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import cx from "classnames"
import { showToastMessagePostRequest, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";

import { yupRequired, yupNumberRequired } from "../../../utils/validation";

import { useCreateReturnReasonMutation } from "../../../newapi/inbound/returnsSlice";

const MAddReturnReason = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [addReturnReason,{isLoading}] = useCreateReturnReasonMutation();

    const [returnReasonInfo, setReturnReasonInfo] = useState({
        description: "",
        requireImageUpload: 'false',
    });

    const handleClose = () => {
        setReturnReasonInfo({
            description: "",
            requireImageUpload: 'false',
        });
        props.handleClose();
    }

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        description: yupRequired('description', t),
        requireImageUpload: yupRequired('requireImageUpload', t),
    };
    
    const schema = yup.object().shape(rules);
   
    const SaveReturnReason = async () => {
        try {
            // Reset field errors
            setFieldErrors({});
        
            await schema.validate(returnReasonInfo, { abortEarly: false });
        
            // If validation is successful, proceed with the addSupplier call
            const data = await addReturnReason(returnReasonInfo).unwrap();
            showToastMessagePostRequest(t, data);

            handleClose();
        } catch (err) {
          if (err.name === 'ValidationError') {
            // Create an errors object where keys are field names and values are error messages
            const errors = err.inner.reduce((acc, currentError) => ({
              ...acc,
              [currentError.path]: currentError.message
            }), {});
      
            // Set the validation errors in the state
            setFieldErrors(errors);
          } else {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, err);
          }
        }
    };

    

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    const handleChange = (key, value) => {
        setReturnReasonInfo(prevState => ({
            ...prevState,
            [key]: value
        }));
        
        setFieldErrors(prevErrors => ({
            ...prevErrors,
            [key]: ''
        }));
    };

    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_return_reason")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    pb:2,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            display:"flex",
                            alignItems:"center",
                            pt:1,
                        }}
                    >
                        <Text>{t("description")}</Text>
                    </Box>
                    <ShValidatedInput
                        name="description"
                        value={returnReasonInfo.description}
                        onChange={handleChange}
                        error={fieldErrors.description}
                    />
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            display:"flex",
                            alignItems:"center",
                            pt:1,
                        }}
                    >
                        <Text>{t("image_upload_required")}</Text>
                    </Box>
                    
                    <ToggleButtonGroup
                        color={returnReasonInfo?.requireImageUpload === "true" || returnReasonInfo?.requireImageUpload === true ? "green" : "red"}
                        value={returnReasonInfo?.requireImageUpload === "true" || returnReasonInfo?.requireImageUpload === true ? "true" : "false"}
                        exclusive
                        onChange={(e) => handleChange('requireImageUpload', e.target.value )}
                        aria-label="Active"

                        className={cx(`${returnReasonInfo?.requireImageUpload}`, {
                            'active': returnReasonInfo?.requireImageUpload === "true" || returnReasonInfo?.requireImageUpload === true
                        })}
                        sx={{
                            background: colors.red[100],
                            padding:0.5,
                            borderRadius:5,
                            transition:"250ms",
                            "&.active" : {
                                background:colors.green[100],
                                transition:"250ms",
                            },  
                            "&.active" : {
                                background:colors.green[100],
                                transition:"250ms",
                            },
                        }}
                    >
                        <ToggleButton 
                            sx={{
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.green[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.red[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="false">
                            {t('no')}
                        </ToggleButton>
                        <ToggleButton 
                            sx={{
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.red[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.green[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="true"
                        >
                            {t('yes')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                        
                </Box>
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    block
                    variant="contained" 
                    onClick={() => SaveReturnReason()}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddReturnReason;
