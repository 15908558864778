import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import Products from "./Views/Products";
import Logs from "./Views/Logs";
import { useTranslation } from 'react-i18next';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spPriceIncreaseDetailsConfig } from "./spPriceIncreaseDetailsConfig";
import { useDeletePriceIncreaseMutation, useGetPriceIncreaseQuery } from "../../../../newapi/financial/priceIncreaseSlice";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";

import { GridCheckIcon } from "@mui/x-data-grid";
import { ClearIcon } from "@mui/x-date-pickers";
import Text from "../../../theme/text/Text";

import MEditPriceIncrease from "../../Modals/MEditPriceIncrease";
import MConfirmDelete from "../../Modals/MConfirmDelete";
import DeleteIcon from '@mui/icons-material/Delete';
import { showToastMessageDeleteRequest } from "../../../../utils/toasts";
import ButtonGroup from "../../../theme/buttons/ButtonGroup";
import { Edit, PendingActions } from "@mui/icons-material";

const SidePanelActions = (props) => {
    const {t} = useTranslation();

    const [addModalOpen, setAddModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }
    const handleClose =(split, ie) => {
        addModal.setOpen(false);
    }

    const [DeletePriceIncrease] = useDeletePriceIncreaseMutation();
    const confirmDelete = async () => {
        try {
            const response = await DeletePriceIncrease(props.priceIncreaseData?.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            confirmDeleteModal.setOpen(false);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };

    const handleDeleteClick = async () => {
        setOpen(true);
    };

    const confirmDeleteModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }

    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>
            {props.priceIncreaseData?.state === "active" &&
                <>
                <ButtonGroup isSidepanel version={2}>
                    <Shbutton
                    
                        onClick={() => addModal.setOpen(true)}
                        className={"group-btn"}
                        // onClick={props.action}
                        color={"primaryGrey"}
                        variant="contained"
                        endIcon={<Edit />}
                    >
                        {t('edit')}
                    </Shbutton>
                    <Shbutton
                        className={"group-btn"}
                        onClick={handleDeleteClick}
                        color="red"
                        variant="contained"
                        endIcon={<DeleteIcon />}
                    >
                        {t('delete')}
                    </Shbutton>
                </ButtonGroup>

                    <MEditPriceIncrease priceIncreaseData={props.priceIncreaseData} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
                    <MConfirmDelete
                        open={confirmDeleteModal.open}
                        handleClose={() => confirmDeleteModal.setOpen(false)}
                        data={props.priceIncreaseData?.name}
                        confirm={confirmDeleteModal.confirm}
                    />
                </>
            }
        </SidepanelHeaderActiongroup>
    )
}

const SpPriceIncreaseDetails = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: priceIncreaseData,isFetching, isLoading,isError } = useGetPriceIncreaseQuery(props.priceIncreaseId,
    {
        skip: !props.priceIncreaseId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <TrendingUpIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t('date')} /> },
        { component: <ShRowSkeleton heading={t('percentage')} /> },
        { component: <ShRowSkeleton heading={t('products')} /> }
    ], [t]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions: <SidePanelActions setIsExtended={setIsExtendedCallback} />
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.productId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && priceIncreaseData) {
            let date = new Date(priceIncreaseData.date);

            setPanel(prevPanel => ({
                ...prevPanel,
                title: priceIncreaseData.name,
                icon: {
                    theme: priceIncreaseData.state === "active" ? "primary" : "green",
                    content: <TrendingUpIcon/>
                },
                options: [
                    { heading: t('date'), content: date.toLocaleDateString(i18n.language) },
                    { heading: t('increase'), content: `${priceIncreaseData.percentage}%` },
                    { heading: t("edit_changed_prices"), content: (
                        <Box sx={{
                            display:"flex",
                            alignItems:"center"
                        }}>
                            <Text variant={priceIncreaseData.modifyCustomerPrices ? "green" : "red"}>
                                {priceIncreaseData.modifyCustomerPrices ? (
                                    <GridCheckIcon />
                                ) : (
                                    <ClearIcon />
                                )}
                            </Text>
                        </Box>
                    )},
                    { heading: t('products'), content: priceIncreaseData.invoiceProducts.length },
                ],
                actions: <SidePanelActions priceIncreaseData={priceIncreaseData} setIsExtended={setIsExtendedCallback} />
            }));
        }
    }, [priceIncreaseData, isLoading, isFetching, t]);

    return (

        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spPriceIncreaseDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    <Products config={spPriceIncreaseDetailsConfig.products} state={priceIncreaseData?.state} priceIncreaseId={props.priceIncreaseId} />
                    <Logs config={spPriceIncreaseDetailsConfig.logs} priceIncreaseId={props.priceIncreaseId}/> 

                </>
        </Sidepanel2>
    );
};

export default SpPriceIncreaseDetails;