import { Box, IconButton, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";


import { useTranslation } from 'react-i18next';
import Details from "./Views/Details";
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';

import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { SpIntegratorDetailsConfig } from "./spIntegratorDetailsConfig";
import { useGetIntegratorSingleQuery } from "../../../../newapi/webshop/integratorSlice";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import Channels from "./Views/Channels";
import { Copy } from "../../../theme/Copy";
import { ContentCopyOutlined } from "@mui/icons-material";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpIntegratorDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

    const { data: integratorData, isLoading,isFetching,isError } = useGetIntegratorSingleQuery(props.integratorId,
    {
        skip: !props.integratorId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ShoppingCartCheckoutOutlinedIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t("relation")} /> },
        { component: <ShRowSkeleton heading={t("channels")} /> },
        { component: <ShRowSkeleton heading={t("products")} /> },
        { component: <ShRowSkeleton heading={t("secret_key")} /> },
    ], [t]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.integratorId,theme.palette.mode])

    const friendlyIntegratorType = (type) => {
        switch (type) {
            case "etailize":
                return "E-Tailize";
            case "productflow":
                return "Productflow"
            default:
                return t('unknown');
        }
    }

    const CopyHeaderElement = (props) => {
        const theme = useTheme();
        const colors = tokens(theme.palette.mode);
    
        return (
            
            <Copy onClick={() => navigator.clipboard.writeText(props.value)} message={props.value}>
                <Box
                    sx={{
                        
                        display:"flex",
                        alignItems:"flex-end",
                    }}
                >
                    <Box
                        sx={{
                            maxWidth:props.small ? 80 : 282,
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >
                        <Text light>{props.title}</Text>
                        <Text bold noBreak>{props.value ? props.value : "N/A"}</Text>
                    </Box>
    
                        <IconButton
                            sx={{ color: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary["400"] }}
                        >
                            <ContentCopyOutlined fontSize="small" />
                        </IconButton>
                </Box>
            </Copy>
        );
    }
    
    useEffect(() => {
        if (!isLoading && !isFetching && integratorData) {
            setPanel(prevPanel => ({ 
                ...prevPanel,
                title: integratorData.name,
                subTitle: integratorData.type ? friendlyIntegratorType(integratorData.type) : t("unknown"),
                icon :defaultIcon,
                options: [
                    {
                        heading: t('relation'),
                        content: integratorData.relation?.name ?? t("unknown")
                    },
                    {
                        heading: t('channels'),
                        content: `${integratorData.webshopcredentials?.length ?? 0} ${t("channels")}` ?? t("unknown")
                    },
                    {
                        heading: t('products'),
                        content: `${integratorData.products?.length ?? 0} ${t("products")}` ?? t("unknown")
                    },
                    {
                        component: <CopyHeaderElement title={t("Secret")} small value={integratorData?.secret}/>
                    },
                ],
            }));
        }
    }, [integratorData, isLoading, isFetching, t,theme.palette.mode]);
    
    return (
        <Sidepanel2
            skeleton={isFetching || isLoading}
            tabConfig={SpIntegratorDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={SpIntegratorDetailsConfig.details} data={integratorData} setIsExtended={props.setIsExtended}/>
                    <Channels config={SpIntegratorDetailsConfig.channels} data={integratorData} /> 
                </>
        </Sidepanel2>
    );
};

export default SpIntegratorDetails;