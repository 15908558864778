import { Box, IconButton, InputBase, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import Text from "../../../../theme/text/Text";
import StockOnLocationCard from "../../../../theme/cards/stock/StockOnLocationCard";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import DeleteIcon from '@mui/icons-material/Delete';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useUpdatePickupCartMutation } from "../../../../../newapi/warehouse/pickupCartSlice";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const Locations = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [isExtended, setIsExtended] = useState(false);

    
    //technically not needed here but since thomas and michael are inconsistent in their copying
    //they may actually just copy some wrong file and complain it wont work but they just forgot
    //to copy this and add the thing after the comma that changes.
    useEffect(() => {
        props.replaceView({
            ...props.config,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    

    
    const [newLocations, setNewLocations] = useState(props.data.pickupCartLocations?.map(location => ({ barcode: location.barcode })) ?? []);
    const [locationCount, setLocationCount] = useState(props.data.capacity);
    const [pickingCartInfo, setPickingCartInfo] = useState({
        state: "active",
        id: 0,
        capacity: 0,
        barcode: "",
        pickupCartLocations: [],
        warehouseZoneId: 0,
        reachTypeId: 0
    });
    useEffect(() => {
        setPickingCartInfo(props.data);
    },[props.data]);

    const handleChange = (index, value) => {
        setNewLocations(currentLines => currentLines.map((line, idx) => 
            idx === index ? { ...line, barcode: value } : line
        ));
    };

    useEffect(() => {

        if (!newLocations.some(line => line.barcode === "")) {
            setNewLocations([...newLocations, { barcode: "" }]);
        }

        const mappedLocations = newLocations?.filter(x => x.barcode?.length > 0);
        handleInput("capacity", mappedLocations.length);
        handleInput("pickupCartLocations", mappedLocations);
    }, [newLocations]);

      
    const handleDeleleteLineRow = (barcode) => {
        setNewLocations(newLocations.filter(line => line.barcode !== barcode));
    }

    const handleInput = (key, value) => {
        setPickingCartInfo(prevState => ({
            ...prevState,
            [key]: value
        }));
    };
    
    const [EditPickingCart,{isLoading}] = useUpdatePickupCartMutation();
    const handleSaveClick = async () => {
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                const data = await EditPickingCart({id: pickingCartInfo.id, data: pickingCartInfo}).unwrap()
                showToastMessageUpdateRequest(t,data);
        } catch (err) {
            showToastMessageRequestError(t("update_failed"),t,err);
          }
            
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"),t,"");

            }    
        }        
    };

    useEffect(() => {
        console.log(pickingCartInfo);
    },[pickingCartInfo]);

    return (
        <>
            <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
                    sx={{
                        "@media screen and (min-width: 48.1em)"  :{
                            marginBottom:11
                        }
                    }}
                >
                    <Dropdown title={t("locations")} open>
                        <Box sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column"
                            },
                            flexDirection:"column"
                        }}>
                <Box 
                sx={{
                    background: colors["blue"][100],
                    color: colors["blue"][400],
                    padding:2,
                    width:1,
                    marginTop:2,
                    marginBottom:2
                }}
            >
                <Typography fontWeight={"bold"} fontSize={"17"}>
                    {t("mp_add_pickupcart_locations_title")}
                </Typography>
                <Box sx={{paddingTop:0.5}}>
                    <Typography fontSize={"14"}>
                    {t("mp_add_pickupcart_locations_text")}
                    </Typography>
                </Box>
            </Box>
            </Box>
                <Box
                    sx={{
                        background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        borderTopLeftRadius:16,
                        borderTopRightRadius:16,
                        display:"flex",
                        flexDirection:"column",
                        gap:2
                    }}
                >
                    
                    {newLocations.map((location, index) => (
                    <Box flex={1}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start",
                        }}
                    >
                        <Box 
                            sx={{
                                width:1,
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"space-between",
                                gap:1
                            }}
                        >
                            {/* <Box
                                sx={{
                                    width:40,
                                }}
                            >
                                <Text light>{t("barcode")}</Text>
                            </Box> */}
                            <Box
                                sx={{
                                    flex:1,
                                    backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                    borderRadius:4,
                                    display:"flex",
                                    height:32
                                }}
                            >
                                <InputBase 
                                    sx={{
                                        px:2,
                                        flex:1, 
                                        color:colors.txt["primary"],
                                        lineHeight:"32px",
                                    }}
                                    value={location.barcode}
                                    onChange={(e) => handleChange(index,  e.target.value)}
                                    />
                            </Box>
                        </Box>
                    
                            <IconButton type="button" onClick={() => handleDeleleteLineRow(location.barcode)} sx={{p:1}}>
                                <DeleteIcon/>
                            </IconButton>
                        </Box>
                    ))}
                </Box>
                <Box 
                display={"flex"} 
                flexDirection={"column"}
                sx={{
                    //maxWidth: 160,
                    width:1,
                    paddingTop:3,
                    "@media screen and (max-width:47.9375em)" : {
                        width:1,
                        flexDirection:"row",
                        gap:4,
                        alignItems:"center",
                        justifyContent:"space-between",
                    }
                }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            mb:0.5,
                            "@media screen and (max-width:47.9375em)" : {
                                mb:0
                            }
                        }}
                    >
                        <Text semiBold>
                            {t('capacity')}
                        </Text>
                        
                    </Box>
                    <ShValidatedInput
                                name="capacity"
                                value={pickingCartInfo?.capacity || ""}
                                onChange={(key,val) => handleInput("capacity",val)}
                                
                            />
        </Box>
        
        </Dropdown>
            </Box>
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
            <GridActions
                    id={props.data.id}
                    action={handleSaveClick}
                    // active={active}
                    // blocked={blocked}
                    // remark={remark}
                    // carrier={carrier}
                    // quarantine={quarantine}
                    // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
            />
            </Box>
        </>
    );
}

export default Locations;