import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { themeSettings, tokens } from "../../../../../theme";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { useUpdateInvoiceProductPricesMutation } from "../../../../../newapi/financial/invoiceProductSlice";
import { useGetRelationInvoiceProductsQuery, useUpdateRelationInvoiceProductsMutation } from "../../../../../newapi/global/relation/relationSlice";
import { useGetInvoiceCategorySelectQuery, useGetProductFromCategoryAndInvoiceProductQuery } from "../../../../../newapi/financial/invoiceCategorySlice";
import Text from "../../../../theme/text/Text";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import Dropdown from "../../../../theme/dropout/DropOut";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import { showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { EuroOutlined } from "@mui/icons-material";
import SpDesktopFooter from "../../../../theme/sidepanel2/SidepanelFooter";
import { RelationFinancialProductCard } from "../../../../theme/cards/financial/RelationFinancialProductCard";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Financial = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [isExtended, setIsExtended] = useState(false);
    const [modifiedProducts, setModifiedProducts] = useState(null);
    const [selectedCategoryUpdate, setSelectedCategoryUpdate] = useState(null);
    const { data: categoryProduct } = useGetProductFromCategoryAndInvoiceProductQuery(
        selectedCategoryUpdate ?? { categoryId: 0, invoiceProductId: 0 },
        { skip: !selectedCategoryUpdate }
    );

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions 
                action={handleSaveClick}/>,
            gridOptions:gridOptions,
        },false);

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const { data: relationProducts, isLoading } = useGetRelationInvoiceProductsQuery({id: props.relationId, onlyActive: false});

    useEffect(() => {
        if (relationProducts && !modifiedProducts) {
            setModifiedProducts(relationProducts);
        }
    }, [relationProducts]);

    const { data: invoiceCategories, invoiceCategoryIsLoading } = useGetInvoiceCategorySelectQuery();

    const [UpdateRelationInvoiceProducts,{ isLoading: isPricesLoading}] = useUpdateRelationInvoiceProductsMutation();
    const handleSaveClick = async () => {
        try {
            const response = await UpdateRelationInvoiceProducts({relationId: props.relationId, products: modifiedProducts}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };

    useEffect(() => {
        if (categoryProduct && selectedCategoryUpdate) {
            setModifiedProducts(current =>
                current.map(product => {
                    if (product.invoiceProductId === selectedCategoryUpdate.invoiceProductId) {
                        return {
                            ...product,
                            invoiceCategoryProductId: categoryProduct.id,
                            categoryProduct: {
                                ...product.categoryProduct,
                                price: categoryProduct.price,
                                id: categoryProduct.id,
                                invoiceCategoryId: selectedCategoryUpdate.categoryId
                            }
                        };
                    }
                    return product;
                })
            );
            setSelectedCategoryUpdate(null);
        }
    }, [categoryProduct]);

    const handleChange = (productId, key, newValue) => {
        if (key === 'invoiceCategoryId') {
            setSelectedCategoryUpdate({ 
                categoryId: newValue, 
                invoiceProductId: productId 
            });
        } else {
            setModifiedProducts(current => 
                current.map(product => {
                    if (product.invoiceProductId === productId) {
                        switch (key) {
                            case 'price':
                                if (!/^\d*\.?\d*$/.test(newValue)) return product;
                                return {
                                    ...product,
                                    isCustomPrice: true,
                                    price: newValue === '.' ? '0.' : newValue
                                };
                                case 'isCustomPrice':
                                    return {
                                        ...product,
                                        isCustomPrice: newValue
                                    };
                                case 'isActive':
                                    return {
                                        ...product,
                                        isActive: newValue
                                    };
                            default:
                                return product;
                        }
                    }
                    return product;
                })
            );
        }
    };

    return (
        <Box display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 47.9375em)"  :{
                    marginBottom:11,
                }
            }}
        >
            <Dropdown title={t("general")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    
                    <Box flex={1}
                        sx={{
                            display:"flex",
                            gap: 0,
                            justifyContent:"flex-start",
                            flexDirection:"column",
                            "@media screen and (max-width: 47.9375em)" : {
                                gap:3,
                            }
                        }}
                    >
                        <Box
                            sx={{
                                mt:2,
                                mb:1.5,
                                display:"flex",
                                gap:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    display:"none"
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    flex:0.75,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                    <Text light>{t("product")}</Text>
                            </Box>
                            <Box
                                sx={{
                                    width:56,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                    <Text light>{t("active")}</Text>
                            </Box>
                            <Box
                                sx={{
                                    flex:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                    <Text light>{t("category")}</Text>
                            </Box>
                            <Box
                                sx={{
                                    width:56,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                    <Text light>{t("custom_price")}</Text>
                            </Box>
                            <Box flex={1}></Box>
                        </Box>
                        {modifiedProducts && modifiedProducts.length > 0 && modifiedProducts.map((product,index) => (
                            <React.Fragment key={"modifiedprodu-"+index}>
                                <Box
                                    sx={{
                                        display:"flex",
                                        gap:3,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            display:"none"
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            overflow:"hidden",
                                            whiteSpace:"nowrap",
                                            textOverflow:"ellipsis",
                                            lineHeight:"40px",
                                            flex:0.75,
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1
                                            }
                                        }}
                                    >
                                        <Text noBreak>{product.invoiceProduct.name}</Text>
                                    </Box>
                                    <Box
                                        sx={{
                                            width:56,
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1
                                            }
                                        }}
                                    >
                                        <Box display="flex"
                                            height="40px"
                                            sx={{
                                                justifyContent:"space-between",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flexDirection:"column"
                                                }
                                            }}
                                        >
                                            <ShSwitch 
                                                checked={product.isActive} 
                                                onChange={(event) => handleChange(product.invoiceProductId, 'isActive', event.target.checked)} 
                                                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            flex:1,
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1
                                            }
                                        }}
                                    >
                                        <InputDropdown
                                            name="name"
                                            displayName="name"
                                            selected={invoiceCategories ? invoiceCategories.findIndex(item => item.id === product.categoryProduct.invoiceCategoryId) ?? -1 : -1}
                                            options={invoiceCategories ?? []}
                                            noSelection={t("choose_option")} 
                                            onChange={(selected) => handleChange(product.invoiceProductId, 'invoiceCategoryId', invoiceCategories[selected]?.id)}
                                            closeOnSelection
                                            disabled={!product.isCustomPrice}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width:56,
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1
                                            }
                                        }}
                                    >
                                        <Box display="flex"
                                            height="40px"
                                            sx={{
                                                justifyContent:"space-between",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flexDirection:"column"
                                                }
                                            }}
                                        >
                                            <ShSwitch 
                                                checked={product.isCustomPrice} 
                                                onChange={(event) => handleChange(product.invoiceProductId, 'isCustomPrice', event.target.checked)} 
                                                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                                            />
                                        </Box>
                                    </Box>
                                    {product.isCustomPrice ?
                                    <Box
                                        sx={{
                                            flex:1,
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1
                                            }
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display:"flex",
                                                alignItems:"center",
                                                background:colors.grey[100],
                                                borderRadius:5,
                                                height:40,
                                                flex:1,
                                                pl:0.5,
                                            }}
                                        >
                                        
                                            <Box
                                                sx={{
                                                    background: themeSettings(theme.palette.mode).palette.primaryGrey.main,
                                                    color:themeSettings(theme.palette.mode).palette.primaryGrey.contrastText,
                                                    height:32,
                                                    width:32,
                                                    display:"flex",
                                                    alignItems:"center",
                                                    justifyContent:"center",
                                                    borderRadius:4,
                                                }}
                                            >
                                                <EuroOutlined fontSize="small"/>
                                            </Box>
                                                <InputBase 
                                                sx={{
                                                    px:2,
                                                    flex:1, 
                                                    color:colors.txt["primary"],
                                                    lineHeight:"32px",
                                                    }}
                                                    disabled={!product.isCustomPrice}
                                                    value={product.isCustomPrice && product.price > 0 ? product.price : product.categoryProduct.price}
                                                    onChange={(e) => handleChange(product.invoiceProductId, 'price', e.target.value)}
                                                />  
                                            </Box>
                                        </Box>
                                    : 
                                        <Box flex={1}></Box>
                                    }
                                </Box>
                                <RelationFinancialProductCard invoiceCategories={invoiceCategories} handleChange={handleChange} product={product}/>
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>
            </Dropdown>
            {/* <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                <GridActions
                    action={handleSaveClick}
                />
            </Box> */}
            <SpDesktopFooter>
                <GridActions
                    action={handleSaveClick}
                />

            </SpDesktopFooter>
        </Box>
    );
}

export default Financial;