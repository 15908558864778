import { Box, InputBase, useTheme } from "@mui/material"
import { useEffect, useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { useUpdateOrderMutation } from "../../../newapi/order/ordersSlice";
import { showToastMessageUpdateRequest, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInputDropdown from "../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired } from "../../../utils/validation";
import { general_states } from "../../../utils/staticEnums";

const MUpdateOrderState = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [updateOrder,{isLoading}] = useUpdateOrderMutation();

    const [orderStateInfo, setOrderStateInfo] = useState({
        id: props.orderId,
        state: ""
    });

    const [stateValues, setStateValues] = useState([]);

    const values = [
        {id: general_states.OPEN, name: t("open")},
        {id: general_states.CANCELLED, name: t("cancelled")},
        {id: general_states.SNOOZE, name: t("snooze")},
    ];

    useEffect(() => {
        setOrderStateInfo({
            id: props.orderId,
            state: props.state
        });

        setStateValues([...values.filter(value => value.id !== props.state)]);
    }, [props.state]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        state: yupRequired('state', t),
    };
    
    const schema = yup.object().shape(rules);
   
    const SaveOrderState = async () => {
        try {
            setFieldErrors({});
            await schema.validate(orderStateInfo, { abortEarly: false });
            const data = await updateOrder(orderStateInfo).unwrap()
            showToastMessageUpdateRequest(t, data);

            // reset object
            setOrderStateInfo({
                id: props.orderId,
                state: ""
            });

            props.handleClose();
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("create_failed"), t, err);
              }
        }
    };
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setOrderStateInfo(prev => ({
          ...prev,
          [key]: value
        }));
        setFieldErrors({
            ...fieldErrors,
            [key]: ''
          });
      };
      

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("update_order_state")}</Subheading>
                <Text light>{`${t("update_order_state_description")} "${t(props.state)}"`}</Text>
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                        }}
                    >
                        <Text light>{t("state")}</Text>
                    </Box>
                    <ShValidatedInputDropdown
                        name="state"
                        displayName="name"
                        changeField="id"
                        options={stateValues}
                        noSelection={t("choose_option")} 
                        onChange={handleChange}
                        error={fieldErrors.state}
                    />
                </Box>
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => SaveOrderState()}
                    >{t("save")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MUpdateOrderState;
