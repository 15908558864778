import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import { useCallback, useEffect, useMemo, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { useEditRelationMutation } from "../../../../../newapi/global/relation/relationSlice";
import { showToastMessageDeleteRequest, showToastMessageRequestError, showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import { useGetWarehouseZonesSelectQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";
import TvDropOut from "../../../../theme/dropout/TvDropOut";
import SettingGroup from "../../../../theme/settings/SettingGroup";
import BoolAuthSetting from "../../../Settings/BoolAuthSetting";
import Sidepanel2 from "../../../../theme/sidepanel2/Sidepanel2";
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import SidepanelHeaderActiongroup from "../../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const Stock = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    const [relationInfo, setRelationInfo] = useState({
      id: 0,
    });

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedBulkOptions, setSelectedBulkOptions] = useState([]);

    const handleZoneChange = (type, zoneId, e) => {
        if (type === "picking") {
            setSelectedOptions(prevOptions => 
                e ? [...prevOptions, zoneId] : prevOptions.filter(id => id !== zoneId)
            );
        } else {
            setSelectedBulkOptions(prevOptions => 
                e ? [...prevOptions, zoneId] : prevOptions.filter(id => id !== zoneId)
            );
        }
    };

    const [open, setOpen] = useState(false);
    
    const { data: warehouseZones, warehouseZoneIsLoading } = useGetWarehouseZonesSelectQuery();
    const [warehouseZonesOptions, setWarehouseZonesOptions] = useState([]);
    useEffect(() => {
        if (!warehouseZones?.data) return;
        
        // Group zones by warehouse
        const warehouseMap = warehouseZones.data.reduce((acc, zone) => {
            const warehouseId = zone.warehouse.id;
            
            if (!acc[warehouseId]) {
                acc[warehouseId] = {
                    id: warehouseId,
                    name: zone.warehouse.name,
                    zones: []
                };
            }
            
            acc[warehouseId].zones.push({
                id: zone.id,
                description: zone.description
            });
            
            return acc;
        }, {});

        // Convert map to array
        const transformedData = Object.values(warehouseMap);
        setWarehouseZonesOptions(transformedData);
    },[warehouseZones]);
    
    const [EditRelation,{isLoading}] = useEditRelationMutation();
    const handleSaveClick = async () => {
        try {
            const updateObj = {
                id: relationInfo.id,
                pickingWarehouseZones: selectedOptions,
                bulkWarehouseZones: selectedBulkOptions,
            }

            const response = await EditRelation(updateObj).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageRequestError(t("update_failed"), t, err);
        }
    };

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <AdminPanelSettingsOutlinedIcon/>
    }), [theme.palette.mode]);

    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        // options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon,/*defaultOptions,*/ setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions 
                id={relationInfo?.id}
                action={handleSaveClick}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[handleSaveClick,relationInfo]);

    useEffect(() => {
        setRelationInfo(props.data);
        setSelectedOptions(props.data.pickingWarehouseZones.map(zone => zone.id));
        setSelectedBulkOptions(props.data.bulkWarehouseZones.map(zone => zone.id));
    },[props.data]);

    return (
            <Box
              height={1}
              display={"flex"}
              flexDirection={"column"}
              paddingX={3}
              sx={{
                px: 3,
                pb: 11,
                // marginBottom:10
              }}
            >
                <TvDropOut
                    icon={<WarehouseOutlinedIcon fontSize="small" />}
                    title={`${t("warehouse_zones_picking")} (${selectedOptions.length} ${t("_zones")})`}
                    subTitle={t("warehouse_zones_picking_description")}
                >
                <Box
                  sx={{
                    display: "flex",
                    gap: "24px 80px",
                    flexWrap: "wrap",
                    "@media screen and (max-width: 63.9375em)": {
                      py: 3,
                      flexDirection: "column",
                      gap: 4,
                      marginBottom: "80px",
                    },
                  }}
                >
                {warehouseZonesOptions.map((warehouse, index) => (
                    <Box
                    key={index}
                    sx={{
                        display: "flex",
                        gap: "24px 80px",
                        flexWrap: "wrap",
                        maxWidth:250,
                        "@media screen and (max-width: 63.9375em)": {
                        py: 3,
                        flexDirection: "column",
                        gap: 4,
                        marginBottom: "80px",
                        },
                        "@media screen and (max-width: 1023px)" : {
                        maxWidth:"unset"
                        }
                    }}
                    >
                    <SettingGroup title={`${t("warehouse")}: ${warehouse.name}`}>
                        {warehouse.zones.map((zone, index) => (
                        <BoolAuthSetting
                        key={index}
                        title={zone.description}
                        val={selectedOptions.includes(zone.id)}
                        onChange={(e) =>
                            handleZoneChange("picking", zone.id, e)
                        }
                        />
                        ))}
                    </SettingGroup>
                    </Box>
                    ))}
                    </Box>
                </TvDropOut>
                <TvDropOut
                    icon={<WarehouseOutlinedIcon fontSize="small" />}
                    title={`${t("warehouse_zones_bulk")} (${selectedBulkOptions.length} ${t("_zones")})`}
                    subTitle={t("warehouse_zones_bulk_description")}
                >
                <Box
                  sx={{
                    display: "flex",
                    gap: "24px 80px",
                    flexWrap: "wrap",
                    "@media screen and (max-width: 63.9375em)": {
                      py: 3,
                      flexDirection: "column",
                      gap: 4,
                      marginBottom: "80px",
                    },
                  }}
                >
                {warehouseZonesOptions.map((warehouse, index) => (
                    <Box
                    key={index}
                    sx={{
                        display: "flex",
                        gap: "24px 80px",
                        flexWrap: "wrap",
                        maxWidth:250,
                        "@media screen and (max-width: 63.9375em)": {
                        py: 3,
                        flexDirection: "column",
                        gap: 4,
                        marginBottom: "80px",
                        },
                        "@media screen and (max-width: 1023px)" : {
                        maxWidth:"unset"
                        }
                    }}
                    >
                    <SettingGroup title={`${t("warehouse")}: ${warehouse.name}`}>
                        {warehouse.zones.map((zone, index) => (
                        <BoolAuthSetting
                        key={index}
                        title={zone.description}
                        val={selectedBulkOptions.includes(zone.id)}
                        onChange={(e) =>
                            handleZoneChange("bulk", zone.id, e)
                        }
                        />
                        ))}
                    </SettingGroup>
                    </Box>
                    ))}
                    </Box>
                </TvDropOut>
              <Box
                  sx={{
                      position:"absolute",
                      bottom:0,
                      right:0,
                      padding:3,
                      width:1,
                      maxWidth:"min-content",
                      background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                      "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                          padding:2,
                          paddingY:2.5,
                          maxWidth:"unset",
                      },
                  }}
              >
               <GridActions
                id={relationInfo?.id}
                action={handleSaveClick}/>
              </Box>
            </Box>
      );
}

export default Stock;