import { Box, InputBase, List, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify"; 
import { useAddProductFieldMutation } from "../../../newapi/product/productFieldSlice";
import { useGetEnumsQuery } from "../../../newapi/global/generalApiSlice";
import { showToastMessagePostRequest, showToastMessageRequestError } from "../../../utils/toasts";
import ShSwitch from "../../theme/inputs/ShSwitch";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired } from "../../../utils/validation";

const MAddProductField = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [addProductField,{isLoading}] = useAddProductFieldMutation();

    const select = (selected) => {
        setSelected(selected);
    }

    const [productFieldInfo, setProductFieldInfo] = useState({
        name: "",
        valueType: ""
    });
      
    const handleClose = () => {
      setProductFieldInfo({
          name: "",
          valueType: ""
      });
      props.handleClose();
  }

    const { data: globalEnums, enumIsLoading } = useGetEnumsQuery("productfield_value_type");

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        valueType: yupRequired('value_type', t)
    };
    
    const schema = yup.object().shape(rules);
      
   
    const SaveProductField = async (productFieldInfo, props) => {
        try {
            setFieldErrors({});
            await schema.validate(productFieldInfo, { abortEarly: false });
            const data = await addProductField(productFieldInfo).unwrap()
            showToastMessagePostRequest(t, data);

            handleClose();
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("create_failed"), t, err);
              }
        }
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setProductFieldInfo({
          ...productFieldInfo,
          [key]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [key]: ''
          });
      };

    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_field")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("name")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                            name="name"
                            value={productFieldInfo.name}
                            onChange={handleChange}
                            error={fieldErrors.name}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("value_type")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                    <ShValidatedInputDropdown
                      height={40}
                      name="valueType"
                      displayName="displayName"
                      changeField={"name"}
                      selected={globalEnums ? globalEnums["productfield.value.type"].findIndex(item => item === productFieldInfo.valueType) ?? -1 : -1}
                      options={globalEnums ? globalEnums["productfield.value.type"].map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
                      noSelection={t("choose_option")} 
                      onChange={handleChange}
                      error={fieldErrors.valueType}
                  />
                    </Box>
                </Box>
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    // paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}> 
                    <Shbutton className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => SaveProductField(productFieldInfo, props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddProductField;
