import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import Text from "../../../../theme/text/Text";

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LogCard from "../../../../theme/cards/other/LogCard";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../../utils";
import { useGetLogsQuery } from "../../../../../newapi/global/logsSlice";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Logs = (props) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [config, setConfig] = useState({
        "type": "Inbound",
        "id": props.data.id
    });

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {

        }
    },[]);
    

    const columns = [
        {field: "description", headerName: t("description") ,cellClassName:"description-cell", flex:3, renderCell : (cellValues) => {
            return (
                <Box
                
                sx={{
                    display:"flex",
                    alignItems:"center"
                }}
                >
                    <Text>{cellValues.value}</Text>
                </Box>
            )
        }},

        {field: "createdAt", headerName: t("created_on"),flex:0.75, renderCell : (cellValues) => {
            const cDateTime = getTranslatedDateAndTime(props.data.createdAt, i18n);
            return (
                <Box
                
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1,
                    }}
                >
                    <Text semibold>{cDateTime.date}</Text>
                    <Text light>{cDateTime.time}</Text>
                </Box>
            )
        }},
        {field: "userName", headerName: t("by"), flex:0.5},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                return (
                    <LogCard {...cellValues}/>
                )
            }
        }
    ];


    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .description-cell" : {
                    // background:"orange !important",
                    // overflow:"hidden",
                    paddingLeft:0,
                    marginRight:2,
                },
                "& [aria-label='Created on']" : {
                    // background:"purple",
                    paddingLeft:3,
                },
                "& [aria-label='Description']" : {
                    // background:"purple",
                    paddingLeft:0,
                },
                "& [aria-label='By']" : {
                    // background:"purple",
                    paddingLeft:3.125,
                }
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetLogsQuery}
                config={config}
                title={t("logs") }
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>
        </Box>
    );
}

export default Logs;