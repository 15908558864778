import { Box, FormControlLabel, Select, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseCard from "../BaseCard";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import { NoTextLabel } from "../../label/NoTextLabel";
import CheckBox from '@mui/icons-material/CheckBox';        // Checked box icon (Yes)
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'; // Empty box icon (No)
import { useEffect, useState } from "react";
import { tokens } from "../../../../theme";

export const PickListCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const { t,i18n } = useTranslation();

    let status = props.state;
    let labelcolor = "grey";
    let labeltext = t('unknown');
    switch(status){
        case "notreceived":
            labelcolor = "grey"
            labeltext = t('not_received');
            break;
        case "partlyreceived":
            labelcolor = "blue"
            labeltext = t('partly_received');
            break;
        case "fullyreceived":
            labelcolor = "green"
            labeltext = t('fully_received');
            break;
        default:
            labelcolor = "red"
            labeltext = t('unknown');
            break;
    }
    let typeLabelColor = props.amountOfLines && props.amountOfLines === 1 ? "orange" : "purple";
    // const [selected, setSelected] = useState(false);

    const handleToggle = (e) => {
        e.stopPropagation();
    //   setSelected(!selected);
      props.select(props.id)
    };


    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            pr:5,
                        }}
                    >
                        <Box>
                            {props.skeleton ?
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text bold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{props.id}</Text><br/>
                                    <Text>{props.relation?.name}</Text>
                                </>
                            }
                        </Box>
                    </Box>
                    <TopRight>
                        
                        <Box 
                            onClick={handleToggle} 
                            sx={{ 
                                borderTopRightRadius:12,
                                borderBottomLeftRadius:12,
                                borderTopLeftRadius:6,
                                borderBottomRightRadius:6,
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent:'center',
                                cursor: 'pointer',
                                width:40,
                                height:32,
                                background:theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100]
                            }}
                        >
                            {/* CheckBoxOutlineBlank for "No", CheckBox for "Yes" */}
                            {props.selected ? <CheckBox sx={{ fontSize: 24, fill: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}} /> : <CheckBoxOutlineBlank sx={{ fontSize: 24, color: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400] }} />}
                        </Box>
                    </TopRight>

                    <Box
                        sx={{
                            mt:3,
                            display:"flex",
                            gap:4,
                        }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column"
                            }}
                        >
                            {props.skeleton ?
                                <ShTextSkeleton/> 
                                :
                                <>
                                    <Text light>{t("products")}</Text>
                                    <Text>{props.totalOrderLines}</Text>
                                </>
                            }
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column"
                            }}
                        >
                            {props.skeleton ?
                                <ShTextSkeleton/> 
                                :
                                <>
                                    <Text light>{t("stream")}</Text>
                                    <Text>{props.stream?.shortName}</Text>
                                </>
                            }
                        </Box>

                        <Box
                            sx={{
                                ml:"auto",
                                display:"flex",
                                alignItems:"center",
                                gap:1,
                            }}
                        >
                            <NoTextLabel palette="normal" variant={typeLabelColor}/>
                            <NoTextLabel palette="normal" variant={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </BaseCard>
    )
}
