import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authApiSlice, useLogoutMutation } from '../../newapi/user/authentication/authApiSlice';
import {persistor} from "../../newapi/store";
import { useDispatch } from 'react-redux';
import { resetSideBar } from '../../newapi/global/settingsSlice';
function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

//   useLogoutMutation
  const [logout, { isSuccess, isLoading,isUninitialized, isError,error }] = useLogoutMutation();

  // const internalLogout()
  useEffect(() => {
    navigate("/login");
    logout();
  },[]);
  
  return <div>Logging out...</div>;
}

export default Logout;
