import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";

import { useTranslation } from 'react-i18next';
import DetailsPanel from "./Views/Details";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spWarehouseLocationTypeDetailsConfig } from "./spWarehouseLocationTypeDetailsConfig";
import { ReactComponent as ShLocation } from '../../../../styles/svg/shmarker.svg';
import { useGetWarehouseLocationTypeQuery } from "../../../../newapi/warehouse/warehouseLocationTypeSlice";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpWarehouseLocationTypeDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

// useGetWarehouseLocationTypeQuery
    const { data: locationTypeData, isLoading,isFetching,isError } = useGetWarehouseLocationTypeQuery(props.warehouseLocationTypeId,
    {
        skip: !props.warehouseLocationTypeId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ShLocation/>
    }), [theme.palette.mode]);

    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        // options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.warehouseLocationTypeId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && locationTypeData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: locationTypeData.description,
                subTitle: [""],
            }));
        }
    }, [locationTypeData, isLoading, isFetching, t,theme.palette.mode]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spWarehouseLocationTypeDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <DetailsPanel setIsExtended={props.setIsExtended} config={spWarehouseLocationTypeDetailsConfig.details} data={locationTypeData}/>
                </>
        </Sidepanel2>
    );
};

export default SpWarehouseLocationTypeDetails;