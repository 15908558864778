// In App.js or index.js (or another top-level file)
export const suppressConsoleWarnings = () => {
    const originalConsoleError = console.error;
  
    console.error = (...args) => {
        if (args[0]?.includes('MUI: useResizeContainer - The parent DOM element of the data grid has an empty width.')) {
            return;
        }
    
        // For all other error messages, call the original console.error
        originalConsoleError(...args);
    };
};
  
