// TabContent1.js
import React, { useState, useEffect, useCallback } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setTabErrors, updateData } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import cx from "classnames"
import { useGetRelationsQuery } from '../../../../../newapi/global/relation/relationSlice';
import ShValidatedInputDropdown from '../../../../theme/dropdowns/ShValidatedInputDropdown';
import {useGetWarehousesSelectQuery } from '../../../../../newapi/warehouse/warehouseApiSlice';
import { InputDropdown } from '../../../../theme/dropdowns/InputDropdown';
import { DropdownBase } from '../../../../theme/dropdowns/Dropdown';


/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { data: warehouses } = useGetWarehousesSelectQuery();

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

   

    const handleChange = (index, key, val) => {
        // Use functional update to ensure we have the latest state
        props.setLocalData((prevLocalData) => {
            let updatedChannels = [...prevLocalData.availableChannels];
            
            if (key === "warehouseId") {
                if (index === 0) {
                    const isAnyOtherSelected = updatedChannels.some(
                        (channel, idx) => idx !== index && channel.warehouseId !== -1
                    );
                    if (!isAnyOtherSelected) {
                        updatedChannels = updatedChannels.map(channel => ({
                            ...channel,
                            warehouseId: warehouses.data[val].id,
                        }));
                    }
                } else {
                    updatedChannels[index] = {
                        ...updatedChannels[index],
                        warehouseId: warehouses.data[val].id,
                    };
                }
            } else {
                // For other keys (e.g., active status)
                updatedChannels[index] = {
                    ...updatedChannels[index],
                    [key]: val === 'true',
                };
            }

            // Update local state with the new value
            return {
                ...prevLocalData,
                availableChannels: updatedChannels,
            };
        });

        dispatch(setTabErrors({ ...props.errors, [key]: 'availableChannels' }));
    };

    return (
        <div>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 0,
                    pb:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                <Box
                    sx={{
                        width:200,
                    }}
                >
                    <Text light>{t("channel")}</Text>
                </Box>
                <Box
                    sx={{
                        width:200,
                        textAlign:"center",
                    }}
                >
                    <Text light>{t("connect")}</Text>
                </Box>
                <Box
                    sx={{
                        width:200,
                        textAlign:"center",
                    }}
                >
                    <Text light>{t("sync_orders")}</Text>
                </Box>
                {warehouses?.data?.length > 1 && (
                    <Box
                        sx={{
                            width:200,
                            textAlign:"center",
                        }}
                    >
                        <Text light>{t("warehouse")}</Text>
                    </Box>
                )}
            </Box>
            <Box sx={{
                width:1,
                height:"500px",
                overflowY:"scroll"
            }}>
                {props.localData.availableChannels?.map((channel, index) => (
                    <Box 
                        key={index}
                        sx={{
                            width: 1,
                            paddingTop: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 7
                        }}
                    >
                        <Box
                            sx={{
                                width: 200,
                            }}
                        >
                            <Text>{channel.name}</Text>
                        </Box>
                        
                        <Box
                            sx={{
                                flex:1,
                                borderRadius:4,
                                display:"flex",
                                height:32,
                                justifyContent:"center",
                            }}
                        >
                            <ToggleButtonGroup
                                color={channel.active ? "green" : "red"}
                                value={channel.active? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange(index, "active", value)}
                                aria-label="active"
                                className={cx(`${channel.active}`, {
                                    'active': channel.active
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                            
                        </Box>

                        <Box
                            sx={{
                                flex:1,
                                borderRadius:4,
                                display:"flex",
                                height:32,
                                justifyContent:"center",
                            }}
                        >
                            <ToggleButtonGroup
                                color={channel.syncOrders ? "green" : "red"}
                                value={channel.syncOrders? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange(index, "syncOrders", value)}
                                aria-label="Sync Orders"
                                className={cx(`${channel.syncOrders}`, {
                                    'active': channel.syncOrders
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {warehouses?.data?.length > 1 && (
                            <Box
                                sx={{
                                    flex:1,
                                    borderRadius:4,
                                    display:"flex",
                                    height:32,
                                    justifyContent:"center",
                                }}
                            >
                                {/* <DropdownBase
                                    key={channel.warehouseId}
                                    displayName="name"
                                    // changeField="id"
                                    selected={warehouses?.data?.findIndex(item => item.id === channel.warehouseId) ?? -1}
                                    options={warehouses?.data ?? []}
                                    noSelection={t("choose_warehouse")} 
                                    onChange={(value) => handleChange(index, "warehouseId", value)}
                                    closeOnSelection
                                >
                                    <Box>
                                        {warehouses?.data?.find(item => item.id === channel.warehouseId)?.name ?? "no_selection"}
                                    </Box>
                                </DropdownBase> */}
                                <InputDropdown
                                    height={32}
                                    // name="warehouseId"
                                    displayName="name"
                                    // changeField="id"
                                    selected={warehouses?.data?.findIndex(item => item.id === channel.warehouseId) ?? -1}
                                    options={warehouses?.data ?? []}
                                    noSelection={t("choose_warehouse")} 
                                    onChange={(value) => handleChange(index, "warehouseId", value)}
                                    closeOnSelection
                                />
                                {/* <ShValidatedInputDropdown
                                    name="warehouseId"
                                    displayName="name"
                                    changeField="id"
                                    selected={warehouses?.data?.findIndex(item => item.id === channel.warehouseId) ?? -1}
                                    options={warehouses?.data ?? []}
                                    noSelection={t("choose_warehouse")} 
                                    onChange={(e,value) => handleChange(index, "warehouseId", value)}
                                    //error={fieldErrors.warehouseId}
                                /> */}
                            </Box>
                        )}

                    </Box>
                ))}
            </Box>

    </div>
    );
};

const Channels = (props) => {
    const { t } = useTranslation();
    const data = useSelector((state) => state.progressiontabs.data);
    const [localData, setLocalData] = useState(data);

    useEffect(() => {
        setLocalData(data);
    },[data])

    return (
        <ProgressionTab 
            tabId="tabChannels" 
            subtitle={t("channels")} 
            step={4}
            maxSteps={4}
            updateData={localData} //updates pre-tab-switch
        >
            <Content localData={localData} setLocalData={setLocalData}/>
        </ProgressionTab>
    )
}

export default Channels;

