import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";

import { useTranslation } from 'react-i18next';
import DetailsPanel from "./Views/Details";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spPrinterDetailsConfig } from "./spPrinterDetailsConfig";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useFindPrinterByIdQuery } from "../../../../newapi/warehouse/printerSlice";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import Workspaces from "./Views/Workspaces";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpPrinterDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: printerData, isLoading,isFetching,isError } = useFindPrinterByIdQuery(props.printerId,
    {
        skip: !props.printerId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <LocalPrintshopIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t("warehouse")} /> },
        { component: <ShRowSkeleton heading={t("label_workspaces")} /> },
        { component: <ShRowSkeleton heading={t("packing_list_workspaces")} /> },
    ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} />   
    }), [defaultIcon, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.printerId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && printerData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                
                title: printerData.description ,
                subTitle: printerData.internal_name,
                options: [
                    {
                        heading: t('warehouse'),
                        content: printerData.warehouse !== null ? printerData.warehouse.name : "-",
                    },
                    {
                        heading: t('label_workspaces'),
                        content: printerData.labelWorkspaces !== null ? printerData.labelWorkspaces.length > 0 ? printerData.labelWorkspaces.length : 0 : "-",
                    },
                    {
                        heading: t('packing_list_workspaces'),
                        content: printerData.packingListWorkspaces !== null ? printerData.packingListWorkspaces.length > 0 ? printerData.packingListWorkspaces.length : 0 : "-",
                    }
                ],
            }));
        }
    }, [printerData, isLoading, isFetching, t,theme.palette.mode]);


    return (
        <Sidepanel2
            tabConfig={spPrinterDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <DetailsPanel config={spPrinterDetailsConfig.details} data={printerData} setIsExtended={props.setIsExtended}/>
                    <Workspaces printerId={props.printerId} setIsExtended={props.setIsExtended}/>
                </>
        </Sidepanel2>
    );
};

export default SpPrinterDetails;