import { useTranslation } from "react-i18next";

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal";
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper";
import ChooseIntegratorType from "./views/ChooseIntegratorType";
import Credentials from "./views/Credentials";
import Sync from "./views/Sync";
import Channels from "./views/Channels";
import { useCreateIntegratorMutation } from "../../../../newapi/webshop/integratorSlice";

const MpAddIntegrator = (props) => {
    const {t} = useTranslation();

    const [CreateIntegrator,{isLoading}] = useCreateIntegratorMutation();
    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useCreateIntegratorMutation} isModal title={t("add_integrator")}>
                <ChooseIntegratorType/>
                <Credentials/>
                <Sync/>
                <Channels/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddIntegrator;