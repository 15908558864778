

import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
export const settingsWarehouseOutboundPickingCoreConfig = {
    future:  {
        title: "future",
        icon: <UpdateOutlinedIcon/>,
    },    
    pickBasis:  {
        title: "pickbasis",
        icon: <AccountTreeOutlinedIcon/>,
    },    
    palletPicking:  {
        title: "PalletPicking",
        icon: <CalendarViewWeekOutlinedIcon/>,
    },    
    notManual:  {
        title: "not_manual",
        icon: <PrecisionManufacturingOutlinedIcon/>,
    },    
    b2b:  {
        title: "b2b",
        icon: <CorporateFareOutlinedIcon/>,
    },
};
