import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";

import { useTranslation } from "react-i18next";
import { useGetLogsQuery } from '../../../../../newapi/global/logsSlice';
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';
import ShLabel from "../../../../theme/label/ShLabel";

const gridOptions = {
    export:false,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};


// List of predefined colors (you can define your own)
const colorList = [
    'red', // Red
    'yellow', // Yellow
    'green', // Green
    'primary', // Blue
    'purple', // Purple
    'orange', // Orange
    'cyan', // Light Green
    'pink', // Light Purple
  ];
  
  // Function to map a value to a color based on the index
  const getColorForValue = (value) => {
    // Use the hash of the value (or a simple modulo) to map it to a color
    const hash = Math.abs(value.toString().hashCode());  // Create a numeric hash
    const colorIndex = hash % colorList.length; // Ensure the index is within the 8 colors
    return colorList[colorIndex]; // Return the mapped color
  };
  
  // A simple hash function to generate a numeric value from a string
  String.prototype.hashCode = function() {
    let hash = 0;
    for (let i = 0; i < this.length; i++) {
      hash = (hash << 5) - hash + this.charCodeAt(i);
    }
    return hash;
  };
  
const Logs = (props) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const [config, setConfig] = useState({type: "Invoice", externalId: props.invoiceId});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const columns = [
        {field: "createdAt", headerName: t("created_on"), flex: 1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            const date = new Date(params.row.createdAt);
            return date.toLocaleString(i18n.language, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
        }},
        {field: "description", headerName: t("description"), flex:2, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.description;
        }},
        {field: "userName", headerName: t("user"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                } 
                const color = getColorForValue(params.row.userName);

                return (
                    <ShLabel borderless variant={color}>
                        {params.row.userName === "system" ? t("system") : params.row.userName}
                    </ShLabel>
                );
    
                // return params.row.userName === "system" ? t("system") : params.row.userName;
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <ShTextSkeleton/>
                )
            }
        },
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetLogsQuery}
                config={config}
                title={t('invoicelines')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
            />
        </Box>
    );
}

export default Logs;