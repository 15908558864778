import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useUpdateWorkflowMutation, useDeleteWorkflowMutation, useUpdateWorkflowActionMutation } from "../../../../../newapi/workflow/workflowSlice";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import { yupRequired, yupNumberRequired } from "../../../../../utils/validation";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import NumberInput from "../../../../theme/inputs/NumberInput";

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { showToastMessageDeleteRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const getInputOptions = (t, colors, theme, action, onChange) => {
    const yesNoOptions = [{
        value: "yes",
        name: t("yes")
    },{
        value: "no",
        name: t("no")
    }];

    switch(action.resultType) {
        case "yesno":
            return <InputDropdown
                displayName={"name"} 
                onChange={(selected) => onChange(action, "result", yesNoOptions[selected].value)}
                noSelection={t("choose_option")}
                options={yesNoOptions}
                selected={yesNoOptions.findIndex(option => option.value === action?.result) ?? -1}
                closeOnSelection
            />;
        case "grade":
            return <NumberInput btnSize={"24"}
                value={action?.result ?? 0}
                change={(e) => onChange(action, "result", e)}
            />;
        case "text":
            return <Box
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32
            }}
        ><InputBase 
                type="text"
                sx={{
                    px:2,
                    flex:1, 
                    color:colors.txt["primary"],
                    lineHeight:`32px`,
                }}
                value={action?.result ?? ""}
                onChange={(e) => onChange(action, "result", e.target.value)}
            /></Box>;
    }
}


const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);
    
    const [workflowInfo, setWorkflowInfo] = useState({
        state: "",
        id: 0,
        name: "",
        parentDescription: "",
        actions: [],
    });
    useEffect(() => {
        setWorkflowInfo(props.data);
    },[props.data]);
    
    const handleChange = (action, column, value) => {
        setWorkflowInfo({
            ...workflowInfo,
            actions: workflowInfo.actions.map(a => a.id === action.id ? {...a, [column]: value} : a)
        });
      };

      
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={workflowInfo}></GridActions>,
        });

        return () => {
        }
    },[workflowInfo]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
    };
    
    const schema = yup.object().shape(rules);

    const [UpdateAction,{isLoading}] = useUpdateWorkflowActionMutation();

    const handleSaveActionClick = async (action) => {
        try {
            const data = await UpdateAction(action).unwrap()
            showToastMessageUpdateRequest(t,data);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("update_failed"), t, err);
              }
        }  
    }

    // const handleDeleteClick = async () => {
    //     setOpen(true);
    // };

    // const confirmDeleteModal = {
    //     open: open,
    //     setOpen: setOpen,
    //     confirm: confirmDelete
    // }

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
        <Dropdown title={t("basic")} alwaysOpen>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={4}
                    sx={{
                        display:"flex",
                        gap:4,
                        justifyContent:"flex-start"
                    }}
                >
                    <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                flex:2,
                            },
                            minWidth: "140px"
                        }}
                    >
                        <Box lineHeight={"38px"}>
                            <Text>{t("name")}</Text>
                        </Box>
                        <Box lineHeight={"38px"}>
                            <Text>{t("linked_to")}</Text>
                        </Box>
                    </Box>
                    <Box sx={{
                        width: "100%"
                    }}>
                        <Box display="flex"
                            height="32px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase 
                                type="text"
                                sx={{
                                    px:2,
                                    flex:1, 
                                    color:colors.txt["primary"],
                                    lineHeight: "32px",
                                }}
                            value={workflowInfo?.name}
                            disabled={true}
                        />
                        </Box>
                        <Box display="flex"
                            height="32px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase 
                                type="text"
                                sx={{
                                    px:2,
                                    flex:1, 
                                    color:colors.txt["primary"],
                                    lineHeight: "32px",
                                }}
                            value={`${t(workflowInfo?.type)} - ${workflowInfo?.parentDescription}`}
                            disabled={true}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
            <Dropdown title={t("actions")} alwaysOpen>
                    
                    {/* Two columns */}
                    { workflowInfo?.actions.map((action) => (
                    <Box 
                    key={action.id} // Always include a unique key when rendering lists in React
                    sx={{
                        display:"flex",
                                flexDirection:"column",
                                gap:1,
                                flex:1,
                                marginBottom:4,
                    }}>
                    <Box 
                        flex={1}
                        sx={{
                        display: "flex",
                        justifyContent: "flex-start"
                        }}
                    >
                        {/* Displaying action details inside the Box */}
                        <div>{action.name}</div>
                        <div>{action.description}</div>
                    </Box>
                    <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                    >
                        <InputBase 
                            type="text"
                            sx={{
                                px:2,
                                flex:1, 
                                color:colors.txt["primary"],
                                lineHeight:`32px`,
                            }}
                            value={action?.remark ?? ""}
                            onChange={(e) => handleChange(action, "remark", e.target.value)}
                        />
                    </Box>
                    <Box 
                        flex={1}
                        sx={{
                        display: "flex",
                        justifyContent: "flex-start"
                        }}
                    >
                        {getInputOptions(t, colors, theme, action, handleChange)}
                    </Box>
                    <Box 
                        flex={1}
                        sx={{
                        display: "flex",
                        justifyContent: "flex-start"
                        }}
                    >
                    <Shbutton
                        className={"group-btn"}
                        onClick={() => handleSaveActionClick(action)}
                        color={theme.palette.mode === "dark" ? "blue" : "primary"}
                        variant="contained"
                        endIcon={<SaveIcon />}
                    >
                            {t('save')}
                        </Shbutton>
                    </Box>
                </Box>
            ))}

                <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
            </Box>
            </Dropdown>
            {/* <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                    <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
        <GridActions
            id={workflowInfo?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
        />
            </Box> */}
        </Box>
    );
}

export default DetailsPanel;