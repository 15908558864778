import { useTheme } from "@emotion/react"
import { tokens } from "../../theme";
import MSelectWarehouses from "./Modals/MSelectWarehouses";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateActive } from "../../newapi/warehouse/warehouseSlice";
import { Box } from "@mui/material";
import Text from "../theme/text/Text";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import { useTranslation } from "react-i18next";
import { apiSlice } from "../../newapi/createApiSlice";

const WarehousePicker = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    
    const wareHouse = useSelector(state => state.warehouse);
    const wareHouseName = useSelector(state => state.warehouse.activeName);
    const translatedWareHouseName = wareHouseName === 'all_warehouses' ? t(wareHouseName) : wareHouseName;
    // const translatedWareHouseName = t(wareHouse);
    // console.log("wareHouseName",wareHouse);
    
    const [display,setDisplay] = useState({phone: props.phone ?? true, desktop: props.desktop ?? true})

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = (updateWarehouse, warehouse) =>  {
        if (updateWarehouse) {
            dispatch(updateActive(warehouse));
            dispatch(apiSlice.util.resetApiState());
        }
        setOpen(false);
    }

    const [windowSize, setWindowSize] = useState(window.innerWidth);
    // const breakpoint = props.breakpoint ?? 767
    const handleResize = (event) => {
        setWindowSize(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    return (
        <>
            {/* Everything above phone */}
            {windowSize > 767 && display.desktop ?
                <Box
                    sx={{
                        // border: `1px solid ${theme.palette.mode === "dark" ? colors.grey[500] : "#43536F"}`,
                        background: theme.palette.mode === "dark" ? colors.nav["linkBg"] : colors.txt["primary"],
                        height: 40,
                        paddingX:2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        borderRadius:5,
                        userSelect: "none",
                        cursor:"pointer",
                        transition:"250ms",
                        color:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["softened"],
                        "&:hover" : {
                            background:theme.palette.mode === "dark" ? colors.grey[300]: colors.nav["linkBg"]
                        },
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"none",
                        }
                    }}
                    onClick={handleOpen}
                >
                    <Box
                        sx={{
                            marginRight:2,
                        }}
                    >
                        <Text bold variant={"white"}>{translatedWareHouseName}</Text>
                        {/* <Text semibold variant={"white"}>magazijnen</Text> */}
                    </Box>
                    <KeyboardArrowDownIcon/>
                </Box>
            : null }

            {/* Phone */}
            {windowSize <= 767 && display.phone ?
                <Box
                    sx={{
                        // border: `1px solid ${theme.palette.mode === "dark" ? colors.grey[500] : "#43536F"}`,
                        background: theme.palette.mode === "dark" ? colors.nav["linkBg"] : colors.txt["primary"],
                        height: 32,
                        paddingX:2,
                        alignItems:"center",
                        justifyContent:"space-between",
                        borderTopLeftRadius:16,
                        borderBottomLeftRadius:16,
                        userSelect: "none",
                        cursor:"pointer",
                        transition:"250ms",
                        display:"none",
                        color:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["softened"],
                        "&:hover" : {
                            background:theme.palette.mode === "dark" ? colors.grey[300]: colors.nav["linkBg"]
                        },
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"flex",
                        }
                    }}
                    onClick={handleOpen}
                >
                    <Text bold variant={"blue"}>Alle</Text>
                    <Box sx={{
                        marginLeft:1,
                        lineHeight:"16px"
                        
                    }}>
                        <Text semibold variant={"white"}>
                            <WarehouseOutlinedIcon/>
                        </Text>
                    </Box>
                </Box>
            : null
            }
            
            <MSelectWarehouses
                open={open}
                handleClose={handleClose}
            />
        </>
    )
}

export default WarehousePicker;