import { Box, useTheme } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import Text from "../../../../../components/theme/text/Text";
import { tokens } from "../../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import MAddReachType from "../../../../../components/global/Modals/MAddReachType";
import SpReachTypeDetails from "../../../../../components/global/Sidepanels/SpReachTypeDetails/SpReachTypeDetails";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetReachTypesQuery } from "../../../../../newapi/warehouse/reachTypeSlice";


const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {},[props.open]);

    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_reach_type")}</Shbutton>
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};


const ReachTypes = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };
      

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            
        }
    },[]);

    const [reachTypeId, setReachTypeId] = useState();
    const reachTypeIdRef = useRef(reachTypeId);
    
    const getReachType = (params) => {
        if (!params) return;
    
        const newreachTypeId = parseInt(params.row.id);
        // Only update state if the reachTypeId has actually changed
        if (reachTypeIdRef.current !== newreachTypeId) {
            setReachTypeId(newreachTypeId);
            reachTypeIdRef.current = newreachTypeId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    

    const columns = [
        {field: "description", 
        headerName: t("description"), 
        flex:2, 
        renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetReachTypesQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t('overview')} 
                gridOptions={gridOptions} 
                onRowClick={getReachType}
                sortModel={{field: columns[0].field,type:"ASC"}}
                
                columns={columns}>
            </ShDataGrid2>

            <MAddReachType onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
            <SpReachTypeDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                reachTypeId={reachTypeId}
                // data={reachTypeData}                
            ></SpReachTypeDetails>
        </Box>
    )
});

export default ReachTypes;