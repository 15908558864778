import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useProcessFileMutation, useProcessImportMutation, useUploadFileMutation, useUploadImportMutation, useUploadLogoMutation } from '../../../../newapi/global/uploadApiSlice';
// import { useUploadFileMutation } from '../../../newapi/global/uploadApiSlice';
import { ReactComponent as Border } from '../../../../styles/svg/border.svg';
import { Box, ButtonBase, useTheme } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Trans, useTranslation } from 'react-i18next';
import { tokens } from '../../../../theme';
import Subheading from '../../text/Subheading';
import Text from '../../text/Text';
import { useSelector } from 'react-redux';
import { ENV_TYPES } from '../../../global/Sidebar';
import { showToastMessagePostRequest, showToastMessageRequestError } from '../../../../utils/toasts';
// import { useUploadFileMutation } from './yourApiSlice';

const UploadLogoProcessor = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    
    const [uploadFile] = useUploadLogoMutation();
    const [selectedFile, setSelectedFile] = useState(null);

    const [dragActive, setDragActive] = useState(false); 
    const fileInputRef = useRef(null); // Create a ref for the file input

    // Function to simulate click on file input
    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Handle drag events
    const handleDrag = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === 'dragenter' || event.type === 'dragover') {
            setDragActive(true);
        } else if (event.type === 'dragleave') {
            setDragActive(false);
        }
    }, []);

    // Handle drop event
    const handleDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            // Handle the file...
            setSelectedFile(event.dataTransfer.files[0]);
            // console.log(event.dataTransfer.files[0]);
        }
    }, []);

    // Handle file selection via input
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            // Handle the file...
            setSelectedFile(event.target.files[0]);
            // console.log(event.target.files[0]);
        }
    };

    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const handleUpload = async (event) => {
        event.stopPropagation();
        if (selectedFile) {
            try {
                // Determine the headers based on the condition
                const headers = {};
                
                // Check if the environment type matches the specific type (e.g., ENV_TYPES[100])
                if (selectedEnvironmentType === ENV_TYPES[100]) {
                    headers['relation_id'] = props.relationId;  // Custom header for ENV_TYPES[100]
                }
                const response = await uploadFile({ 
                    file: selectedFile,
                    headers
                }).unwrap();

                showToastMessagePostRequest(response);

                if (response) {
                    props.close();
                //     props.setData({
                //         columns: response.columns,
                //         headers: response.headers,
                //         internalFile: response.file,
                //         file:selectedFile.name
                //     });
                //     // setColumns(response.columns);
                //     // setHeaders(response.headers);
                //     // setInternalFile(response.file);
                }
            } catch (error) {
                showToastMessageRequestError(t("error"),t,error);
            }
        }
    };


    //Phone: 768
    //tablet 1024
    //smallpc 1366
    const breakpoint = props.breakpoint ?? "phone";

    const getBreakPoint = () => {
        if(breakpoint == "phone") return 768;
        if(breakpoint == "tablet") return 1024;
        if(breakpoint == "smallpc") return 1366;
    }
    

    return (
        <Box
            sx={{
                flex:1,
                flexShrink:0,
            }}
        >

            <ButtonBase
                component="div"
                onDragEnd={handleDrag}
                onDragOver={handleDrag}
                onDragLeave={handleDrag}
                onDrop={handleDrop}
                onClick={handleClick}
                TouchRippleProps={{
                    style: {
                        color: colors[props.color][200],
                        // color: "rgba(65,128,246,.35)"
                    }
                }}
                sx={{
                    position: 'relative',
                    borderRadius:3,
                    background: colors[props.color][100],
                    // background:"rgba(65,128,246,.15)",
                    display:"flex",
                    width:1,
                    "& .border" : {
                        position:"absolute",
                        top:0,
                        left:0,
                        height:1,
                        width:1,
                        borderRadius:3,

                        "& path" : {
                            fill: "none",
                            stroke: colors[props.color][400],
                            // stroke: "rgb(65,128,246)",
                            strokeWidth:4,
                            strokeDasharray:"10,10",
                            animation: "dash 25s linear infinite",
                            animationPlayState: "paused"
                        },
                        
                    },
                    "&:hover path" : {
                        animationPlayState: "running"
                    },
                }}
            >
                <input
                    type="file"
                    id="file-upload"
                    ref={fileInputRef} // Attach the ref to the file input
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="image/jpeg, image/png, image/jpg"
                />

                <Border className='border'/>
                <Box
                    sx={{
                        position: "relative",
                        top: 0,
                        left: 0,
                        width:1,
                        display:"flex"
                    }}
                >
                    {/* Inner content */}
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            flexDirection:"column",
                            paddingX:3,
                            paddingTop:4,
                            paddingBottom:selectedFile ? 4 : 6,
                            width:1,
                            [`@media screen and (max-width: ${getBreakPoint()}px)`] : {
                                paddingBottom:4,
                                alignItems:"flex-start",
                            }
                        }}
                    >
                        <Box
                            sx={{

                                display:"flex",
                                alignItems:"center",
                                flexDirection:"column",
                                [`@media screen and (max-width: ${getBreakPoint()}px)`] : {
                                    flexDirection:"row",
                                    justifyContent:"flex-start",
                                    gap:2,
                                    alignItems:"flex-start"
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    marginBottom:2,
                                    [`@media screen and (max-width: ${getBreakPoint()}px)`] : {
                                        marginBottom:0,
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width:40,
                                        height:40,
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        borderRadius:5,
                                        background:colors[props.color][100],
                                        color:colors[props.color][400],
                                        "& svg path" : {
                                            fill: colors[props.color][400]
                                        }
                                    }}
                                >
                                    {props.icon}
                                    {/* <LocationOnOutlinedIcon/> */}

                                </Box>
                                {/* <Icon rounded theme="primary">
                                    <LocationOnOutlinedIcon/>
                                </Icon> */}
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    flexDirection:"column",
                                    textAlign:"center",
                                    gap:0.5,
                                    ".colored" : {
                                        // color: "rgb(65,128,246)",
                                        color: colors[props.color][400]
                                    },
                                    [`@media screen and (max-width: ${getBreakPoint()}px)`] : {
                                        // justifyContent:"flex-start",
                                        textAlign:"left",
                                        alignItems:"flex-start"
                                    }
                                }}
                            >
                                <Subheading>{props.title}</Subheading>
                                <Box>
                                    <Trans 
                                        i18nKey="uploadFileText" 
                                        values={{ clickHere: t('clickHereText') }} 
                                        components={{
                                        strong: <strong className="colored" />
                                        }}
                                        // components={{ strong: <strong className="colored" /> }}
                                    />
                                </Box>

                                {/* <Text light>
                                    {t('uploadFileText', { 
                                        clickHere: t('clickHereText') // Use `t()` for the dynamic part as well
                                    })}
                                </Text> */}

                                {/* <Text light>Sleep een bestand of <strong className='colored'>klik hier</strong> om toe te voegen</Text> */}
                            </Box>
                        </Box>
                        
                        {selectedFile ?
                            <Box
                                sx={{
                                    paddingLeft:2,
                                    paddingRight:0.5,
                                    height:40,
                                    borderRadius:5,
                                    // background:"rgba(65,128,246,.15)",
                                    background: colors[props.color][100],
                                    marginTop:2,
                                    color: "rgb(65,128,246)",
                                    fontWeight:700,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                    [`@media screen and (max-width: ${getBreakPoint()}px)`] : {
                                        width:"calc(100% - 56px)",
                                        marginLeft:7,
                                    }
                                }}
                            >
                                <Text
                                noBreak
                                    variant={props.color}
                                    // color="rgb(65,128,246)"
                                >
                                    {selectedFile.name}
                                </Text>
                                
                                <ButtonBase
                                    onClick={handleUpload}
                                    sx={{
                                        marginLeft:2,
                                        height:32,
                                        // width:32,
                                        paddingX:1.5,
                                        background:"rgb(65,128,246)",
                                        background: colors[props.color][400],
                                        borderRadius:4,
                                        color:'white',
                                        fontWeight:700
                                    }}
                                >
                                    {t("upload")}
                                </ButtonBase>
                            </Box>
                        : null}


                    </Box>

                </Box>
            </ButtonBase>
            
        </Box>
    );
}

export default UploadLogoProcessor;
