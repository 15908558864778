import { Box, List, useTheme } from "@mui/material"
import SHModal from "../../theme/Modal/SHModal"
import Shbutton from "../../theme/buttons/Shbutton"
import { tokens } from "../../../theme";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import SelectGroup from "../../theme/Select/SelectGroup";
import { useState, useEffect } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useGetWarehousesSelectQuery } from "../../../newapi/warehouse/warehouseApiSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MSelectWarehouses = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    const [selected, setSelected] = useState(0);
    const [data, setData] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const selectedActiveWarehouseId = useSelector(state => state.warehouse.activeId);

    const select = (selected) => {
        setSelected(selected);
        setSelectedWarehouse(data[selected]);
    }

    const { data: warehouseData, isLoading } = useGetWarehousesSelectQuery();

    useEffect(() => {
        if (!isLoading && warehouseData) {
            const modifiedArray = warehouseData.data.map(item => ({
                ...item,
                title: item.name
              }));
            
            if (!props.ignoreAll) {
                modifiedArray.unshift({id: -1, title: t("all_warehouses"), name: t("all_warehouses")});
            }
            
            setSelected(0);
            setSelectedWarehouse(modifiedArray[0]);
            
            setData(modifiedArray);
        }
    }, [warehouseData, isLoading,t]);

    useEffect(() => {
        const foundWarehouse = data.findIndex(item => item.id === selectedActiveWarehouseId);
        if (foundWarehouse !== -1) {
            setSelected(foundWarehouse);
            setSelectedWarehouse(data[foundWarehouse]);
        }
    }, [data, selectedActiveWarehouseId]);

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    return(
        <SHModal
            open={props.open}
            onClose={() => props.handleClose(false, null)}
        >
            
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{props.subheading || t("select_warehouse")}</Subheading>
                <Text center light>{props.text || t("select_warehouse_description")}</Text>
            </Box>
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    maxHeight:300,
                    overflow:"auto",
                }}
            >
                <SelectGroup selected={selected} setSelected={select} options={data} />
            </Box>
           

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                
                <Shbutton isCancel={true} className={"always-visible"} onClick={() => props.handleClose(false, null)} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} onClick={() => props.handleClose(true, selectedWarehouse)} variant="contained">{t('select')}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MSelectWarehouses;