// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";


// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: "en", 
//     detection: {
//       order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"],
//       caches: ["cookie"]
//     },
//     interpolation: {
//       escapeValue: false,
//     },
//     react: {
//       useSuspense: false,
//     }
//   });

// export default i18n;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {store} from './newapi/store';
import { setLanguage } from "./newapi/global/settingsSlice";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "nl",
    detection: {
      order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"],
      caches: ["cookie"]
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    }
  });

if (!store.getState().settings.language) {
    const detectedLanguage = i18n.language;  // i18n.language is set by LanguageDetector
    store.dispatch(setLanguage(detectedLanguage));
}

store.subscribe(() => {
    const newLanguage = store.getState().settings.language;
    if (i18n.language !== newLanguage) {
      i18n.changeLanguage(newLanguage).then(() => {
        // This is where you can confirm the language has been changed
        // console.log(`Language changed to: ${newLanguage}`);
      });
        // i18n.changeLanguage(newLanguage);
    }
});

export default i18n;
