import React, { useRef, useState,createRef, forwardRef, useEffect, useContext } from "react";
import { Box, IconButton, Typegraphy, Typography, useTheme, Button, Icon } from '@mui/material';
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
// import { createRef } from "react";
import cx from 'classnames';
import Text from "../../../components/theme/text/Text";

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import CloseOverlayBtn from "../buttons/CloseOverlayBtn";

// const ColorButton = styled(Button)<ButtonProps>((props) => ({
//     color: props.theme.palette.getContrastText(props.colors.orange[300]),
//     backgroundColor: props.colors.orange[300],
//     '&:hover': {
//       backgroundColor: props.colors.orange[400],
//     },
//   }));

const Spheadingrow = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const options = props.options;

    return (
        <Box 
            className={cx({
                'sub-row': props.isSubRow,
            })}
            sx={
                props.rowsettings ? props.rowsettings :
                {
                paddingTop:4,
                display:"flex",
                justifyContent:"space-between",
                gap:"24px 56px",
                flexWrap:"wrap",
                "@media screen and (max-width: 63.9375em)":{
                    overflowX:"auto",
                    flexWrap:"nowrap",
                    gap: "16px",
                    paddingTop:3,
                    "&.sub-row": {
                        paddingTop:2,
                        // marginRight:3
                    }
                }
            }}>
            {options.map((option, index) => (
                <Box 
                    key={index}
                    display="flex" 
                    flexDirection="column"
                    justifyContent={"center"}
                    sx={{
                        position:"relative",
                        ...(typeof option.component === "undefined" && {
                            "@media screen and (max-width: 63.9375em)": {
                                background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                paddingX: 2,
                                paddingY: 2,
                                borderRadius: 3,
                                flexShrink: 0,
                            },
                        }),
                    }}>
                        {typeof option.component === "undefined" ? 
                            <>
                                <Text light>{option.heading}</Text>
                                <Text fontWeight="500">{option.content}</Text>
                            </>
                        : 
                            option.component
                        }
                </Box>
            ))}
        </Box>
    );
};

export default Spheadingrow;