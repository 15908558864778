import { Box, InputBase, useTheme,IconButton } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import NumberInput from "../../theme/inputs/NumberInput";
import { useTranslation } from "react-i18next";
import { useAddChildProductsMutation } from "../../../newapi/warehouse/productSlice";
import { showToastMessagePostRequest, showToastMessage, showToastMessageRequestError } from "../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../utils/validation";
import { useUploadLogoMutation } from "../../../newapi/global/uploadApiSlice";
import UploadLogoProcessor from "../../theme/upload/logo/UploadLogoProcessor";
import { Image } from "@mui/icons-material";

const MAddRelationLogo = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [uploadLogo,{isLoading}] = useUploadLogoMutation();


    const rules = {
        sku: yupRequired('sku', t),
    };

    const schema = yup.object().shape(rules);

    const handleClose = () => {
        props.handleClose();
    }
      
    
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    } 

    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("upload_logo")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderRadius:4,
                    // borderTopLeftRadius:16,
                    // borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                <UploadLogoProcessor
                    close={handleClose}
                    relationId={props.relationId}
                    icon={<Image/>}
                    title={t("logo")}
                    type="product"
                    breakpoint="smallpc"
                    color={theme.palette.mode === "dark" ? "blue" : "primary"}
                />
            </Box>
            {/* <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => saveChildProducts(props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box> */}
        </SHModal>
    )
}

export default MAddRelationLogo;
