import { Box, IconButton, InputBase, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import Text from "../../../../theme/text/Text";
import StockOnLocationCard from "../../../../theme/cards/stock/StockOnLocationCard";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import DeleteIcon from '@mui/icons-material/Delete';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useUpdatePickupCartMutation } from "../../../../../newapi/warehouse/pickupCartSlice";
import { showToastMessageDeleteRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import { useGetWarehousesSelectQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";
import cx from "classnames"
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { useGetConnectionDefinitionsQuery } from "../../../../../newapi/webshop/etailizeSlice";
import { useDeleteIntegratorMutation, useEditIntegratorMutation } from "../../../../../newapi/webshop/integratorSlice";
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const Channels = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [isExtended, setIsExtended] = useState(false);
    const [credentials, setCredentials] = useState();
    const { data: warehouses } = useGetWarehousesSelectQuery();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        props.replaceView({
            ...props.config,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    
    const [integratorInfo, setIntegratorInfo] = useState();

    useEffect(() => {
        setIntegratorInfo({
            ...props.data,
            availableChannels: integratorInfo?.availableChannels
        });
    },[props.data]);



    
    const [EditIntegrator,{isLoading}] = useEditIntegratorMutation();
    const [DeleteIntegrator,{isLoading2}] = useDeleteIntegratorMutation();
    const handleSaveClick = async () => {
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                const data = await EditIntegrator(integratorInfo).unwrap()
                showToastMessageUpdateRequest(t,data);
        } catch (err) {
            showToastMessageRequestError(t("update_failed"),t,err);
          }
            
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"),t,"");

            }    
        }        
    };

    const { data: connectionDefinitions, isLoading: connectionDefinitionsLoading, isError: connectionDefinitionsError } = useGetConnectionDefinitionsQuery(integratorInfo?.credentials,
        {
            skip: !integratorInfo || !integratorInfo?.credentials?.apiKey || !integratorInfo?.credentials?.apiSecret || !integratorInfo?.credentials?.clientId || integratorInfo?.type !== "etailize"
        }
    );
    useEffect(() => {
        if(!integratorInfo) return;
        if(connectionDefinitionsError || !integratorInfo?.credentials?.apiKey || !integratorInfo?.credentials?.apiSecret || !integratorInfo?.credentials?.clientId || integratorInfo?.type !== "etailize") {
            setIntegratorInfo({ ...integratorInfo, availableChannels: [] });
            return;
        }
        if (connectionDefinitions) {
            const updatedConnectionDefinitions = connectionDefinitions.map(definition => {
                const matchingCredential = integratorInfo.webshopcredentials.find(credential => credential.webshop_type === definition.id);
                if (matchingCredential) {
                    return {
                        ...definition,
                        active: true,
                        syncOrders: matchingCredential.syncOrders,
                        warehouseId: matchingCredential.warehouseId
                    };
                } else {
                    return {
                        ...definition,
                        active: false,
                        syncOrders: true,
                        warehouseId: -1
                    };
                }
            });
            setIntegratorInfo({ ...integratorInfo, availableChannels: updatedConnectionDefinitions });
        }   
    }, [connectionDefinitions, connectionDefinitionsError]);

    const handleChange = (index, key, val) => {
        // Use functional update to ensure we have the latest state
        setIntegratorInfo((prevIntegratorInfo) => {
            let updatedChannels = [...prevIntegratorInfo.availableChannels];
            
            if (key === "warehouseId") {
                if (index === 0) {
                    const isAnyOtherSelected = updatedChannels.some(
                        (channel, idx) => idx !== index && channel.warehouseId !== -1
                    );
                    if (!isAnyOtherSelected) {
                        updatedChannels = updatedChannels.map(channel => ({
                            ...channel,
                            warehouseId: warehouses.data[val].id,
                        }));
                    }
                } else {
                    updatedChannels[index] = {
                        ...updatedChannels[index],
                        warehouseId: warehouses.data[val].id,
                    };
                }
            } else {
                // For other keys (e.g., active status)
                updatedChannels[index] = {
                    ...updatedChannels[index],
                    [key]: val === 'true',
                };
            }

            // Update local state with the new value
            return {
                ...prevIntegratorInfo,
                availableChannels: updatedChannels,
            };
        });

    };

    const handleDeleteClick = () => {
        setOpen(true); // Open confirmation modal
    };

    const confirmDelete = async () => {
        try {
            const response = await DeleteIntegrator(integratorInfo.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageRequestError(t("delete_failed"), t, err);
        } finally {
            setOpen(false); // Close modal after deletion
        }
    };

    return (
        <>
            <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
                    sx={{
                        "@media screen and (min-width: 48.1em)"  :{
                            marginBottom:11
                        }
                    }}
                >
                    <Dropdown title={t("locations")} open>
                        <Box sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column"
                            },
                            flexDirection:"column"
                        }}>
                <Box 
                sx={{
                    width:1,
                    paddingTop: 0,
                    pb:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                <Box
                    sx={{
                        width:200,
                    }}
                >
                    <Text light>{t("channel")}</Text>
                </Box>
                <Box
                    sx={{
                        width:200,
                        textAlign:"center",
                    }}
                >
                    <Text light>{t("connect")}</Text>
                </Box>
                <Box
                    sx={{
                        width:200,
                        textAlign:"center",
                    }}
                >
                    <Text light>{t("sync_orders")}</Text>
                </Box>
                {warehouses?.data?.length > 1 && (
                    <Box
                        sx={{
                            width:200,
                            textAlign:"center",
                        }}
                    >
                        <Text light>{t("warehouse")}</Text>
                    </Box>
                )}
            </Box>
            <Box sx={{
                width:1,
            }}>
                {integratorInfo?.availableChannels?.map((channel, index) => (
                    <Box 
                        key={index}
                        sx={{
                            width: 1,
                            paddingTop: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 7
                        }}
                    >
                        <Box
                            sx={{
                                width: 200,
                            }}
                        >
                            <Text>{channel.name}</Text>
                        </Box>
                        
                        <Box
                            sx={{
                                flex:1,
                                borderRadius:4,
                                display:"flex",
                                height:32,
                                justifyContent:"center",
                            }}
                        >
                            <ToggleButtonGroup
                                color={channel.active ? "green" : "red"}
                                value={channel.active? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange(index, "active", value)}
                                aria-label="active"
                                className={cx(`${channel.active}`, {
                                    'active': channel.active
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                            
                        </Box>
                        

                        <Box
                            sx={{
                                flex:1,
                                borderRadius:4,
                                display:"flex",
                                height:32,
                                justifyContent:"center",
                            }}
                        >
                        {channel.active && (
                            <ToggleButtonGroup
                                color={channel.syncOrders ? "green" : "red"}
                                value={channel.syncOrders ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange(index, "syncOrders", value)}
                                aria-label="Sync Orders"
                                className={cx(`${channel.syncOrders}`, {
                                    'active': channel.syncOrders
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                            )}  
                        </Box>
                        
                        {warehouses?.data?.length > 1  && (
                            <Box
                                sx={{
                                    flex:1,
                                    borderRadius:4,
                                    display:"flex",
                                    height:32,
                                    justifyContent:"center",
                                }}
                            >
                                {channel.active && (
                                <InputDropdown
                                    height={32}
                                    // name="warehouseId"
                                    displayName="name"
                                    // changeField="id"
                                    selected={warehouses?.data?.findIndex(item => item.id === channel.warehouseId) ?? -1}
                                    options={warehouses?.data ?? []}
                                    noSelection={t("choose_warehouse")} 
                                    onChange={(value) => handleChange(index, "warehouseId", value)}
                                    closeOnSelection
                                />
                                )}
                                {/* <ShValidatedInputDropdown
                                    name="warehouseId"
                                    displayName="name"
                                    changeField="id"
                                    selected={warehouses?.data?.findIndex(item => item.id === channel.warehouseId) ?? -1}
                                    options={warehouses?.data ?? []}
                                    noSelection={t("choose_warehouse")} 
                                    onChange={(e,value) => handleChange(index, "warehouseId", value)}
                                    //error={fieldErrors.warehouseId}
                                /> */}
                            </Box>
                        )}
                    </Box>
                ))}
                </Box>
            </Box>
        </Dropdown>
            </Box>
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
            <GridActions
                    id={props.data.id}
                    action={handleSaveClick}
                    deleteAction={handleDeleteClick}
        />
        <MConfirmDelete
            open={open}
            handleClose={() => setOpen(false)}
            confirm={confirmDelete}
        />
            </Box>
        </>
    );
}

export default Channels;