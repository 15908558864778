// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import NumberInput from '../../../../theme/inputs/NumberInput';
import ShDateRangePicker from "../../../../theme/ShDateRangePicker";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { useDispatch } from 'react-redux';
import * as yup from "yup";
import { yupRequired } from '../../../../../utils/validation';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';



/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    const select = (date) => {
        const formattedDate = date[0].startDate.toISOString().split('T')[0];
        props.handleDataUpdate({ shipmentDate: formattedDate });
    }

    return (
        <>
            <Box 
                sx={{
                    display:'flex',
                    flexDirection:"column",
                    gap:1,
                }}
            >
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("length")} ({t("mm")})</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex"
                        }}
                    >
                        <NumberInput btnSize={"24"}
                                    value={data.length ?? 0}
                                change={(e) => handleInput('length', e)}/>
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("width")} ({t("mm")})</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex"
                        }}
                    >
                        <NumberInput btnSize={"24"}
                                    value={data.width ?? 0}
                                change={(e) => handleInput('width', e)}/>
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("height")} ({t("mm")})</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex"
                        }}
                    >
                        <NumberInput btnSize={"24"}
                                    value={data.height ?? 0}
                                change={(e) => handleInput('height', e)}/>
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("weight")} ({t("gram")})</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex"
                        }}
                    >
                        <NumberInput btnSize={"24"}
                                    value={data.weight ?? 0}
                                change={(e) => handleInput('weight', e)}/>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const DimensionsAndWeight = (props) => {
    const {t} = useTranslation();

    return (
        <ProgressionTab 
            tabId="tabDimensionsAndWeight" 
            subtitle={t("dimensions_and_weight")} 
            step={2}
            maxSteps={2}
        >
            <Content/>
        </ProgressionTab>
    )
}

export default DimensionsAndWeight;

