// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { countriesFromLocale } from '../../../../../utils/countries';
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { countryCodeNL } from '../../../../../utils/constants';


/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    
    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language);

    // Set standard country on init
    if (typeof props.data?.country === "undefined") {
        handleChange('country', countryCodeNL);
    }

    return (
        <>
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("street")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                            name="street"
                            value={data.street}
                            onChange={handleChange}
                            error={props.errors.street}
                        />
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("house_nr")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                            name="house_nr"
                            value={data.house_nr}
                            onChange={handleChange}
                            error={props.errors.house_nr}
                        />
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("house_nr_extra")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <InputBase 
                    sx={{
                        px:2,
                        flex:1, 
                        color:colors.txt["primary"],
                        lineHeight:"32px",
                    }}
                    value={data.house_nr_extra}
                    onChange={(e) => handleChange(props.house_nr_extra, e.target.value)}
                />
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("postal")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                            name="postal"
                            value={data.postal}
                            onChange={handleChange}
                            error={props.errors.postal}
                        />
                    
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("city")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                            name="city"
                            value={data.city}
                            onChange={handleChange}
                            error={props.errors.city}
                        />
                    
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("country")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    
                        <InputDropdown
                            // variant="darker"
                            displayName={"name"} 
                            onChange={(selected) => handleChange('country', countries[selected].alpha2)}
                            noSelection={t("select_country")} //Custom text when nothing is selected
                            options={countries ?? null}
                            selected={countries?.findIndex(country => (props.data?.country && props.data.country === country.alpha2) || (country.alpha2 === countryCodeNL)) ?? -1}
                            closeOnSelection
                        />
                </Box>
            </Box>
        </>
    );
};

const LocationDetails = (props) => {
    const { t } = useTranslation();

    const schema = yup.object({
        street: yupRequired('street',t),
        house_nr: yupRequired('house_nr',t),
        city: yupRequired('city',t),
        postal: yupRequired('postal',t),
    });

    return (
        <ProgressionTab 
        tabId="tabLocationDetails" 
        subtitle={t('location_details')} 
        step={2}
        nextTab={"tabOtherDetails"}
        maxSteps={3}
        schema={schema}
    >
        <Content />
    </ProgressionTab>
    )
}

export default LocationDetails;