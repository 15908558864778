// import { apiSlice } from '../api/apiSlice'
// import 

import { id } from "date-fns/locale";
import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const INBOUND_BASE = "/inbound";
const buildUrl = (endpoint) => INBOUND_BASE + endpoint;

const inboundsApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInbounds: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Inbounds' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getInbounds', 'Inbounds')
        }),
        getInboundLines: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/inboundlines?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (args) => [{ type: 'InboundLines', id: args?.id }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getInboundLines', 'InboundLines')
        }),
        getAllInboundLines: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/inboundlines?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'InboundLines' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getInboundLines', 'InboundLines')
        }),
        getInbound: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (id) => [{ type: `InboundSingle` }]
        }),
        addInbound: builder.mutation({
            query: inboundInfo => ({
                url: buildUrl(`/add`),
                method: 'POST',
                body: inboundInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Inbounds'}],
        }),
        updateInbound: builder.mutation({
            query: inboundInfo => ({
                url: buildUrl(`/${inboundInfo.id}`),
                method: 'PUT',
                body: inboundInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Inbounds' }, { type: `InboundSingle` }],
        }),
        deleteInbound: builder.mutation({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Inbounds' }, { type: `InboundSingle` }],
        }),
        getInboundShipments: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/shipments?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (args) => [{ type: 'InboundShipments', id: args?.id }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getInboundShipments', 'InboundShipments')
        }),
        deleteInboundShipment: builder.mutation({
            query: args => ({
                url: buildUrl(`/${args.inboundId}/shipments/${args.id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'InboundShipments'}],
        }),
        addInboundShipments: builder.mutation({
            query: inboundShipmentsInfo => ({
                url: buildUrl(`/${inboundShipmentsInfo.inboundId}/shipments/add`),
                method: 'POST',
                body: inboundShipmentsInfo.body,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'InboundShipments'}],
        }),
        updateInboundShipment: builder.mutation({
            query: inboundShipmentsInfo => ({
                url: buildUrl(`/${inboundShipmentsInfo.inboundId}/shipments/${inboundShipmentsInfo.id}`),
                method: 'PUT',
                body: inboundShipmentsInfo.body,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'InboundShipments'}],
        }),
    })
});

export const {
    useGetInboundsQuery,
    useGetInboundLinesQuery,
    useGetAllInboundLinesQuery,
    useGetInboundQuery,
    useAddInboundMutation,
    useUpdateInboundMutation,
    useDeleteInboundMutation,
    useGetInboundShipmentsQuery,
    useDeleteInboundShipmentMutation,
    useAddInboundShipmentsMutation,
    useUpdateInboundShipmentMutation
  } = inboundsApiSlice;
