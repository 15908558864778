import * as yup from "yup";

export const yupRequired = (name, t) => {
    return yup
    .string(t(name) + " " + t("validation_required"))
    .trim(t(name) + " " + t("validation_required"))
    .required(t(name) + " " + t("validation_required"))
    .test('not-null', t(name) + " " + t("validation_required"), value => value !== null);
}

export const yupNumberRequired = (name,t) => {
    return yup.number()
    .typeError(`${t(name)} ${t("validation_required")}`)
    .required(t(name) + " " + t("validation_required"))
    .min(1, t('amount_greater_than_zero'));
}


export const yupDecimalRequired = (name, t) => {
    return yup.number()
        .typeError(`${t(name)} ${t("validation_required")}`)
        .required(t(name) + " " + t("validation_required"))
        .positive(t('amount_greater_than_zero'))
        .test('is-decimal', t('invalid_decimal'), value => 
            (value && !/^\d+(\.\d{1,2})?$/.test(value)) ? false : true
        );
}

export const yupEmailRequired = (name,t) => {
    return yup
    .string()
    .trim()
    .required(t(name) + " " + t("validation_required"))
    .email(t("invalid_email"));
    
}

export const yupArrayRequired = (name, t) => {
    return yup
    .array(t(name) + " " + t("validation_required"))
    .required(t(name) + " " + t("validation_required"))
    .test('not-null', t(name) + " " + t("validation_required"), value => value !== null);
}

export const yupDateRequired = (name, t) => {
    return yup.date()
    .required(t(name) + " " + t("validation_required"))
    .test('not-null', t(name) + " " + t("validation_required"), value => value !== null);
}
