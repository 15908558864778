import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import ContactsIcon from '@mui/icons-material/Contacts';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import RelationContacts from "./Views/RelationContacts";
import WebshopCredentials from "./Views/WebshopCredentials";
import Financial from "./Views/Financial";
import Stock from "./Views/Stock";
import Palletspots from "./Views/Palletspots";
import { useTranslation } from 'react-i18next';
import DetailsPanel from "./Views/Details";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spRelationDetailsConfig } from "./spRelationDetailsConfig";
import { useDeleteRelationMutation, useGetRelationQuery } from "../../../../newapi/global/relation/relationSlice";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import MConfirmDelete from "../../Modals/MConfirmDelete";
import { showToastMessageDeleteRequest } from "../../../../utils/toasts";

import DeleteIcon from '@mui/icons-material/Delete';
import ButtonGroup from "../../../theme/buttons/ButtonGroup";
import MAddRelationLogo from "../../Modals/MAddRelationLogo";

const SidePanelActions = (props) => {
    const {t} = useTranslation();
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>
            <ButtonGroup version={2} isSidepanel>
                <Shbutton
                    block
                    className={"group-btn"}
                    onClick={props.deleteAction}
                    color="red"
                    variant="contained"
                    endIcon={<DeleteIcon />}
                >
                    {t('delete')}
                </Shbutton>
            </ButtonGroup>

        </SidepanelHeaderActiongroup>
    )
}

const SpRelationDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [logoOpen,setLogoOpen] = useState(false);

    const { data: relationData, isLoading,isFetching,isError } = useGetRelationQuery(props.relationId,
    {
        skip: !props.relationId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ContactsIcon/>,
        onClick: () => { console.log("called");  setLogoOpen(true)},
    }), [theme.palette.mode,props.relationId]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t("phone")} /> },
        { component: <ShRowSkeleton heading={t("email")} /> },
        { component: <ShRowSkeleton heading={t("website")} /> },
    ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback}/>   
    }), [defaultIcon,defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.relationId,theme.palette.mode])
    

    const [DeleteRelationMutation] = useDeleteRelationMutation();
    const confirmDelete = async () => {
        try {
            const response = await DeleteRelationMutation({id: props.data.id}).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };
    const handleDeleteClick = async () => {
        setOpen(true);
    };

    const confirmDeleteModal = {
        data: relationData?.name,
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }

    useEffect(() => {
        if (!isLoading && !isFetching && relationData) {
            const options = [
                {
                    heading: t('phone'),
                    content: relationData.phone
                },
                {
                    heading: t('email'),
                    content: relationData.email
                },
                {
                    heading: t('website'),
                    content: relationData.website
                },
            ];

            if (relationData.invoiceCategory) {
                options.push({
                    heading: t('category'),
                    content: relationData.invoiceCategory.name
                });
            }

            setPanel(prevPanel => ({
                ...prevPanel,
                title: relationData.name,
                subTitle: [""],
                options: options,
                actions: <SidePanelActions setIsExtended={setIsExtendedCallback} deleteAction={handleDeleteClick}/>
            }));
        }
    }, [relationData, isLoading, isFetching, t,theme.palette.mode]);


    return (
        <>
            <Sidepanel2
                skeleton={isLoading || isFetching}
                tabConfig={spRelationDetailsConfig}
                content={panel} 
                isExtended={props.isExtended} 
                setIsExtended={props.setIsExtended}>
                    <>
                        <DetailsPanel config={spRelationDetailsConfig.details} data={relationData} setIsExtended={props.setIsExtended} />
                        <RelationContacts config={spRelationDetailsConfig.relationContacts} relationId={props.relationId}/>
                        <WebshopCredentials config={spRelationDetailsConfig.webshopCredentials} relationId={props.relationId} />
                        <Stock config={spRelationDetailsConfig.stock} data={relationData} setIsExtended={props.setIsExtended} />
                        <Financial config={spRelationDetailsConfig.financial} relationId={props.relationId} />
                        <Palletspots config={spRelationDetailsConfig.palletspots} relationId={props.relationId} />
                    </>
            </Sidepanel2>
            <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />

            <MAddRelationLogo
                open={logoOpen}
                handleClose={() => setLogoOpen(false)}
                relationId={props.relationId}
            />
        </>
    );
};

export default SpRelationDetails;