import React, { useState } from 'react';
import {Box, Button, ButtonBase, IconButton, styled, useTheme} from "@mui/material";
import { useContext } from 'react';
// import { ColorModeContext, tokens } from '../../../theme';
import InputBase from '@mui/material/InputBase';
import  LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import  DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import  NotificationsOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import  SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import  PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import  SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu'; 
import { MenuStateContext } from '../../contexts/MenuState';
import { tokens } from '../../theme';
import Text from '../theme/text/Text';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MSelectWarehouses from './Modals/MSelectWarehouses';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import TopbarActionModal from './Modals/MTopbarAction';
import { useSelector, useDispatch } from "react-redux";
import { updateActive } from '../../newapi/warehouse/warehouseSlice';
import WarehousePicker from './WarehousePicker';
import { useTranslation } from 'react-i18next';
import { selectAuthStatus } from '../../newapi/user/authentication/authSlice';
import { ENV_TYPES } from './Sidebar';
import { toggleMenu } from '../../newapi/global/settingsSlice';
// import { MenuStateContext } from '../../../contexts/MenuState';


const TopbarIconButton = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (

        <Button
            onClick={props.onClick}
            TouchRippleProps={{ 
                style: { 
                    color: theme.palette.mode === "dark" ? "rgba(255,255,255,.5)" : 'rgba(49, 65, 95, 0.5)' 
                } 
            }} // Directly styling the ripple here
            variant='contained'
            sx={{
                background:theme.palette.mode === "dark" ? colors.nav["linkBg"]  : "#fff",
                width:40,
                height:40,
                borderRadius:20,
                padding:0,
                minWidth:0,
                boxShadow:"none",
                display:"flex",
                alignItems:"center",
                transition:"250ms",
                color: colors.txt["primary"],
                opacity:1,
                "&:hover" : {
                    transition:"250ms",
                    background:theme.palette.mode === "dark" ? colors.grey[300] : "white",
                    boxShadow:"0px 2px 8px rgba(49, 65, 95, 0.25)"
                },
                "@media screen and (max-width: 47.9375em)" : {
                    width:32,
                    height:32,
                    borderRadius:16
                }
            }}
        >
            {props.children}

        </Button>
    )
}

const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    // const {setCollapsed} = useContext(MenuStateContext);


    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const filterPopoverOpen = Boolean(filterAnchorEl);
    const filterPopoverId = filterPopoverOpen ? 'simple-popover' : undefined;

    const handleFilterPopoverClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterPopoverClose = () => {
        setFilterAnchorEl(null);
        // setExportAnchorEl(null);
    };

    const changeMenuState = () => {
        dispatch(toggleMenu());
    }
    
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);

    return (
        <Box 
            className="topbar" 
            display="flex" 
            justifyContent="space-between" 
            paddingX={4} paddingY={2}
            sx={{
                background:colors.bg["tertiary"],
                "@media screen and (max-width: 47.9375em)" : {
                    paddingX:2,
                    paddingRight:0,
                }
            }}    
        >

            <IconButton type="button" onClick={changeMenuState} sx={{p:1}}>
                <MenuIcon/>
            </IconButton>


            {/* Icons */}
            <Box 
                display="flex"
                gap={2}
                sx={{
                    "@media screen and (max-width: 47.9375em)" : {
                        alignItems:"center",
                    }
                }}
            >

                { /* Searchbar */}
                {/* <Box display="flex"
                    borderRadius="20px"
                    backgroundColor={colors.grey[200]}
                    sx={{
                        "@media screen and (max-width:47.9375em)" : {
                            display:"none"
                        }
                    }}
                >
                    <InputBase sx={{ml: 2, flex:1}} placeholder={t("search")} />
                    <IconButton type="button" sx={{p: 1}}>
                        <SearchIcon />
                    </IconButton>
                </Box> */}
                {selectedEnvironmentType == ENV_TYPES[100] &&
                    <WarehousePicker phone={false} />
                }

                {/* <IconButton
                    sx={{
                        display:"none",
                        "@media screen and (max-width:47.9375em)" : {
                            display:"flex"
                        }
                    }}
                >
                    <SearchIcon />
                </IconButton> */}

                {/* <TopbarIconButton>
                    <NotificationsOutlinedIcon />
                </TopbarIconButton> */}

                <TopbarIconButton
                    onClick={handleFilterPopoverClick}
                >
                    <SettingsOutlinedIcon />
                </TopbarIconButton>

                <TopbarActionModal
                    id={filterPopoverId}
                    open={filterPopoverOpen}
                    anchorEl={filterAnchorEl}
                    onClose={handleFilterPopoverClose}
                >

                </TopbarActionModal>

                {/* <IconButton 
                color='red'
                onClick={toggleColor}>
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton> */}
                {/* <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton> */}
                {selectedEnvironmentType === ENV_TYPES[100] &&
                    <WarehousePicker desktop={false} />
                }
            </Box>
            
        </Box>
    );
};

export default Topbar;