import { Box, IconButton, InputBase, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateProductMutation } from "../../../../../newapi/warehouse/productSlice";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import NumberInput from "../../../../theme/inputs/NumberInput";
import SpDesktopFooter from "../../../../theme/sidepanel2/SidepanelFooter";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const AvailableStock = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const  { t } = useTranslation();

    const [productInfo, setProductInfo] = useState({
        active: false,
        sku: '',
        barcode: '',
        secondarySku: '',
        safetyStock: 0,
        food: false,
        fragile: false,
        dangerous: false,
        informShipper: false,
        highcare: false,
        enterDimensionsAfterInbound: false,
        DaWBlockedByWarehouse: false,
        quarantineAfterReturn: false,
        lotPossible: false,
        lotRequired: false,
        batchPossible: false,
        batchRequired: false,
        customInboundSettings: false,
        inboundProcessType: '',
        repackingRequired: false,
        directReplenish: false,
        maxStockPickingLocation: 0,
        maxStockBulkLocation: 0,
        amountInCase: 0,
        amountOnPallet: 0
    });
    
    useEffect(() => {
        setProductInfo(props.data);
    },[props.data]);

    const handleInputChange = (value, field) => {
        setProductInfo({ ...productInfo, [field]: value });
    };

    const [UpdateProduct,{isLoading}] = useUpdateProductMutation();
    const handleSaveClick = async () => {
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                const data = await UpdateProduct(productInfo).unwrap()
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("update_failed"), t, err);
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }   
        }        
    };


    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions 
                            id={props.data?.id}
                            action={handleSaveClick} />
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[productInfo,props.data]);

    return (
        <Box display={"flex"} flexDirection={"column"} paddingX={3} overflow={"auto"}
            sx={{
                "@media screen and (min-width: 47.9375em)"  :{
                    marginBottom:11,
                }
            }}
        >
            <Dropdown title={t("available_stock")} alwaysOpen>
                <Box
                    sx={{
                        display:"flex",
                        // alignItems:"center",
                        flexDirection:"column",
                        gap:2,
                        // gap:4,
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column",
                            gap:1
                        }
                    }}
                >
                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('all_stock')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockQuantity ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;({t('pcs')})
                            </Text>
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box> */}
                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('in_order')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockInOrder ?? 0}
                            </Text>

                            <Text italic>
                                &nbsp;({t('pcs')})
                            </Text>
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box> */}

                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >

                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                        <Text light>
                            {t('pickingprocess')}
                        </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockPickingprocess ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;({t('pcs')})
                            </Text>
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box> */}

                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >

                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('blocked')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockBlocked ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;({t('pcs')})
                            </Text>
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box> */}

                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('quarantine')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockQuarantine ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;({t('pcs')})
                            </Text>
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box> */}

                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('reserved')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockReserved ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;({t('pcs')})
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width:210,
                            mt:"-16px",
                            fontSize:25,
                            // mb:"-15px",
                            display:"flex",
                            justifyContent:"flex-end",
                            alignItems:"flex-end",
                            borderBottom:`2px solid ${colors.grey[200]}`,
                            // background:"orange",
                            "@media screen and (max-width: 47.9375em)" : {
                                width:1,
                                mt:1,
                                mb:1,
                            //     display:"none"
                            }
                        }}
                    >
                        <Box
                            sx={{
                                mb:1.5,
                                width:12,
                                height:3,
                                background:colors.grey[400],
                            }}
                        ></Box>
                    </Box>

                    <Box 
                        display={"flex"} 
                        // flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>
                                {t('available')}
                            </Text>
                        </Box>
                        <Box>
                            <Text bold>
                                {productInfo?.totalStockAvailable ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;({t('pcs')})
                            </Text>
                        </Box>
                    </Box>
                </Box>

            </Dropdown>
            <Dropdown title={t("extra_options")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                
                {/* Two columns */}
                
                    <Box flex={1}
                        sx={{
                            display:"flex",
                            gap: 1.5,
                            justifyContent:"flex-start",
                            flexDirection:"column"
                        }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                                gap:2,
                                alignItems:"center",
                            }}
                        >
                            <Box 
                                sx={{
                                    width:240,
                                    display:"flex",
                                    alignItems:"center",
                                }}
                            >
                                <Text noBreak>{t("safety_stock")}</Text>
                            </Box>
                            <Box display="flex"
                                height="40px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                sx={{
                                    flex:1,
                                    minWidth:140,
                                }}
                            >
                                <NumberInput
                                    value={productInfo.safetyStock ?? 0}
                                    change={(e) => handleInputChange(e, 'safetyStock')}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display:"flex",
                                gap:2,
                                alignItems:"center",
                            }}
                        >
                            <Box 
                                sx={{
                                    width:240,
                                    display:"flex",
                                    alignItems:"center",
                                }}
                            >
                                <Text noBreak>{t("max_stock_picking_location")}</Text>
                            </Box>
                            <Box display="flex"
                                height="40px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                sx={{
                                    flex:1,
                                    minWidth:140,
                                }}
                            >
                                <NumberInput
                                    value={productInfo.maxStockPickingLocation ?? 0}
                                    change={(e) => handleInputChange(e, 'maxStockPickingLocation')}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display:"flex",
                                gap:2,
                                alignItems:"center",
                            }}
                        >
                            <Box 
                                sx={{
                                    width:240,
                                    display:"flex",
                                    alignItems:"center",
                                }}
                            >
                                <Text noBreak>{t("max_stock_bulk_location")}</Text>
                            </Box>
                            <Box display="flex"
                                height="40px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                sx={{
                                    flex:1,
                                    minWidth:140,
                                }}
                            >
                                <NumberInput
                                    value={productInfo.maxStockBulkLocation ?? 0}
                                    change={(e) => handleInputChange(e, 'maxStockBulkLocation')}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display:"flex",
                                gap:2,
                                alignItems:"center",
                            }}
                        >
                            <Box 
                                sx={{
                                    width:240,
                                    display:"flex",
                                    alignItems:"center",
                                }}
                            >
                                <Text noBreak>{t("amount_in_case")}</Text>
                            </Box>
                            <Box display="flex"
                                height="40px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                sx={{
                                    flex:1,
                                    minWidth:140,
                                }}
                            >
                                <NumberInput
                                    value={productInfo.amountInCase ?? 0}
                                    change={(e) => handleInputChange(e, 'amountInCase')}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display:"flex",
                                gap:2,
                                alignItems:"center",
                            }}
                        >
                            <Box 
                                sx={{
                                    width:240,
                                    display:"flex",
                                    alignItems:"center",
                                }}
                            >
                                <Text noBreak>{t("amount_on_pallet")}</Text>
                            </Box>
                            <Box display="flex"
                                height="40px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                sx={{
                                    flex:1,
                                    minWidth:140,
                                }}
                            >
                                <NumberInput
                                    value={productInfo.amountOnPallet ?? 0}
                                    change={(e) => handleInputChange(e, 'amountOnPallet')}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dropdown>
            <SpDesktopFooter>
                <GridActions 
                id={props.data?.id}
                action={handleSaveClick} />
            </SpDesktopFooter>
        </Box>
    );
}

export default AvailableStock;