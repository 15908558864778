import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";


//import Echeck from "./views/Echeck";
import { useTranslation } from "react-i18next";
import { InboundChart } from "../../../components/charts/dashboard/fulfillment/InboundChart";
import { OrderHistoryChart } from "../../../components/charts/dashboard/fulfillment/OrderHistoryChart";
import { OrderlinesChart } from "../../../components/charts/dashboard/fulfillment/OrderlinesChart";
import { OrdersChart } from "../../../components/charts/dashboard/fulfillment/OrdersChart";
import { ReturnChart } from "../../../components/charts/dashboard/fulfillment/ReturnChart";
import { useGetDashboardOrderHistoryQuery } from "../../../newapi/reports/fulfillment/reportFulfillmentSlice";

const Dashboard = () => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const {data: orderHistoryData, isLoading: isDashboardOrderHistoryLoading, isFetching: isDashboardOrderHistoryFetching, isError: isDashboardOrderHistoryError, error: dashboardOrderHistoryError} = useGetDashboardOrderHistoryQuery();
    

    return (
        <Box sx={{background:colors.bg['tertiary'], width:1,paddingTop:9,paddingX:4,}}>
            {ready ?
                <Box>
                    <Box
                        sx={{
                            display:'grid',
                            gridTemplateColumns: '2fr 4fr',
                            gap:3,
                            "@media screen and (max-width: 1024px)" : {
                                gridTemplateColumns: "2fr 2fr",
                            },
                            "@media screen and (max-width: 768px)" : {
                                gridTemplateColumns: "repeat(2, 1fr)"
                            }
                        }}
                    >
                        <OrdersChart/>
                        <OrderHistoryChart/>
                    </Box>
                    <Box
                        sx={{
                            mt:3,
                            display:'grid',
                            gridTemplateColumns: '2fr 4fr',
                            gap:3,
                            "@media screen and (max-width: 1024px)" : {
                                gridTemplateColumns: "repeat(2, 1fr)"
                            }
                        }}
                    >
                        <OrderlinesChart/>
                        <Box
                            sx={{
                                display:"grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                // gridTemplateRows:1,
                                gap:3,
                                "@media screen and (max-width: 1024px)" : {
                                    gridArea: "2 / 1 / 3 / 3",
                                },
                                "@media screen and (max-width: 768px)" : {
                                    // flexWrap: "wrap"
                                    gridTemplateColumns:"1fr",
                                    gridTemplateRows:"repeat(2, 1fr)",
                                }
                            }}
                        >
                            <InboundChart/>
                            <ReturnChart/>

                        </Box>

                    </Box>
                </Box>
            :null}
        </Box>
    );
};

export default Dashboard;