import { Box, useTheme } from "@mui/material";
import {
  useEffect,
  useState,
} from "react";
import { tokens } from "../../../../../../theme";
import Text from "../../../../../../components/theme/text/Text";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useTranslation } from "react-i18next";
import Subheading from "../../../../../../components/theme/text/Subheading";
import DropdownSetting from "../../../../../../components/global/Settings/DropdownSetting";
import NumberSetting from "../../../../../../components/global/Settings/NumberSetting";
import BoolSetting from "../../../../../../components/global/Settings/BoolSetting";
import { useGetSettingsQuery } from "../../../../../../newapi/global/generalApiSlice";
import { useGetEnumsQuery } from "../../../../../../newapi/global/generalApiSlice";
import { useGetInvoiceProductsSelectQuery } from "../../../../../../newapi/financial/invoiceProductSlice";

const Invoicing = ( props ) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [view, setView] = useState({
    title: 'invoicing',
    icon: <ReceiptLongIcon />,
  });

  const { data: settings} = useGetSettingsQuery("invoicing");
  const { data: invoiceProducts } = useGetInvoiceProductsSelectQuery();

  useEffect(() => {
    props.replaceView({
        ...props.config,
    },);

    return () => {};
  }, []);

  const palletspotOptions = [
    {id: "palletspot", value: "palletspot", name: t("palletspot")},
    {id: "volume", value: "volume", name: t("volume")},
  ];

  const { data: globalEnums, enumIsLoading } = useGetEnumsQuery("time_based_frequencies");

  return (
    <Box
      sx={{
        height: 1,
        "@media screen and (max-width: 47.9375em)": {
          paddingTop: 0,
          paddingX: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
          gap: 4,
          overflow: "hidden",
          background:
            theme.palette.mode === "dark"
              ? colors.grey[300]
              : colors.bg["tertiary"],
          paddingX: 2,
          paddingTop: 4,
          height: 1,
          position: "relative",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          "@media screen and (max-width: 47.9375em)": {
            paddingTop: 0,
            paddingX: 0,
            background: colors.bg["tertiary"],
          },
          "& .carrier": {
            paddingLeft: 0,
            marginRight: 2,
          },
          "& [aria-label='Carrier']": {
            // background:"purple",
            paddingLeft: 0,
            marginRight: 2,
          },
        }}
      >
        <Box
          sx={{
            paddingX: 3,
            "@media screen and (max-width: 47.9375em)": {
              display: "none",
            },
          }}
        >
          <Subheading>{t("core_invoicing_title")}</Subheading>
          <Text light>{t("core_invoicing_subtitle")}</Text>
        </Box>
        {/* <GridActions></GridActions> */}
        <Box
          sx={{
            background:
              theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
            flex: 1,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            "@media screen and (max-width: 48em)": {
              // display:"none"
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[200]
                  : colors.grey[0],
            },
          }}
        >
        <Box
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[400]
                  : colors.grey[0],
              padding: 3,
              display:"flex",
              flexDirection:"column",
              gap:1,
            }}
          >
            <BoolSetting settings={settings} skey="invoicing_auto_invoice_palletspots"/>
          </Box>
        <Box
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[400]
                  : colors.grey[0],
              padding: 3,
              display:"flex",
              flexDirection:"column",
              gap:1,
            }}
          >
            <DropdownSetting 
            settings={settings} 
            objects={palletspotOptions} 
            noselection={"choose_type"}
            variant={"default"} 
            skey="invoicing_palletspot_type"
            />
          </Box>
        <Box
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[400]
                  : colors.grey[0],
              padding: 3,
              display:"flex",
              flexDirection:"column",
              gap:1,
            }}
          >
            <DropdownSetting 
            settings={settings} 
            objects={globalEnums ? globalEnums["time.based.frequencies"].map((item, index) => ({ id: item, value: item, name: t(item) })) : []} 
            noselection={"choose_option"}
            variant={"default"} 
            skey="invoicing_palletspot_frequency"
            />
          </Box>
        <Box
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[400]
                  : colors.grey[0],
              padding: 3,
              display:"flex",
              flexDirection:"column",
              gap:1,
            }}
          >
            <DropdownSetting 
            valueKey={"id"}
            settings={settings} 
            searchOptions={{
                enabled: true,
                placeholder: t("search_invoice_products")
            }}
            objects={invoiceProducts ?? []} 
            noselection={"choose_option"}
            variant={"default"} 
            skey="invoicing_palletspot_standard_product_id"
            />
          </Box>
        <Box
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[400]
                  : colors.grey[0],
              padding: 3,
              display:"flex",
              flexDirection:"column",
              gap:1,
            }}
          >
          <NumberSetting maxWidth={240} settings={settings} variant={"default"} skey="invoicing_palletspot_volume_divisor"/>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Invoicing;
