// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import cx from "classnames"
import { useGetRelationsQuery } from '../../../../../newapi/global/relation/relationSlice';
import ShValidatedInputDropdown from '../../../../theme/dropdowns/ShValidatedInputDropdown';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const dispatch = useDispatch();
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    const [webshopCredentialsInfo, setWebshopCredentialsInfo] = useState(data.credentials);

    const handleCredentialsChange = (key, val) => {
        console.log(key, val);
        setWebshopCredentialsInfo({
            ...webshopCredentialsInfo,
            [key]: val
        });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    }

    useEffect(() => {
        props.handleDataUpdate({ credentials: webshopCredentialsInfo });
    }, [webshopCredentialsInfo])

    // Fetch relations using the useGetRelationsQuery hook
    const { data: relationsData, isLoading: relationsLoading } = useGetRelationsQuery({
        args: { page: 1, size: 100, body: {} }, // Adjust the args as needed
    });

    return (
        <div>
            { isFulfilment ? 
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("relation")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                     <ShValidatedInputDropdown
                            name="relationId"
                            displayName="name"
                            changeField="id"
                            selected={relationsData?.data?.findIndex(relation => relation.id === data?.relationId) ?? 0}
                            noSelection={t("choose_relation")} //Custom text when nothing is selected
                            options={relationsData?.data ?? []}
                            onChange={handleInput}
                        />
                    
                </Box>
            </Box>
             : 
             <></>}
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("description")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                            name="description"
                            value={data.description ?? ""}
                            onChange={handleInput}
                            error={props.errors.description}
                        />
                    
                </Box>
            </Box>
            {Object.keys(webshopCredentialsInfo)
            .filter(key => key !== "measuring_units")
            .map((key) => (
                <Box 
                    key={key}
                    sx={{
                        width: 1,
                        paddingTop: 1.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 7
                    }}
                >
                    <Box
                        sx={{
                            width: 120,
                        }}
                    >
                        <Text>{t(key)}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex: 1,
                            //backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            borderRadius: 4,
                            display: "flex",
                            height: 32
                        }}
                    >
                        {key.includes('_bool') ? (
                            <ToggleButtonGroup
                                color={webshopCredentialsInfo[key] === 'true' ? "green" : "red"}
                                value={webshopCredentialsInfo[key] === 'true' ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleCredentialsChange(key, value)}
                                aria-label="Active"
                                className={cx(`${webshopCredentialsInfo[key]}`, {
                                    'active': webshopCredentialsInfo[key] === 'true'
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        ) : (
                            <ShValidatedInput
                                name={key}
                                value={webshopCredentialsInfo[key] ?? ""}
                                onChange={handleCredentialsChange}
                                error={props.errors[key]}
                            />
                        )}
                    </Box>
                </Box>
            ))}
    </div>
    );
};

const Credentials = (props) => {
    const data = props.data;
    const { t } = useTranslation();

    // let schema = {
    //     description: yupRequired('description', t),
    // };

    // if (data && data.webshop_type === "woocommerce") {
    //     schema = {
    //         ...schema,
    //         url: yupRequired('url', t),
    //         consumer_key: yupRequired('consumer_key', t),
    //         consumer_secret: yupRequired('consumer_secret', t),
    //     };
    // } else if (data && data.webshop_type === "shopify") {
    //     schema = {
    //         ...schema,
    //         shop: yupRequired('shop', t),
    //         client_key: yupRequired('client_key', t),
    //         access_token: yupRequired('access_token', t),
    //     };
    // }
    return (
        <ProgressionTab 
        tabId="tabCredentials" 
        subtitle={t("webshopcredentials")} 
        step={2}
        nextTab={"tabMeasurementUnits"}
        maxSteps={4}
        // schema={schema}
    >
        <Content />
    </ProgressionTab>
    )
}

export default Credentials;

