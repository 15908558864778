import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

// import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Channels from "./Views/Channels";
import Logs from "./Views/Logs";
import { useTranslation } from 'react-i18next';
import Details from ".//Views/Details";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spCarrierDetailsConfig } from "./spCarrierDetailsConfig";
import { useGetCarrierQuery } from "../../../../newapi/carrier/carrierSlice";
import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpCarrierDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

    const { data: carrierData, error, isLoading,isFetching } = useGetCarrierQuery(
        props.carrierId,
         {
             skip: !props.carrierId
         }
     );


    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <LocalShippingIcon/>
    }), [theme.palette.mode]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        actions: <SidePanelActions setIsExtended={setIsExtendedCallback} />
    }), [defaultIcon, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.carrierId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && carrierData) {
            // let locations = carrierData.stocks.map(stock => stock.warehouseLocation.location);
            // let combinedLocations = locations.join(", ");

            setPanel(prevPanel => ({
                ...prevPanel,
                title: carrierData.name,
                subTitle: `${carrierData.length} x ${carrierData.width} x ${carrierData.height}`,
                icon: defaultIcon,
            }));
        }
    }, [carrierData, isLoading, isFetching, t,theme.palette.mode]);

    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spCarrierDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spCarrierDetailsConfig.details} data={carrierData} setIsExtended={props.setIsExtended}/>
                    {/* { <Barcodes id={props.data ? props.data.id : -1} apiRef={barcodesApiRef}/> } */}
                    {/* <Channels config={spCarrierDetailsConfig.channels} id={props.carrierId} />  */}
                     <Logs config={spCarrierDetailsConfig.logs} id={props.carrierId}/> 
                </>
        </Sidepanel2>
    );
};

export default SpCarrierDetails;