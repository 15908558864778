import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import { useGetPalletspotsQuery } from "../../../../../newapi/warehouse/palletSpotSlice";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import Text from "../../../../theme/text/Text";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const GridActions = (props) => {
    return <></>;
}

const Palletspots = (props) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({relationId: props.relationId});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions/>,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    

    const columns = [
        {
            field: "date", 
            headerName: t('date'),
            flex:2, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = new Date(params.row.date);
              
                return (
                    <Text>
                        {date.toLocaleDateString(i18n.language)}
                    </Text>
                );
            }
        },
        {
            field: "totalLocations", 
            headerName: t('total_physical_locations'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.totalLocations
            }
        },
        {
            field: "invoicablePalletspots", 
            headerName: t('total_invoicable_locations'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.invoicablePalletspots
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return <></>
            }
        },
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetPalletspotsQuery}
                config={config}
                gridActions={<GridActions></GridActions>} 
                title={t('palletspots')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>
        </Box>
    );
}

export default Palletspots;