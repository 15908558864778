// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { tokens } from '../../../../../theme';
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { yupRequired, yupNumberRequired } from '../../../../../utils/validation';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { useGetRelationsSelectQuery } from "../../../../../newapi/global/relation/relationSlice";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { ENV_TYPES } from "../../../Sidebar";

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const { data: relationData, relationIsLoading } = useGetRelationsSelectQuery();

    // Get values to determine if the relation dropdown should be shown
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (
        <>
            <Box 
                sx={{
                    display:'flex',
                    flexDirection:"column",
                    gap:1.5,
                }}
            >
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("sku")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex"
                    }}
                >
                    <ShValidatedInput
                        name="sku"
                        value={data.sku}
                        onChange={handleInput}
                        error={props.errors.sku}
                    />
                </Box>
            </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("barcode")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex"
                        }}
                    >
                        <ShValidatedInput
                            name="barcode"
                            value={data.barcode}
                            onChange={handleInput}
                            error={props.errors.barcode}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("description")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex"
                        }}
                    >
                        <ShValidatedInput
                            name="description"
                            value={data.description}
                            onChange={handleInput}
                            error={props.errors.description}
                        />
                    </Box>
                </Box>
                {!isRelation && isFulfilment && (
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("relation")}</Text>
                    </Box>
                
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex"
                        }}
                    >
                        <ShValidatedInputDropdown
                            key={data.relationId}
                            displayName={"name"} 
                            changeField={"id"}
                            valueKey={"id"}
                            closeOnSelection
                            searchOptions={{
                                enabled: true,
                                placeholder: t("search_relation_placeholder")
                            }}
                            styles={{
                                minWidth:300
                            }}

                            disallowDeselection
                            name="relationId"
                            selected={relationData?.find(item => item.id === data.relationId)?.id ?? -1}
                            options={relationData ? relationData : []}
                            noSelection={t("choose_option")} 
                            onChange={handleInput}
                            error={props.errors.relationId}
                        />
                    </Box>
                </Box>
                )}
            </Box>
        </>
    );
};

const Details = (props) => {
    // Get values to determine if the relation dropdown should be shown
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];
    
    const {t} = useTranslation();
    const schema = yup.object({
        sku: yupRequired('sku',t),
        barcode: yupRequired('barcode',t),
        description: yupRequired('description',t),
        relationId: !isRelation && isFulfilment ? yupNumberRequired('relation',t) : null,
    });

    return (
        <ProgressionTab 
            tabId="tabDetails" 
            subtitle={t("Details")} 
            step={1}
            nextTab={"tabDimensionsAndWeight"}
            maxSteps={2}
            schema={schema}
        >
            <Content/>
        </ProgressionTab>
    )
}

export default Details;

