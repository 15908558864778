import { Box, Button, Divider, FormControl, IconButton, InputBase, InputLabel, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';

import Text from "../../../../theme/text/Text";

import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";

import NumberInput from "../../../../theme/inputs/NumberInput";

import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditStreamMutation, useGetPickingprocesstypesQuery } from "../../../../../newapi/global/stream/streamSlice";

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

import { showToastMessageRequestError, showToastMessageUpdateRequest, showToastMessageDeleteRequest, showToastMessage, showToastMessagePostRequest } from "../../../../../utils/toasts";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { useGetReachTypesSelectQuery } from "../../../../../newapi/warehouse/reachTypeSlice";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedNumberInput from "../../../../theme/inputs/ShValidatedNumberInput";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired, yupNumberRequired } from "../../../../../utils/validation";
import DeleteIcon from '@mui/icons-material/Delete';
import { KeyboardReturn } from "@mui/icons-material";
import MConfirmationWithValue from "../../../Modals/MConfirmationWithValue";
import MConfirmDelete from "../../../Modals/MConfirmDelete";
import { useGetWarehousesSelectQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";


const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            {props.state === 'active' ? (
                <Shbutton
                    className={"group-btn"}
                    onClick={props.deactivateAction}
                    color="red"
                    variant="contained"
                    endIcon={<DeleteIcon />}
                >
                    {t('deactivate')}
                </Shbutton>
            ) : (
                <Shbutton
                    className={"group-btn"}
                    onClick={props.activateAction}
                    color="green"
                    variant="contained"
                    endIcon={<KeyboardReturn />}
                >
                    {t('activate')}
                </Shbutton>
            )}
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [streamInfo, setStreamInfo] = useState({
      id: 0,
      name: "",
      shortName: "",
      minWeight: 0,
      minLngth: 0,
      minWidth: 0,
      minHeight: 0,
      minVolume: 0,
      maxWeight: 0,
      maxLength: 0,
      maxWidth: 0,
      maxHeight: 0,
      maxVolume: 0,
      deliverytimePriority: false,
      shipperId: 0,
      reachTypeId : 0,
      pickingprocesstypes: "",
      maxPickingOrderAmount: 0,
      warehouseId: 0
    });

    const { data: pickingprocesstypes } = useGetPickingprocesstypesQuery();
    const { data: reachtypes } = useGetReachTypesSelectQuery();
    const { data: warehouses } = useGetWarehousesSelectQuery();

    useEffect(() => {
        setStreamInfo(props.data);
    },[props.data]);
    

    const handleChange = (key, value) => {
        setStreamInfo({
          ...streamInfo,
          [key]: value
        });
      };

      
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={streamInfo}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[streamInfo]);

    // useEffect(() => {
    //     if (props) {
    //         props.addView({
    //             ...view,
    //         },true);
    //     } 
    // }, [props]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        console.log("hello");
        setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        shortName: yupRequired('short_name',t),
        maxWeight: yupNumberRequired('max_weight',t),
        maxLength: yupNumberRequired('max_length',t),
        maxWidth: yupNumberRequired('max_width',t),
        maxHeight: yupNumberRequired('max_height',t),
        maxVolume: yupNumberRequired('max_volume',t),
        reachTypeId: yupNumberRequired('reachtype',t),
        pickingprocesstype: yupRequired('pickingprocesstype',t),
        warehouseId: yupNumberRequired('warehouseId',t)
    };
    
    const schema = yup.object().shape(rules);
    
    const [EditStream,{isLoading}] = useEditStreamMutation();
    const handleSaveClick = async () => {
        const canSave = true;
        if(canSave){    
            try {
                setFieldErrors({});
                await schema.validate(streamInfo, { abortEarly: false });
                const data = await EditStream(streamInfo).unwrap()
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                if (err.name === 'ValidationError') {
                    // Create an errors object where keys are field names and values are error messages
                    const errors = err.inner.reduce((acc, currentError) => ({
                        ...acc,
                        [currentError.path]: currentError.message
                    }), {});
                
                    // Set the validation errors in the state
                    setFieldErrors(errors);
                } else {
                    // Handle other errors, such as network errors
                    showToastMessageRequestError(t("update_failed"), t, err);
                }
            }
        } else {
            if(!isLoading) {
                  showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
            }    
        }        
    };

    const confirmDeactivate = async () => {
        try {
            const response = await EditStream({id: props.data.id, state: 'inactive'}).unwrap();
            showToastMessage("success", t("stream_deactivated_successfully"));
            props.setIsExtended(false);
        } catch (err) {
            showToastMessagePostRequest(t, {success: false, error: err.error});
        }
    };
    const confirmActivate = async () => {
        try {
            const response = await EditStream({id: props.data.id, state: 'active'}).unwrap();
            showToastMessage("success", t("stream_activated_successfully"));
            props.setIsExtended(false);
        } catch (err) {
            showToastMessagePostRequest(t, {success: false, error: err.error});
        }
    };
    const handleDeactivateClick = async () => {
        setDeactivateOpen(true);
    };
    const handleActivateClick = async () => {
        setOpen(true);
    };
    const [open, setOpen] = useState(false);
    const [deactivateOpen, setDeactivateOpen] = useState(false);
    const confirmDeactivateModal = {
        open: deactivateOpen,
        setOpen: setDeactivateOpen,
        confirm: confirmDeactivate
    } 
    
    const confirmActivateModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmActivate,
        data: {
            title: t("activate_stream"),
            subtitle: t("activate_stream_confirmation"),
            content: t("are_you_sure_you_want_to_activate_this_stream")
        }
    } 

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                overflow:'auto',
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("basic")} open>
                <Box sx={{
                    display:"flex",
                    // justifyContent:"space-between",
                    // flexDirection:"column",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    
                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start",
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("name")}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <ShValidatedInput
                                    name="name"
                                    value={streamInfo?.name}
                                    onChange={handleChange}
                                    error={fieldErrors.name}
                                />
                            </Box>
                            
                        </Box>
                    </Box>

                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    > 
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1,
                                    // width:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("short_name")}</Text>
                            </Box>
                            
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                            // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <ShValidatedInput
                                    name="shortName"
                                    value={streamInfo?.shortName}
                                    onChange={handleChange}
                                    error={fieldErrors.shortName}
                                />
                            </Box>
                            
                        </Box>
                    </Box>
                    
                </Box>
                <Box 
                    display={"flex"} 
                    flexDirection={"column"}
                    sx={{
                        //maxWidth: 160,
                        width:1,
                        paddingTop:3,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            flexDirection:"row",
                            gap:4,
                            alignItems:"center",
                            justifyContent:"space-between",
                        }
                    }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            mb:0.5,
                            "@media screen and (max-width:47.9375em)" : {
                                mb:0
                            }
                        }}
                    >
                        <Text semiBold>
                            {t('max_amount_orders_cart')}
                        </Text>
                        {/*  */}
                        {/* <EMDef */}
                        
                    </Box>
                    <NumberInput 
                        btnSize={"24"}
                        value={streamInfo?.maxPickingOrderAmount ?? 0}
                        change={(e) => handleChange('maxPickingOrderAmount', e)}
                    />
                </Box>
                <Box 
                    display={"flex"} 
                    flexDirection={"column"}
                    sx={{
                        //maxWidth: 160,
                        width:1,
                        paddingTop:3,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            flexDirection:"row",
                            gap:4,
                            alignItems:"center",
                            justifyContent:"space-between",
                        }
                    }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            mb:0.5,
                            "@media screen and (max-width:47.9375em)" : {
                                mb:0
                            }
                        }}
                    >
                        <Text semiBold>
                            {t('reachtype')}
                        </Text>
                        
                    </Box>
                    <ShValidatedInputDropdown
                        name="reachTypeId"
                        displayName="description"
                        changeField="id"
                        selected={reachtypes?.data.findIndex(reachtype => reachtype.id === streamInfo?.reachTypeId) ?? 0}
                        noSelection={t("choose_reachtype")} //Custom text when nothing is selected
                        options={reachtypes?.data ?? []}
                        onChange={handleChange}
                        error={fieldErrors.reachTypeId}
                    />
                </Box>
                <Box 
                    display={"flex"} 
                    flexDirection={"column"}
                    sx={{
                        //maxWidth: 160,
                        width:1,
                        paddingTop:3,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            flexDirection:"row",
                            gap:4,
                            alignItems:"center",
                            justifyContent:"space-between",
                        }
                    }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                mb:0.5,
                                "@media screen and (max-width:47.9375em)" : {
                                    mb:0
                                }
                            }}
                        >
                            <Text semiBold>
                                {t('pickingprocesstype')}
                            </Text>
                            {/*  */}
                            {/* <EMDef */}
                            
                        </Box>
                        <ShValidatedInputDropdown
                            name="pickingprocesstype"
                            displayName="displayName"
                            changeField="name"
                            selected={pickingprocesstypes?.findIndex(pickingprocesstype => pickingprocesstype === streamInfo?.pickingprocesstype) ?? -1}
                            noSelection={t("choose_picking_process_type")} //Custom text when nothing is selected
                            options={pickingprocesstypes ? pickingprocesstypes.map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
                            onChange={handleChange}
                            error={fieldErrors.pickingprocesstype}
                        />
                </Box>
                <Box 
                    display={"flex"} 
                    flexDirection={"column"}
                    sx={{
                        //maxWidth: 160,
                        width:1,
                        paddingTop:3,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            flexDirection:"row",
                            gap:4,
                            alignItems:"center",
                            justifyContent:"space-between",
                        }
                    }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                mb:0.5,
                                "@media screen and (max-width:47.9375em)" : {
                                    mb:0
                                }
                            }}
                        >
                            <Text semiBold>
                                {t('warehouse')}
                            </Text>
                            {/*  */}
                            {/* <EMDef */}
                            
                        </Box>
                        <ShValidatedInputDropdown
                            name="warehouseId"
                            displayName="name"
                            changeField="id"
                            selected={warehouses?.data?.findIndex(warehouse => warehouse.id === streamInfo?.warehouseId) ?? -1}
                            noSelection={t("choose_warehouse")} //Custom text when nothing is selected
                            options={warehouses?.data ?? []}
                            onChange={handleChange}
                            error={fieldErrors.warehouseId}
                        />
                </Box>
            </Dropdown>
            <Dropdown title={t("measurements")} subTitle={t("stream_min_max_dimensions_and_weight_description")} open={window.innerWidth > 767} isLast sx={{paddingBottom:"0px"}}>
            {/* <Divider></Divider> */}
                <Box sx={{
                    display:"flex",
                    flexDirection:"column"
                }}>
                    
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:7
                        }}
                    >

                    <Box
                        sx={{
                            width:88,
                        }}
                    >

                    </Box>
            
                    <Box    
                        sx={{
                                flex:1,
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"center"
                            }}
                    >
                        <Box   
                            sx={{
                                flex:1,
                                display:"flex",
                                justifyContent: "center"
                            }}
                        >
                            <Text bold>{t("min")}</Text>
                        </Box>
                        <Box   
                            sx={{
                            flex:1,
                            display:"flex",
                            justifyContent: "center"
                            }}
                        >
                            <Text bold>{t("max")}</Text>
                        </Box>
                    </Box>
                </Box>

                <Box 
                    sx={{
                        width:1,
                        paddingTop: 2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:88,
                        }}
                    >
                        <Text>{t("weight")}</Text>&nbsp;
                        <Text italic light>(kg)</Text>
                    </Box>
                    
                    <Box 
                        sx={{
                            display:"flex",
                            flex:1,
                            gap:1,
                            alignItems:"flex-start",
                        }}
                    >
                            
                        <NumberInput
                        value={streamInfo?.minWeight ?? 0}
                        change={(e) => handleChange('minWeight', e)}/>
                        
                        
                        <ShValidatedNumberInput
                            name="maxWeight"
                            value={streamInfo?.maxWeight ?? 0}
                            onChange={handleChange}
                            error={fieldErrors.maxWeight}
                        />
                        
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:88,
                        }}
                    >
                        <Text>{t("length")}</Text>&nbsp;
                        <Text italic light>({t("mm")})</Text>
                    </Box>
                    
                    <Box 
                        sx={{
                            display:"flex",
                            flex:1,
                            gap:1,
                            alignItems:"flex-start",
                        }}
                    >
                            
                        <NumberInput
                        value={streamInfo?.minLength ?? 0}
                        change={(e) => handleChange('minLength', e)}/>
                        
                
                        <ShValidatedNumberInput
                            name="maxLength"
                            value={streamInfo?.maxLength ?? 0}
                            onChange={handleChange}
                            error={fieldErrors.maxLength}
                        />
                        
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:88,
                        }}
                    >
                        <Text>{t("width")}</Text>&nbsp;
                        <Text italic light>({t("mm")})</Text>
                    </Box>
                    
                    <Box 
                        sx={{
                            display:"flex",
                            flex:1,
                            gap:1,
                            alignItems:"flex-start",
                        }}
                    >

                        <NumberInput
                        value={streamInfo?.minWidth ?? 0}
                        change={(e) => handleChange('minWidth', e)}/>

                        <ShValidatedNumberInput
                            name="maxWidth"
                            value={streamInfo?.maxWidth ?? 0}
                            onChange={handleChange}
                            error={fieldErrors.maxWidth}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:88,
                        }}
                    >
                        <Text>{t("height")}</Text>&nbsp;
                        <Text italic light>({t("mm")})</Text>
                    </Box>
                    
                    <Box 
                        sx={{
                            display:"flex",
                            flex:1,
                            gap:1,
                            alignItems:"flex-start",
                        }}
                    >
                        
                        <NumberInput
                        value={streamInfo?.minHeight ?? 0}
                        change={(e) => handleChange('minHeight', e)}/>
                        
                        <ShValidatedNumberInput
                            name="maxHeight"
                            value={streamInfo?.maxHeight ?? 0}
                            onChange={handleChange}
                            error={fieldErrors.maxHeight}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:88,
                        }}
                    >
                        <Text>{t("volume")}</Text>&nbsp;
                        <Text italic light>({t("mm3")})</Text>
                    </Box>
                    
                    <Box 
                        sx={{
                            display:"flex",
                            flex:1,
                            gap:1,
                            alignItems:"flex-start",
                        }}
                    >
                        <NumberInput
                        value={streamInfo?.minVolume ?? 0}
                        change={(e) => handleChange('minVolume', e)}/>
                        
                        <ShValidatedNumberInput
                            name="maxVolume"
                            value={streamInfo?.maxVolume ?? 0}
                            onChange={handleChange}
                            error={fieldErrors.maxVolume}
                        />
                    </Box>
                </Box>
                    
                </Box>
            </Dropdown>

            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    },
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"none"
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
                    <MConfirmDelete
                    open={confirmDeactivateModal.open}
                    handleClose={() => confirmDeactivateModal.setOpen(false)}
                    data={confirmDeactivateModal.data}
                    confirm={confirmDeactivateModal.confirm}
                />
                <MConfirmationWithValue
                    open={confirmActivateModal.open}
                    handleClose={() => confirmActivateModal.setOpen(false)}
                    data={confirmActivateModal.data}
                    confirm={confirmActivateModal.confirm}
                />
                <GridActions
                    id={streamInfo?.id}
                    action={handleSaveClick}
                    deactivateAction={handleDeactivateClick}
                    activateAction={handleActivateClick}
                    state={streamInfo?.state}
                />
            </Box>
        </Box>
    );
}

export default DetailsPanel;