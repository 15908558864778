import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import Text from "../../../../../components/theme/text/Text";
import { tokens } from "../../../../../theme";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import SpReturnDetails from "../../../../../components/global/Sidepanels/SpReturnDetails/SpReturnDetails"; // Changed from SpInboundDetails to SpReturnDetails
import ShLabel from "../../../../../components/theme/label/ShLabel";
import { useGetReturnsQuery } from "../../../../../newapi/inbound/returnsSlice"; // Changed from inboundsSlice to returnsSlice
// import ReturnsCard from "../../../../../components/theme/cards/returns/ReturnsCard"; // Changed from InboundsCard to ReturnsCard
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";

import { useNavigate } from 'react-router-dom';
import { WarehouseReturnCard } from "../../../../../components/theme/cards/inbounds/ReturnCards";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import { checkUserPermission, UNAUTHORIZED_ENDPOINT, UserPrivileges } from '../../../../../utils/permissions';

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                // onClick={confirm} 
                endIcon={<AddIcon/>}
                variant="contained"
            >
                New
            </Shbutton>
        </ButtonGroup>
        // <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        //     <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        // </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const InboundReturns = (props) => { // Changed from InboundInbounds to ReturnReturns
    // Check if user has permission to access the page
   const navigate = useNavigate();
   useEffect(() => {
       if (!checkUserPermission([UserPrivileges.RETURN_READ])) { // Changed from INBOUND_READ to RETURN_READ
         navigate(UNAUTHORIZED_ENDPOINT); // Use navigate instead of history.push
       }
     }, [navigate]);
     
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
        }
    },[]);


    const [returnId, setReturnId] = useState();
    const returnIdRef = useRef(returnId);
    
    const getReturnDetails = (params) => {
        if (!params) return;
    
        const newreturnId = parseInt(params.row.id);
        // Only update state if the returnId has actually changed
        if (returnIdRef.current !== newreturnId) {
            setReturnId(newreturnId);
            returnIdRef.current = newreturnId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
             
        {field: "id", headerName: t("relation"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return(
                <Text >
                    {params.row.relation?.name}
                </Text>)
            }
        },
        {
            field: "createdAt", 
            headerName: t("created"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = new Date(params.row.createdAt);
              
                return (
                    <Text>
                        {date.toLocaleDateString(i18n.language)}
                    </Text>
                );
            }
        },
        {field: "name", headerName: t("sender"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return(
                    <Text>
                        {params.row.name}
                    </Text>
                )
            }
        },
        {field: "reference", headerName: t("reference"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.reference;
            }
        },
        {field: "warehouse.name", headerName: t("warehouse"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return(
                    <Text >
                        {params.row.warehouse?.name}
                    </Text>
                )
            }
        },
        {field: "skuCount", headerName: t("sku_count"),flex:1,
            sortable:false,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.skuCount;
            }
        },        

        {field: "itemCount", headerName: t("items"),flex:1,
            sortable:false,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.itemCount;
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                let status = params.row.state;
                let labelcolor = "";
                let labeltext = "";
                switch(status){
                    case "announced":
                        labelcolor = "blue"
                        labeltext = t('announced');
                        break;
                    case "approved":
                        labelcolor = "green"
                        labeltext = t('approved');
                        break;
                    case "declined":
                        labelcolor = "red"
                        labeltext = t('declined');
                        break;
                    case "cancelled":
                        labelcolor = "pink"
                        labeltext = t('cancelled');
                        break;
                    case "receivedwithoutnotice":
                        labelcolor = "purple"
                        labeltext = t('receivedwithoutnotice');
                        break;
                    default:
                        labelcolor = "orange"
                        labeltext = t('unknown');
                        break;
                }
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehouseReturnCard skeleton={params.row.skeleton ?? false} {...params.row} onClick={() => getReturnDetails(params)}/>
                    // <InboundsCard skeleton={params.row.skeleton ?? false} {...params.row} />
                // <Box padding={3} sx={{background:"orange"}}></Box>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >
                    //     {cellValues.row.id + " " + cellValues.row.name}
                    //     <br/>
                    //     {cellValues.row.age}
                    //     <br/>
                    //     {cellValues.row.phone}
                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetReturnsQuery} // Changed from useGetInboundsQuery to useGetReturnsQuery
                // gridActions={<GridActions></GridActions>} 
                title={t('all_returns') } // Changed from all_inbounds to all_returns
                gridOptions={gridOptions} 
                 
                sortModel={{field: columns[1].field,type:"DESC"}}
                columns={columns}
                onRowClick={getReturnDetails}> 
            </ShDataGrid2>
            <SpReturnDetails // Changed from SpInboundDetails to SpReturnDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                returnId={returnId}
                // data={returnData} // Changed from inboundData to returnData
            ></SpReturnDetails>

        </Box>
    );
}

export default InboundReturns; // Changed from InboundInbounds to ReturnReturns
