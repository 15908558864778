import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import NumberInput from "../../../../theme/inputs/NumberInput";
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import { useEditSupplierMutation } from "../../../../../newapi/global/supplier/supplierSlice";

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { tokens } from "../../../../../theme";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Subheading from "../../../../theme/text/Subheading";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { countriesFromLocale } from '../../../../../utils/countries';
import { countryCodeNL } from '../../../../../utils/constants';
import { useSelector } from 'react-redux';
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import * as yup from "yup";
import { yupRequired } from "../../../../../utils/validation";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [supplierInfo, setSupplierInfo] = useState({
        id: null,
        state: null,
        name: "",
        street: "",
        number: "",
        insertion: "",
        postal: "",
        city: "",
        province: "",
        country: "",
        phone: "",
        email: "",
        remark: "",
        relation: null,
        inbounds: []
    });

    useEffect(() => {
        setSupplierInfo(props.data);
    }, [props.data]);

    const handleChange = (key, value) => {
        setSupplierInfo({
            ...supplierInfo,
            [key]: value
        });
    };

    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language);
    
    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        street: yupRequired('street', t),
        number: yupRequired('number', t),
        postal:yupRequired('postal', t),
        city: yupRequired('city', t),
        country: yupRequired('country', t),
        email: yupRequired('email', t),
    };
    
    const schema = yup.object().shape(rules);

    const [EditSupplier, { isLoading }] = useEditSupplierMutation();

    const handleSaveClick = async () => {
        const canSave = true; // Add validation logic here
        if (canSave) {
            try {
                const data = await EditSupplier(supplierInfo).unwrap();
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                showToastMessageRequestError(t("update_failed"), t, err);
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }  
        }
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:  <GridActions id={supplierInfo?.id}  action={handleSaveClick}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[supplierInfo,handleSaveClick]);

     return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
        <Dropdown title={t("general")} open>
        <Box sx={{
            display:"flex",
            justifyContent:"space-between",
            "@media screen and (max-width: 47.9375em)" : {
                flexDirection:"column"
            }
        }}>
            
            {/* Two columns */}
            
            <Box flex={1}
                sx={{
                    display:"flex",
                    gap: 1.5,
                    justifyContent:"flex-start",
                    flexDirection:"column"
                }}
            >
            <Box
                sx={{
                    display:"flex",
                    gap:2,
                    alignItems:"center",
                }}
            >
                <Box 
                    sx={{
                        width:140,
                        display:"flex",
                        alignItems:"center",
                    }}
                >
                    <Text noBreak>{t("name")}</Text>
                </Box>
                <Box display="flex"
                    height="40px"
                    // maxWidth="400px"
                    borderRadius="20px"
                    sx={{
                        flex:1,
                    }}
                >
                    <ShValidatedInput
                        height={40}
                        name="name"
                        value={supplierInfo?.name}
                        onChange={handleChange}
                        error={fieldErrors.name}
                    />
                </Box>
            </Box>
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("phone")}</Text>
                    </Box>
                    <Box display="flex"
                        height="40px"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="phone"
                            value={supplierInfo?.phone}
                            onChange={handleChange}
                            error={fieldErrors.phone}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("email")}</Text>
                    </Box>
                    <Box display="flex"
                        height="40px"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="email"
                            value={supplierInfo?.email}
                            onChange={handleChange}
                            error={fieldErrors.email}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("remark")}</Text>
                    </Box>
                    <Box display="flex"
                        height="40px"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="remark"
                            value={supplierInfo?.remark}
                            onChange={handleChange}
                            error={fieldErrors.remark}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    </Dropdown>
            <Dropdown title={t("address_details")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("street")}</Text>
                    </Box>
                    <Box display="flex"
                        height="40px"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="street"
                            value={supplierInfo?.street}
                            onChange={handleChange}
                            error={fieldErrors.street}
                        />
                    </Box>
                </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("number")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="number"
                                value={supplierInfo?.number}
                                onChange={handleChange}
                                error={fieldErrors.number}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("postal")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="postal"
                                value={supplierInfo?.postal}
                                onChange={handleChange}
                                error={fieldErrors.postal}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("city")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="city"
                                value={supplierInfo?.city}
                                onChange={handleChange}
                                error={fieldErrors.city}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("province")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="province"
                                value={supplierInfo?.province}
                                onChange={handleChange}
                                error={fieldErrors.province}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("country")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                        <ShValidatedInputDropdown
                            height={40}
                            name="country"
                            displayName="name"
                            changeField="alpha2"
                            options={countries ?? null}
                            noSelection={t("choose_option")} 
                            onChange={handleChange}
                            selected={countries?.findIndex(country => (supplierInfo?.country && supplierInfo?.country === country.alpha2) || (country.alpha2 === countryCodeNL)) ?? -1}
                            error={fieldErrors.country}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                <GridActions
                    id={supplierInfo?.id}
                    action={handleSaveClick}
                />
            </Box>
        </Box>
    );
}


export default DetailsPanel;
