import { Box, useTheme } from "@mui/material"
import { tokens } from "../../../theme";

//VERSION 1
    // - Has NO gaps
    // - has Background
    // - has 4px padding
//VERSION 2
    // - HAS gaps
    // - has NO background
    // - has 0 padding

const ButtonGroup = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const version = props.version ?? 1;
    const options = props.options ?? [];

    return (
        <Box
            sx={{
                display:"flex",
                gap:version == 1 ? 0 : 2,
                background: version === 1 ? props.background ?? theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[400] : null,
                padding: version === 1 ? 0.5 : 0,
                width:version === 1 ? "fit-content" : null,
                justifyContent:"flex-end",
                alignItems:"center",
                borderRadius:6,
                overflow:"hidden",
                "@media screen and (max-width: 47.9375em)" : {
                    gap:0,
                    padding:0,
                    background: props.background ?? theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[400],
                    width:1,
                    borderTopRightRadius:0,
                    borderBottomRightRadius:0,
                    mr: props.isSidepanel ? "-24px" : null,
                    height: props.isSidepanel ? "40px" : null
                }
            }}
        >
            {props.children.length > 1 ?
                props.children.map((child, index) => (
                    <Box
                        key={"bgi"+index}
                        sx={{
                            display:"flex",
                            // background:"orange",
                            // display: typeof options[index] !== "undefined" && !options[index].showOnPhone ? "block" : "none",
                            "@media screen and (max-width: 47.9375em)" : {
                                flex:1,
                                // display: typeof options[index] !== "undefined" && !options[index].hideOnPhone ? "block" : "none",
                            }
                        }}
                    >
                        {child}
                    </Box>
                ))
            :
                <Box
                    sx={{
                        display:"flex",
                        // display: typeof options[0] === "undefined" || !options[0].showOnPhone ? "block" : "none",
                        "@media screen and (max-width: 47.9375em)" : {
                            flex:1,
                            // display: typeof options[0] === "undefined" || !options[0].hideOnPhone ? "block" : "none",
                        }
                    }}
                >
                    {props.children}
                </Box>
            }
        </Box>
    )
}

export default ButtonGroup;