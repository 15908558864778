import { createSlice, current } from '@reduxjs/toolkit';
import { PURGE, REHYDRATE } from 'redux-persist';
import { produce } from "immer";
/**
 * Redux slice to manage the state of the tabs, progression info, and data.
 */
const initialState = {
    currentTab: null,
    previousTabs: [],
    currentStep: 1.0,
    data: {},
    title: '',
    subtitle: '',
    maxSteps: 1,
    shouldSaveData: false,
    errors: {},
    nextDisabled:false,
};
const progressionTabSlice = createSlice({
    name: 'progressiontabs',
    initialState: initialState,
    reducers: {
        setResetTab: () => initialState,
        
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
            state.previousTabs.push(state.currentTab);
        },
        setPreviousTabActive: (state) => {
            state.previousTabs.pop();
            state.currentTab = state.previousTabs[state.previousTabs.length-1];
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        updateData: (state, action) => {
            state.data = { ...state.data, ...action.payload };

            // // Ensure we are not mutating the state directly but instead deeply merging the data.
            // state.data = { 
            //     ...state.data, // Keep top-level state intact
            //     ...action.payload, // Merge in the action's payload at the top level
            // };

            // // Now we recursively go through any keys that are arrays or objects in `state.data` and `action.payload`
            // Object.keys(action.payload).forEach(key => {
            //     // If the value is an array or object, we want to deeply update it
            //     if (Array.isArray(action.payload[key]) || (action.payload[key] && typeof action.payload[key] === 'object')) {
            //         state.data[key] = deepMerge(state.data[key], action.payload[key]);
            //     }
            // });

             // Iterate over the action payload keys and update accordingly
            // state.data = deepUpdate(state.data, action.payload);
        },
        setProgressionInfo: (state, action) => {
            const { title, subtitle, maxSteps,shouldSaveData,nextDisabled } = action.payload;
            if (title !== undefined) state.title = title;
            if (subtitle !== undefined) state.subtitle = subtitle;
            if (maxSteps !== undefined) state.maxSteps = maxSteps;
            if (shouldSaveData !== undefined) state.shouldSaveData = shouldSaveData
            if (nextDisabled !== undefined) state.nextDisabled = nextDisabled;
        },
          // ... other reducers
        setTabErrors: (state, action) => {
            state.errors = action.payload;
            // state.errors = action.payload.errors.reduce((acc, currentError) => ({
            //     ...acc,
            //     [currentError.path]: currentError.message
            // }), {});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(PURGE, () => initialState)
          // ... your other matchers
          .addCase(REHYDRATE, (state, action) => {
            // Or if you want to update the state with the rehydrated state:
            if (action.payload) {
              return { ...state, ...action.payload.progressiontabs};
            }
            return state;
          })
      }
});


// Function to deeply update arrays and objects
function deepUpdate(currentState, newData) {
    if (Array.isArray(currentState)) {
      // If the current state is an array, create a new array with the updated data
      return currentState.map((item, index) => {
        // Only update the item if the index matches or if there's new data
        return newData[index] !== undefined ? newData[index] : item;
      });
    }
  
    if (typeof currentState === 'object' && currentState !== null) {
      // If it's an object, recursively merge the keys
      return Object.keys(newData).reduce((acc, key) => {
        acc[key] = deepUpdate(acc[key], newData[key]); // Recursively update nested objects
        return acc;
      }, {...currentState});  // Create a shallow copy to ensure immutability
    }
  
    // If it's a primitive (string, number, etc.), just return the new data directly
    return newData;
}

export const { setResetTab, setCurrentTab, setCurrentStep, updateData, setProgressionInfo,setPreviousTabActive, setTabErrors } = progressionTabSlice.actions;
export default progressionTabSlice;
