import React, { useState, useContext } from "react";
import { Box, Typegraphy, Typography, useTheme } from '@mui/material';
import { tokens } from "../../../theme";
import cx from 'classnames';

import 'react-pro-sidebar/dist/css/styles.css';


const Text = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const color = typeof props.color !== "undefined" ? props.color : typeof props.variant !== "undefined" && props.variant !== null ? props.variant !== "white" && props.variant !== "#fff" ? colors[props.variant][400] : "#fff" : colors.txt["primary"];
    const align = typeof props.align !== "undefined" ? props.align : "left"; 

    return (
        <Typography
            className={cx(`${props.size}`, {
                'light': props.light,
                'bold' : props.bold,
                'semibold' : props.semibold,
                'italic' : props.italic,
                'no-break': props.noBreak,
                'clickable' : props.clickable,
                'active' : props.active,
                "center": props.center,
            })} 
            variant="span" 
            fontWeight={props.fontWeight} 
            fontFamily="Roboto, sans-serif"
            sx={{
                color:color,
                fontSize:props.fontSize ?? 14,
                display:"inline-block",
                transition:"250ms",
                textAlign:align,
                "&.light" : {
                    color:`${colors.txt["secondary"]}`,
                },
                "&.fs13" : {
                    fontSize:13
                },
                "&.semibold" : {
                    fontWeight:500
                },
                "&.bold" : {
                    fontWeight:"700"
                },
                "&.italic" : {
                    fontStyle:"italic"
                },
                "&.no-break" : {
                    whiteSpace:"nowrap",
                    wordBreak:"keep-all",
                    textOverflow:"ellipsis",
                    overflow:"hidden"
                },
                "&.clickable" : {
                    cursor: "pointer"
                },
                "&.active" : {
                    color: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400],
                    transition:"250ms"
                },
                "&.center" : {
                    textAlign:"center"
                },
                ...props.sx
        }}>
            {props.children}
        </Typography>
    );
};

export default Text;