import { Box, IconButton, useTheme } from "@mui/material";
import { Copy } from "./Copy";

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { tokens } from "../../theme";

const ShCopyButton = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        
        <Copy 
            closeAfter={props.closeAfter}
            onClick={() => {
                // e.stopPropagation();
                navigator.clipboard.writeText(props.value)
            }} 
            message={props.value}
        >
            <Box
                className={props.className}
                sx={{
                    
                    display:"flex",
                    alignItems:"flex-end",
                    ...props.sx
                }}
            >
                <IconButton
                    variant={"contained"}
                    sx={{ color: props.color? props.color : theme.palette.mode === "dark" ? colors.blue[400] : colors.primary["400"],...props.styles?.button }}
                >
                    <ContentCopyOutlinedIcon fontSize="small" />
                </IconButton>
            </Box>
        </Copy>
    );
}
export default ShCopyButton;