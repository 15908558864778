import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import StockOnLocation from "./Views/StockOnLocation";
import WarehouseLocation from "./Views/WarehouseLocation";
import Logs from "./Views/Logs";

import { useTranslation } from 'react-i18next';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spLocationDetailsConfig } from "./spLocationDetailsConfig";
import { useDeleteWarehouseLocationMutation, useGetWarehouseLocationQuery } from "../../../../newapi/warehouse/warehouselocationSlice";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import MConfirmDelete from "../../Modals/MConfirmDelete";
import { showToastMessageDeleteRequest, showToastMessageRequestError } from "../../../../utils/toasts";

import DeleteIcon from '@mui/icons-material/Delete';

const SidePanelActions = (props) => {
    const { t } = useTranslation();
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>
            {!props.hasStock && (
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            )}

        </SidepanelHeaderActiongroup>
    )
}

const MultiProductBox = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <>
        
        <Box width={200} display={"flex"} alignItems={"center"}>
                <Box sx={{
                    width:128,
                    flexShrink:0,
                    mr:1
                }}>
                    <Text noBreak light>{t('in_multi_product_zone')}</Text>
                </Box>
                {props.skeleton ? 
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <Box width={20} marginRight={0.5}>
                        <Text bold variant={props.multiproductzone ? "green" : "red"}>{props.multiproductzone ? t('yes') : t('no')}</Text>
                    </Box>
                }
            </Box>
            <Box width={200} display={"flex"} alignItems={"center"}>
                <Box sx={{
                    width:128,
                    flexShrink:0,
                    mr:1
                }}>
                    <Text noBreak light>{t('in_multi_batch_zone')}</Text>
                </Box>
                {props.skeleton ? 
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <Box  width={20} marginRight={0.5}>
                        <Text bold variant={props.multibatchzone ? "green" : "red"}>{props.multibatchzone ? t('yes') : t('no')}</Text>
                    </Box>
                }
            </Box>
            <Box width={200} display={"flex"} alignItems={"center"}>
                <Box sx={{
                    width:128,
                    flexShrink:0,
                    mr:1
                }}>
                    <Text noBreak light>{t('multiproduct_location')}</Text>
                </Box>
                

                {props.skeleton ? 
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <Box width={20} marginRight={0.5}>
                        <Text bold variant={props.multiproduct ? "green" : "red"}>{props.multiproduct ? t('yes') : t('no')}</Text>
                    </Box>
                }
            </Box>
        </>
    )

}
const SpLocationDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: locationData,isFetching, isLoading,isError } = useGetWarehouseLocationQuery(props.locationId,
    {
        skip: !props.locationId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <StoreMallDirectoryIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t('zone')} /> },
        { component: <MultiProductBox skeleton/>},
        { component: <ShRowSkeleton heading={t('location_type')} /> },
        { component: <ShRowSkeleton heading={t('reach_type')} /> }
    ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions: <SidePanelActions hasStock={true} setIsExtended={setIsExtendedCallback}/>
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.locationId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && locationData ) {
            setPanel((prevPanel) => ({
                ...prevPanel,
                title: locationData.location,
                icon :{
                    theme: theme.palette.mode === "dark" ? "blue" : "primary",
                    content: <StoreMallDirectoryIcon/>
                },
                
                subTitle: [locationData.warehouse?.name, locationData.warehouseLevel?.description],
                options: [
                    {
                        heading: t('zone'),
                        content: locationData.warehouseZone?.description
                    },
                    {
                        component: <MultiProductBox 
                        multiproduct={locationData.multiproduct} 
                        multiproductzone={locationData.warehouseZone?.multiproduct} 
                        multibatchzone={locationData.warehouseZone?.multibatch}/>
                    },
                    {
                        heading: t('location_type'),
                        content: locationData.warehouseLocationType?.description
                    },
                    {
                        heading: t('reach_type'),
                        content: locationData.reachType?.description
                    }
                ],
                actions: <SidePanelActions hasStock={locationData && locationData.stocks && locationData.stocks.length > 0} setIsExtended={setIsExtendedCallback} deleteAction={handleDeleteClick}/>
            }));
        }
    }, [locationData, isLoading, isFetching, t]);

    const [DeleteWarehouseLocationMutation] = useDeleteWarehouseLocationMutation();
      const confirmDelete = async () => {
        try {
            const response = await DeleteWarehouseLocationMutation(props.locationId).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageRequestError(t('delete_failed'), t, err);
        }
    };
    const handleDeleteClick = async () => {
        setOpen(true);
    };
    const [open, setOpen] = useState(false);
    const confirmDeleteModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    } 
    
    return (
        <>
            <Sidepanel2
                skeleton={isLoading || isFetching}
                tabConfig={spLocationDetailsConfig}
                content={panel} 
                isExtended={props.isExtended} 
                setIsExtended={props.setIsExtended}>
                    <>
                        <StockOnLocation config={spLocationDetailsConfig.stockOnLocation} warehouseLocationId={props.locationId} />
                        <WarehouseLocation config={spLocationDetailsConfig.details} data={locationData} setIsExtended={props.setIsExtended}/>
                        <Logs config={spLocationDetailsConfig.logs} warehouseLocationId={props.locationId}/>
                    </>
            </Sidepanel2>

            <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}/>
        </>
    );
};

export default SpLocationDetails;