import { Box, InputBase, useTheme } from "@mui/material";
import BaseCard from "../BaseCard";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import Text from "../../text/Text";
import TopRight from "../../positionals/TopRight";
import CardLabel from "../../label/CardLabel";
import ShLabel from "../../label/ShLabel";
import { formatPrice } from "../../../../utils/helpers";
import { themeSettings, tokens } from "../../../../theme";
import { useTranslation } from "react-i18next";
import { CheckBox } from "@mui/icons-material";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import { InputDropdown } from "../../dropdowns/InputDropdown";
import ShSwitch from "../../inputs/ShSwitch";
import { EuroOutlined } from "@mui/icons-material";

export const RelationFinancialProductCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t,i18n } = useTranslation();
    const color = props.isConcept ? "orange" : "green";


              
    const handleToggle = (e) => {
        e.stopPropagation();
        e.preventDefault();
        props.handleChange(props.product.invoiceProductId, 'isActive', !props.product.isActive);
        // props.select(props.id);
    };

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}
            cardStyles={{
                display:"none",
                "@media screen and (max-width: 47.9375em)" : {
                    display:"unset"
                }
            }}
        >
            <Box>
                <Box
                    sx={{

                    }}
                >
                    {/* Top row */}
                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            gap:3,
                        }}
                    >
                        <Box sx={{ flexGrow: 1, flexShrink: 1, overflow:"hidden" }}>
                            <Box>
                                <Text bold>{props.product.invoiceProduct.name}</Text>
                            </Box>
                            {/* Rleation name */}
                            <Box
                                sx={{
                                    
                                }}
                            >
                                <Text light>{props.relation?.name}</Text>
                            </Box>
                        </Box>
                        <CardLabel sx={{pr:0.5}} variant={props.product.isActive ? "green" : "red"} borderless palette={"normal"} fitted size={32}>
                            {props.product.isActive ? t("active") : t("inactive")}
                            <Box 
                                onClick={handleToggle} 
                                sx={{ 
                                    ml:1.5,
                                    borderTopRightRadius:12,
                                    borderBottomLeftRadius:12,
                                    borderTopLeftRadius:6,
                                    borderBottomRightRadius:6,
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent:'center',
                                    cursor: 'pointer',
                                    width:24,
                                    height:24,
                                    background:props.product.isActive ? colors.green[100] : colors.red[100]
                                }}
                            >
                                {/* CheckBoxOutlineBlank for "No", CheckBox for "Yes" */}
                                {props.product.isActive ? 
                                    <CheckBox sx={{ fontSize: 24, fill: colors.green[400]}} /> 
                                    : 
                                    <CheckBoxOutlineBlank sx={{ fontSize: 24, color: colors.red[400] }} />
                                }
                            </Box>
                        </CardLabel>
                    </Box>
                    <Box marginTop={0} display={"flex"} alignItems={"flex-start"} flexDirection={"column"} gap={1}>

                      <Box sx={{
                        width:1,
                        display:'flex',
                        justifyContent:"space-between",
                        alignItems:"center",
                      }}>
                        <Box sx={{
                            flex:1,
                        }}>
                            <Text>{t("category")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <InputDropdown
                                name="name"
                                displayName="name"
                                selected={props.invoiceCategories ? props.invoiceCategories.findIndex(item => item.id === props.product.categoryProduct.invoiceCategoryId) ?? -1 : -1}
                                options={props.invoiceCategories ?? []}
                                noSelection={t("choose_option")} 
                                onChange={(selected) => props.handleChange(props.product.invoiceProductId, 'invoiceCategoryId', props.invoiceCategories[selected]?.id)}
                                closeOnSelection
                                disabled={!props.product.isCustomPrice}
                            />
                        </Box>
                      </Box>

                      <Box sx={{
                        width:1,
                        display:'flex',
                        justifyContent:"space-between",
                        alignItems:"center",
                      }}>
                        <Box sx={{
                            flex:1,
                        }}>
                            <Text>{t("custom_price")}</Text>
                            <ShSwitch 
                                checked={props.product.isCustomPrice} 
                                onChange={(event) => props.handleChange(props.product.invoiceProductId, 'isCustomPrice', event.target.checked)} 
                                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                            />
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    background:colors.grey[100],
                                    borderRadius:5,
                                    height:40,
                                    flex:1,
                                    pl:0.5,
                                }}
                            >
                            
                                <Box
                                    sx={{
                                        background: themeSettings(theme.palette.mode).palette.primaryGrey.main,
                                        color:themeSettings(theme.palette.mode).palette.primaryGrey.contrastText,
                                        height:32,
                                        width:32,
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        borderRadius:4,
                                    }}
                                >
                                    <EuroOutlined fontSize="small"/>
                                </Box>
                                <InputBase 
                                sx={{
                                    px:2,
                                    flex:1, 
                                    color:colors.txt["primary"],
                                    lineHeight:"32px",
                                    }}
                                    disabled={!props.product.isCustomPrice}
                                    value={props.product.isCustomPrice && props.product.price > 0 ? props.product.price : props.product.categoryProduct.price}
                                    onChange={(e) => props.handleChange(props.product.invoiceProductId, 'price', e.target.value)}
                                />  
                            </Box>
                        </Box>
                      </Box>

                    </Box>

                </Box>
            </Box>
        </BaseCard>
    )
}
