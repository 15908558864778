import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import Text from "../../../../theme/text/Text";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../../../theme/skeleton/ShDoubleTextSkeleton";
import { useGetWebshopCredentialsQuery } from "../../../../../newapi/webshop/webshopSlice";
import { useGetWarehousesSelectQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";
import { useEditWebshopCredentialsMutation } from "../../../../../newapi/webshop/webshopSlice";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const WebshopCredentials = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [isExtended, setIsExtended] = useState(false);
    const [config, setConfig] = useState({relationId: props.relationId, integrator: -1});

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const [EditWebshopCredentials,{isLoading}] = useEditWebshopCredentialsMutation();
    const handleUpdateWarehouse = async (webshopCredentialsId, warehouseId) => {
        try {
            const response = await EditWebshopCredentials({id: webshopCredentialsId, warehouseId: warehouseId}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };

    const { data: warehouses } = useGetWarehousesSelectQuery();

    const [modifiedWarehouses, setModifiedWarehouses] = useState([]);

    useEffect(() => {
        if (warehouses) {
            const modifiedData = [{id: -1, name: t('no_default_warehouse')}, ...warehouses.data];
            setModifiedWarehouses(modifiedData);
        }
    }, [warehouses]);

    

    const columns = [
        {
            field: "name", 
            headerName: t('name'),
            flex:1.5, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                if(params.row.integrator) {
                    return <Box display="flex" flexDirection="column" >    
                        <Text>{params.row.description}</Text>
                        <Text light>{params.row.integrator?.name}</Text>
                        </Box>
                } else {
                    return <Text>{params.row.description}</Text>
                }
            }
        },
        {
            field: "webshop_type", 
            headerName: t('webshop_type'),
            flex:2,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                
                return <Text>{params.row.webshop_type}</Text>
            }
        },
        {
            field: "warehouse", 
            headerName: t('warehouse'),
            flex:2,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return <InputDropdown
                variant="darker"
                displayName={"name"} 
                onChange={(selected) => handleUpdateWarehouse(params.row.id, modifiedWarehouses[selected].id)}
                noSelection={t("no_default_warehouse")} //Custom text when nothing is selected
                options={modifiedWarehouses ?? null}
                selected={modifiedWarehouses?.findIndex(warehouse => params.row.warehouse && params.row.warehouse.id === warehouse.id) ?? -1}
                closeOnSelection
            />
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetWebshopCredentialsQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('webshopcredentials')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>
        </Box>
    );
}

export default WebshopCredentials;