import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Shbutton from "../../../../theme/buttons/Shbutton";

import ShLabel from "../../../../theme/label/ShLabel";
import Text from "../../../../theme/text/Text";

import { useTranslation } from "react-i18next";
import { useGetInboundShipmentsQuery, useDeleteInboundShipmentMutation } from "../../../../../newapi/inbound/inboundsSlice";
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import ShLabelSkeleton from "../../../../theme/skeleton/ShLabelSkeleton";
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';
import { showToastMessageRequestError, showToastMessageDeleteRequest } from '../../../../../utils/toasts';
import MConfirmDelete from "../../../Modals/MConfirmDelete";
import MAddInboundShipments from "../../../Modals/MAddInboundShipments";
import MUpdateInboundShipment from "../../../Modals/MUpdateInboundShipment";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('add_shipment')}
            </Shbutton>
        </ButtonGroup>
    )
}


const gridOptions = {
    export:false,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Shipments = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const [shipmentInfo, setShipmentInfo] = useState(null);

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };
    
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const updateModal = {
        open: updateModalOpen,
        setOpen: setUpdateModalOpen
    }
  
    const handleCloseUpdate = () => {
        setUpdateModalOpen(false);
    };

    const [shipmentId, setShipmentId] = useState(null);
    const handleDeleteShipmentClick = (id) => {
        setShipmentId(id);
    }
    
    useEffect(() => {
        if (shipmentId) {
            setConfirmDeleteModalOpen(true);
        }
    }, [shipmentId]);

    const [DeleteInboundShipment,{syncIsLoading}] = useDeleteInboundShipmentMutation();
    const handleDeleteShipment = async (id) => {
        try {
            const response = await DeleteInboundShipment({id: shipmentId, inboundId: props.inboundId}).unwrap();
            showToastMessageDeleteRequest(t, response);
        } catch (err) {
            showToastMessageRequestError(t("delete_failed"), t, err);
        }
    };

    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const confirmDeleteModal = {
        open: confirmDeleteModalOpen,
        setOpen: setConfirmDeleteModalOpen,
        confirm: handleDeleteShipment
    }

    useEffect(() => {
        if (shipmentInfo) {
            updateModal.setOpen(true);
        }
    }, [shipmentInfo]);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions: <GridActions handleOpen={() => setAddModalOpen(true)}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const columns = [
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                let status = params.row.state;
                let labelcolor = "";
                let labeltext = "";
                switch(status){
                    case "active":
                        labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
                        labeltext = t('active');
                        break;
                    case "fullyreceived":
                        labelcolor = "green"
                        labeltext = t('received');
                        break;
                    case "cancelled":
                        labelcolor = "grey"
                        labeltext = t('cancelled');
                        break;
                    default:
                        labelcolor = "orange"
                        labeltext = t('unknown');
                        break;
                }
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
        {field: "trackandtrace", headerName: t("track_trace"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold>{cellValues.row.trackandtrace}</Text>
                </Box>
            )
        }},
        {
            field: "actions", 
            headerName: "",
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return <Box>
                <IconButton type="button" onClick={() => setShipmentInfo(params.row)}
                sx={{ 
                    color: colors.txt["primary"]
                }}>
                    <EditIcon/>
                </IconButton>
                <IconButton type="button" onClick={() => handleDeleteShipmentClick(params.row.id)}
                sx={{ 
                    color: colors.red[400]
                }}>
                    <DeleteIcon/>
                </IconButton>
            </Box>
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                return (
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >
                        {/* {cellValues.row.id + " " + cellValues.row.name}
                        <br/>
                        {cellValues.row.age}
                        <br/>
                        {cellValues.row.phone} */}
                    </Box>
                )
            }
        }
    ];

    return (
    <Box height={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
        <ShDataGrid2 key={theme.palette.mode}
            itemId={props.inboundId}
            content={useGetInboundShipmentsQuery}
            gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}/>}
            title={t("shipments")}
            gridOptions={gridOptions}
            isSidepanelGrid
            columns={columns}
        />
        <MAddInboundShipments inboundId={props.inboundId} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        <MUpdateInboundShipment inboundId={props.inboundId} onOpen={() => updateModal.setOpen(true)} onClose={() => updateModal.setOpen(false)} open={updateModal.open} handleClose={handleCloseUpdate} shipmentInfo={shipmentInfo} />
            
        <MConfirmDelete
            open={confirmDeleteModal.open}
            handleClose={() => confirmDeleteModal.setOpen(false)}
            data={confirmDeleteModal.data}
            confirm={confirmDeleteModal.confirm}
        />
    </Box>
      );
      
}

export default Shipments;