import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const BASE = "/palletspottotals";
const ApiName = "Palletspot";
const buildUrl = (endpoint) => BASE + endpoint;

const palletSpotSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPalletspots: builder.query({
            query: ({args}) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body
            }),
            providesTags: (result, error, arg) => [{ type: ApiName }],
            onQueryStarted: muiDataGridStarted.bind(null, 'Palletspots', 'Palletspots')
        }),
        getPalletspot: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: ApiName, id }],
        }),
    })
});

export const { 
    useGetPalletspotsQuery,
    useGetPalletspotQuery
} = palletSpotSlice;
