import { Box, Button, IconButton, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { tokens } from "../../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../theme"; 
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import Subheading from "../../../../../components/theme/text/Subheading";
import Text from "../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import AllCarriersCard from "../../../../../components/theme/cards/carriers/AllCarriersCard";
import SpCarrierDetails from "../../../../../components/global/Sidepanels/SpCarrierDetails/SpCarrierDetails";
import { useTranslation } from "react-i18next";
import MAddCarrier from "../../../../../components/global/Modals/carriers/AddCarrier";
import {useGetUsersQuery, useGetUserQuery} from "../../../../../newapi/user/userSlice"
import MpAddUser from "../../../../../components/global/ModalsProgression/MpAddUser/MpAddUser";
import SpUserDetails from "../../../../../components/global/Sidepanels/SpUserDetails/SpUserDetails";
import SpUserPermissionDetails from "../../../../../components/global/Sidepanels/SpUserPermissionDetails/SpUserPermissionDetails";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetUsergroupQuery, useGetUsergroupsQuery } from "../../../../../newapi/user/usergroupSlice";
import MAddUsergroup from "../../../../../components/global/Modals/MAddUsergroup";
import { WarehouseUserSettingsCard } from "../../../../../components/theme/cards/settings/UserSettingsCard";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
// import MpAddShipper from "../../../../../components/global/ModalsProgression/MpAddShipper/MpAddShipper";
// import SpShipperDetails from "../../../../../components/global/Sidepanels/SpShipperDetails/SpShipperDetails";


const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_usergroup")}
                </Shbutton>
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};


const All = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const apiRef = props.apiRef;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    const [isPermissionExtended,setIsPermissionExtended] = useState(false);
    // const { getCarrier} = useGetCarrierQuery();
    const [selectedRowId, setSelectedRowId] = useState(1);
    const [rowSelected, setRowSelected] = useState(false);
    // const { data: usergroupData, error, isLoading } = useGetUsergroupQuery(
    //    selectedRowId,
    //     {
    //         skip: !rowSelected
    //     }
    // );
    


    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    // const [view, setView] = useState({
    //     title: "usergroups",
    //     subTitle: "Manage usergroups",
    //     icon: <Inventory2OutlinedIcon/>,
    //     // view: <AllBody apiRef={apiRef}/>,
    //     topRight:<WareHouseDropdown {...props.warehouseModal}/>,
    //     isSingleDataGrid: true,
    //     apiRef: apiRef,
    //     gridOptions:gridOptions,
    //     mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
    // })

    // const getUsergroupDetails = (row) => {  
    //     if (!row) return;
    //     setSelectedRowId(row.id);
    //     setRowSelected(true);
    // };

    // useEffect(() => {
    //     if (!isLoading && usergroupData && rowSelected) {
    //         setIsExtended(true);
    //     }
    // }, [usergroupData, isLoading, rowSelected]);

    // useEffect(() => {
    //     if(!isExtended) {
    //         setRowSelected(false);
    //     }
    // },[isExtended])
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props]);

    // useEffect(() => {
    //     if (props.apiRef.current) {
    //         props.addView({
    //             ...view,
    //             apiRef: props.apiRef
    //         },true);
    //     } 
    // }, [props.apiRef.current]);

    const [userGroupId, setUserGroupId] = useState();
    const userGroupIdRef = useRef(userGroupId);
    
    const getUsergroupDetails = (params) => {
        if (!params) return;
    
        const newuserGroupId = parseInt(params.row.id);
        // Only update state if the userGroupId has actually changed
        if (userGroupIdRef.current !== newuserGroupId) {
            setUserGroupId(newuserGroupId);
            userGroupIdRef.current = newuserGroupId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    
    
    const columns = [
        {field: "name", headerName: t("name") , flex:2, cellClassName: "user", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.value
        }},
        {field: "userPrivileges", headerName: t("rights") , flex:2, cellClassName: "user",
            sortable:false,
             renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            if(params.row.admin){
                return (
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{t("administrator")}</Text>
                )
            } else {
                return (
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{`${params.value?.length ?? 0} ${t("rights")}`}</Text>
                )
            }
            }   
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehouseUserSettingsCard skeleton={params.row.skeleton ?? false}  {...params.row}/>
                )
                // return {
                //     <UserSett
                // }
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];

    useImperativeHandle(ref, () => ({
    }));

    const handleRowClick = (params) => {
        setIsExtended(true);
    };
    
    return (
        props.isActive ?
            <Box
                sx={{
                    height:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingTop:0,
                        paddingX:0,
                    }
                }}
            >
                
                <ShDataGrid2 key={theme.palette.mode}
                    content={useGetUsergroupsQuery}
                    gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                    title={t("usergroups")}
                    gridOptions={gridOptions} 
                    sortModel={{field: columns[0].field,type:"ASC"}}
                    columns={columns} 
                    
                    onRowClick={getUsergroupDetails}>
                </ShDataGrid2>

                <MAddUsergroup onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
                <SpUserPermissionDetails userGroupId={userGroupId} isExtended={isExtended} setIsExtended={setIsExtended}/>
            </Box>
        : null
    )
});

export default All;