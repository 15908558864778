import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import ProductOverview from "./Views/ProductOverview";
import VirtualProductOverview from "./Views/VirtualProductOverview";

import TabView3 from "../../../components/theme/TabView3/TabView3";
import { relationProductConfig } from "./relationProductConfig";

const Products = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box sx={{background:"", width:1,paddingTop:9}}>
            <TabView3
            tabConfig={relationProductConfig}
                sx={{
                    background:"green",
                    minHeight:"calc(100% - 190px)",
                    background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    position:"relative",
                    zIndex:2,
                    
                    display:"flex",
                    flexDirection:"column",

                    "&:before" : {
                        content: "''",
                        display:"block",
                        width:"32px",
                        height:"32px",
                        position:"absolute",
                        left:0,
                        top:0,
                        zIndex:1,
                        backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        top:"40px",
                        height:"48px",
                        width:"24px",
                        borderTopLeftRadius:"24px",
                        boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                        // transition:"250ms"
                    },

                    "&:after" : {
                        content: "''",
                        display:"block",
                        width:"32px",
                        height:"32px",
                        position:"absolute",
                        top:0,
                        zIndex:1,
                        backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        top:"40px",
                        right:0,
                        height:"48px",
                        width:"24px",
                        borderTopLeftRadius:"24px",
                        boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                        transform:"scaleX(-1)",
                        // transition:"250ms"
                    },

                    "@media screen and (max-width: 47.9375em)" : {
                        "&:after, &:before" : {
                            top:"-0px"
                        }
                    }
                }}>
                    <>
                        <ProductOverview config={relationProductConfig.productOverview}/>
                        <VirtualProductOverview config={relationProductConfig.virtualProductOverview} />
                    </>
                </TabView3>

            

        </Box>
    );
};

export default Products;