import React, { } from "react";
import { Typography, useTheme } from '@mui/material';
import { tokens } from "../../../theme";
import cx from 'classnames';

import 'react-pro-sidebar/dist/css/styles.css';


const Subheading = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const color = typeof props.color !== "undefined" ? props.color : typeof props.variant !== "undefined" ?  colors[props.variant][400] : colors.txt["primary"];
    const fontWeight = props.fontWeight ?? "bold";

    return (
        <Typography variant="h3" fontWeight={fontWeight} fontSize={17} fontFamily="Roboto, sans-serif"

            className={cx(`${props.size}`, {
                'light': props.light,
                'allow-overflow': props.allowOverflow
            })} 
            sx={{
                color:color,
                textOverflow:"ellipsis",
                whiteSpace:"nowrap",
                overflow:"hidden",
                textAlign: props.textAlign ?? null,
                "&.light" : {
                    color:`${colors.txt["secondary"]}`,
                },
                "&.allow-overflow": {
                    whiteSpace:"unset",
                    textOverflow:"initial",
                    overflow:"visible"
                },
                ...props.sx
            }}>
                {props.children}
            </Typography>
    );
};

export default Subheading;