import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Products from "./Views/Products";
import Logs from "./Views/Logs";
import Shipments from "./Views/Shipments";
import Details from "./Views/Details";

import {useTranslation} from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../utils";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { InputDropdown } from "../../../theme/dropdowns/InputDropdown";
import { useGetWarehousesSelectQuery } from "../../../../newapi/warehouse/warehouseApiSlice";
import { useGetInboundQuery, useUpdateInboundMutation } from "../../../../newapi/inbound/inboundsSlice";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spInboundDetailsConfig } from "./spInboundDetailsConfig";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import { ENV_TYPES } from "../../Sidebar";

const SidePanelActions = (props) => {
    const { t } = useTranslation();

    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                {t("close")}
            </Shbutton>
            {/* <Shbutton 
                onClick={() => props.setIsExtended(false)} 
                color="primaryGrey" 
                variant="contained" 
                startIcon={<ModeEditOutlinedIcon/>}
            >
                {t("edit")}
            </Shbutton>
            <Shbutton 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="contained" 
                startIcon={<PictureAsPdfOutlinedIcon/>}
            >
                {t("upload_packing_list")}
            </Shbutton> */}

        </SidepanelHeaderActiongroup>
    )
}

const HeaderDateBox = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [from, setFrom] = useState({});
    const [to, setTo] =  useState({});
    
    useEffect(() => {
        setFrom(props.data?.shipmentDate && props.data?.shipmentDate !== null ? getTranslatedDateAndTime(props.data?.shipmentDate, props.t) : "-");
        setTo(props.data?.receivedToDate && props.data?.receivedToDate !== null ? getTranslatedDateAndTime(props.data?.receivedToDate, props.t) : "-");
    },[props.data])

    return (
        <>
            {/* <Text light>Date</Text> */}
            <Box display={"flex"} alignItems={"center"} width={210}>
                <Box 
                    sx={{
                        width:66,
                        flexShrink:0,
                        mr:1,
                    }}
                >
                    <Text light>Scheduled</Text>
                </Box>
                {props.skeleton ?
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <>
                        <Box marginRight={1}><Text bold>{from?.date}</Text></Box>
                        <Text>{from?.time}</Text>
                    </>
                }
            </Box>
            <Box  display={"flex"} alignItems={"center"} width={210}>
                
                <Box 
                    sx={{
                        width:66,
                        flexShrink:0,
                        mr:1,
                    }}
                >
                    <Text light>Shipped</Text>
                </Box>
                {props.skeleton ?
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <>
                        <Box marginRight={1}><Text bold>{to?.date}</Text></Box>
                        <Text>{to?.time}</Text>
                    </>
                }
            </Box>
                
        </>
    )

}

const getInboundStatus = (data, t)  => {
    let status = data.state;
    let labelcolor = "";
    let labeltext = "";
    console.log(data.state);
    switch(status){
        case "announced":
            labelcolor = "blue"
            labeltext = t('announced');
            break;
        case "approved":
            labelcolor = "green"
            labeltext = t('approved');
            break;
        case "declined":
            labelcolor = "red"
            labeltext = t('declined');
            break;
        case "cancelled":
            labelcolor = "grey"
            labeltext = t('cancelled');
            break;
        case "receivedwithoutnotice":
            labelcolor = "purple"
            labeltext = t('receivedwithoutnotice');
            break;
        default:
            labelcolor = "orange"
            labeltext = t('unknown');
            break;
    }
    return {text: labeltext, color: labelcolor};
}

const InboundDropdown = (props) => { 
    const { t } = useTranslation();
    const { data: warehouses, warehousesLoading } = useGetWarehousesSelectQuery();
    const [updateProduct,{isLoading, isSuccess}] = useUpdateInboundMutation();

    const handleChange = async(selectedOption) => {
        updateProduct({ id: props.id, warehouseId: selectedOption.id })
            .unwrap()
            .then((updatedInbound) => {
                // Handle the success state
                toast.success(t('Inbound updated successfully'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                // Handle the error state
                toast.error(t('Inbound update failed'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    return (
        <Box
            sx={{
                mr:2,
                "@media screen and (max-width: 63.9375em)" : {
                    width:1,
                    mr:0,
                }
                
            }}
        >
            <InputDropdown
                variant="darker"
                displayName={"name"}
                onChange={(selected) => handleChange(warehouses.data[selected])}
                noSelection={t("choose_option")} 
                options={warehouses?.data ?? []}
                selected={warehouses?.data ? warehouses?.data.findIndex(warehouse => warehouse.id === props.warehouseId) ?? -1 : -1}
                closeOnSelection
            />
        </Box>
    )
}

const SpInboundDetails = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];

    const { data } = props;

    const { t, ready, i18n } = useTranslation();
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    const { data: inboundData, isLoading,isFetching,isError } = useGetInboundQuery(props.inboundId,
    {
        skip: !props.inboundId
    });
        

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ImportExportIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <HeaderDateBox skeleton t={i18n}/> },
        { component: <ShRowSkeleton heading={t("packing_list")} /> },
        { component: <ShRowSkeleton heading={t("pallets")} /> },
        { component: <ShRowSkeleton heading={t("colli")} /> },
        { component: <ShRowSkeleton heading={t("sku_amount")} /> },
        { component: <ShRowSkeleton heading={t("items")} /> }
    ], [t]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions: <SidePanelActions setIsExtended={setIsExtendedCallback} />,
    }), [defaultIcon, defaultOptions, setIsExtendedCallback,props.data]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.inboundId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && inboundData) {
            // let locations = productData.stocks.map(stock => stock.warehouseLocation.location);
            // let combinedLocations = locations.join(", ");
            const { text, color } = getInboundStatus(inboundData, t);

            setPanel(prevPanel => ({
                ...prevPanel,
                title: inboundData.reference ?? t("reference_missing"),
                subTitle: text,
                icon :defaultIcon,
                options : [
                    ...isFulfilment ? [{
                        heading: t("relation"),
                        content: inboundData.relation?.name
                    }] : [],
                    {
                        component: <HeaderDateBox data={inboundData} t={i18n}/>
                    },
                    {
                        heading: t("packing_list"),
                        content: "list.pdf"
                    },
                    {
                        heading: t("pallets"),
                        content: inboundData.pallets
                    },
                    {
                        heading: t("colli"),
                        content: inboundData.colli
                    },
                    {
                        heading: t("sku_amount"),
                        content: inboundData.skuCount
                    },
                    {
                        heading: t("items"),
                        content: inboundData.itemCount
                    }
                ],
                topRight: !isRelation ? <InboundDropdown id={props.inboundId ?? 0} warehouseId={inboundData.warehouseId ?? 0} /> : <></>
            }));
        }
    }, [inboundData, isLoading, isFetching, t,theme.palette.mode]);
   
    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spInboundDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} 
        >
            <>
                <Products config={spInboundDetailsConfig.products} data={inboundData}/>
                <Details config={spInboundDetailsConfig.details} data={inboundData} inboundId={props.inboundId} setIsExtended={props.setIsExtended}/>
                <Shipments config={spInboundDetailsConfig.shipments} inboundId={props.inboundId}/>
                <Logs config={spInboundDetailsConfig.logs} id={props.inboundId}/> 
            </>
        </Sidepanel2>
    );
};

export default SpInboundDetails;