// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import { ReactComponent as DhlLogo } from '../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../../styles/svg/postnl.svg';
import { ReactComponent as BolLogo } from '../../../../../styles/svg/bol.svg';
import { ReactComponent as ParcelLogo } from '../../../../../styles/svg/parcel.svg';
import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined';
import { setProgressionInfo } from '../../../../../newapi/global/progressionTabSlice';
import { useDispatch } from 'react-redux';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    
    const dispatch = useDispatch();
    // if (props.title || props.subtitle || typeof props.maxSteps !== 'undefined') {
    //     dispatch(setProgressionInfo({ title: props.title, subtitle: props.subtitle, maxSteps: props.maxSteps }));
    //   }

    const columns = [
        {title: "PostNL", logo: <PostNLLogo width={60} height={60}/>, key: "postnl"},
        {title: "DHL", logo: <DhlLogo width={60} height={60}/>, key: "dhl"},
        {title: "Dpd", logo: <DpdLogo width={60} height={60}/>, key: "dpd"},
        // {title: "Bol.", logo: <BolLogo width={60} height={60}/>, key: "bol"}, // Bol.com is not a shipper, it's a webshop
        {title: "Parcel", logo: <ParcelLogo width={60} height={60}/>, key: "parcel"},
        {title: "Anders", logo: <AltRouteOutlinedIcon sx={{fontSize:"60px"}} />, key: "other"}
        
    ]
    // New prop is called "Logo"
    // {title: 'normal', logo: <CheckCircleOutlineIcon />, key: "normal"},
    //     {title: 'runner', logo: <DirectionsRunIcon />, key: "runner"},
    //     {title: 'highcare', logo: <FavoriteOutlinedIcon />, key: "highcare"},
    //     {title: 'special', logo: <AutoAwesomeOutlinedIcon />, key: "special"}

    const handleOptionSelect = (option) => {
        props.handleDataUpdate({ selectedOption: columns[option].key });
        props.handleNext("tabBasicInfo");

        if(columns[option].key === "other") {
            dispatch(setProgressionInfo({maxSteps:5}));
        }else {
            dispatch(setProgressionInfo({maxSteps:6}));
        }
    };

    return (
            <SelectGroup selected={-1} setSelected={handleOptionSelect} options={columns} image={true} />
    );
};

const ChooseShipperType = (props) => {
    return (
        <ProgressionTab tabId="tabChooseType" step={1} maxSteps={6}>
            <Content/>
        </ProgressionTab>
    )
}

export default ChooseShipperType;

