import { Box, InputBase, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useCallback, useEffect, useMemo, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import WidgetsIcon from '@mui/icons-material/Widgets';

import { useTranslation } from "react-i18next";
import { showToastMessageDeleteRequest, showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import { useDeleteReachTypeMutation, useUpdateReachTypeMutation } from "../../../../../newapi/warehouse/reachTypeSlice";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../../../utils/validation";
import { useDeleteReturnReasonMutation, useUpdateReturnReasonMutation } from "../../../../../newapi/inbound/returnsSlice";
import cx from 'classnames';
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [returnReasonInfo, setReturnReasonInfo] = useState({
      id: 0,
      description: "",
      requireImageUpload: false,
    });

    useEffect(() => {
        setReturnReasonInfo(props.data);
    },[props.data]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        description: yupRequired('description', t)           
    };  

    const schema = yup.object().shape(rules);

    const [UpdateReturnReason,{isLoading}] = useUpdateReturnReasonMutation();
    const handleSaveClick = async () => {
        try {
            setFieldErrors({});
            await schema.validate(returnReasonInfo, { abortEarly: false });
            const response = await UpdateReturnReason({id: props.data.id, reason: returnReasonInfo}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                    ...acc,
                    [currentError.path]: currentError.message
                }), {});
            
                // Set the validation errors in the state
                setFieldErrors(errors);
            } else {
                // Handle other errors, such as network errors
                showToastMessageUpdateRequest(t, {success: false, error: err.error});
            }
        }
    };

    const [open, setOpen] = useState(false);
    const [DeleteReturnReason] = useDeleteReturnReasonMutation();
    const confirmDelete = async () => {
        try {
            const response = await DeleteReturnReason(props.data.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
  };

  const handleDeleteClick = async () => {
      setOpen(true);
  };

  const confirmDeleteModal = {
      data: returnReasonInfo?.description,
      open: open,
      setOpen: setOpen,
      confirm: confirmDelete
  }
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions 
                id={returnReasonInfo?.id}
                action={handleSaveClick}
                deleteAction={handleDeleteClick}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[returnReasonInfo,handleSaveClick,handleDeleteClick]);

    

    useEffect(() => {
        setReturnReasonInfo(props.data);
    },[props.data]);

    const handleChange = (key, value) => {
        setReturnReasonInfo({
          ...returnReasonInfo,
          [key]: value
        });
      };
    
    

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
        <Dropdown title={t("general")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:160,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("description")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                name="description"
                                value={returnReasonInfo?.description || ""}
                                onChange={handleChange}
                                error={fieldErrors.description}
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:160,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text>{t("image_upload_required")}</Text>
                        </Box>
                        <Box display="flex"
                            height="40px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                        <ToggleButtonGroup
                            color={returnReasonInfo?.requireImageUpload === "true" || returnReasonInfo?.requireImageUpload === true ? "green" : "red"}
                            value={returnReasonInfo?.requireImageUpload === "true" || returnReasonInfo?.requireImageUpload === true ? "true" : "false"}
                            exclusive
                            onChange={(e) => handleChange('requireImageUpload', e.target.value )}
                            aria-label="Active"
    
                            className={cx(`${returnReasonInfo?.requireImageUpload}`, {
                                'active': returnReasonInfo?.requireImageUpload === "true" || returnReasonInfo?.requireImageUpload === true
                            })}
                            sx={{
                                background: colors.red[100],
                                padding:0.5,
                                borderRadius:5,
                                transition:"250ms",
                                "&.active" : {
                                    background:colors.green[100],
                                    transition:"250ms",
                                },  
                                "&.active" : {
                                    background:colors.green[100],
                                    transition:"250ms",
                                },
                            }}
                        >
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.green[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.red[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="false">
                                {t('no')}
                            </ToggleButton>
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.red[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.green[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="true"
                            >
                                {t('yes')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
        <Box
            sx={{
                position:"absolute",
                bottom:0,
                right:0,
                padding:3,
                width:1,
                background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                    padding:2,
                    paddingY:2.5,
                }
            }}
        >
        <GridActions
            id={returnReasonInfo?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
        />
            </Box>
            <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
        </Box>
    );
}

export default DetailsPanel;