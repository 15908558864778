import AddIcon from '@mui/icons-material/Add';
import CropFreeIcon from '@mui/icons-material/CropFree';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Shbutton from "../../../../theme/buttons/Shbutton";

import ShLabel from "../../../../theme/label/ShLabel";
import Text from "../../../../theme/text/Text";

import { useTranslation } from "react-i18next";
import SpStockDetails from "../../../../../components/global/Sidepanels/SpStockDetails/SPStockDetails";
import { useGetInboundLinesQuery } from '../../../../../newapi/inbound/inboundsSlice';
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import ShLabelSkeleton from '../../../../theme/skeleton/ShLabelSkeleton';
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';
import { WarehouseInboundLineProductCard } from '../../../../theme/cards/inbounds/InboundLineProductCard';

// import classNames from "classnames";
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>{t("save")}</Shbutton>
        </ButtonGroup>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    
    filter : {
        name: "productsFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Products = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.product?.id);
        if(newProductId === null || isNaN(newProductId)) {
            return;
        }
        

        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    const columns = [
        {field: "sku", headerName: t("sku"), renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
            }
            // const handleClick = () => getProductDetails(params.row.product);
            const sku = params.row.isDescriptionRow ? "" : params.row.product.sku;
            return (
                <Box >
                    <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{sku}</Text>
                </Box>
            );
            // if(params.row.skeleton) {
            //     return <ShTextSkeleton/>
            // }
            // return (
            //     <div onClick={() => getProductDetails(params.row.product.id)}>
            //     <Box>
            //         <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product.sku}</Text>
            //     </Box>
            //     </div>
            // )
        }},
        {field: "description", headerName: t("description"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            const description = params.row.isDescriptionRow ? params.row.description : params.row.product.description;
            return (
                <Box>
                    <Text semibold>{description}</Text>
                </Box>
            )
        }},
        {field: "amountForecasted", headerName: t("forecasted"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        {field: "amountReceived", headerName: t("received"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                let status = params.row.state;
                let labelcolor = "";
                let labeltext = "";
                switch(status){
                    case "notreceived":
                        labelcolor = "grey"
                        labeltext = t('not_received');
                        break;
                    case "partlyreceived":
                        labelcolor = "orange"
                        labeltext = t('partly_received');
                        break;
                    case "fullyreceived":
                        labelcolor = "green"
                        labeltext = t('fully_received');
                        break;
                    default:
                        labelcolor = "red"
                        labeltext = t('unknown');
                        break;
                }
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
        {field: "remark", headerName: t("remark"), flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            if(params.row.remark !== ""){
                return (
                    <Text light><MessageIcon/></Text>
                    
                  );
            } else {
                
            }
            
          }},
        {
            field: "phoneView", 
            headerName: "phoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehouseInboundLineProductCard skeleton={params.row.skeleton ?? false} {...params.row} onClick={() => getProductDetails(params)}/>
                )
            }
        }
    ];

    return (
        props.isActive ? (
          <>
            <Box height={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
                {props.data && props.data.id && 
                    <ShDataGrid2 key={theme.palette.mode}
                        itemId={props.data?.id}
                        content={useGetInboundLinesQuery}
                        // gridActions={<GridActions></GridActions>}
                        title={t("products")}
                        gridOptions={gridOptions}
                        isSidepanelGrid
                        columns={columns}
                        onRowClick={getProductDetails}
                    />
                }
            </Box>
            <SpStockDetails
              isExtended={isExtended}
              setIsExtended={setIsExtended}
              productId={productId}
            //   data={productData}
            />
          </>
        ) : null
      );
      
}

export default Products;