import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useUpdateProductMutation } from "../../../../../newapi/warehouse/productSlice";
import SpDesktopFooter from "../../../../theme/sidepanel2/SidepanelFooter";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import ShValidatedNumberInput from "../../../../theme/inputs/ShValidatedNumberInput";
import EMGeneralExplainerBox from "../../../ModalsPopover/explainers/EMGeneralExplainerBox";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
        <Shbutton
            className={"group-btn"}
            onClick={props.action}
            color={theme.palette.mode === "dark" ? "blue" : "primary"}
            variant="contained"
            endIcon={<SaveIcon />}
        >
            {t('save')}
        </Shbutton>
    </ButtonGroup>
    );
};

const SizeAndWeight = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const  { t } = useTranslation();

    const [productInfo, setProductInfo] = useState({
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        stackSize: 0,
        stackHeight: 0,
    });
    const [fieldErrors, setFieldErrors] = useState({});
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions productInfo={productInfo}></GridActions>
        });
    },[productInfo]);
    
    useEffect(() => {
        if(props.data){
            setProductInfo(props.data);
        }
    },[props.isActive,props.data]);


    const handleNumberChange = (key, val) => {
        setProductInfo({ ...productInfo, [key]: val });
    };

    const [UpdateProduct,{isLoading}] = useUpdateProductMutation();
    const handleSaveClick = async () => {
        try {
            const productObj = { 
                length: productInfo.length, 
                width: productInfo.width, 
                height: productInfo.height, 
                weight: productInfo.weight,
                stackSize: productInfo.stackSize,
                stackHeight: productInfo.stackHeight,
                id: productInfo.id
            };
            
            // setFieldErrors({});
            // await schema.validate(productInfo, { abortEarly: false });
            const data = await UpdateProduct(productObj).unwrap()
            showToastMessageUpdateRequest(t, data);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                    ...acc,
                    [currentError.path]: currentError.message
                }), {});
            
                // Set the validation errors in the state
                setFieldErrors(errors);
            } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("update_failed"), t, err);
            }
        }       
    };

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3} overflow={"auto"}>
        <Dropdown title={t('dimensions_and_weight')} open>
            
            <Box
                sx={{
                    display:"flex",
                    gap:4,
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column",
                        gap:2
                    }
                }}
            >
                <Box 
                    display={"flex"} 
                    flexDirection={"column"}
                    sx={{
                        gap:1,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            flexDirection:"row",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:3
                        }
                    }}
                    >
                    <Box
                        sx={{
                            "@media screen and (max-width: 48em)" : {
                                width:150
                            }
                        }}>
                        <Text light>
                            {t('length')}
                        </Text>
                        <Text italic>&nbsp;({t("mm")})</Text>
                    </Box>
                    <ShValidatedNumberInput
                        btnSize={"24"}
                        name="length"
                        value={productInfo?.length ?? 0}
                        onChange={(e, val) =>  handleNumberChange('length', val)}
                        error={fieldErrors.length}
                    />
                </Box>
                <Box 
                    display={"flex"} 
                    flexDirection={"column"}
                    sx={{
                        gap:1,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            flexDirection:"row",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:3
                        }
                    }}
                    >
                    <Box
                        sx={{
                            "@media screen and (max-width: 48em)" : {
                                width:150
                            }
                        }}>
                        <Text light>
                            {t('width')}
                        </Text>
                        <Text italic>&nbsp;({t("mm")})</Text>
                    </Box>
                    <ShValidatedNumberInput
                        btnSize={"24"}
                        name="width"
                        value={productInfo?.width ?? 0}
                        onChange={(e, val) =>  handleNumberChange('width', val)}
                        error={fieldErrors.width}
                    />
                </Box>
                <Box 
                    display={"flex"} 
                    flexDirection={"column"}
                    sx={{
                        gap:1,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            flexDirection:"row",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:3
                        }
                    }}
                    >
                    <Box
                        sx={{
                            "@media screen and (max-width: 48em)" : {
                                width:150
                            }
                        }}>
                        <Text light>
                            {t('height')}
                        </Text>
                        <Text italic>&nbsp;({t("mm")})</Text>
                    </Box>
                    <ShValidatedNumberInput
                        btnSize={"24"}
                        name="height"
                        value={productInfo?.height ?? 0}
                        onChange={(e, val) =>  handleNumberChange('height', val)}
                        error={fieldErrors.height}
                    />
                </Box>
                <Box 
                    display={"flex"} 
                    flexDirection={"column"}
                    sx={{
                        gap:1,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            flexDirection:"row",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:3
                        }
                    }}
                    >
                    <Box
                        sx={{
                            "@media screen and (max-width: 48em)" : {
                                width:150
                            }
                        }}>
                        <Text light>
                            {t('weight')}
                        </Text>
                        <Text italic>&nbsp;(gram)</Text>
                    </Box>
                    <ShValidatedNumberInput
                        btnSize={"24"}
                        name="weight"
                        value={productInfo?.weight ?? 0}
                        onChange={(e, val) =>  handleNumberChange('weight', val)}
                        error={fieldErrors.weight}
                    />
                </Box>
            </Box>

        </Dropdown>
        <Dropdown title={t('extra_options')} open>
            <Box
                sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:1.5,
                }}
            >
                <Box 
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            justifyContent:"space-between",
                        }
                    }}
                >
                    <Box
                        sx={{
                            width:220,
                            "@media screen and (max-width:63.9375em)" : {
                                width:130,
                            }
                        }}
                    >
                        <Text light>{t('stack_size')}</Text>
                        <EMGeneralExplainerBox title={t('em_stacksize_title')} text={t('em_stacksize_text')}/>
                    </Box>
                        {/* Secondary SKU */}

                    <Box  display="flex"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedNumberInput
                            width={1}
                            btnSize={"24"}
                            name="stackSize"
                            value={productInfo?.stackSize ?? 0}
                            onChange={(e, val) =>  handleNumberChange('stackSize', val)}
                            error={fieldErrors.stackSize}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1,
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            justifyContent:"space-between",
                        }
                    }}
                >
                    <Box
                        sx={{
                            width:220,
                            "@media screen and (max-width:63.9375em)" : {
                                width:130,
                            }
                        }}
                    >
                        <Text light>{`${t('stack_height')} (${t("mm")})`}</Text>
                        <EMGeneralExplainerBox title={t('em_stackheight_title')} text={t('em_stackheight_text')}/>
                    </Box>
                        {/* Secondary SKU */}

                    <Box display="flex"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedNumberInput
                            btnSize={"24"}
                            name="stackHeight"
                            value={productInfo?.stackHeight ?? 0}
                            onChange={(e, val) =>  handleNumberChange('stackHeight', val)}
                            error={fieldErrors.stackHeight}
                        />
                    </Box>
                </Box>
            </Box>
        </Dropdown>
        <SpDesktopFooter>
                <GridActions 
                id={props.data?.id}
                action={handleSaveClick} />
        </SpDesktopFooter>

        </Box>
    );
}

export default SizeAndWeight;