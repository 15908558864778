import { Box, ButtonBase, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import Subheading from "../../../components/theme/text/Subheading";
import Text from "../../../components/theme/text/Text";
import { selectUser } from "../../../newapi/user/authentication/authSlice";
import { tokens } from "../../../theme";
import Personal from "./views/Personal";

import Icon from "../../../components/theme/icons/Icon";

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import TabView3 from "../../../components/theme/TabView3/TabView3";
import { userAccountConfig } from "./userAccountConfig";

const AccountGeneral = forwardRef((props, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = useState(false);
    const warehouseModal = {
        open: open,
        setOpen: setOpen
    }
    
    const user = useSelector(selectUser);

    useImperativeHandle(ref, () => ({
    }));
    return (
        <Box 
        sx={{
            background:"", 
            width:1,
            paddingTop:9,
            position:"relative",
            "@media screen and (max-width:47.9375em)" : {
                    background:"", 
                width:1,
                marginTop:9,
                height: "calc(100% - 72px)",
                // position:"relative",
                paddingX:4,
                paddingTop:4,
                overflow:"hidden",
                display:"flex",
                flexDirection:"column",
                
            },
            "@media screen and (max-width: 47.9375em)" : {
                paddingX:0,
                paddingTop:1,
                background:colors.bg["tertiary"],
                "&:before,&:after":{
                    display:"none"
                }
            },
        }}
        >

            <Box
                sx={{
                    // display:"none",
                    // "@media screen and (max-width: 47.9375em)" : {
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        width:1,
                        paddingX:4,
                        pt:2,
                        pb:3,
                        background: colors.bg['tertiary'],
                        "@media screen and (max-width: 47.9375em)" : {
                            pt:0,
                            pl:3,
                            pr:0,
                        }
                        // marginBottom:6.5,
                    // }
                }}
            >
                <Box
                
                    sx={{
                        height: 48,
                        display: "flex",
                        alignItems:"center",
                        boxShadow: ' 0px 1px 2px 0px rgba(49, 65, 95, 0.25)',
                        pl:0.5,
                        pr:3,
                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                        borderRadius:6,
                        "@media screen and (max-width: 47.9375em)" : {
                            background:"unset",
                            pl:0,
                            boxShadow:"none",
                            pr:0,
                        }
                        
                        // flexDirection:"column",
                        // gap:0.5,
                    }}
                >
                    <Icon rounded theme="pink"> <PersonOutlinedIcon/> </Icon>
                    <Box sx={{
                        ml:2,
                        display:"flex",
                        alignItems:"center",
                        gap:2,
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column",
                            gap:0,
                            alignItems:"flex-start"
                        }
                    }}>
                        <Subheading>{user?.name}</Subheading>
                        <Text>{user?.email}</Text>
                    </Box>
                </Box>

                {/* REFRESH DESKTOP */}
                <Box
                    sx={{
                        height: 48,
                        display: "flex",
                        alignItems:"center",
                        boxShadow: ' 0px 1px 2px 0px rgba(49, 65, 95, 0.25)',
                        pr:0.5,
                        pl:3,
                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                        borderRadius:6,
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"none"
                        }

                        
                        // flexDirection:"column",
                        // gap:0.5,
                    }}
                >
                    <Box
                        sx={{
                            mr:3,
                            display:"flex",
                            alignItems:"center",
                            gap:2,
                        }}
                    >
                        <Text light>Laatste update:</Text>
                        <Subheading>TBA</Subheading>
                        {/* <Text>{user.email}</Text> */}
                    </Box>
                    <ButtonBase
                        sx={{
                            width:40,
                            height:40,
                            background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                            color:"#fff",
                            borderRadius:5,
                        }}
                    >
                        <RefreshIcon/>
                    </ButtonBase>
                </Box>

                {/* Refresh and activity MOBILE */}
                <Box
                    sx={{
                        height: 48,
                        display: "none",
                        alignItems:"center",
                        boxShadow: ' 0px 1px 2px 0px rgba(49, 65, 95, 0.25)',
                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                        // borderRadius:6,
                        borderTopLeftRadius:24,
                        borderBottomLeftRadius:24,
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"flex",
                            
                        }

                        
                        // flexDirection:"column",
                        // gap:0.5,
                    }}
                >

                    <ButtonBase
                        sx={{
                            width:40,
                            height:40,
                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                            color:colors.txt["primary"],
                            borderTopLeftRadius:24,
                            borderBottomLeftRadius:24,
                        }}
                    >
                        <DirectionsRunIcon/>
                    </ButtonBase>

                    <ButtonBase
                        sx={{
                            width:40,
                            height:40,
                            background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                            color:"#fff",
                            borderTopLeftRadius:24,
                            borderBottomLeftRadius:24,
                        }}
                    >
                        <RefreshIcon/>
                    </ButtonBase>
                </Box>

            </Box>

                    
            <TabView3
                tabConfig={userAccountConfig}
                // viewType="bodytabs"
                sx={{
                    minHeight:"calc(100%)",
                    background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    position:"relative",
                    zIndex:2,
                    height:1,
                    display:"flex",
                    flexDirection:"column",
                    // "@media screen and (max-width: 47.9375em)" : {
                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"48px",
                            position:"absolute",
                            left:0,
                            top:"40px",
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transition:"250ms"
                        },
        
                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"48px",
                            position:"absolute",
                            top:"40px",
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            right:0,
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            transition:"250ms"
                        },
                        "@media screen and (max-width: 47.9375em)" : {

                            "&:before, &:after" : {
                                top:0,
                                width:"24px",
                                // height:"24px",
                            }
                        }
                    // }
                  
                }}
                // title={panel.title}
                // views={panel.views}
                // tabClasses={"btn-body-tab"}
            >
                <>
                    <Personal config={userAccountConfig.personal}/>
                    {/* <Business /> */}
                </>
            </TabView3>

        </Box>
    )
});

export default AccountGeneral;