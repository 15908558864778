import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const STRATEGY_BASE = "/countingstrategies";
const buildUrl = (endpoint) => STRATEGY_BASE + endpoint;
const API_TAG = 'CountingStrategies'
const API_TAG_SINGLE = 'CountingStrategySingle'

const countingStrategySlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCountingStrategies: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: API_TAG }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getCountingStrategies', API_TAG)
        }),
        getCountingStrategy: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            // providesTags: (id) => [{ type: `CountingStrategyTemplateSingle`,id }]
            providesTags: (result, error, arg) => [{ type: API_TAG_SINGLE, id: arg }]
        }),
        updateCountingStrategy: builder.mutation({
            query: countingStrategyInfo => ({
                url: buildUrl(`/${countingStrategyInfo.id}`),
                method: 'PUT',
                body: countingStrategyInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: API_TAG_SINGLE, id: arg.id }],
        }),
        completeCountingStrategy: builder.mutation({
            query: id => ({
                url: buildUrl(`/${id}/complete`),
                method: 'PUT',
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: API_TAG_SINGLE, id: arg.id }],
        }),
        deleteCountingStrategy: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: API_TAG_SINGLE, id: arg.id }],
        }),
        getCountingStrategySteps: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/steps?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: API_TAG_SINGLE, id: arg.id }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getCountingStrategyProducts', API_TAG_SINGLE)
        }),
    })
});

export const {
    useGetCountingStrategiesQuery,
    useGetCountingStrategyQuery,
    useUpdateCountingStrategyMutation,
    useDeleteCountingStrategyMutation,
    useGetCountingStrategyStepsQuery,
    useCompleteCountingStrategyMutation
  } = countingStrategySlice;
