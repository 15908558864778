import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";

import { useTranslation } from 'react-i18next';
import Details from "./Views/Details";

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spUserDetailsConfig } from "./spUserDetailsConfig";
import { useGetUserQuery } from "../../../../newapi/user/userSlice";
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import { MailOutline } from "@mui/icons-material";
import { useRequestPasswordResetMutation } from "../../../../newapi/user/passwordresetSlice";
import { showToastMessage, showToastMessagePostRequest, showToastMessageRequestError } from "../../../../utils/toasts";
const SidePanelActions = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

                <Shbutton
                    className={"group-btn"}
                    onClick={() => props.sendInvite()}
                    color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    variant="contained"
                    endIcon={<MailOutline />}
                >
                    {t('send_invite')}
                </Shbutton>
        </SidepanelHeaderActiongroup>
    )
}

const SpUserDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: userData, isLoading,isFetching,isError } = useGetUserQuery(props.userId,
    {
        skip: !props.userId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <Person2OutlinedIcon/>
    }), [theme.palette.mode]);

    // const defaultOptions = useMemo(() => [
    //     { component: <ShRowSkeleton heading={t("order")} /> },
    //     { component: <ShRowSkeleton heading={t("reference")} /> },
    //     { component: <ShRowSkeleton heading={t("name")} /> },
    //     { component: <ShRowSkeleton heading={t("phone")} /> },
    //     { component: <ShRowSkeleton heading={t("email")} /> },
    //     { component: <ShRowSkeleton heading={t("relation")} /> },
    //     { component: <HeaderDateBox skeleton t={i18n}/> },
    //     { component: <ShRowSkeleton heading={t("warehouse")} /> },
    //     { component: <ShRowSkeleton heading={t("sku_amount")} /> },
    //     { component: <ShRowSkeleton heading={t("items")} /> },
    // ], [t]);
    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    const [SendInviteToUser, {isLoading: isSendingInvite}] = useRequestPasswordResetMutation();
    const sendInvite = () => {
        if(isSendingInvite) return;
        if(!userData) return;
        if (userData?.email) {
            SendInviteToUser({email: userData.email});
            showToastMessage("success", t("invite_sent"));
        } else {
            showToastMessageRequestError("invite_failed", t, {success: false, data: { error: "user_has_no_email" }});
        }
    }

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        // options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} sendInvite={sendInvite} userData={userData} t={t}/>   
    }), [defaultIcon,/*defaultOptions,*/ setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.userId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && userData) {
        
            setPanel((prevPanel) => ({
                ...prevPanel,
                title: `${userData.firstname}${userData.insertion ? "" + userData.insertion : ""} ${userData.lastname}`,
                icon :defaultIcon,
                subTitle: userData.email,
                options: [],
            }));
        }
    }, [userData, isLoading,isFetching, t,theme.palette.mode]);

    


    return (
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={spUserDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spUserDetailsConfig.details} data={userData} setIsExtended={props.setIsExtended}/>
                </>
        </Sidepanel2>
    );
};

export default SpUserDetails;