import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import Text from "../../../../theme/text/Text";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import { useGetInvoiceCategoryRelationsQuery } from "../../../../../newapi/financial/invoiceCategorySlice";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import SpRelationDetails from "../../SpRelationDetails/SpRelationDetails";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Shbutton from "../../../../theme/buttons/Shbutton";
import MAddInvoiceCategory from "../../../Modals/MAddInvoiceCategory";
import MBindRelationToCategory from "../../../Modals/MBindRelationToCategory";
const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};


const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('add_relation')}
            </Shbutton>
        </ButtonGroup>
    )
}


const Relations = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [isExtended, setIsExtended] = useState(false);


    const [relationId, setRelationId] = useState();
    const relationIdRef = useRef(relationId);
    
    const handleOpen = () => {
        setAddModalOpen(true);
    }
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };
    
    const getRelationDetails = (params) => {
        if (!params && params.row) return;
    
        const newRelationId = parseInt(params.row.id);
        // Only update state if the productId has actually changed
        if (relationIdRef.current !== newRelationId) {
            setRelationId(newRelationId);
            relationIdRef.current = newRelationId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions/>,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const columns = [
        {
            field: "name", 
            headerName: t('name'),
            flex:2, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if (params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Text bold clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.name}</Text>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetInvoiceCategoryRelationsQuery}
                itemId={props.invoiceCategoryId}
                gridActions={<GridActions handleOpen={handleOpen}></GridActions>} 
                title={t('relations')}
                gridOptions={gridOptions} 
                onRowClick={getRelationDetails}
                isSidepanelGrid 
                columns={columns} 
                rows={data}/>

            <SpRelationDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                relationId={relationId}
            ></SpRelationDetails>

            <MBindRelationToCategory 
                invoiceCategoryId={props.invoiceCategoryId} 
                onOpen={() => addModal.setOpen(true)} 
                onClose={() => addModal.setOpen(false)} 
                open={addModal.open} handleClose={handleClose} 
            />
        </Box>
    );
}

export default Relations;