import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import {  useDeleteRelationMutation, useEditRelationMutation } from "../../../../../newapi/global/relation/relationSlice";
import { useGetInvoiceCategorySelectQuery } from "../../../../../newapi/financial/invoiceCategorySlice";

import { useTranslation } from "react-i18next";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { showToastMessageDeleteRequest, showToastMessageRequestError, showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";
import SpDesktopFooter from "../../../../theme/sidepanel2/SidepanelFooter";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../../../utils/validation";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                isLoading={props.isLoading}
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [fieldErrors, setFieldErrors] = useState({});
    const rules = {
        name: yupRequired('name', t),
    };
    const schema = yup.object().shape(rules);

    const [relationInfo, setRelationInfo] = useState({
      id: 0,
      name: "",
      phone: "",
      email: "",
      website: "",
      street: "",
      house_nr: "",
      house_nr_extra: "",
      postal: "",
      city: "",
      kvk: "",
      vatNumber: "",
      eoriNumber: "",
      debtorNumber: "",
      invoiceCategoryId: 0
    });

    const [open, setOpen] = useState(false);
    
    const { data: invoiceCategories, invoiceCategoryIsLoading } = useGetInvoiceCategorySelectQuery();
    
    const [EditRelation,{isLoading}] = useEditRelationMutation();
    const handleSaveClick = async () => {
        try {
            if(isLoading) return;

            setFieldErrors({});
            await schema.validate(relationInfo, { abortEarly: false });
            const response = await EditRelation(relationInfo).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                    ...acc,
                    [currentError.path]: currentError.message
                }), {});
            
                // Set the validation errors in the state
                setFieldErrors(errors);
            } else {
                showToastMessageRequestError(t("update_failed"), t, err);
            }
        }
    };

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions 
                id={relationInfo?.id}
                action={handleSaveClick}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[handleSaveClick,relationInfo]);

    

    useEffect(() => {
        setRelationInfo(props.data);
    },[props.data]);

    const handleChange = (key, value) => {
        setRelationInfo({
          ...relationInfo,
          [key]: value
        });
      };

    const handleDeleteClick = async () => {
        setOpen(true);
    };


    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
        <Dropdown title={t("contact")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("name")}</Text>
                    </Box>
                    <Box display="flex"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="name"
                            value={relationInfo?.name}
                            onChange={handleChange}
                            error={fieldErrors.name}
                        />
                    </Box>
                </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("phone")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="phone"
                                value={relationInfo?.phone}
                                onChange={handleChange}
                                error={fieldErrors.phone}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("email")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="email"
                                value={relationInfo?.email}
                                onChange={handleChange}
                                error={fieldErrors.email}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("website")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="website"
                                value={relationInfo?.website}
                                onChange={handleChange}
                                error={fieldErrors.website}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
        <Dropdown title={t("address_details")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("street")}</Text>
                    </Box>
                    <Box display="flex"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="street"
                            value={relationInfo?.street}
                            onChange={handleChange}
                            error={fieldErrors.street}
                        />
                    </Box>
                </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("house_nr")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="house_nr"
                                value={relationInfo?.house_nr}
                                onChange={handleChange}
                                error={fieldErrors.house_nr}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("house_nr_extra")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="house_nr_extra"
                                value={relationInfo?.house_nr_extra}
                                onChange={handleChange}
                                error={fieldErrors.house_nr_extra}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("postal")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="postal"
                                value={relationInfo?.postal}
                                onChange={handleChange}
                                error={fieldErrors.postal}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("city")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="city"
                                value={relationInfo?.city}
                                onChange={handleChange}
                                error={fieldErrors.city}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
        <Dropdown title={t("other_details")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("coc_number")}</Text>
                    </Box>
                    <Box display="flex"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="kvk"
                            value={relationInfo?.kvk}
                            onChange={handleChange}
                            error={fieldErrors.kvk}
                        />
                    </Box>
                </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("vat_number")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="vatNumber"
                                value={relationInfo?.vatNumber}
                                onChange={handleChange}
                                error={fieldErrors.vatNumber}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("eori_number")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                            <ShValidatedInput
                                height={40}
                                name="eoriNumber"
                                value={relationInfo?.eoriNumber}
                                onChange={handleChange}
                                error={fieldErrors.eoriNumber}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
        <Dropdown title={t("financial")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("debtor_number")}</Text>
                    </Box>
                    <Box display="flex"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="debtorNumber"
                            value={relationInfo?.debtorNumber}
                            onChange={handleChange}
                            error={fieldErrors.debtorNumber}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("category")}</Text>
                    </Box>
                    <Box display="flex"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                    <InputDropdown
                        name="name"
                        displayName="name"
                        selected={invoiceCategories ? invoiceCategories.findIndex(item => item.id === relationInfo?.invoiceCategory?.id) ?? -1 : -1}
                        options={invoiceCategories ?? []}
                        noSelection={t("choose_option")} 
                        onChange={(selected) => handleChange('invoiceCategoryId', invoiceCategories[selected]?.id)}
                        closeOnSelection
                    />
                    </Box>
                </Box>
                </Box>
            </Box>
        </Dropdown>
            <SpDesktopFooter>

                <GridActions
                    id={relationInfo?.id}
                    action={handleSaveClick}
                    isLoading={isLoading}
                />
            </SpDesktopFooter>
            {/* <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                <GridActions
                    id={relationInfo?.id}
                    action={handleSaveClick}
                />
            </Box> */}
        </Box>
    );
}

export default DetailsPanel;