import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import Info from "./Views/Info";
import SizeAndWeight from "./Views/SizeAndWeight";
import AvailableStock from "./Views/AvailableStock";
import Instructions from "./Views/Instructions";
import StockMutations from "./Views/StockMutations";
import { useTranslation } from 'react-i18next';

import Warehouse from "./Views/Warehouse";
import InventoryIcon from '@mui/icons-material/Inventory';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spStockDetailsConfig } from "./spStockDetailsConfig";
import { useGetProductQuery } from "../../../../newapi/warehouse/productSlice";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import MConfirmDelete from "../../Modals/MConfirmDelete";
import { useDeleteProductMutation } from "../../../../newapi/warehouse/productSlice";
import { showToastMessageDeleteRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../utils/toasts";

import DeleteIcon from '@mui/icons-material/Delete';

const SidePanelActions = (props) => {
    const { t } = useTranslation();

    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>
            {props.productData && props.productData.productReferences.length === 0 && (parseInt(props.productData.totalStockQuantity) === 0 || props.productData.totalStockQuantity === null) && (
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            )}
        </SidepanelHeaderActiongroup>
    )
}

const SPStockDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: productData,isFetching, isLoading,isError } = useGetProductQuery(props.productId,
    {
        skip: !props.productId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <InventoryIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t('location')} /> },
        { component: <ShRowSkeleton heading={t('barcode')} /> },
        { component: <ShRowSkeleton heading={t('total_available')} /> }
    ], [t]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions: <SidePanelActions setIsExtended={setIsExtendedCallback}/>
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.productId,theme.palette.mode]);

    const [DeleteProductMutation] = useDeleteProductMutation();
    const confirmDelete = async () => {
        try {
            
            const response = await DeleteProductMutation(props.productId).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageRequestError(t('delete_failed'), t, err);
        }
    };
    const handleDeleteClick = async () => {
        setOpen(true);
    };
    const [open, setOpen] = useState(false);
    const confirmDeleteModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    } 
    
    useEffect(() => {
        if (!isLoading && !isFetching && productData) {
            let locations = productData.stocks.map(stock => stock.warehouseLocation.location);
            let combinedLocations = locations.join(", ");

            setPanel(prevPanel => ({
                ...prevPanel,
                title: productData.description,
                subTitle: productData.sku,
                options: [
                    { heading: t('location'), content: combinedLocations || t('no_location') },
                    { heading: t('barcode'), content: productData.barcode || t('barcode') },
                    { heading: t('total_available'), content: productData.totalStockAvailable },
                ],
                actions: <SidePanelActions productData={productData} setIsExtended={setIsExtendedCallback} deleteAction={handleDeleteClick}/>
            }));
        }
    }, [productData, isFetching, isLoading, t]);

    return (
        <>
            <Sidepanel2
                skeleton={isLoading || isFetching}
                tabConfig={spStockDetailsConfig}
                content={panel} 
                isExtended={props.isExtended} 
                setIsExtended={props.setIsExtended} >
                <>
                    {/* <Warehouse config={spStockDetailsConfig.warehouse} productId={props.data ? props.data.id : -1} />
                    <Info config={spStockDetailsConfig.info} data={props.data}/>
                    <SizeAndWeight config={spStockDetailsConfig.sizeAndWeight} data={props.data} />
                    <AvailableStock config={spStockDetailsConfig.stock} data={props.data}/>
                     <Batches apiRef={batchesApiRef} /> 
                    <Instructions config={spStockDetailsConfig.instructions} data={props.data}/>
                    <Efullfillment config={spStockDetailsConfig.efullfillment}/>
                    <Logs config={spStockDetailsConfig.logs} data={props.data}/> */}

                    <Warehouse config={spStockDetailsConfig.warehouse} productId={props.productId} />
                    <Info config={spStockDetailsConfig.info} data={productData}/>
                    <SizeAndWeight config={spStockDetailsConfig.sizeAndWeight} data={productData} />
                    <AvailableStock config={spStockDetailsConfig.stock} data={productData}/>
                    <Instructions config={spStockDetailsConfig.instructions} data={productData}/>
                    {/* <Efullfillment config={spStockDetailsConfig.efullfillment} data={productData}/> */}
                    <StockMutations config={spStockDetailsConfig.stockMutations} data={productData}/>

                </>
            </Sidepanel2>
            <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
        </>
    );
};

export default SPStockDetails;