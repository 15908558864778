import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import Text from "../../../../theme/text/Text";

import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Dropdown from "../../../../theme/dropout/DropOut";

import { useTranslation } from "react-i18next";
import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import { yupRequired, yupNumberRequired } from "../../../../../utils/validation";
import SpDesktopFooter from "../../../../theme/sidepanel2/SidepanelFooter";
import { useUpdateInboundMutation, useDeleteInboundMutation } from "../../../../../newapi/inbound/inboundsSlice";
import { showToastMessageRequestError, showToastMessageUpdateRequest, showToastMessageDeleteRequest} from "../../../../../utils/toasts";
import SaveIcon from '@mui/icons-material/Save';
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { useSelector } from 'react-redux';
import { UserPrivileges } from "../../../../../utils/permissions";
import { selectUserPermission } from '../../../../../newapi/user/authentication/authSlice';
import ShDateRangePicker from "../../../../theme/ShDateRangePicker";
import { useGetSuppliersSelectQuery } from '../../../../../newapi/global/supplier/supplierSlice';
import NumberInput from '../../../../theme/inputs/NumberInput';
import MConfirmDelete from "../../../Modals/MConfirmDelete";
import DeleteIcon from '@mui/icons-material/Delete';

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (<>
        {props.hasPermission && (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.onclick}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    )}
    </>
    );
};

const Details = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({});
    const hasInboundManagePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.INBOUND_MANAGE, UserPrivileges.RP_INBOUND_MANAGE])
    );
    const { data: suppliers, suppliersLoading } = useGetSuppliersSelectQuery();
    const [deleteOpen, setDeleteOpen] = useState(false);

    const [inboundData, setInboundData] = useState({
        reference: "",
        shipmentDate: new Date(),
        supplierId: 0,
        pallets: 0,
        colli: 0
    });

    useEffect(() => {
        setInboundData(props.data);
    }, [props.data]);

    const handleChange = (key, value) => {
        setInboundData(prevState => ({
          ...prevState,
          [key]: value
        }));
        // Optionally clear the error for that field
        setFieldErrors(prevState => ({
          ...prevState,
          [key]: ''
        }));
    };

    const handleDateChange = (date) => {
        handleChange("shipmentDate", date[0].formattedStartDate);
    };

    const rules = {
        reference: yupRequired('reference', t),
        supplierId: yupNumberRequired('supplier', t),
    };
    
    const schema = yup.object().shape(rules);
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions onclick={handleSaveClick}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[ /* Add dependencies here once we have status data */]);
    
    const [EditInbound,{isLoading}] = useUpdateInboundMutation();
    const handleSaveClick = async () => {
        try {
            const updateObject = {
                reference: inboundData.reference,
                shipmentDate: inboundData.shipmentDate,
                supplierId: inboundData.supplierId,
                pallets: inboundData.pallets,
                colli: inboundData.colli,
                id: props.inboundId
            }
            
            setFieldErrors({});
            await schema.validate(updateObject, { abortEarly: false });
            
            const response = await EditInbound(updateObject).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("update_failed"), t, err);
              }
        }
    };

    const [deleteInbound,{isLoading: isDeleting}] = useDeleteInboundMutation();

    const handleDeleteClick = () => {
        setDeleteOpen(true); // Open confirmation modal
    };

    const confirmDelete = async () => {
        try {
            // Implement the delete logic here, e.g., call a delete mutation
            const response = await deleteInbound(props.inboundId).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageRequestError(t("delete_failed"), t, err);
        }
    };
    
    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
        <Dropdown title={t("details")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1.5,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >
                <Box
                    sx={{
                        display:"flex",
                        gap:2,
                        alignItems:"center",
                    }}
                >
                    <Box 
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text noBreak>{t("reference")}</Text>
                    </Box>
                    <Box display="flex"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                            flex:1,
                        }}
                    >
                        <ShValidatedInput
                            height={40}
                            name="reference"
                            value={inboundData.reference ?? ""}
                            onChange={handleChange}
                            error={fieldErrors.reference}
                        />
                    </Box>
                </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("shipment_date")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                        <ShDateRangePicker 
                            singleSelect={true} 
                            startDate={inboundData?.shipmentDate && inboundData.shipmentDate !== null ? inboundData.shipmentDate : new Date()} 
                            select={handleDateChange} 
                            size={32}
                        >
                        </ShDateRangePicker>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("pallets")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                        <NumberInput btnSize={"24"}
                                    value={inboundData.pallets ?? 0}
                                change={(e) => handleChange('pallets', e)}/>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("colli")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                        <NumberInput btnSize={"24"}
                                    value={inboundData.colli ?? 0}
                                change={(e) => handleChange('colli', e)}/>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{t("supplier")}</Text>
                        </Box>
                        <Box display="flex"
                            // maxWidth="400px"
                            borderRadius="20px"
                            sx={{
                                flex:1,
                            }}
                        >
                        <ShValidatedInputDropdown
                                disabled={!hasInboundManagePermission}
                                name="supplierId"
                                displayName="name"
                                changeField="id"
                                options={suppliers ?? null}
                                noSelection={t("choose_option")} 
                                onChange={handleChange}
                                selected={suppliers?.findIndex(supplier => (inboundData.supplierId && inboundData.supplierId === supplier.id)) ?? -1}
                                error={fieldErrors.supplierId}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dropdown>
        <SpDesktopFooter>
            <GridActions
                onclick={handleSaveClick}
                deleteAction={handleDeleteClick}
                hasPermission={hasInboundManagePermission}
            />
        </SpDesktopFooter>
        <MConfirmDelete
            open={deleteOpen}
            handleClose={() => setDeleteOpen(false)}
            confirm={confirmDelete}
        />
        </Box>
    );
}


export default Details;