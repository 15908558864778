import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";

import { useGridApiRef } from "@mui/x-data-grid";

import OutboundBatchesDoing from "./views/Doing";
import OutboundBatchesFinished from "./views/Finished";
import OutboundBatchesTodo from "./views/Todo";

// import { getOrderStatusCounts } from "../../../../api/orders/orders";
import { useTranslation } from 'react-i18next';
import TabView3 from "../../../../components/theme/TabView3/TabView3";
import { warehouseOutboundBatchesConfig } from "./warehouseOutboundBatchesConfig";

const OutboundBatches = () => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const actionRequiredRef = useGridApiRef();
    const priorityRef = useGridApiRef();
    const replenishRef = useGridApiRef();
  
    return (
        <Box sx={{background:"", width:1,paddingTop:9}}>
            {ready ?
            <>
                <TabView3
                    tabConfig={warehouseOutboundBatchesConfig}
                    // ref={ref}
                    sx={{
                        background:"green",
                        minHeight:"calc(100% - 190px)",
                        background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        position:"relative",
                        zIndex:2,
                        display:"flex",
                        flexDirection:"column",

                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            left:0,
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"40px",
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transition:"250ms"
                        },

                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"40px",
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            transition:"250ms"
                        },

                        "@media screen and (max-width: 47.9375em)" : {
                            "&:after, &:before" : {
                                top:"-0px"
                            }
                        }
                    }}
                >
                    <>
                        {/* <ActionRq */}
                        <OutboundBatchesTodo config={warehouseOutboundBatchesConfig.todo}/>
                        <OutboundBatchesDoing config={warehouseOutboundBatchesConfig.doing}/>
                        <OutboundBatchesFinished config={warehouseOutboundBatchesConfig.finished} />
                    {/* <ActionReq */}
                    </>
                </TabView3>
                {/* </TabView> */}
            </>
            :null}
        </Box>
    );
};

export default OutboundBatches;