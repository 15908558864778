import { Box, useTheme } from "@mui/material";

// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { useEffect, useState } from "react";
import { tokens } from "../../theme";

import { useGridApiRef } from "@mui/x-data-grid";

// Import Swiper React components

// Import Swiper styles
import 'swiper/css';
import "swiper/css/effect-cards";
// import "./styles.css";

// import required modules


const OverflowRow = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [isExtended, setIsExtended] = useState(false);

    const contactApiRef = useGridApiRef();
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const breakpoint = props.breakpoint ?? 767
    const handleResize = (event) => {
        setWindowSize(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    return (
        <Box overflow="hidden" >
            <Box 
                className={props.className}
                display={"flex"} 
                justifyContent={"flex-start"} 
                paddingTop={2}
                paddingBottom={4}
                alignItems={"stretch"} 
                gap={3} 
                flexShrink={0} 
                sx={{
                    background:colors.bg["tertiary"],
                    overflowX:"auto",
                    "& > *:nth-of-type(1)": { // Updated to nth-of-type
                        marginLeft:4,
                        // background:"#5200FF",
                        // background:"orange"
                    },
                    "& > *:last-of-type": {
                        marginRight:4,
                        position:"relative",
                        //Safari hack
                        "&:after" : {
                            content: "''",
                            height:1,
                            width:32,
                            position:"absolute",
                            background: colors.bg["tertiary"],
                            display:"block",
                            right:-32,
                            top:0,
                            transition:"250ms"
                        }
                    },
                    "&:last-child" : {
                        // marginRight:3
                    },
                    "@media screen and (max-width: 47.9375em)" : {
                        "& > *:nth-of-type(1)": { // Updated to nth-of-type
                            marginLeft:3,
                        },
                        "& > *:last-of-type": {
                            marginRight:3,
                            //Safari hack
                            "&:after" : {
                                width:"24px",
                                right:"-24px",
                            }
                        },

                        "&.is-mobile-col" : {
                            overflowX:"hidden",
                            flexDirection:"column"
                        },
                        "&.is-mobile-col > *" : {
                            mx:3,
                        }
                    },

                    
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
};

export default OverflowRow;