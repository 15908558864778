import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const PRICEINCREASE_BASE = "/priceincreases";
const buildUrl = (endpoint) => PRICEINCREASE_BASE + endpoint;
const API_TAG = 'PriceIncreases';

const priceIncreaseSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPriceIncreases: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: API_TAG }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getPriceIncreases', API_TAG)
        }),
        getPriceIncrease: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            // providesTags: (id) => [{ type: `CountingStrategyTemplateSingle`,id }]
            providesTags: (result, error, arg) => [{ type: `${API_TAG}Single`, id: arg }]
        }),
        addPriceIncrease: builder.mutation({
            query: priceIncreaseInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: priceIncreaseInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG},{type: 'InvoiceProducts'}],
        }),
        updatePriceIncrease: builder.mutation({
            query: priceIncreaseInfo => ({
                url: buildUrl(`/${priceIncreaseInfo.id}`),
                method: 'PUT',
                body: priceIncreaseInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG },{type:"InvoiceProducts"}, { type: `${API_TAG}Single`, id: arg.id }],
        }),
        deletePriceIncrease: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG },{type:"InvoiceProducts"}, { type: `${API_TAG}Single`, id: arg.id }],
        }),
        // getPriceIncreaseProducts: builder.query({
        //     query: id => ({
        //         url: buildUrl(`/${id}/products`),
        //         method: 'GET',
        //     }),
        //     providesTags: (result, error, arg) => [{ type: `${API_TAG}Single`, id: arg }]
        // }),

        getPriceIncreaseProducts: builder.query({
            query: ({ args, invalidate }) => ({
                // url: buildUrl(`/${args.id}/invoicelines?page=${args.page}&size=${args.size}`),
                url: buildUrl(`/${args.id}/products?page=${args.page}&size=${args.size}`),
                method: 'GET',
                // body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${API_TAG}`}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getInvoiceProductPriceIncreases', API_TAG)
        }),
        getPriceIncreaseProductOptions: builder.query({
            query: () => ({
                url: buildUrl(`/products/options`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: `${API_TAG}Single`, id: arg }]
        }),
        addPriceIncreaseProducts: builder.mutation({
            query: ({id, products}) => ({
                url: buildUrl(`/${id}/products`),
                method: 'POST',
                body: {productIds: products},
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }],
        }),
        deletePriceIncreaseProduct: builder.mutation({
            query: ({ id, productId }) => ({
                url: buildUrl(`/${id}/products/${productId}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: `${API_TAG}Single`, id: arg.id }],
        }),
        getPriceIncreaseLogs: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/logs?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${API_TAG}Single`, id: arg }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getPriceIncreaseLogs', API_TAG)
        }),
    })
});

export const {
    useGetPriceIncreasesQuery,
    useGetPriceIncreaseQuery,
    useAddPriceIncreaseMutation,
    useUpdatePriceIncreaseMutation,
    useDeletePriceIncreaseMutation,
    useGetPriceIncreaseProductsQuery,
    useAddPriceIncreaseProductsMutation,
    useDeletePriceIncreaseProductMutation,
    useGetPriceIncreaseProductOptionsQuery,
    useGetPriceIncreaseLogsQuery,
  } = priceIncreaseSlice;
