import { Box } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";

import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ShDateRangePicker from "../../../../theme/ShDateRangePicker";
import Subheading from "../../../../theme/text/Subheading";
import Progressbar from "../../../../theme/Progressbar";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTranslatedDateAndTime, getOrderStatusProgress } from "../../../../../utils";


const PickBonContent = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    return(
        <>
            <Text light>{t("pickreceipt")}</Text>
            <Text bold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{props.data?.pickingJobId ?? t("create")}</Text>
        </>
    )
}

const IsPickedContent = (props) => {
    const { t, ready } = useTranslation();

    if (!ready || !props.data) {
        return null;  // Or return a loading indicator, or some placeholder content
    }

    const { date, time } = getTranslatedDateAndTime(props.data?.pickingJob?.status, t);
    return(
        <>
            <Text light>{props.data?.pickingJob?.status ?? t("picking_status_unknown")}</Text>
            <Box>
                <Text bold variant={"green"}>{date}</Text>&nbsp;&nbsp;<Text variant={"green"}>{time}</Text>
            </Box>
        </>
    )
}
const EcheckedContent = (props) => {
    const { t, ready } = useTranslation();
    const { date, time } = getTranslatedDateAndTime(props.data?.date_shipped, t);
    return(
        !ready || !props.data ?
            <>
                <Text light>{t("e_checked")}</Text>
                <Box>
                    <Text bold>{date}</Text><Text>{time}</Text>
                </Box>
            </>
        : null
    )
}

const Status = (props) => {
    const {t, ready} = useTranslation();
    const ref = useRef();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const { color: progressColor = "grey", percentage: progressPercentage = 0 } = props.data ? getOrderStatusProgress(props.data, t) : {color: theme.palette.mode === "dark" ? "blue" : "primary",percentage:100} ;

    
    useEffect(() => {
        props.replaceView({
            ...props.config, 
            progressBar:{
                ref:ref,
                progress:((progressPercentage || 0)/100),
                status:{
                    variant:progressColor,
                    value: `${progressPercentage}% ${t("picked")}`
                }
            },
            options : [
                {
                    component: <PickBonContent data={props.data}/>
                },
                {
                    heading: t("order_status"),
                    content: props.data?.pickingJob?.status ?? t("not_picked")
                },
                {
                    component: <IsPickedContent data={props.data}/>
                },
                {
                    component: <EcheckedContent data={props.data}/>
                },
            ],
        });

        return () => {
            
        }
    },[ref,progressPercentage,progressColor,props.data]);

    


    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingTop={3} paddingLeft={3}>
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    gap:4,
                    "@media screen and (max-width: 768px)" : {
                        display:"none"
                    }
                }}
            >
                <Subheading>{props.data?.warehouse?.name ?? "..."}</Subheading>
                <Progressbar size={32} isHeader progress={progressPercentage/100} status={{variant:progressColor,value:`${progressPercentage}% ${t("picked")}`}}/>
            </Box>
            <Box
                sx={{
                    paddingTop:3,
                    display:"flex",
                    gap:5,
                    width:1,
                    "@media screen and (max-width: 768px)" : {
                        flexDirection:"column",
                        paddingRight:3,
                        gap:1.5,
                        display:"none"
                    }
                }}
            >
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 768px)" : {
                            width:1,
                            flexDirection:"row",
                            justifyContent:"space-between",
                        }
                    }}
                >
                    <PickBonContent data={props.data}/>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 768px)" : {
                            width:1,
                            flexDirection:"row",
                            justifyContent:"space-between"
                        }
                    }}
                >
                    <Text light>{t("order_status")}</Text>
                    <Text bold>{props.data?.pickingJob?.status ?? t("not_picked")}</Text>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 768px)" : {
                            width:1,
                            flexDirection:"row",
                            justifyContent:"space-between"
                        }
                    }}
                >
                    <IsPickedContent data={props.data}/>
                    
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 768px)" : {
                            width:1,
                            flexDirection:"row",
                            justifyContent:"space-between"
                        }
                    }}
                >
                    <EcheckedContent data={props.data}/>
                </Box>
            </Box>
        
            <Box
                sx={{
                    height:"1px",
                    paddingRight:3,
                    background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[100],
                    marginY:4,
                    "@media screen and (max-width: 768px)" : {
                        display:"none"
                    }
                }}
            ></Box>
            <Subheading>{t("extra_details")}</Subheading>
            <Box
                sx={{
                    paddingTop:3,
                    display:"flex",
                    gap:5,
                    width:1,
                    "@media screen and (max-width: 768px)" : {
                        flexDirection:"column",
                        paddingRight:3,
                        gap:1.5,
                        paddingTop:0
                    }
                }}
            >
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 768px)" : {
                            width:1,
                            flexDirection:"row",
                            justifyContent:"space-between",
                        }
                    }}
                >
                    <Text light>{t("in_put_to_light")}</Text>
                    <Text bold>{props.data?.put_to_light ?? t("no")}</Text>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 768px)" : {
                            width:1,
                            flexDirection:"row",
                            justifyContent:"space-between",
                        }
                    }}
                >
                    <Text light>{t("suitable_for_put_to_light")}</Text>
                    <Text bold variant={"green"}>{props.data?.put_to_light_available ?? t("no")}</Text>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 768px)" : {
                            width:1,
                            flexDirection:"row",
                            justifyContent:"space-between",
                        }
                    }}
                >
                    <Text light>{t("is_backorder")}</Text>
                    <Text bold>{(props.data?.backorder || false) ? t("yes") : t("no")}</Text>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 768px)" : {
                            width:1,
                            flexDirection:"row",
                            justifyContent:"space-between",
                        }
                    }}
                >
                    <Text light>{t("is_blocked")}</Text>
                    <Text bold>{(props.data?.blocked || false) ? t("yes") : t("no")}</Text>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 768px)" : {
                            width:1,
                            flexDirection:"row",
                            justifyContent:"space-between",
                        }
                    }}
                >
                    <Text light>{t("is_packing_order")}</Text>
                    <Text bold>{(props.data?.packingorder || false) ? t("yes") : t("no")}</Text>
                </Box>
            </Box>
        </Box>
    );
}


export default Status;
