import { Box, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
// import { tokens } from "../../../../../theme";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { tokens } from "../../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import MAddWorkflowTemplate from "../../../../../components/global/Modals/MAddWorkflowTemplate";
import SpWorkflowTemplateDetails from "../../../../../components/global/Sidepanels/SpWorkflowTemplateDetails/SpWorkflowTemplateDetails";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import { useGetWorkflowTemplateQuery, useGetWorkflowTemplatesQuery } from "../../../../../newapi/workflow/workflowTemplateSlice";

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_workflow_template")}</Shbutton>
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    // const { getCarrier} = useGetCarrierQuery();
    const [selectedRowId, setSelectedRowId] = useState(1);
    const [rowSelected, setRowSelected] = useState(false);


    const [workflowTemplateId, setWorkflowTemplateId] = useState();
    const workflowTemplateIdRef = useRef(workflowTemplateId);
    
    const getWorkflowDetails = (params) => {
        if (!params) return;
    
        const newWorkflowId = parseInt(params.row.id);
        // Only update state if the productId has actually changed
        if (workflowTemplateIdRef.current !== params.row.id) {
            setWorkflowTemplateId(params.row.id);
            workflowTemplateIdRef.current = params.row.id; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const { data: workflowTemplateData, error, isLoading } = useGetWorkflowTemplateQuery(
       selectedRowId,
        {
            skip: !rowSelected
        }
    );

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };


    useEffect(() => {
        if (!isLoading && workflowTemplateData && rowSelected) {
            setIsExtended(true);
        }
    }, [workflowTemplateData, isLoading, rowSelected]);

    useEffect(() => {
        if(!isExtended) {
            setRowSelected(false);
        }
    },[isExtended])
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.name
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
            }
        }
    ];

    useImperativeHandle(ref, () => ({
    }));

    const handleRowClick = (params) => {
        setIsExtended(true);
    };
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <ShDataGrid2
                content={useGetWorkflowTemplatesQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                isSidepanelGrid
                columns={columns} 
                onRowClick={getWorkflowDetails}
                rows={data}>
            </ShDataGrid2>
            <MAddWorkflowTemplate onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
            {/* <SpCarrierDetails isExtended={isExtended} setIsExtended={setIsExtended}/> */}
            { <SpWorkflowTemplateDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                workflowTemplateId={workflowTemplateId}                
            ></SpWorkflowTemplateDetails> }
        </Box>
    )
});

export default All;