import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { themeSettings, tokens } from "../../../../../theme";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { useUpdateInvoiceProductPricesMutation } from "../../../../../newapi/financial/invoiceProductSlice";
import { useGetInvoiceCategoryProductsQuery } from "../../../../../newapi/financial/invoiceCategorySlice";
import { useSelector } from "react-redux";
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { general_states } from "../../../../../utils/staticEnums";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import { showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import { EuroOutlined } from "@mui/icons-material";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Products = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [isExtended, setIsExtended] = useState(false);
    const [modifiedProducts, setModifiedProducts] = useState(null);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        },false);

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const { data: invoiceCategoryProducts, isLoading:isLoading, isFetching:isFetching } = useGetInvoiceCategoryProductsQuery(props.invoiceCategoryId);

    useEffect(() => {
        if (invoiceCategoryProducts && !modifiedProducts) {
            // setModifiedProducts(invoiceCategoryProducts);
            setModifiedProducts(invoiceCategoryProducts);
        }
    }, [invoiceCategoryProducts]);


    const [UpdateInvoiceProductPrices,{ isPricesLoading}] = useUpdateInvoiceProductPricesMutation();
    const handleSaveClick = async () => {
        try {
            const productArray = modifiedProducts.map(product => ({
                id: product.invoiceProductId,
                categories: [
                    {
                        id: props.invoiceCategoryId,
                        price: product.price
                    }
                ]
            }));

            const invoiceProductInfo = {
                products: productArray
            };

            const response = await UpdateInvoiceProductPrices(invoiceProductInfo).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };

    const handleChange = (productId, price) => {
        // Only allow numbers and one decimal point
        if (!/^\d*\.?\d*$/.test(price)) return;
        
        setModifiedProducts(current => 
            current.map(product => {
                if (product.invoiceProductId === productId) {
                    return {
                        ...product,
                        price: price === '.' ? '0.' : price
                    };
                }
                return product;
            })
        );
    };

    const themeSettingsMemo = useMemo(() => themeSettings(theme.palette.mode), [theme.palette.mode]);


    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
        <Dropdown title={t("overview")} open>
            <Box sx={{
                display:"flex",
                justifyContent:"space-between",
                "@media screen and (max-width: 47.9375em)" : {
                    flexDirection:"column"
                }
            }}>
                
                {/* Two columns */}
                
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap: 1,
                        justifyContent:"flex-start",
                        flexDirection:"column"
                    }}
                >

                {isLoading || isFetching ? 
                    
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                            color:"orange",
                            flexDirection:"column"
                        }}
                    >
                        <ShTextSkeleton/>
                        <ShTextSkeleton/>
                        <ShTextSkeleton/>
                        <ShTextSkeleton/>
                    </Box>
                
                :modifiedProducts && modifiedProducts.length > 0 && modifiedProducts.map((product,index) => (
                    <Box key={"modified-products-"+index}
                        sx={{
                            display:"flex",
                            gap:2,
                            alignItems:"center",
                        }}
                    >
                        
                        <Box 
                            sx={{
                                width:140,
                                display:"flex",
                                alignItems:"center",
                            }}
                        >
                            <Text noBreak>{product.invoiceProduct.name}</Text>
                        </Box>
                        <Box
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                background:colors.grey[100],
                                borderRadius:5,
                                height:40,
                                flex:1,
                                pl:0.5,
                            }}
                        >
                            
                            <Box
                                sx={{
                                    background: themeSettings(theme.palette.mode).palette.primaryGrey.main,
                                    color:themeSettings(theme.palette.mode).palette.primaryGrey.contrastText,
                                    height:32,
                                    width:32,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    borderRadius:4,
                                }}
                            >
                                <EuroOutlined fontSize="small"/>
                            </Box>
                            <InputBase 
                                sx={{
                                    px:2,
                                    flex:1, 
                                    color:colors.txt["primary"],
                                    lineHeight:"32px",
                                }}
                                value={product.price}
                                onChange={(e) => handleChange(product.invoiceProductId, e.target.value)}
                            />  
                        </Box>
                    </Box>

                ))}


                   
                </Box>
            </Box>
        </Dropdown>
        <Box
            sx={{
                position:"absolute",
                bottom:0,
                right:0,
                padding:3,
                width:1,
                background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                    padding:2,
                    paddingY:2.5,
                }
            }}
        >
            <GridActions
                action={handleSaveClick}
            />
            </Box>
        </Box>
    );
}

export default Products;