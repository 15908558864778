import { Box, Fade, IconButton, Popper, Typography, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import React, { useCallback, useEffect, useMemo, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Details from "./Views/Details";

import { useTranslation } from 'react-i18next';
import {  useGetWebshopCredentialsSingleQuery, useSyncWebshopProductsMutation } from "../../../../newapi/webshop/webshopSlice";
import { showToastMessage, showToastMessageDeleteRequest, showToastMessagePostRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../utils/toasts";
import SyncIcon from '@mui/icons-material/Sync';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

import { Copy } from "../../../theme/Copy";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spWebshopCredentialsDetailsConfig } from "./spWebshopCredentialsDetailsConfig";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import MConfirmationWithValue from "../../Modals/MConfirmationWithValue";
import Sync from "./Views/Sync";


const SidePanelActions = (props) => {
    const { t } = useTranslation();
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={() => props.syncAction(props?.id)}
                color={"primaryGrey"}
                variant="contained"
                endIcon={<SyncIcon />}
            >
                {t('sync')}
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}


const CopyHeaderElement = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        
        <Copy onClick={() => navigator.clipboard.writeText(props.value)} message={props.value}>
            <Box
                sx={{
                    
                    display:"flex",
                    alignItems:"flex-end",
                }}
            >
                <Box
                    sx={{
                        maxWidth:props.small ? 80 : 282,
                        display:"flex",
                        flexDirection:"column"
                    }}
                >
                    <Text light>{props.title}</Text>
                    <Text bold noBreak>{props.value ? props.value : "N/A"}</Text>
                </Box>

                    <IconButton
                        sx={{ color: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary["400"] }}
                    >
                        <ContentCopyOutlinedIcon fontSize="small" />
                    </IconButton>
            </Box>
        </Copy>
    );
}

const SpWebshopCredentialsDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

    const [SyncWebshopProducts,{isLoading: syncIsLoading}] = useSyncWebshopProductsMutation();
    
    const [openConfirmation, setOpenConfirmation] = useState(false); // State to control the dialog visibility
    const [selectedId, setSelectedId] = useState(null); // State to store the selected ID for sync

    const handleSyncClick = async (id) => {
         // Set the selected ID
        setOpenConfirmation(true); // Open the confirmation dialog
    };

    useEffect(() => {
        if(props.webshopCredentialsId){
            setSelectedId(props.webshopCredentialsId);
        }
    }, [props.webshopCredentialsId]);

    const confirmSync = async () => {
        try {
            const response = await SyncWebshopProducts({ id: selectedId }).unwrap();
            showToastMessage("success", response.message);
        } catch (err) {
            showToastMessageRequestError(t('sync_failed'), t, err);
        } finally {
            setOpenConfirmation(false); // Close the dialog after action
        }
    };

    const { data: webshopCredsData, isLoading,isFetching,isError } = useGetWebshopCredentialsSingleQuery(props.webshopCredentialsId,
    {
        skip: !props.webshopCredentialsId
    });

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <StorefrontOutlinedIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <ShRowSkeleton heading={t("token")} /> },
        { component: <ShRowSkeleton heading={t("order_webhook")} /> },
        { component: <ShRowSkeleton heading={t("product_webhook")} /> },
    ], [t]);

    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        options: defaultOptions,
        icon: defaultIcon,
        actions:<SidePanelActions setIsExtended={setIsExtendedCallback} syncAction={handleSyncClick} id={props?.webshopCredentialsId} />   
    }), [defaultIcon, defaultOptions, setIsExtendedCallback]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.webshopCredentialsId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && webshopCredsData) {
            setPanel(prevPanel => ({
                ...prevPanel,
                title: webshopCredsData.description,
                subTitle: [webshopCredsData.webshop_type],
                icon :defaultIcon,
                options: [
                    {
                        component: <CopyHeaderElement title={t("token")} small value={webshopCredsData.token}/>
                    },
                    {
                        component: <CopyHeaderElement title={t("order_webhook")} value={webshopCredsData.webhooks.order}/>
                    },
                    {
                        component: <CopyHeaderElement title={t("product_webhook")} value={webshopCredsData.webhooks.product}/>
                    },
                ],
            }));
        }
    }, [webshopCredsData, isLoading, isFetching, t,theme.palette.mode]);



    const panelSettings = { 
        row: {
            paddingTop:4,
            display:"flex",
            gap:"24px 40px",
            flexWrap:"wrap",
            "@media screen and (max-width: 63.9375em)":{
                overflowX:"auto",
                flexWrap:"nowrap",
                gap: "16px",
                paddingTop:3,
                "&.sub-row": {
                    paddingTop:2,
                    // marginRight:3
                }
            }
        },
    };


    return (
        <>
            <MConfirmationWithValue
                open={openConfirmation}
                handleClose={() => setOpenConfirmation(false)}
                confirm={confirmSync}
                data={{
                    title: t('confirm_sync_title'),
                    subtitle: t('confirm_sync_description'),
                    content: <Text>{t('confirm_sync_content')}</Text> // You can customize this content as needed
                }}
            />
            <Sidepanel2
                skeleton={isLoading || isFetching}
                tabConfig={spWebshopCredentialsDetailsConfig}
                content={panel}
                settings={panelSettings}
                isExtended={props.isExtended}
                setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spWebshopCredentialsDetailsConfig.details} data={webshopCredsData} setIsExtended={props.setIsExtended} />
                    <Sync config={spWebshopCredentialsDetailsConfig.sync} data={webshopCredsData} setIsExtended={props.setIsExtended} />
                </>
            </Sidepanel2>
        </>
    );
};

export default SpWebshopCredentialsDetails;
