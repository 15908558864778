

import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';

export const userAccountConfig = {
    personal:  {
        title: 'personal',
        icon: <UpdateOutlinedIcon />,
    },
};
