import { useTranslation } from "react-i18next";

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal";
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper";
import { useAddInboundMutation } from "../../../../newapi/inbound/inboundsSlice";
import Details from "./views/Details";
import InboundLines from "./views/InboundLines";
import Shipments from "./views/Shipments";

const MpAddInbound = (props) => {
    const {t} = useTranslation();

    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useAddInboundMutation} isModal title={t("add_inbound")}>
                <Details/>
                <InboundLines/>
                <Shipments/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddInbound;