const { general_states } = require("./staticEnums");

const getOrderStateData = (state, theme) => {
    let labelcolor = "";
    let labeltext = "";
    switch(state){
        case general_states.ACTIVE:
            labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
            labeltext = "active";
            break;
        case general_states.OPEN:
            labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
            labeltext = "order_can_be_picked";
            break;
        case general_states.SHIPPED:
            labelcolor = "green"
            labeltext = "shipped";
            break;
        case general_states.FULLYPICKED:
            labelcolor = "green"
            labeltext = "fullypicked";
            break;
        case general_states.PICKED:
            labelcolor = "green"
            labeltext = "picked";
            break;
        case general_states.BLOCKED:
            labelcolor = "red"
            labeltext = "blocked";
            break;
        case general_states.CANCELLED:
            labelcolor = "grey"
            labeltext = "cancelled";
            break;
        case general_states.REPLENISH:
            labelcolor = "purple"
            labeltext = "replenish";
            break;
        case general_states.BACKORDERED:
            labelcolor = "pink"
            labeltext = "backordered";
            break;
        case general_states.SNOOZE:
            labelcolor = "pink"
            labeltext = "snooze";
            break;
        case general_states.PICKING_IN_PROGRESS:
            labelcolor = "purple"
            labeltext = "picking_in_progress";
            break;
        default:
            labelcolor = "orange"
            labeltext = 'unknown';
            break;
    }

    return {
        labelcolor,
        labeltext
    }
}

const getInboundStateData = (state, theme) => {
    let labelcolor = "";
    let labeltext = "";

    switch(state){
        case general_states.ACTIVE:
            labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
            labeltext = "active";
            break;
        case general_states.CANCELLED:
            labelcolor = "red"
            labeltext = "cancelled";
            break;
        case general_states.PARTLYRECEIVED:
            labelcolor = "orange"
            labeltext = "partly_received";
            break;
        case general_states.FULLYRECEIVED:
            labelcolor = "green"
            labeltext = "fully_received";
            break;
        default:
            labelcolor = "grey"
            labeltext = "unknown";
            break;
    }

    return {
        labelcolor,
        labeltext
    }
}

module.exports = {
    getOrderStateData,
    getInboundStateData
}
