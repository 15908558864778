

import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
export const settingsWarehouseOutboundPickingStreamsConfig = {
    core:  {
        title: "core",
        icon: <AdjustOutlinedIcon/>,
    },
    orderSizing:  {
        title: "ordersizing",
        icon: <StraightenOutlinedIcon/>,
    },
    bulkXl:  {
        title: "bulkxl",
        icon: <LayersOutlinedIcon/>,
    },
};
