import { Box, Button, Popper, useTheme } from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "../../../theme";
import PopoverModal from "../Modal/PopoverModal";
import TextInput from "../inputs/TextInput";

import { ReactComponent as TableEmpty } from '../../../styles/svg/tableempty.svg'
import Text from "../text/Text";
import Subheading from "../text/Subheading";

export const PaginationDropUp = (props) => {
    if(!props.children) throw Error("No child element set for Dropdownbase");
    
    const [anchorEl, setAnchorEl] = useState(null);
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    const [selected, setSelected] = useState(props.selected ?? 1); //inner for displayname


    useEffect(() => {
        // Call the toggleDragAndDrop function prop with the popoverOpen state
        if (props.toggleDragAndDrop) {
            props.toggleDragAndDrop(!popoverOpen);
        }
    }, [popoverOpen, props.toggleDragAndDrop]);
    
    const handlePopoverClick = (event) => {
        if(props.stopPropagation){
            event.stopPropagation();
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (selected) => {
        setSelected(selected);
        props.onChange(selected);
        if(props.closeOnSelection) {
            setAnchorEl(null);
        }
        if(props.resetOnSelection){
            setSelected(-1);
        }
    }

    useEffect(() => {
        if(typeof props.selected !== "undefined"){
            setSelected(props.selected)
        }
    },[props.selected])


    const excludedProps = ['noSelection','closeOnSelection','toggleDragAndDrop','disallowDeselection', 'describedBy','useTranslation','anchorOrigin','transformOrigin',];

    const filteredProps = useMemo(() => Object.keys(props).reduce((acc, key) => {
        if (!excludedProps.includes(key)) {
            // console.log("key",key)
            acc[key] = props[key];
        }
        return acc;
    }, {}),[props]);


    return (
        <>
            {React.cloneElement(props.children, 
                {
                    ...filteredProps,
                    onClick:handlePopoverClick
                }
            )}

            {/* <ShDropdown */}
            <ShPaginationDropUp
                shComponent={props.shComponent}
                anchorOrigin={props.anchorOrigin}
                transformOrigin={props.transformOrigin}
                id={popoverId}
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                onChange={handleChange}
                selected={selected}
                maxHeight={props.maxHeight}
                styles={props.styles}
                amount={props.amount}
                min={props.min}
                disallowDeselection={props.disallowDeselection ?? null}
                // enableSearch={props.enableSearch}
            />
        </>
    );
}

const ShPaginationDropUp = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    
    const switchSelected = (selected) => {
        
        props.onChange(selected);
    };

    const isSelected = (index) => {
        return props.selected === index;
        
    };

    // Memoized function to get the background color based on the index and selection status
    const getBackground = useCallback(
        (index) => {
            if (isSelected(index)) {
                return theme.palette.mode === 'dark' ? colors.blue[100] : colors.primary[100];
            }
            return theme.palette.mode === 'dark' ? colors.grey[300] : '#fff';
        },
        [theme,props.selected] // Dependencies array includes theme since it's used inside the callback
    );

    // Memoized function to get the text color based on the index and selection status
    const getColor = useCallback(
        (index) => {
            if (isSelected(index)) {
                return theme.palette.mode === 'dark' ? colors.blue[400] : colors.primary[400];
            }
            return colors.txt.primary;
        },
        [theme,props.selected] // Dependencies array includes theme since it's used inside the callback
    );

    // Define the props you want to exclude
    const excludedProps = ['noSelection','closeOnSelection','toggleDragAndDrop','disallowDeselection', 'describedBy'];


    // Programmatically filter out the props
    const filteredProps = useMemo(() => Object.keys(props).reduce((acc, key) => {
        if (!excludedProps.includes(key)) {
            // console.log("key",key)
            acc[key] = props[key];
        }
        return acc;
    }, {}),[props]);

    const handleSelect = useCallback((index) => {
        switchSelected(index);
    }, [switchSelected]); // Include any dependencies if necessary

    

    const optionsList = useMemo(() => {
        const list = [];
        
        for (let index = props.min ?? 1; index < props.amount; index++) {
            list.push(
                <Button
                    key={"dd"+index}
                    onClick={() => handleSelect(index)}
                    TouchRippleProps={{ 
                        style: { 
                            color: theme.palette.mode === "dark" ? "rgba(255,255,255,.5)" : 'rgba(49, 65, 95, 0.5)' 
                        } 
                    }} // Directly styling the ripple here
                    variant='contained'
                    sx={{
                        background:getBackground(index),
                        color: getColor(index),
                        width:1,
                        height:32,
                        flexShrink:0,
                        borderRadius:"8px",
                        padding:0,
                        minWidth:0,
                        paddingX:1.5,
                        display: "flex",
                        alignItems:"center",
                        justifyContent:"flex-start",
                        textTransform:"none",
                        minWidth:150,
                        boxShadow: "none",
                        opacity:1,
                        "&:hover" : {
                            transition:"250ms",
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"],
                            boxShadow:"none"
                        },
                    }}
                >
                    {index}

                </Button>
            );
        }
        return list;
    }, [props.amount,props.selected,props.min]); // Dependencies for memoization

    return (
        
        <PopoverModal
            {...filteredProps}
            triangle={{position:"center",color:theme.palette.mode === "dark" ? colors.grey[300] : "#fff"}}
        >
            <Box 
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[300] : "#fff",
                    padding:1.5,
                    paddingBottom: isStandAlone ? 4 : 1.5,
                    borderRadius:"16px",
                    borderBottomLeftRadius:0,
                    borderBottomRightRadius:0,
                    "@media screen and (max-width: 48em)" : {
                        borderRadius:"32px",
                        borderTopLeftRadius:"12px",
                        borderTopRightRadius:"12px",
                        borderBottomLeftRadius:0,
                        borderBottomRightRadius:0,

                    }
                }}
            >
                
                <Box
                    sx={{
                        minWidth: props.styles?.minWidth ?? null,
                        maxHeight:props.maxHeight || 300,
                        display:"flex",
                        gap:1,
                        flexDirection:"column",
                        overflowY:"auto",
                        alignItems:'center',
                    }}
                >

                   {optionsList}
                   
                </Box>

                
            </Box>

        </PopoverModal>
    );
}

export default ShPaginationDropUp;