import { Box } from "@mui/material"

const BottomLeft = (props) => {
    return (
        <Box
            sx={{
                position: "absolute",
                bottom:0,
                left:0,
                display:"flex"
            }}
        >
            {props.children}
        </Box>
    )
}

export default BottomLeft;