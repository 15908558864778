import { Box, useTheme } from "@mui/material"
import { tokens } from "../../../theme"

const SidepanelHeaderActiongroup = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    return (

        <Box
            sx={{
                "@media screen and (max-width: 63.9375em)":{
                    position:"absolute",
                    bottom:"0px",
                    left:"0px",
                    width:"fit-content",
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    paddingRight:3,
                    zIndex:1050,
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    width:1,
                    justifyContent:"space-between",
                },
                "@media screen and (max-width: 47.9375em)" : {
                    width:1
                }
            }}
        >
            {props.children}
    </Box>
    )
}

export default SidepanelHeaderActiongroup;