import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import Text from "../../../../theme/text/Text";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../../utils";
import { useGetStockMutationsQuery } from "../../../../../newapi/warehouse/stockSlice";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import { convertDisplayNameStockMutation } from "../../../../../utils/logs";
import StockMutationCard from "../../../../theme/cards/stock/StockMutationCard";

const GridActions = (props) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        </Box>
    )
}

const gridOptions = {
    export:false,
    search: false,
    isSidepanel: true,
};

const StockMutations = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [config, setConfig] = useState({productId: props.data.id});

    useEffect(() => {
        setConfig({productId: props.data.id});
    },[props.data])
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
        }
    },[]);
    
    

    const columns = [
        {
            field: "createdAt", 
            headerName: t('created_on'),
            flex:1,
            renderCell : (cellValues) => {
                const {date, time} = getTranslatedDateAndTime(cellValues.row.createdAt, i18n);
                return (
                    <>
                        <Text semibold>{date}</Text>
                        &nbsp;&nbsp;
                        <Text light>{time}</Text>
                    </>
                )
            }
        },
        {
            field: "mutationType", 
            headerName: t('mutation_type'),
            flex:1,
            renderCell : (cellValues) => {
                return t(cellValues.row.type);
            }
        },
        {
            field: "stockType", 
            headerName: t('stock_type'),
            flex:1,
            renderCell : (cellValues) => {
                return t(cellValues.row.column);
            }
        },
        {
            field: "mutation", 
            headerName: t('mutation'),
            flex:1,
            renderCell : (cellValues) => {
                return cellValues.row.mutation;
            }
        },
        {
            field: "by", 
            headerName: t('by'),
            flex:1,
            renderCell : (cellValues) => {
                return convertDisplayNameStockMutation(cellValues.row, t);
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            // hide: true,
            renderCell: (params) => {
                // console.log(cellValues.row);
                return (
                    <StockMutationCard skeleton={params.row.skeleton ?? false} {...params.row}/>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >

                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetStockMutationsQuery}
                config={config}
                gridActions={<GridActions></GridActions>} 
                title={t('overview')} 
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>
        </Box>
    );
}

export default StockMutations;